import React, { useEffect } from "react";

const TweetIframe = ({ tweeturl }) => {
  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, []);

  return (
    <div className="twitter-container">
      <blockquote className="twitter-tweet">
        <a href={tweeturl}></a>
      </blockquote>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      ></script>
    </div>
  );
};

export default TweetIframe;
