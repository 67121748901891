import React, { useEffect, useRef, useState } from "react";
import { Layout } from "antd";
import { HStack, VStack } from "native-base";
import HeaderBar from "../components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { retrieveItem, storeItem } from "../../helpers/functions";
import Footer from "../pages/footer";
import SearchBar from "../components/headerbar/search_bar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LayoutOptions from "./layout_options";
import { UpOutlined } from "@ant-design/icons";
import BellIcon from "../components/landing_page/bell_icon";

const { Content } = Layout;

const CustomLayout = ({ children }) => {
  const is_logged_in = useCheckLogin();
  const [search_open, set_search_open] = useState(false);
  const [search_string_value, set_search_string_value] = useState("");
  const top_ref = useRef(null);
  const history = useHistory();
  const [show_scroll_icon, set_show_scroll_icon] = useState(false);
  const [handle_logout, set_handle_logout] = useState("");
  const layout_ref = useRef();

  // // const dispatch = useDispatch();
  // const { items: roles_items } = useDynamicSelector("get_roles");

  // const get_all_system_role_list = () => {
  //   let key = [{ key: "get_roles", loading: true }];
  //   let query = query_get_system_role_list;
  //   dispatch(dynamic_request(key, query));
  // };
  // useEffect(() => {
  //   get_all_system_role_list();
  // }, []);

  // const system_user_sub_menu = roles_items?.map((role) => ({
  //   key: role?.name,
  //   icon: <BiSolidUserDetail />,
  //   label: role?.name,
  //   url: `${ROUTES.SYSTEM_USERS}/${role?.name}`,
  // }));

  // {
  //   key: "Dashboard User Dashboard",
  //   url: ROUTES.DASHBOARD,
  //   accessKey: "Dashboard User Dashboard",
  // },

  // useEffect(() => {
  //   if (is_logged_in === false) {
  //     navigate(ROUTES.HOME);
  //   }
  // }, [is_logged_in]);

  const handle_scroll = () => {
    const scroll_to_top = top_ref?.current.scrollTop;
    const scroll_threshold = 800;
    set_show_scroll_icon(scroll_to_top > scroll_threshold);
  };

  useEffect(() => {
    const scroll_element = top_ref?.current;
    if (scroll_element) {
      scroll_element.addEventListener("scroll", handle_scroll);
      return () => {
        scroll_element.removeEventListener("scroll", handle_scroll);
      };
    }
  }, []);

  const handle_scroll_to_top = () => {
    const element = top_ref.current;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (search_string_value) {
      const queryParams = new URLSearchParams();
      queryParams.set("type", search_string_value);
      history.push({
        pathname: "/search-results",
        search: `?${queryParams.toString()}`,
      });
    }
  }, [search_string_value]);

  useEffect(() => {
    const un_listen = history.listen((location, action) => {
      if (action === "PUSH" && search_open) {
        set_search_open(false);
      }
    });
    return () => {
      un_listen();
    };
  }, [history, search_open]);

  return (
    // <Layout ref={layout_ref}>
    //   <LayoutOptions handle_logout={handle_logout} />
    //   <HeaderBar
    //     set_search_open={set_search_open}
    //     search_open={search_open}
    //     set_search_string_value={set_search_string_value}
    //     set_handle_logout={set_handle_logout}
    //   />
    //   {search_open && (
    //     <div className="mobile_search_bar">
    //       <SearchBar set_search_string_value={set_search_string_value} />
    //     </div>
    //   )}

    //   <HStack flex={1}>
    //     <VStack flex={1}>
    //       <Content
    //         style={{ overflowY: "auto", backgroundColor: "white" }}
    //         ref={top_ref}
    //       >
    <div>
      {children}
    </div>
    //         <Footer on_scroll_top={handle_scroll_to_top} />
    //       </Content>
    //     </VStack>
    //   </HStack>

    //   <div style={{ backgroundColor: "pink" }}>
    //     <BellIcon />
    //   </div>

    //   {show_scroll_icon && (
    //     <div
    //       onClick={handle_scroll_to_top}
    //       style={{
    //         position: "fixed",
    //         bottom: "20px",
    //         right: "20px",
    //         backgroundColor: "#1890ff",
    //         color: "white",
    //         borderRadius: "50%",
    //         width: "40px",
    //         height: "40px",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <UpOutlined style={{ fontSize: "20px" }} />
    //     </div>
    //   )}
    // </Layout>
  );
};
export default CustomLayout;
