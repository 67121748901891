import { Form, Input, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_request,
  query_get_all_category_tag,
  useDynamicSelector,
} from "../../../services/redux";
import { retrieveItem } from "../../../helpers/functions";
import { IoOptionsOutline } from "react-icons/io5";

const CategoriesListSelector = (props) => {
  const { set_category_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");

  const { items: category_list_selector } = useDynamicSelector(
    "get_all_category_tag"
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (selectedCategory) {
      set_category_id(selectedCategory.id);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRadioChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const hover_effect = (e) => {
    e.target.style.fontWeight = "bold";
    e.target.style.color = "#00adee";
  };

  const get_category_list_selector = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category_tag;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_category_list_selector();
  }, []);

  return (
    <>
      <Form.Item name="category_list">
        <div onClick={showModal} onMouseEnter={hover_effect}>
          <Input
            readOnly
            value={
              selectedCategory ? selectedCategory.name : t("select_category")
            }
            style={{ color: selectedCategory ? "#1890ff" : "black" }}
            suffix={
              <IoOptionsOutline
                style={{
                  cursor: "pointer",
                  color: selectedCategory ? "#1890ff" : "black",
                }}
              />
            }
          />
        </div>
      </Form.Item>
      <Modal
        title={t("select_category")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Radio.Group
          onChange={handleRadioChange}
          value={selectedCategory}
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {category_list_selector?.map((option, index) => (
            <div
              key={option.id}
              style={{
                width: "50%",
                padding: "8px",
                boxSizing: "border-box",
              }}
            >
              <Radio
                value={option}
                style={{
                  display: "block",
                  width: "100%",
                  color:
                    selectedCategory && selectedCategory.id === option.id
                      ? "#1890ff"
                      : "black",
                }}
              >
                {option.name}
              </Radio>
            </div>
          ))}
        </Radio.Group>
      </Modal>
    </>
  );
};

export default CategoriesListSelector;
