import { Col, Image, Row, Skeleton } from "antd";
import React from "react";
import { ROUTES } from "../../routes/my_routes";
import { capitalize } from "lodash";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomText from "../custom/custom_text";
import { split_tamil_english_words } from "../../../helpers/functions";
import moment from "moment";

const CustomFactCheckRectangleSmallCard = (props) => {
  const {
    src,
    date,
    title,
    height,
    name,
    loading,
    perma_link,
    type,
    img_height,
    img_width,
  } = props;
  const history = useHistory();
  const { is_dark_mode } = useDarkMode();

  const on_click = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton.Input style={{ height: "200px", marginTop: "10px" }} active />
      ) : (
        <Row
          className="fact_check_rectangle_card"
          style={{
            height: height ? height : "13.4vh",
            display: "flex",
            boxShadow: "10px 10px 10px rgba(0, 101, 204, 0.1)",
            cursor: "pointer",
            flexWrap: "nowrap",
            backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF",
          }}
          onClick={on_click}
        >
          <Col className="card_hidden">
            <Image
              className="rectangle_small_card_image"
              style={{
                width: img_width ? img_width : "25vh",
                height: img_height ? img_height : "13.4vh",
              }}
              visible={false}
              preview={false}
              src={src}
            />
          </Col>
          <Col className="fact_check_rectangle_content">
            <div style={{ margin: "10px 5px 0 15px" }}>
              <Row
                className="rectangle_small_card_first_half"
                style={{ marginTop: "5px" }}
              >
                <CustomText
                  class_name={"fact_check_rectangle_news "}
                  text={split_tamil_english_words(title, _, 30)}
                  size={"12px"}
                  weight={"600"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </Row>
              <Row className="rectangle_small_card_second_half">
                <CustomText
                  class_name={"fact_check_rectangle_name"}
                  text={`${capitalize(name)} | ${moment(date).format(
                    "MMM DD, YYYY"
                  )}`}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CustomFactCheckRectangleSmallCard;
