import React from "react";
import CustomText from "../custom/custom_text";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";

const PartyWiseRecentMiddleCard = ({ list, on_click }) => {
  return (
    <div className="part-wise-stories-content">
      {list?.map((article) => (
        <div
          className="fact-check-small-card-container"

          // onClick={on_click}
        >
          <div style={{ flex: "0 1 0%" }}>
            <img
              style={{
                height: "100%",
              }}
              src={article?.thumbnail_img}
            />
          </div>
          <div
            className="fact_check_rectangle_content"
            style={{
              flex: 1,
            }}
          >
            <div className="fact-check-mini-content-container">
              <div>
                <CustomText
                  class_name={"fact_check_rectangle_name"}
                  text={`YouTurn Editorial | ${
                    article
                      ? moment(article.created_date_time).format(`MMM D,YYYY`)
                      : ""
                  }`}
                  size={"12px"}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </div>
              <div>
                <CustomText
                  class_name={"fact_check_rectangle_news"}
                  text={
                    // article?.title?.split(" ").length > 5
                    //     ? article?.title?.split(" ")?.slice(0, 5)?.join(" ") + "..."
                    //     : article?.title
                    article?.subtitle?.slice(
                      0,
                      isMobile ? 70 : isTablet ? 80 : 60
                    ) + "..."
                  }
                  size={"12px"}
                  // color={"#61666D"}
                  weight={"600"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PartyWiseRecentMiddleCard;
