import React from "react";
import CustomBreadCrumbs from "../../custom/custom_breadcrumbs";
import CustomText from "../../custom/custom_text";
import CustomFactCheckCardFirstHalf from "../../custom/custom_fact_check_card_first_half";
import CustomFactCheckText from "../../custom/custom_fact_check_text";
import {
  extractTextFromPTags,
  split_tamil_english_words,
} from "../../../../helpers/functions";
import { useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

const FactCheckFirstHalfContent = () => {
  const { t } = useTranslation();
  const { items: first_half_items, loading: first_half_loading } =
    useDynamicSelector("get_all_fact_check_first_half");
  return (
    <div>
      <div className="fact-check-second-div">
        <div className="fact-check-third-div_dublicate">
          <div style={{ width: "90%" }} className="fact-check-third-div">
            <div className="bread-crumb-div">
              <div className="mobile_bread_cream">
                <CustomBreadCrumbs pages={"Home/Fact check"} />
              </div>
              <div style={{ marginTop: "2vh" }} className="fact_check_heading">
                <CustomText
                  class_name={"fact_check_title_in_mobile"}
                  text={t("fact_check")}
                  size={"25px"}
                  font_family={"Kufam"}
                  color={"#FFFFFF"}
                  align={"left"}
                  line_height={"5vh"}
                />
              </div>
            </div>

            <div className="fact_check_card_mobile_view">
              <div className="fact_check_first_half_second_div">
                <CustomFactCheckCardFirstHalf
                  image={
                    first_half_items ? first_half_items[0]?.thumbnail_img : ""
                  }
                  margin_top={"40vh"}
                  text={
                    first_half_items
                      ? split_tamil_english_words(
                          first_half_items?.[0]?.title,
                          _,
                          100
                        )
                      : ""
                  }
                  views={first_half_items ? first_half_items[0]?.views : ""}
                  // date={
                  //   first_half_items
                  //     ? moment(
                  //         first_half_items[0]?.created_date_time ||
                  //           published_date_time
                  //       ).format("MMM DD, YYYY")
                  //     : ""
                  // }
                  date={
                    first_half_items
                      ? moment(
                          first_half_items[0]?.published_date_time
                            ? parseInt(
                                first_half_items[0]?.published_date_time,
                                10
                              )
                            : first_half_items[0]?.created_date_time
                        ).format(`MMM DD, YYYY`)
                      : ""
                  }
                  id={first_half_items ? first_half_items[0]?.id : ""}
                  perma_link={
                    first_half_items ? first_half_items[0]?.perma_link : ""
                  }
                  fact_check_list_loading={first_half_loading}
                  card_width={"112vh"}
                  card_height={"65vh"}
                  name={
                    first_half_items
                      ? first_half_items[0]?.staff_profile?.name ||
                        "YouTurn Editorial"
                      : ""
                  }
                  class_name={"fact_check_top_image"}
                  class_name_title_card={"custom-fact-check-card-first-half"}
                />
                <CustomFactCheckText
                  headline={
                    first_half_items && first_half_items[0]
                      ? split_tamil_english_words(
                          first_half_items[0].title,
                          "_",
                          100
                        )
                      : ""
                  }
                  content={
                    first_half_items && first_half_items[0]?.subtitle
                      ? first_half_items[0].subtitle
                      : extractTextFromPTags(
                          first_half_items && first_half_items[0]
                            ? first_half_items[0].content
                            : ""
                        )
                  }
                  type={"fact-check"}
                  id={
                    first_half_items && first_half_items[0]
                      ? first_half_items[0].perma_link
                      : ""
                  }
                  fact_check_list_loading={first_half_loading}
                  marginTop={""}
                  size={34}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactCheckFirstHalfContent;
