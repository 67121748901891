import React, { useEffect } from "react";
import CustomText from "../custom/custom_text";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import { query_get_my_all_claims } from "../../../services/redux/slices/graphql/graphql_user_profile";
import { Divider } from "antd";
import { NO_DATA_IMAGE } from "../../../helpers/image_constants";
import { useDarkMode } from "../contexts/dark_mode_provider";
import LastFourClaims from "./user_claim_card/last_four_claims";

const UserProfileClaimDetails = (props) => {
  const { card_title } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const { is_dark_mode } = useDarkMode();

  const { items: my_claims } = useDynamicSelector("get_all_my_user_claims");
  const last_four_claims = my_claims?.slice(0, 4);

  useEffect(() => {
    if (session_id) {
      get_all_my_claims();
    }
  }, [session_id]);

  const get_all_my_claims = () => {
    let key = [{ key: "get_all_my_user_claims", loading: true }];
    let query = query_get_my_all_claims;
    dispatch(dynamic_request(key, query));
  };

  const on_click_claim = () => {
    history.push(`${ROUTES.USER_PROFILE_CLAIM_LIST}`);
  };

  return (
    <div
      className="user_profile_book_mark_card"
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
    >
      <div className="profile_card_title_with_icon">
        <CustomText
          text={card_title}
          font_family={"Lato"}
          size={"13px"}
          color={"#140F2D"}
          align={"left"}
          weight={"700"}
        />

        {my_claims && my_claims?.length > 0 && (
          <CustomText
            text={"View all"}
            font_family={"Lato"}
            size={"12px"}
            color={"#140F2D"}
            align={"left"}
            weight={"600"}
            is_link={true}
            on_click={on_click_claim}
          />
        )}
      </div>

      <Divider className="user_profile_divider" />

      {my_claims && my_claims?.length > 0 ? (
        <>
          <LastFourClaims last_four_claims={last_four_claims} />
        </>
      ) : (
        <div className="user_profile_no_data_image">
          <img width={90} src={NO_DATA_IMAGE} style={{ objectFit: "cover" }} />
        </div>
      )}
    </div>
  );
};
export default UserProfileClaimDetails;
