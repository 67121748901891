import React, { useEffect } from "react";
import { Image, Popover, Row, Skeleton } from "antd";
import {
  REPORT_ICON_WHITE,
  SAVE_WHITE_FILLED,
  SHARE_NEW,
} from "../../../helpers/image_constants";

import CustomText from "../../components/custom/custom_text";
import AuthorNameAndImage from "../../components/article_preview/author_name_and_image";
import { query_get_article_preview } from "../../../services/redux/slices/graphql/article_preview_graphql";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDarkMode } from "../../components/contexts/dark_mode_provider";
import ArticleContent from "../../components/article_preview/article_content";
import { useTranslation } from "react-i18next";
import SingleArticleBackGroundContainer from "../../components/article_preview/article_preview/preview_single_article_back_ground_container";

const ArticlePreview = () => {
  const {
    title,
    thumbnail_img,
    loading: article_loading,
  } = useDynamicSelector("get_article");
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();
  const queryParams = new URLSearchParams(window.location.search);
  const articleId = queryParams.get("article_id");

  const get_one_article = () => {
    let key = [{ key: "get_article", loading: true }];
    let query = query_get_article_preview;
    let variables = {
      id: articleId,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_one_article();
  }, [location]);
  return (
    <div
      className={is_dark_mode ? "content-dark" : ""}
      style={{ overflow: "scroll" }}
    >
      <SingleArticleBackGroundContainer />
      <ArticleContent />
    </div>
  );
};

export default ArticlePreview;
