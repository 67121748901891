import { useRef, useState, useEffect } from "react";
import { retrieveItem } from "./functions";

export function useCheckLogin() {
  // const [is_logged_in, setIsLoggedIn] = useState(null);
  let is_logged_in = null;

  // useEffect(() => {
  // const checkIsLoggedIn = (event) => {
  let session_id = retrieveItem("session_id");
  try {
    if (session_id) {
      is_logged_in = true;

      // setIsLoggedIn(true);
    } else {
      is_logged_in = false;

      // setIsLoggedIn(false);
    }
  } catch (e) {
    is_logged_in = false;

    // setIsLoggedIn(false);
  }
  // };

  // window.addEventListener("session_id", checkIsLoggedIn);
  // checkIsLoggedIn();
  // });
  return is_logged_in;
}

export const usePageAccess = (key) => {
  let hasAccess = null;
  let page_accesses = retrieveItem("page_accesses");

  try {
    let pageAccess = page_accesses.find((x) => x === key);
    if (pageAccess) {
      hasAccess = true;
    } else {
      hasAccess = false;
    }
  } catch (e) {
    hasAccess = false;
  }
  return hasAccess;
};

export function usePageComponentAccess(key) {
  const [hasAccess, setHasAccess] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let pageComponentAccess = user.PageComponentAccess.find(
        (x) => x.Name === key
      );
      if (pageComponentAccess) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    } catch (e) {
      setHasAccess(false);
    }
  });
  return hasAccess;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowSize() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useParentSize(ref) {
  const [parentSize, setParentSize] = useState({
    parentWidth: undefined,
    parentHeight: undefined,
  });
  useEffect(() => {
    if (ref?.current?.parentNode) {
      setParentSize({
        parentWidth: ref.current.parentNode.clientWidth,
        parentHeight: ref.current.parentNode.clientHeight,
      });
    }
    return () => true;
  }, []);
  return parentSize;
}

// export function useComponentSize(ref) {
//   const initialState = { width: 0, height: 0 };
//   const [dimensions, setDimensions] = useState(initialState);
//   const resizeObserverRef = useRef(null);

//   useEffect(() => {
//     resizeObserverRef.current = new ResizeObserver((entries = []) => {
//       entries.forEach((entry) => {
//         const { width, height } = entry.contentRect;
//         setDimensions({ width, height });
//       });
//     });
//     if (ref.current) resizeObserverRef.current.observe(ref.current);
//     return () => {
//       if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
//     };
//   }, [ref]);
//   return dimensions;
// }

export function useEventListener(eventName, functionToCall, element) {
  const savedFunction = useRef();

  useEffect(() => {
    savedFunction.current = functionToCall;
  }, [functionToCall]);

  useEffect(() => {
    if (!element) return;
    const eventListener = (event) => savedFunction.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        e.preventDefault();
        el.scrollLeft += e.deltaY;
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, [elRef?.current]);
  return elRef;
}
