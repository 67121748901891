import { Image, Tabs } from "antd";
import React from "react";
import { FILTER_ICON } from "../../../../helpers/image_constants";

const { TabPane } = Tabs;

const SearchResultsArticlesDropDown = (props) => {
  const { set_result_type } = props;

  const handleTabChange = (key) => {
    set_result_type(key);
  };

  return (
    <div className="selector_and_divider">
      <Tabs
        defaultActiveKey="article"
        onChange={handleTabChange}
        className="search_tab custom-tabs custom-active-tab"
      >
        <Tabs.TabPane tab="Articles" key="article"></Tabs.TabPane>
        <Tabs.TabPane tab="Fact Check" key="fact_check"></Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SearchResultsArticlesDropDown;
