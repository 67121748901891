import React, { useEffect, useState } from "react";
import {
  dynamic_clear,
  dynamic_request,
  get_otp_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { Form } from "antd";

import OTPScreen from "../../login_page_otp/otp_screen";
import toast from "react-hot-toast";
import SignUpFormItems from "./sign_up_form_items/sign_up_form_items";

const SignUpMainForm = (props) => {
  const {
    toggle_password_visibility,
    password_visible,
    sign_in,
    mutation_sign_in,
    set_hide_form,
    hide_form,
    otp,
    set_otp,
  } = props;
  const dispatch = useDispatch();
  const [mobile_number, set_mobile_number] = useState();
  const [all_values, set_all_values] = useState();
  const [register_form] = Form.useForm();
  const [form_submitted, set_form_submitted] = useState(true);
  const {
    status: get_otp_status,
    error: get_otp_error,
    loading: get_otp_loading,
  } = useDynamicSelector("get_otp");
  useEffect(() => {
    if (get_otp_status === "Success") {
      set_form_submitted(true);
      set_otp(true);
      set_hide_form(false);
      register_form.resetFields("");
      toast.success("OTP sent to your mobile number");
      dispatch(dynamic_clear("get_otp"));
    } else if (get_otp_status === "Failure") {
      toast.error(get_otp_error.message);
      dispatch(dynamic_clear("get_otp"));
    }
  }, [get_otp_status, get_otp_error]);
  const get_otp_api_call = (values) => {
    let key = [{ key: "get_otp", loading: true }];
    let query = get_otp_query;
    let variables = {
      mobile: values.mobile,
      email: values.email,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const on_finish = (values) => {
    set_all_values(values);
    set_mobile_number(values?.mobile);
    get_otp_api_call(values);
  };
  const handle_key_press = (event) => {
    const charCode = event.charCode || event.keyCode;
    if (
      !/[0-9]/.test(String.fromCharCode(charCode)) &&
      charCode !== 8 &&
      charCode !== 9 &&
      charCode !== 13
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className="form-container sign-up">
      {form_submitted && (
        <Form onFinish={on_finish} layout="vertical" form={register_form}>
          <SignUpFormItems
            hide_form={hide_form}
            handle_key_press={handle_key_press}
            password_visible={password_visible}
            toggle_password_visibility={toggle_password_visibility}
            get_otp_loading={get_otp_loading}
            sign_in={sign_in}
          />
        </Form>
      )}

      {otp && (
        <div style={{ marginBottom: "100%" }} className="otp_form">
          <OTPScreen
            mobile_number={mobile_number}
            mutation_sign_in={mutation_sign_in}
            all_values={all_values}
            get_otp_api_call={get_otp_api_call}
          />
        </div>
      )}
    </div>
  );
};

export default SignUpMainForm;
