import { HStack, VStack } from "native-base";
import React from "react";
import PageTitle from "../../components/system/page_title/page_title";
import ArticleHistoryList from "../../components/admin/admin_article_history/article_history_list";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AdminArticleHistory = () => {
  const location = useLocation();
  const article_id = location?.props_state?.record?.id;
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Article History"} />
      </HStack>
      <VStack padding={"40px"}>
        <ArticleHistoryList article_id={article_id} />
      </VStack>
    </div>
  );
};

export default AdminArticleHistory;
