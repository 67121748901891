import { Button, Col, Space, Typography } from "antd";
import React from "react";

const CustomButton = (props) => {
  const {
    id,
    name,
    on_Click,
    width,
    height,
    icon_back,
    icon_front,
    backgroundColor,
    border,
    color,
    custom_padding,
    justify_Content,
    max_width,
    minWidth,
    is_submit,
    value,
    loading,
    border_radius,
    class_name,
    is_disabled,
    cursor,
    is_disabled_button,
  } = props;
  return (
    <Col>
      <Button
        id={id}
        loading={loading}
        className={`custom-button ${class_name} ${
          is_disabled && "custom-button-disable"
        } ${is_disabled_button && "donation-button-disable"}`}
        style={{
          width: width ? width : "100%",
          maxWidth: max_width ? max_width : "290px",
          minWidth: minWidth ? minWidth : "150px",
          color: color ? color : "white",
          height: height ? height : "40px",
          backgroundColor: backgroundColor ? backgroundColor : "#00adee",
          border: border ? border : "none",
          padding: custom_padding ? custom_padding : "8px",
          borderRadius: border_radius ? border_radius : "6px",
          cursor: cursor ? cursor : "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: justify_Content ? justify_Content : "center",
          textAlign: "center",
        }}
        onClick={on_Click}
        htmlType={is_submit ? "submit" : ""}
      >
        {/* <div className="flex-center m-auto">
          <>
            <Space className="flex-center m-auto">
              {icon_front && icon_front}
            </Space>
          </> */}
        {value ? `${name} ₹${value}` : name}
        {/* <>
            <Space className="flex-center m-auto">
              {icon_back && icon_back}
            </Space>
          </>
        </div> */}
      </Button>
    </Col>
  );
};

export default CustomButton;
