import { Card, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircle, IoMdClose } from "react-icons/io";
import CustomText from "../../custom/custom_text";
import {
  PREMIUM_BADGE,
  PROFILE2,
  YOUTUBE_LOGO,
  YOUTURN_NEW_LOGO,
  YOU_TURN_LOGO,
} from "../../../../helpers/image_constants";
import {
  dynamic_request,
  query_get_back_access_user,
  query_get_one_previleged_access_to_user,
  useDynamicSelector,
} from "../../../../services/redux";
import { FaHandHoldingHeart, FaKey, FaUser } from "react-icons/fa";
import { FaFilePen, FaRegFileLines } from "react-icons/fa6";
import { width } from "styled-system";
import { IoCall } from "react-icons/io5";
import { TbMailFilled, TbSocial } from "react-icons/tb";
import DonationList from "./donation_list";
import {
  query_get_dash_board_count,
  query_get_user_donation_list,
} from "../../../../services/redux/slices/graphql/graphql_user_profile";
import { useDispatch } from "react-redux";
import { quey_get_user_profile } from "../../../../services/redux/slices/graphql/graphql_file_upload";
import AdminUserProfileClaim from "../admin_user_profile_details/admin_user_profile_claim";
import AdminUserProfileDonations from "../admin_user_profile_details/admin_user_profile_donations";
import AdminUserContributions from "../admin_user_profile_details/admin_user_contributions";
import { PiLockOpenFill } from "react-icons/pi";
import toast from "react-hot-toast";

const { TabPane } = Tabs;
const UserDetailsModal = (props) => {
  const { set_modal_open, modal_open, user_id } = props;
  const [type, set_type] = useState("profile");
  const dispatch = useDispatch();

  const user_details = useDynamicSelector("get_user");

  const { status: access_status } = useDynamicSelector(
    "get_one_give_previleged_access_to_user"
  );

  const { status: get_back_status } = useDynamicSelector(
    "get_back_access_for_user"
  );

  const [user_role, set_user_role] = useState(user_details?.roles?.[0]?.name);

  const { article_count, fact_check_count } = useDynamicSelector(
    "get_dashboard_count"
  );

  useEffect(() => {
    if (user_id) {
      get_user_profile();
      get_all_dash_board_count();
    }
  }, [user_id]);

  const get_user_profile = () => {
    let key = [{ key: "get_user", loading: true }];
    let query = quey_get_user_profile;
    let variables = {
      id: user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_privileged_access_user = () => {
    let key = [
      { key: "get_one_give_previleged_access_to_user", loading: true },
    ];
    let query = query_get_one_previleged_access_to_user;
    let variables = {
      user_id: user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_back_access = () => {
    let key = [{ key: "get_back_access_for_user", loading: true }];
    let query = query_get_back_access_user;
    let variables = {
      user_id: user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_dash_board_count = () => {
    let key = [{ key: "get_dashboard_count", loading: true }];
    let query = query_get_dash_board_count;
    let variables = {
      user_id: user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handleTabChange = (key) => {
    set_type(key);
  };
  const on_close = () => {
    set_modal_open(false);
    set_type("profile");
  };

  const on_privileged_access_click = () => {
    get_privileged_access_user();
  };

  const on_privileged_lock_click = () => {
    get_back_access();
  };

  useEffect(() => {
    if (access_status) {
      toast.success("Accessed");
      set_user_role("Previleged User");
    }
  }, [access_status]);

  useEffect(() => {
    if (get_back_status) {
      toast.success("Accessed Removed");
      set_user_role("User");
    }
  }, [get_back_status]);

  return (
    <Modal
      width={"60vw"}
      className="admin-user-profile-modal"
      title={<div className="custom-modal-title">Profile</div>}
      open={user_id ? modal_open : false}
      onCancel={on_close}
      destroyOnClose={true}
      closeIcon={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "16px",
          }}
        >
          <IoIosCloseCircle />
        </span>
      }
      footer={null}
      style={{ textAlign: "center" }}
    >
      <div
        style={{
          height: "15vh",
          backgroundColor: "#140f2d",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div
          style={{
            // backgroundColor: "pink",
            width: "90%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "30%",
              // backgroundColor: "yellow",
              height: "auto",

              height: "15vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "green",
                height: "15vh",
              }}
            >
              <div
                style={{
                  width: "10vw",
                  // backgroundColor: "orange",
                  height: "15vh",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  width={70}
                  height={70}
                  src={
                    user_details?.staff_profile?.profile_pic ||
                    user_details?.user_profile?.profile_pic
                  }
                  style={{ objectFit: "cover", borderRadius: 35 }}
                />
              </div>
              <div
                style={{
                  width: "34vw",
                  // backgroundColor: "wheat",
                  height: "15vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {user_role === "Previleged User" && (
                  <img
                    width={15}
                    height={15}
                    src={PREMIUM_BADGE}
                    alt="Premium Badge"
                    style={{ objectFit: "cover" }}
                  />
                )}

                <CustomText
                  class_name={"user_name_in_donation"}
                  text={
                    user_details?.staff_profile?.name ||
                    user_details?.user_profile?.name
                  }
                  font_family={"Lato"}
                  size={"2vh"}
                  color={"#FFFFFF"}
                  align={"left"}
                  weight={"700"}
                />
                <CustomText
                  class_name={"user_name_in_donation"}
                  text={"Member"}
                  font_family={"Lato"}
                  size={"1.8vh"}
                  color={"#FFFFFF"}
                  align={"left"}
                  weight={"400"}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "70%",
              // backgroundColor: "red",
              height: "auto",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "7vw",
                // backgroundColor: "yellow",
                height: "15vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "15vh",
                  }}
                >
                  <CustomText
                    text={article_count}
                    font_family={"Lato"}
                    size={"2.2vh"}
                    color={"#FFFFFF"}
                    align={"left"}
                    weight={"700"}
                    line_height={"4vh"}
                  />
                  <CustomText
                    text={"Article"}
                    font_family={"Lato"}
                    size={"2vh"}
                    color={"#FFFFFF"}
                    align={"left"}
                    weight={"400"}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "7vw",
                // backgroundColor: "violet",
                height: "15vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "15vh",
                  }}
                >
                  <CustomText
                    text={fact_check_count}
                    font_family={"Lato"}
                    size={"2.2vh"}
                    color={"#FFFFFF"}
                    align={"left"}
                    weight={"700"}
                    line_height={"4vh"}
                  />
                  <CustomText
                    text={"Factcheck"}
                    font_family={"Lato"}
                    size={"2vh"}
                    color={"#FFFFFF"}
                    align={"left"}
                    weight={"400"}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "25vw",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {user_role === "Previleged User" ? (
                <PiLockOpenFill
                  className="privileged-access-icon"
                  onClick={on_privileged_lock_click}
                />
              ) : (
                <FaKey
                  className="privileged-access-icon"
                  onClick={on_privileged_access_click}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "8vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "90%" }}>
          <Tabs onChange={handleTabChange}>
            <TabPane
              tab={
                <span>
                  <FaUser style={{ marginRight: "6px" }} />
                  Profile
                </span>
              }
              key="profile"
            ></TabPane>
            <TabPane
              tab={
                <span>
                  <FaRegFileLines style={{ marginRight: "6px" }} />
                  Contributions
                </span>
              }
              key="contribution"
            ></TabPane>
            <TabPane
              tab={
                <span>
                  <FaFilePen style={{ marginRight: "6px" }} />
                  Claims
                </span>
              }
              key="claim"
            ></TabPane>
            <TabPane
              tab={
                <span>
                  <FaHandHoldingHeart style={{ marginRight: "6px" }} />
                  Donations
                </span>
              }
              key="donations"
            ></TabPane>
          </Tabs>
        </div>
      </div>

      <div
        style={{
          height: "30vh",
        }}
      >
        {type === "profile" && (
          <>
            <div
              style={{
                height: "7vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <CustomText
                  text={"About Me"}
                  font_family={"Lato"}
                  size={"2.2vh"}
                  color={"black"}
                  align={"left"}
                  weight={"700"}
                  line_height={"4vh"}
                />
              </div>
            </div>

            <div
              style={{
                // height: "13vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "90%",
                }}
              >
                <div
                  style={{
                    width: "70%",
                  }}
                >
                  <CustomText
                    text={
                      user_details?.staff_profile?.about ||
                      user_details?.user_profile?.about
                    }
                    font_family={"Lato"}
                    color={"black"}
                    align={"left"}
                    line_height={"4vh"}
                    weight={400}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                height: "12vh",
                // backgroundColor: "gray",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: "12vh",
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <IoCall />
                  <CustomText
                    text={
                      user_details?.staff_profile?.mobile ||
                      user_details?.user_profile?.mobile
                    }
                    font_family={"Lato"}
                    class_name={"admin-user-profile-details"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TbMailFilled />
                  <CustomText
                    text={
                      user_details?.staff_profile?.email ||
                      user_details?.user_profile?.email
                    }
                    font_family={"Lato"}
                    class_name={"admin-user-profile-details"}
                  />
                </div>
                {/* <div
                  style={{
                    display: "flex",
                  }}
                >
                  <TbSocial />
                  <CustomText
                    text={
                      user_details?.staff_profile?.url ||
                      user_details?.user_profile?.url ||
                      "-"
                    }
                    font_family={"Lato"}
                    class_name={"admin-user-profile-details"}
                  />
                </div> */}
              </div>
            </div>
          </>
        )}

        {type === "donations" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "90%" }}>
              <AdminUserProfileDonations type={type} user_id={user_id} />
            </div>
          </div>
        )}

        {type === "claim" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "90%" }}>
              <AdminUserProfileClaim user_id={user_id} type={type} />
            </div>
          </div>
        )}

        {type === "contribution" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "90%" }}>
              <AdminUserContributions user_id={user_id} type={type} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
