import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import { retrieveItem } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../contexts/dark_mode_provider";

const FactCheckCategories = (props) => {
  const {
    set_category_id,
    set_category_name,
    set_page_limit,
    set_default_category_name,
    default_page_limit,
  } = props;
  const { t } = useTranslation();
  const { items: category_list } = useDynamicSelector("get_all_category_tag");
  const [categories_more, set_categories_more] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [category_key, set_category_key] = useState(t("all"));
  const { is_dark_mode } = useDarkMode();

  useEffect(() => {
    if (category_list) {
      set_categories_more([
        { id: "All", name: t("all") },
        ...category_list?.slice(0, 7),
        { id: "More", name: t("more") },
      ]);
    }
  }, [category_list]);

  const handleClick = (category, category_name) => {
    set_default_category_name(category);
    set_page_limit(6);
    default_page_limit(7);
    if (category !== "More" && category !== "மேலும்") {
      set_category_id(category_name);
    } else if (category === "More" || category === "மேலும்") {
      setShowMore(false);
      set_category_key((pre) => pre);
    }
    if (category !== "More" && category !== "மேலும்") {
      set_category_key(category);
    }
    set_category_name(category);

    if (category === "More" || category === "மேலும்") {
      set_categories_more([
        { id: "All", name: t("all") },
        ...category_list?.slice(0, 20),
        ...(showMore ? [] : [{ id: "More", name: t("more") }]),
      ]);
    }
  };

  return (
    <div className="fact_check_category_tags">
      {categories_more?.map((item) => (
        <span
          key={item.id}
          style={{
            cursor: "pointer",
            padding: "5px 28px 5px 28px",
            border: "1px solid #0065CC",
            borderRadius: "17px",
            boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor:
              category_key === item.name && category_key !== t("more")
                ? "#00adee"
                : "transparent",
            color: is_dark_mode
              ? "#FFFF"
              : category_key === item.name && category_key !== t("more")
              ? "white"
              : "black",
          }}
          onClick={() => handleClick(item?.name, item?.id)}
        >
          {item.name}
        </span>
      ))}
    </div>
  );
};

export default FactCheckCategories;
