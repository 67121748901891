import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Space } from "antd";
import {
  dynamic_clear,
  dynamic_request,
  payment_handler,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { initiate_request_razorpay_payment } from "../../../helpers/functions";
import DonationAmountInput from "./donation_amount_input";
import MobileDonationForm from "./mobile_donation_form";
import CustomText from "../custom/custom_text";
import DonationSmallCardText from "./donation_smal_card_text";
import ConfettiAnimation from "./result";
import { THANK_YOU_IMAGE } from "../../../helpers/image_constants";
import { useTranslation } from "react-i18next";
import UserDetailsForm from "./user_details_main_form_and_form_items/user_details_form";

const DonationCardSmall = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form_for_donate] = Form.useForm();
  const { loading, order_id } = useDynamicSelector("create_donation");
  const [donation_amount, set_donation_amount] = useState(null);
  const [payment_response, set_payment_response] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [user_details_modal_open, set_user_details_modal_open] =
    useState(false);
  const [user_details_values, set_user_details_values] = useState();
  const payment_status = payment_response?.razorpay_signature;

  const donor_name = Form.useWatch("name", form_for_donate);
  const donor_mobile = Form.useWatch("mobile", form_for_donate);
  const donor_address = Form.useWatch("address", form_for_donate);

  useEffect(() => {
    if (payment_status) {
      set_payment_response({});
      setPaymentStatus(true);
      setShowModal(true);
      setTimeout(() => {
        setPaymentStatus(false);
        setShowModal(false);
      }, 10000);
    }
  }, [payment_status]);

  const query_get_order_id = (amount) => {
    const key = [{ key: "create_donation", loading: true }];
    const query = payment_handler;
    const variables = {
      amount: parseInt(amount),
      mobile: String(donor_mobile),
      address: donor_address,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_form_submit = (values) => {
    set_user_details_modal_open(true);
  };
  const user_detail_modal_close = () => {
    set_user_details_modal_open(false);
    form_for_donate.resetFields();
  };
  // const handle_ok = (values) => {
  //   form_for_donate.submit();
  //   console.log("values", values);

  //   query_get_order_id(donation_amount);
  //   if (order_id) {
  //     initiate_request_razorpay_payment(order_id, set_payment_response);
  //   }
  //   dispatch(dynamic_clear("create_donation"));
  //   form_for_donate.resetFields();
  // };
  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(order_id, set_payment_response);
      dispatch(dynamic_clear("create_donation"));
    }
    // if (create_donation_status === "Success") {
    //   form.resetFields();
    // }
  }, [order_id]);
  const handle_ok = (values) => {
    form_for_donate.submit();

    query_get_order_id(donation_amount);
    set_user_details_modal_open(false);
    form_for_donate.resetFields();
  };
  return (
    <Card className="fact_check_donation_card" id="donation-card">
      <Modal
        visible={showModal}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => setShowModal(false)}
      >
        <div style={{ textAlign: "center", height: "400px" }}>
          <h2 style={{ color: "#61443b" }}>Thank You!</h2>
          <h6>We extend our sincere appreciation for your donation.</h6>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",
              height: "170px",
              marginTop: "4vh",
            }}
            src={THANK_YOU_IMAGE}
            alt="Profile"
          />
          <h6 style={{ marginTop: "4vh" }}>
            Together, let's make an impact through your donations. Keep
            supporting us.
          </h6>
          {paymentStatus && <ConfettiAnimation />}{" "}
        </div>
      </Modal>
      <Modal
        title={t("enter_details")}
        width={500}
        open={user_details_modal_open}
        onCancel={user_detail_modal_close}
        // onOk={handle_ok}
        footer={null}
      >
        <UserDetailsForm
          form={form_for_donate}
          set_user_details_values={set_user_details_values}
          handle_ok={handle_ok}
        />
      </Modal>
      <DonationSmallCardText padding={"10px 20px 0 20px"} font_size={"10px"} />
      <Space className="fact_news_title_main_div">
        <CustomText
          text={"#"}
          font_family={"kufam"}
          size={"60px"}
          color={"#f49d36"}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <CustomText
            class_name={"donation-small-card-fight-text"}
            text={t("fight_against")}
            font_family={"kufam"}
            size={"30px"}
            color={"#f49d36"}
            align={"left"}
          />
          <CustomText
            class_name={"donation-small-card-fight-text"}
            text={t("fake_news_text")}
            font_family={"kufam"}
            size={"30px"}
            color={"#f49d36"}
            align={"left"}
          />
        </div>
      </Space>

      <div className="mobile-donation-form">
        <Form form={form} className="donation-form-input">
          <MobileDonationForm set_donation_amount={set_donation_amount} />
        </Form>
      </div>

      <div className="donation-input-div">
        <Form form={form}>
          <DonationAmountInput set_donation_amount={set_donation_amount} />
        </Form>
      </div>
      <Space className="donate_button_fact_check">
        <div className="donate_button_width">
          <Button
            disabled={donation_amount > 0 ? false : true}
            className="donation-custom-button donate_button_height"
            style={{
              borderRadius: "5px",
              height: "40px",
              border: "transparent",
              backgroundColor: "#00adee",
              color: "white",
              width: "100%",
            }}
            onClick={handle_form_submit}
            htmlType="submit"
            loading={loading}
          >
            {t("donate")}
          </Button>
        </div>

        <div className="donation_button_note" style={{ lineHeight: "10px" }}>
          <text
            className="donation-note-text"
            style={{
              color: "gray",
              fontSize: "1.6vh",
              fontFamily: "Lato",
              lineHeight: "3vh",
            }}
          >
            {t("currently_we_accept_only_INR_donations")}
          </text>
        </div>
      </Space>
    </Card>
  );
};

export default DonationCardSmall;
