import React from "react";
import CustomText from "../custom/custom_text";
import { split_tamil_english_words } from "../../../helpers/functions";
import { useDynamicSelector } from "../../../services/redux";

const SingleFactCheckTopTitle = () => {
  const { title } = useDynamicSelector("get_fact_check_by_perma_link");

  return (
    <div className="single-fact-check-div">
      <div className="single-fact-check-title-small-div">
        <CustomText
          class_name={"single-fact-check-title"}
          text={split_tamil_english_words(title, _, 200)}
          font_family={"Kufam"}
          align={"left"}
        />
      </div>
    </div>
  );
};

export default SingleFactCheckTopTitle;
