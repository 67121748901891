import React from "react";
import CustomText from "../../custom/custom_text";

const UserDetailsTitle = () => {
  return (
    <div className="user_profile_details_first_div">
      <CustomText
        text={"Profile"}
        font_family={"Lato"}
        size={"15px"}
        color={"white"}
        align={"left"}
        weight={"700"}
      />
    </div>
  );
};

export default UserDetailsTitle;
