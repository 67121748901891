import React from "react";
import UserContributionTopHalf from "./user_contribution_top_fist_half";

const UserContributionFirstHalf = (props) => {
  const { recently_added_for_user_contribution_loading } = props;
  return (
    <div style={{ height: "86vh" }}>
      <div className="user_contribution">
        <UserContributionTopHalf
          recently_added_for_user_contribution_loading={
            recently_added_for_user_contribution_loading
          }
        />
      </div>
    </div>
  );
};

export default UserContributionFirstHalf;
