import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../routes/my_routes";
import { navigate, navigateWithProps } from "../../../helpers/navigator";
import { HStack, VStack } from "native-base";
import PageTitle from "../system/page_title/page_title";
import { Button, Pagination, Table } from "antd";
import { countFormat, getDefaultLanguageId } from "../../../helpers/constants";
import {
  dynamic_request,
  get_all_my_articles_query,
  useDynamicSelector,
} from "../../../services/redux";
import { get_status, retrieveItem } from "../../../helpers/functions";
import UserProfileTitle from "../user_profile/user_profile_title";
import CustomText from "../custom/custom_text";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { startCase } from "lodash";
import moment from "moment";

const UserArticleList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const { is_dark_mode } = useDarkMode();

  const {
    items: all_article_list,
    loading: all_article_loading,
    pagination: all_article_pagination,
  } = useDynamicSelector("get_all_my_article");

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [language_id, set_language] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );

  useEffect(() => {
    get_my_article_list();
  }, [language_id, page_limit, page_number]);

  const on_click = () => {
    navigate(`${ROUTES.USER_PROFILE_LIST}/my-article`);
  };

  const get_my_article_list = () => {
    let key = [{ key: "get_all_my_article", loading: true }];
    let query = get_all_my_articles_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,

      status: "pending",
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const fetch_data = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const [contribution_type, set_contribution_type] = useState("");
  const handle_row_click = (record) => {
    set_contribution_type("article");
    navigateWithProps(ROUTES.USER_PROFILE_PENDING_FORM, {
      record: { record },
      contribution_type: contribution_type,
    });
  };

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created date",
      key: "created_date_time",
      render: (record) => {
        return moment.utc(record?.created_date_time).format("DD/MM/YYYY");
      },
    },
    {
      title: t("status"),
      key: "status",
      render: (record) => {
        return startCase(record?.status);
      },
    },
  ];
  return (
    <div className={is_dark_mode ? "content-dark" : ""}>
      {/* <CustomText
        class_name={"user_profile_titles"}
        text={"Article"}
        font_family={"Lato"}
        size={"24px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      /> */}

      {/* <HStack justifyContent={"flex-end"}>
        <div style={{ width: "10%" }}>
          <Button
            style={{ backgroundColor: "#0065cc", color: "white" }}
            onClick={on_click}
          >
            Back
          </Button>
        </div>
      </HStack> */}
      <div className="my_article_container">
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={all_article_list}
          pagination={false}
          loading={all_article_loading}
          onRow={(record) => ({
            onClick: () => handle_row_click(record),
          })}
        />

        <HStack justifyContent={"flex-end"} mt={"20px"}>
          <Pagination
            total={all_article_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </HStack>
      </div>
    </div>
  );
};

export default UserArticleList;
