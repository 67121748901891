import React from "react";
import UserContributeText from "../user_contribution/user_contribute_text";
import { Button, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { retrieveItem } from "../../../helpers/functions";
import {
  EMAIL_LOGO,
  FACEBOOK_COLOR,
  TWITTER_X_LOGO_BLACK,
  WHATS_APP_LOGO,
} from "../../../helpers/image_constants";

const CustomUserContributionFactCheck = () => {
  const session_id = retrieveItem("session_id");
  const role = retrieveItem("role");
  const { t } = useTranslation();
  const history = useHistory();

  const handle_become_member = () => {
    history.push(ROUTES.ADMIN_LOGIN);
  };

  const url = process.env.REACT_APP_WEB_URL;

  const content = (
    <div style={{ display: "flex", gap: "10px" }}>
      <a
        href={`whatsapp://send?text=${encodeURIComponent(url)}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={WHATS_APP_LOGO} alt="WhatsApp" style={{ height: "25px" }} />
      </a>

      <a
        href={`https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(
          url
        )}`}
        target="_blank"
      >
        <img src={FACEBOOK_COLOR} alt="launch" style={{ height: "25px" }} />
      </a>

      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          url
        )}`}
        target="_blank"
      >
        <img
          src={TWITTER_X_LOGO_BLACK}
          alt="launch"
          style={{ height: "20px" }}
        />
      </a>
      <a href={`mailto:?body=${encodeURIComponent(url)}`} target="_blank">
        <img src={EMAIL_LOGO} alt="launch" style={{ height: "25px" }} />
      </a>
    </div>
  );

  return (
    <div id="donation-card" className="user-contribute-fact-div">
      <div className="donation-card-small">
        <UserContributeText />

        <div style={{ justifyContent: "start" }}>
          <text className={"donation-main-content"}>#</text>
          <text className="donation-main-content">{t("empower_truth")}</text>
        </div>

        {session_id && (role === "User" || role === "Previleged User") ? (
          <Popover
            placement="top"
            content={content}
            trigger="hover"
            className="share_pop_over"
          >
            <Button
              className="join_us_button_contribute"
              htmlType="submit"
              style={{ backgroundColor: "#00adee", color: "white" }}
            >
              {t("invite_friends")}
            </Button>
          </Popover>
        ) : (
          <div className="join_us_button_contribute_div">
            <Button
              className="join_us_button_contribute"
              htmlType="submit"
              style={{ backgroundColor: "#00adee", color: "white" }}
              onClick={handle_become_member}
            >
              {t("become_member")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomUserContributionFactCheck;
