import React from "react";
import ArticleTopOne from "../article_top_one";
import TopFirstFeaturedArticleInMobile from "../top_first_featured_article_in_mobile";

const TopFirstFeaturedArticle = () => {
  return (
    <div>
      <ArticleTopOne />
      <TopFirstFeaturedArticleInMobile />
    </div>
  );
};

export default TopFirstFeaturedArticle;
