import { Router } from "@views/routes/Router";
import { useEffect } from "react";
import { changeLanguage } from "./i18n";
import { retrieveItem, storeItem } from "./helpers/functions";
import { language } from "./helpers/constants";

function App() {
  let get_session_id = retrieveItem("session_id");
  useEffect(() => {
    let url = window.location.href;
    if (url.includes(process.env.REACT_APP_EN_WEB_URL)) {
      // window.location.reload();
      changeLanguage("en");
      storeItem("language", {
        name: "english",
        id: language?.english_id,
        key: "en",
      });
      // if (get_session_id) {
      //   storeItem("session_id", get_session_id);
      // }
    } else if (url.includes(process.env.REACT_APP_WEB_URL)) {
      // window.location.reload();
      changeLanguage("ta");
      storeItem("language", {
        name: "tamil",
        id: language?.tamil_id,
        key: "ta",
      });
      // if (get_session_id) {
      //   storeItem("session_id", get_session_id);
      // }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return <Router />;
}
export default App;
