import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { retrieveItem, showToast } from "../../helpers/functions";

const BASE_URL = "https://youturn.in/api/dev/graphql";
// const BASE_URL = "https://dev.vertace.org/youturn-sit/api/dev/graphql";
// const BASE_URL = "http://192.168.251.168:6060/dev/graphql";
// const BASE_URL = "http://192.168.1.53:6060/dev/graphql";
// const BASE_URL = "http://192.168.1.35:6060/dev/graphql";

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const session_id = retrieveItem("session_id");
  return {
    headers: {
      ...headers,
      Session_id: session_id ? session_id : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

const handleLogout = async () => {
  showToast({
    type: "error",
    message: `Session Expired`,
  });
  await setTimeout(() => {
    window.localStorage.clear();
    window.location.replace("/login");
  }, 2000);
};

export async function QueryRequest(query, variables, dispatch) {
  try {
    let response = await client.query({
      query,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      handleLogout();
      return "Not authorized";
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    return { req_error: e };
  }
}
export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      handleLogout();
      return "Not authorized";
    }
    return response;
  } catch (e) {
    console.log("Invalid request");
    
    // if (dispatch) {
    //   //  dispatch(logoutRequest("invalid"));
    // }
    return { req_error: e };
  }
}
