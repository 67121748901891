import { Button, Space } from "antd";
import React from "react";

const CustomAddButton = (props) => {
  const {
    type,
    button_name,
    onclick_button,
    icon,
    bg_color,
    disabled,
    rounded,
    is_submit,
    form_id,
    loading,
    id,
  } = props;

  return (
    <Space>
      <Button
        id={id}
        loading={loading}
        form={is_submit ? form_id : ""}
        type={"primary"}
        key={is_submit ? "confirm" : ""}
        htmlType={is_submit ? "submit" : ""}
        // style={{
        //   // backgroundColor: bg_color ? bg_color : "#179093",
        //   border: "none",
        //   // color: bg_color === "white" ? "black" : "white",
        //   display: "flex",
        //   alignItems: "center",
        //   textAlign: "center",
        //   justifyContent: "space-around",
        //   borderRadius: rounded ? "3px" : "0px",
        // }}
        disabled={disabled}
        onClick={onclick_button}
      >
        <span style={{ marginRight: "3px", marginTop: "2px" }}>{icon}</span>
        <span style={{ marginBottom: "2px" }}>
          {button_name ? button_name : ""}
        </span>
      </Button>
    </Space>
  );
};

export default CustomAddButton;
