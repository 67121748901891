import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";
import { VIDEO_ROUND_ICON } from "../../../helpers/image_constants";
import { Col, Image, Row, Skeleton } from "antd";
import { useDynamicSelector } from "../../../services/redux";
import { useTranslation } from "react-i18next";
import {
  format_view_count,
  split_tamil_english_words,
} from "../../../helpers/functions";
import { useDarkMode } from "../contexts/dark_mode_provider";

const VideosTopContent = () => {
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();

  const { items: you_turn_videos_list, loading: you_turn_videos_list_loading } =
    useDynamicSelector("you_turn_videos");

  const navigate_youtube = () => {
    window.open(
      `https://www.youtube.com/watch?v=${you_turn_videos_list?.[0]?.video_id}`,
      "_blank"
    );
  };
  return (
    <div className="top-content-videos">
      <div className="videos-row-class">
        <div className="videos-top-box">
          <div className="video-check-bread-crumbs-div">
            <CustomBreadCrumbs pages={"Home/ Videos"} />
          </div>

          <div className="videos-top-image-contents">
            <Row
              className="videos-top-image  cursor-pointer"
              onClick={navigate_youtube}
            >
              <Image
                className="video-image"
                visible={false}
                preview={false}
                src={you_turn_videos_list?.[0]?.thumbnails}
              />

              <Col className="you_turn_video_page_card">
                <div
                  style={{
                    backgroundColor: is_dark_mode
                      ? "#36454F"
                      : "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="video-round-icon">
                    <Image
                      visible={false}
                      preview={false}
                      src={VIDEO_ROUND_ICON}
                    />
                  </div>
                  <div className="views-full-box">
                    <CustomText
                      class_name={"you-video-page-title-text"}
                      text={split_tamil_english_words(
                        you_turn_videos_list?.[0]?.title,
                        _,
                        3
                      )}
                      font_family={"Lato"}
                      align={"left"}
                    />
                    <CustomText
                      class_name={"youtube-views-and-time"}
                      text={`${format_view_count(
                        you_turn_videos_list?.[0]?.views
                      )} views | ${
                        you_turn_videos_list?.[0]?.days_since_published
                      } days ago`}
                      font_family={"Lato"}
                      align={"left"}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <div className="videos-top-title-div">
              <div className="videos-top-featured">
                {you_turn_videos_list_loading ? (
                  <Skeleton.Input
                    className="Skeleton-videos-top-featured"
                    active
                  />
                ) : (
                  <div className="mobile-display-none">
                    <CustomText
                      class_name={"featured-story-text"}
                      text={t("featured_video")}
                      size={"4vh"}
                      color={"orange"}
                      weight={"700"}
                      font_family={"Lato"}
                      align={"left"}
                    />
                  </div>
                )}

                {you_turn_videos_list_loading ? (
                  <Skeleton.Input
                    className="Skeleton-videos-top-featured"
                    active
                  />
                ) : (
                  <div className="videos-top-title">
                    <div className="video-page-title-text-div">
                      <CustomText
                        class_name={"video-page-title-text"}
                        text={you_turn_videos_list?.[0]?.title}
                        weight={"600"}
                        font_family={"Lato"}
                        align={"left"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosTopContent;
