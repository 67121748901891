import React, { useEffect, useRef } from "react";
import CustomText from "../custom/custom_text";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import { useDarkMode } from "../contexts/dark_mode_provider";

const PoliticalInvolvementPolicy = () => {
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  const first =
    "YouTurn is a non-partisan fact-checking organization, and as a result, employees associated with us are strictly refrained to participate in any political or non-political activity that effects the company’s policy. To protect YouTurn’s commitment to non-partisanship any New/Existing – Employees/Volunteers should adhere to all the company’s standards and policies. This document covers the important aspects of the policy but it is not limited to only below. Every employee plays a vital and is deemed responsible in case of any violations.";
  const second =
    "The Term Employee here refers to all the people who work full time, Part-time or Individual contributors. This Policy is applicable to all the above.";
  const third =
    "1. Unbiased Analysis – Employees are subject to fact-check, validate, analyse the claims on a non-partisan basis irrespective of their personal opinions. This includes Political Parties, Corporations or Organizations but not limited to the above. Fact-check is to be completely based on the evidence gathered.";
  const fourth =
    "2. Political Activity – Employees should not engage in any political activities, such as but not limited to, being a part of any of the political parties, advocacy organizations, participating in any events of electoral campaigns or raising funds towards the same.";

  const five =
    "3. Non-partisan Article – Employees must provide unbiased fact-check information in the article regardless of their personal affiliation to any Political Party, Religion, Caste, Community, Race and Sexual Orientation or anything for that matter. The article may not endorse or oppose anything as stated above but it has to provide the required information with appropriate evidence.";

  const six =
    "4. Article Review – Effective multi-layer validation of the article is to done before publishing it. Validation done by any volunteer is to be re-checked by an Employee or by the supervisor. No compromise is accepted on providing biased information. Influencing personal preferences in the article is not encouraged.";

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  return (
    <div
      ref={top_ref}
      className={`correction_policy_container  ${
        is_dark_mode ? "content-dark" : ""
      }`}
    >
      {/* <CustomBreadCrumbs
        pages={"Home/About us/Political involvement policy"}
        color={"#000000"}
      /> */}
      <div>
        <CustomText
          weight={"700"}
          text={t("political_policy")}
          size={"4vh"}
          font_family={"lato"}
          align={"left"}
          line_height={"5vh"}
          marginBottom={"2vh"}
          class_name={"correction_policy_title"}
        />
      </div>
      <CustomText
        text={t(first)}
        font_family={"lato"}
        align={"left"}
        line_height={"3vh"}
        weight={"500"}
      />
      <div>
        <CustomText
          text={t(second)}
          font_family={"lato"}
          align={"left"}
          line_height={"3vh"}
          margin_top={"5vh"}
          weight={"500"}
        />
      </div>
      <div style={{ width: "90%", marginLeft: "4vh" }}>
        <div>
          <CustomText
            text={t(third)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(fourth)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(five)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
        <div>
          <CustomText
            text={t(six)}
            font_family={"lato"}
            align={"left"}
            line_height={"3vh"}
            margin_top={"5vh"}
            weight={"500"}
          />
        </div>
      </div>
    </div>
  );
};

export default PoliticalInvolvementPolicy;
