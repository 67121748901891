import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Radio, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_request,
  query_get_all_party_tag,
  useDynamicSelector,
} from "../../../services/redux";
import { retrieveItem } from "../../../helpers/functions";
import { IoOptionsOutline } from "react-icons/io5";

const PartWiseListSelector = (props) => {
  const { set_party_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const current_language = retrieveItem("language");

  const { items: party_list_selector, loading } =
    useDynamicSelector("get_all_party_tag");

  const [is_modal_visible, set_is_modal_visible] = useState(false);
  const [selected_party_id, set_selected_party_id] = useState(null);

  const show_modal = () => {
    set_is_modal_visible(true);
  };

  const handle_ok = () => {
    set_is_modal_visible(false);
    if (selected_party_id) {
      set_party_id(selected_party_id);
    }
  };

  const handle_cancel = () => {
    set_is_modal_visible(false);
  };

  const handle_radio_change = (e) => {
    set_selected_party_id(e.target.value);
  };

  const hover_effect = (e) => {
    e.target.style.fontWeight = "bold";
    e.target.style.color = "#00adee";
  };

  const get_party_list_selector = () => {
    const key = [{ key: "get_all_party_tag", loading: true }];
    const query = query_get_all_party_tag;
    const variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_party_list_selector();
  }, []);

  return (
    <>
      <Form.Item name="party_list">
        <div onClick={show_modal} onMouseEnter={hover_effect}>
          <Input
            placeholder={t("select_party")}
            value={
              selected_party_id
                ? party_list_selector?.find(
                    (party) => party?.id === selected_party_id
                  )?.name
                : ""
            }
            style={{ width: "100%" }}
            suffix={<IoOptionsOutline style={{ color: "#1890ff" }} />}
            readOnly
          />
        </div>
      </Form.Item>
      <Modal
        title={t("select_party")}
        visible={is_modal_visible}
        onOk={handle_ok}
        onCancel={handle_cancel}
        confirmLoading={loading}
      >
        <Radio.Group
          onChange={handle_radio_change}
          value={selected_party_id}
          style={{ width: "100%" }}
        >
          <Row gutter={[16, 16]}>
            {party_list_selector &&
              party_list_selector.map((option) => (
                <Col key={option.id} span={12}>
                  <Radio value={option.id}>{option.name}</Radio>{" "}
                </Col>
              ))}
          </Row>
        </Radio.Group>
      </Modal>
    </>
  );
};

export default PartWiseListSelector;
