import React from "react";
import { HStack, VStack } from "native-base";
import PageTitle from "../../components/system/page_title/page_title";
import PartyTagList from "../../components/admin/party_tags/party_tag_list";

const PartyTag = () => {
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Party Wise tags"} />
      </HStack>
      <VStack padding={"40px"}>
        <PartyTagList />
      </VStack>
    </div>
  );
};

export default PartyTag;
