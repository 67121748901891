import { Button, Form, Modal, Pagination, Table } from "antd";
import Search from "antd/es/input/Search";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import {
  comments_processing_query,
  delete_comments_query,
  get_all_comments_query,
} from "../../../../services/redux/slices/graphql/graphql_comments";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CommentsDetails from "./comments_details";
import { navigateWithProps } from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import CommentsForm from "./comments_form";
import { MdKey } from "react-icons/md";
import { FaHandPaper } from "react-icons/fa";
import { countFormat } from "../../../../helpers/constants";

const CommentsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [mutation_type, set_mutation_type] = useState("");
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [delete_modal, set_delete_modal] = useState(false);
  const [user_profile_card, set_user_profile_card] = useState(false);
  const [delete_id, set_delete_id] = useState("");
  const [comment_id, set_comment_id] = useState("");
  const [comment_data, set_comments_data] = useState("");
  const [profile_id, set_profile_id] = useState("");
  const [type, set_type] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [search_value, set_search_value] = useState("");

  const {
    items: comments_list,
    loading: comments_loading,
    pagination: comments_pagination,
  } = useDynamicSelector("get_all_comments");

  const {
    status: delete_comments_status,
    loading: delete_comment_loading,
    error: delete_comment_error,
  } = useDynamicSelector("delete_comment");

  const {
    status: process_comments_status,
    loading: process_comments_loading,
    error: process_comments_error,
  } = useDynamicSelector("process_comment");

  useEffect(() => {
    get_user_comments();
  }, [page_limit, page_number, search_value]);

  useEffect(() => {
    if (type) {
      comments_processing(type);
    }
  }, [type]);

  useEffect(() => {
    if (process_comments_status === "Success") {
      if (type === "approve") {
        toast.success("Comment approved successfully");
      } else if (type === "reject") {
        toast.success("Comment rejected successfully");
      }
      set_type("");
      edit_modal_close();
      get_user_comments();
      dispatch(dynamic_clear("process_comment"));
    } else if (process_comments_error) {
      edit_modal_close();
      set_type("");
      toast.error(process_comments_error?.message);
      dispatch(dynamic_clear("process_comment"));
    }
  }, [process_comments_status, process_comments_error, type]);

  useEffect(() => {
    if (delete_comments_status === "Success") {
      toast.success("Comment deleted successfully");
      delete_modal_close();
      get_user_comments();
      dispatch(dynamic_clear("delete_comment"));
    } else if (delete_comment_error) {
      toast.error(delete_comment_error?.message);
      delete_modal_close();
      dispatch(dynamic_clear("delete_comment"));
    }
  }, [delete_comments_status, delete_comment_error]);

  useEffect(() => {
    if (mutation_type === "edit") {
      form.setFieldsValue({ content: comment_data?.content });
    }
  }, [form, mutation_type, comment_data]);

  const get_user_comments = () => {
    let key = [{ key: "get_all_comments", loading: true }];
    let query = get_all_comments_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      search_string: search_value,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const delete_user_comments = () => {
    let key = [{ key: "delete_comment", loading: true }];
    let query = delete_comments_query;
    let variables = {
      id: delete_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const comments_processing = (type) => {
    let key = [{ key: "process_comment", loading: true }];
    let query = comments_processing_query;
    let variables = {
      id: comment_id,
      type: type,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete = (record) => {
    set_delete_id(record?.id);
    set_delete_modal(true);
  };
  const delete_modal_close = () => {
    set_delete_modal(false);
  };
  const handle_edit = (record) => {
    set_mutation_modal(true);
    navigateWithProps(`${ROUTES.COMMENTS_LIST}/${record?.id}`, {
      record: record,
    });
  };
  const handle_edit_comment = (record) => {
    set_mutation_modal(true);
    set_mutation_type("edit");
    set_comment_id(record.id);
    set_comments_data(record);
  };
  const edit_modal_close = () => {
    set_mutation_modal(false);
  };

  const action_items = (record) => {
    return (
      <HStack justifyContent={"space-between"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_edit_comment(record);
          }}
        >
          <AiOutlineEdit size={20} />
        </Pressable>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  const get_user_details = (record) => {
    set_user_profile_card(true);
    set_profile_id(record?.commented_by?.id);
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: "Comment",
      key: "comment",
      render: (record) => record?.content,
    },
    {
      title: "Comment by",
      key: "comment_by",
      render: (record) => {
        return record?.commented_by?.username;
      },
      // <a href="#" onClick={() => get_user_details(record)}>
      // </a>
      // ),
    },
    {
      title: t("table:action"),
      key: "action",
      width: "100px",
      render: (record) => action_items(record),
    },
  ];

  const on_click = (value) => {
    set_type(value);
  };

  const on_search = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_value(search_value);
    }
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Search
          onSearch={on_search}
          onChange={on_search}
          enterButton={true}
          allowClear={true}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
      </HStack>
      <Table
        columns={columns}
        loading={comments_loading}
        dataSource={comments_list}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => handle_edit(record), // click row
        //     className: "table-cursor",
        //   };
        // }}
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={comments_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
      <Modal
        open={mutation_modal}
        title={"Update Comment"}
        onCancel={edit_modal_close}
        footer={[
          <div>
            <Button
              style={{ backgroundColor: "red", color: "white" }}
              loading={type === "reject" && process_comments_loading}
              onClick={() => on_click("reject")}
            >
              Reject
            </Button>
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              loading={type === "approve" && process_comments_loading}
              onClick={() => on_click("approve")}
            >
              Approve
            </Button>
            <Button onClick={edit_modal_close}>Cancel</Button>
          </div>,
        ]}
      >
        <CommentsForm form={form} />
      </Modal>
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={delete_modal_close}
      >
        <span>Are you sure to delete this comment ?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={delete_modal_close} danger>
            Close
          </Button>
          <Button
            onClick={delete_user_comments}
            type="primary"
            loading={delete_comment_loading}
          >
            Ok
          </Button>
        </HStack>
      </Modal>
      <Modal
        title={"User Details"}
        open={user_profile_card}
        onCancel={() => set_user_profile_card(false)}
      ></Modal>
    </VStack>
  );
};

export default CommentsList;
