import { gql } from "@apollo/client";

export const get_trending_fact_checks = gql`
  query get_trending_fact_checks($language_id: String!) {
    get_trending_fact_checks(language_id: $language_id) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
        reading_time
        likes
        views
        comments_count
        is_reported
        created_date_time
        published_date_time
        thumbnail_img
        perma_link
        error {
          status_code
          message
        }
      }
    }
  }
`;
