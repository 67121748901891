import React from "react";
import { YOU_TURN_LOGO_BLUE } from "../../../../helpers/image_constants";
import { navigate } from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";

const LogoImageForHomePage = () => {
  const handle_back = () => {
    navigate(ROUTES.LANDING_PAGE);
  };

  return (
    <div onClick={handle_back} className="back_arrow">
      <img
        style={{
          borderRadius: "9px",
          zIndex: 1,
          objectFit: "cover",
          height: "50px",
          cursor: "pointer",
        }}
        src={YOU_TURN_LOGO_BLUE}
        alt="Profile"
      />
    </div>
  );
};

export default LogoImageForHomePage;
