import React, { useEffect, useRef, useState } from "react";
import CustomText from "../components/custom/custom_text";
import { DEFAULT_USER, UNDEFINED_PROFOLE } from "../../helpers/image_constants";
import { Button, Col, Row, Skeleton } from "antd";
import CustomContributors from "../components/custom/custom_contributors";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { get_all_user_contributors_query } from "../../services/redux/slices/graphql/graphql_user_contribution";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { retrieveItem } from "../../helpers/functions";
import CustomBreadCrumbs from "../components/custom/custom_breadcrumbs";

const TopContributorsPage = () => {
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const [filtered_contributors, set_filtered_contributors] = useState([]);
  const [page_limit_user_contributors, set_page_limit_user_contributors] =
    useState(8);

  const {
    items: get_all_contributers_items,
    loading: get_all_contributers_items_loading,
    pagination: get_all_user_contributers_pagination,
  } = useDynamicSelector("get_all_user_contributers");

  useEffect(() => {
    if (get_all_contributers_items && get_all_contributers_items.length > 0) {
      set_filtered_contributors([...get_all_contributers_items]);
    }
  }, [get_all_contributers_items]);

  useEffect(() => {
    get_all_user_contribution_details();
  }, [page_limit_user_contributors]);
  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  const get_all_user_contribution_details = () => {
    let key = [{ key: "get_all_user_contributers", loading: true }];
    let query = get_all_user_contributors_query;
    let variables = {
      language_id: current_language?.id,
      page_number: 1,
      page_limit: page_limit_user_contributors,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_click_contributors = () => {
    set_page_limit_user_contributors(page_limit_user_contributors + 4);
  };
  return (
    <div ref={top_ref}>
      <div className="user_contribution_bread_cr_div">
        <div className="user_contribution_bread_cr_sec_div">
          <CustomBreadCrumbs
            pages={"Home/ User Contribution/ Top Contributors"}
          />
        </div>
      </div>
      <div style={{ margin: "5vh" }}>
        <Col
          style={{
            width: "100%",
            height: "auto",
            top: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <CustomText
            text={t("top_contributors")}
            font_family={"Kufam"}
            color={"#140F2D"}
            size={"4vh"}
            align={"start"}
            marginLeft={"5vh"}
          />

          <div
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: "4vh",
            }}
          >
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "6vh",
              }}
            >
              {filtered_contributors?.map((contributor, index) => (
                <CustomContributors
                  key={index}
                  contributor_name={contributor.name}
                  works={contributor.total_work}
                  views={contributor.total_views}
                  joined={moment(contributor?.joined_date).format(
                    "MMM DD, YYYY"
                  )}
                  contributor_image={
                    contributor.profile_pic
                      ? contributor.profile_pic
                      : DEFAULT_USER
                  }
                />
              ))}
            </Row>
          </div>
          {get_all_contributers_items?.length !==
            get_all_user_contributers_pagination?.total_count &&
          get_all_contributers_items?.length < 9 ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                style={{
                  color: "white",
                  borderRadius: "10px",
                  width: "280px",
                  backgroundColor: "rgb(0, 101, 205)",
                }}
                onClick={handle_click_contributors}
              >
                {t("see_all")}
              </Button>
            </div>
          )}
          {get_all_contributers_items_loading &&
            Array.from(
              { length: page_limit_user_contributors + 4 },
              (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="top_contibutors_card_skeleton"
                  active
                />
              )
            )}
        </Col>
      </div>
    </div>
  );
};

export default TopContributorsPage;
