import React, { useEffect, useRef, useState } from "react";
import UserProfileTitle from "./user_profile_title";
import CustomText from "../custom/custom_text";
import CustomFactCheckCategories from "../custom/custom_fact_check_categories";
import { Button, Empty, Skeleton } from "antd";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import { query_get_all_user_book_mark } from "../../../services/redux/slices/graphql/graphql_user_profile";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { navigate, retrieveItem } from "../../../helpers/functions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { debounce } from "lodash";
import { ROUTES } from "../../routes/my_routes";

const BookmarkList = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const session_id = retrieveItem("session_id");
  const [page_limit, set_page_limit] = useState(6);
  const [all_items, set_all_items] = useState([]);
  const { is_dark_mode } = useDarkMode();
  const top_ref = useRef(null);

  const {
    items: my_book_marks,
    loading: my_book_marks_loading,
    pagination: my_book_marks_pagination,
  } = useDynamicSelector("get_user_bookmarks");

  useEffect(() => {
    if (type) {
      get_all_my_book_mark(type);
    }
  }, [type, page_limit]);

  useEffect(() => {
    if (my_book_marks && my_book_marks?.length >= 0) {
      set_all_items([...my_book_marks]);
    }
  }, [my_book_marks]);

  const get_all_my_book_mark = (value) => {
    let key = [{ key: "get_user_bookmarks", loading: true }];
    let query = query_get_all_user_book_mark;
    let variable = {
      type: value,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variable));
  };
  const on_click = () => {
    set_page_limit(page_limit + 6);
  };

  const handle_click = () => {
    if (type === "article") {
      navigate(ROUTES.ARTICLES);
    } else {
      navigate(ROUTES.FACT_CHECK);
    }
  };

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  useEffect(() => {
    if (my_book_marks && my_book_marks.length >= 0) {
      debouncedHandleTitleClick();
    }
  }, [my_book_marks]);

  return (
    <div
      ref={top_ref}
      style={{ overflow: "hidden" }}
      className={is_dark_mode ? "content-dark" : ""}
    >
      <UserProfileTitle type={"Bookmarks"} title={""} />

      <CustomText
        class_name={"user_profile_titles"}
        text={
          type === "article" ? "Bookmarked Articles" : "Bookmarked Fact Checks"
        }
        font_family={"Lato"}
        size={"4vh"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />

      <div className="top_six_article_card">
        <div className="top_six_article">
          {all_items?.length > 0
            ? all_items?.map((item) => (
                <CustomFactCheckCategories
                  loading={false}
                  category_image={
                    item?.thumbnail_img === "null"
                      ? BROKEN_IMAGE
                      : item.thumbnail_img
                  }
                  title={item?.title ? item.title : ""}
                  sub_title={item?.subtitle ? item.subtitle : ""}
                  created_date={
                    item
                      ? moment(item.created_date_time).format(`MMM D,YYYY`)
                      : ""
                  }
                  perma_link={item?.perma_link ? item.perma_link : ""}
                  type={type}
                  name={
                    item.user_profile?.name ||
                    item.staff_profile?.name ||
                    "YouTurn Editorial"
                  }
                />
              ))
            : !my_book_marks_loading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh",
                    flexDirection: "column",
                  }}
                >
                  <Empty
                    description={
                      type === "article"
                        ? t("no_data_article_text")
                        : t("no_data_fact_check_text")
                    }
                  />
                  <a
                    onClick={handle_click}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {type === "article"
                      ? t("click_here_to_view_articles")
                      : t("click_here_to_view_fact_checks")}
                  </a>
                </div>
              )}

          {my_book_marks_loading &&
            Array.from({ length: 6 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="six-categories-card-second-div-for-skeleton"
                active
              />
            ))}
        </div>
      </div>

      <div className="see_all_article_button">
        <div className="user_profile_load_more_button">
          {all_items?.length === my_book_marks_pagination?.total_count ||
          all_items?.length < 6 ? (
            ""
          ) : (
            <Button
              className="user_profile_load_button_color"
              onClick={on_click}
            >
              {t("Load More")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookmarkList;
