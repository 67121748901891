import React from "react";
import VForm from "../../components/system/ui/antd_form/antd_form";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { VStack } from "native-base";
import CustomText from "../custom/custom_text";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
const SignInForm = ({ on_finish }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const handle_login = () => {
    history.push("/login");
  };

  return (
    <div style={{ width: "100%", height: "70%" }}>
      <Row
        gutter={24}
        // style={{ width: "50vw", backgroundColor: "pink" }}
        className="single-fact-check-sign-up-row"
      >
        <Col className="single-fact-check-sign-up-col">
          <Form.Item
            label={t("form:name")}
            name="name"
            rules={[
              {
                required: true,
                message: `${t("form:name")} is required`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col className="single-fact-check-sign-up-col">
          <Form.Item
            label={t("form:mobile_number")}
            name="mobile"
            rules={[
              {
                required: true,
                message: `${t("form:mobile_number")} is required`,
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit mobile number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col className="single-fact-check-sign-up-col">
          <Form.Item
            label={t("form:email")}
            name="username"
            rules={[
              {
                required: true,
                message: `${t("username")} is required`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col className="single-fact-check-sign-up-col">
          <Form.Item
            label={t("form:Password")}
            name="password"
            rules={[
              {
                required: true,
                message: `${t("form:Password")} is required`,
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <VStack
          space={"10px"}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              htmlType="submit"
              // loading={update_loading || create_loading}
              type="primary"
            >
              {t("sign_in")}
            </Button>

            <CustomText text={t("or")} font_family={"Kufam"} size={"15px"} />

            <Button htmlType="submit" type="primary" onClick={handle_login}>
              {t("login")}
            </Button>
          </div>
        </VStack> */}
    </div>
  );
};

export default SignInForm;
