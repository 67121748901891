import React from "react";
import { Form, Input, Row } from "antd";
import { Box, Text } from "native-base";
import { useState } from "react";

const PreviewAmountCard = (props) => {
  const { handle_get_amount, class_name } = props;
  const [selectedAmount, setSelectedAmount] = useState("");

  let amount_values = [
    {
      id: "199",
      name: "₹199",
    },
    {
      id: "499",
      name: "₹499",
    },
    {
      id: "999",
      name: "₹999",
    },
    {
      id: "1999",
      name: "₹1,999",
    },
  ];

  const AmountFormatContainer = () => {
    return amount_values.map((amt) => {
      const isSelected = selectedAmount === amt.id;

      return (
        <div
          key={amt.id}
          className={class_name}
          style={{
            backgroundColor: isSelected ? "#00adee" : "gray.200",
            color: isSelected ? "white" : "black",
            width: "125px",
            padding: "10px",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <Text
            fontFamily="lato"
            fontSize="15px"
            fontWeight="400"
            color={isSelected ? "white" : "black"}
          >
            {amt.name}
          </Text>
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <AmountFormatContainer />
    </div>
  );
};

export default PreviewAmountCard;
