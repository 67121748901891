import { Skeleton } from "antd";
import React from "react";
import CustomNewsStoryCardVertical from "../../custom/custom_news_story_card_vertical";
import {
  extractTextFromPTags,
  split_tamil_english_words,
} from "../../../../helpers/functions";
import moment from "moment";
import CustomFactCheckRectangleSmallCard from "../../custom/custom_fact_check_rectangle_small_card";
import ListOfArticleTopContent from "../../list_of_article_page/list_of_article_top_content";
import CustomRecentlyAddedCarousel from "../../custom/custom_recently_added_carousel";
import CustomText from "../../custom/custom_text";
import { useTranslation } from "react-i18next";

const UserContributionRecentlyAddedCard = (props) => {
  const {
    top_fact_check,
    recently_added_for_user_contribution_loading,
    recent_fact_checks,
  } = props;

  const { t } = useTranslation();
  return (
    <div className="user_contribution_recently_added_first_div">
      <div className="user_contribution_recently_added_second_div">
        <div
          style={{
            height: "100px",
            width: "400px",
            marginTop: "15px",
          }}
        >
          <CustomText
            text={t("recently_added")}
            font_family={"kufam"}
            size={"24px"}
            align={"left"}
            weight={"700"}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "8vh",
        }}
        className="recently_added_and_small_card_div"
      >
        {recently_added_for_user_contribution_loading
          ? Array.from({ length: 1 }, (_, index) => (
              <Skeleton.Input
                key={index}
                active
                style={{ width: "20vw", height: "80vh" }}
              />
            ))
          : top_fact_check?.map((item, index) => (
              <CustomNewsStoryCardVertical
                reading_time={item?.reading_time}
                view_count={item?.views}
                date={moment(
                  item?.published_date_time || created_date_time
                ).format("MMM YYYY")}
                name={item?.user_profile?.name}
                src={item?.thumbnail_img}
                title={split_tamil_english_words(item?.title, "_", 100)}
                subtitle={split_tamil_english_words(
                  item?.subtitle
                    ? item.subtitle
                    : extractTextFromPTags(item?.content),
                  "_", // Moved delimiter argument here
                  100
                )}
                perma_link={item?.perma_link}
                type={item?.record_type}
                height={"77vh"}
                max_height={"85vh"}
              />
            ))}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {recently_added_for_user_contribution_loading
            ? Array.from({ length: 4 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  active
                  style={{ width: "30vw", height: "13vh" }}
                />
              ))
            : recent_fact_checks?.map((item, index) => (
                <CustomFactCheckRectangleSmallCard
                  src={item?.thumbnail_img}
                  title={item?.title}
                  date={item?.published_date_time || created_date_time}
                  perma_link={item?.perma_link}
                  name={item?.user_profile?.name}
                  type={item?.record_type}
                />
              ))}
        </div>
      </div>
      <div className="user_contribution_recently_added_mobile_view">
        {recently_added_for_user_contribution_loading
          ? Array.from({ length: 1 }, (_, index) => (
              <Skeleton.Input
                key={index}
                active
                style={{ width: "50vw", height: "70vh" }}
              />
            ))
          : top_fact_check?.map((item, index) => (
              <div className="recently_added_top_view_div">
                <ListOfArticleTopContent
                  loading={recently_added_for_user_contribution_loading}
                  title={item?.title}
                  image={item?.thumbnail_img}
                  view_count={item?.views}
                  created_date_time={moment(item?.created_date_time).format(
                    "MMM D, YYYY"
                  )}
                  id={item?.perma_link}
                  name={item?.user_profile?.name}
                  type={item?.record_type}
                  height={"26vh"}
                  width={"90vw"}
                  inside_card_class={"inside_card_user_contribution"}
                />
              </div>
            ))}
      </div>
      <div className="user_contribution_recently_added_carousel">
        <CustomRecentlyAddedCarousel list={recent_fact_checks} />
      </div>
    </div>
  );
};

export default UserContributionRecentlyAddedCard;
