import React from "react";
import { USER_PROFILE_BACK_GROUND } from "../../../helpers/image_constants";
import UserDetailsCard from "./user_details_card";
import UserProfileDonationDetails from "./user_profile_donation_details";
import UserProfileBookMarks from "./user_profile_book_marks";
import UserProfileClaimDetails from "./user_profile_claim_details";

const UserProfileCard = () => {
  return (
    <div
      className="user_profile_background_div"
      style={{
        background: `url('${USER_PROFILE_BACK_GROUND}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <UserDetailsCard />
      <div className="donation_and_contribution_cards">
        <UserProfileDonationDetails card_title={"Donations"} />
        <UserProfileBookMarks card_title={"Contributions"} type={"post"} />
      </div>

      <div className="donation_and_contribution_cards">
        <UserProfileBookMarks card_title={"Bookmarks"} type={"book-mark"} />
        <UserProfileClaimDetails card_title={"Claims"} />
      </div>
    </div>
  );
};

export default UserProfileCard;
