import React, { useEffect, useState } from "react";
import { Form } from "antd";
import CustomReportModel from "../custom/custom_report_model";
import { retrieveItem } from "../../../helpers/functions";
import toast from "react-hot-toast";
import { ROUTES } from "../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  dynamic_clear,
  dynamic_request,
  mutation_report,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { useTranslation } from "react-i18next";
import SingleFactCheckReportText from "./single_fact_check_report_text";
import SingleFactCheckReportButton from "./single_fact_check_report_button";

const SingleFactCheckReportCard = (props) => {
  const { margin_top } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { is_dark_mode } = useDarkMode();
  const session_id = retrieveItem("session_id");
  const [model_open, set_model_open] = useState(false);
  const role = retrieveItem("role");

  const { id: fact_check_id } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );
  const { id: article_id } = useDynamicSelector("get_article_by_perma_link");

  const { status: create_report_status, error: create_report_error } =
    useDynamicSelector("create_report");

  useEffect(() => {
    if (create_report_status === "Success") {
      toast.success("Report submitted");
      set_model_open(false);
      form.resetFields("");
      dispatch(dynamic_clear("create_report"));
    } else if (create_report_error) {
      toast.error(create_report_error?.message);
    }
    dispatch(dynamic_clear("create_report"));
  }, [create_report_status, create_report_error]);

  const report_click = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      set_model_open(true);
    } else {
      history.push(`${ROUTES.ADMIN_LOGIN}`);
    }
  };

  const report = (values) => {
    let key = [{ key: "create_report", loading: true }];
    let query = mutation_report;
    let variables = {
      json: fact_check_id
        ? {
            fact_check_id: fact_check_id,
            reason_id: values?.reason,
            description: values?.description,
          }
        : {
            article_id: article_id,
            reason_id: values?.reason,
            description: values?.description,
          },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div
      className="single-fact-check-report-card"
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
    >
      <SingleFactCheckReportText margin_top={margin_top} />

      <SingleFactCheckReportButton
        report_click={report_click}
        margin_top={margin_top}
      />

      <div>
        <CustomReportModel
          model_open={model_open}
          set_model_open={set_model_open}
          report={report}
          form={form}
        />
      </div>
    </div>
  );
};
export default SingleFactCheckReportCard;
