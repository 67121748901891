import React, { useEffect, useState } from "react";

import SingleFactCheckRelatedFactCheck from "./single_fact_check_related_fact_check";
import SingleFactCheckRecentFactCheck from "./single_fact_check_recent_fact_check";

const SingleFactCheckRelatedRecentFactCheck = () => {
  return (
    <div className="single-fact-check-related-fact-ful-div">
      <div className="related-fact-check-row">
        <SingleFactCheckRelatedFactCheck />
        <SingleFactCheckRecentFactCheck />
      </div>
    </div>
  );
};

export default SingleFactCheckRelatedRecentFactCheck;
