import { gql } from "@apollo/client";
export const get_admin_login_query = gql`
  query admin_login($username: String, $password: String) {
    admin_login(username: $username, password: $password) {
      user_id
      name
      mobile
      email
      username
      session_id
      status
      page_accesses
      page_component_accesses
      roles {
        id
        name
      }
      is_active
      error {
        message
      }
    }
  }
`;

export const all_user_login_query = gql`
  query login($username: String, $password: String) {
    login(username: $username, password: $password) {
      user_id
      name
      mobile
      email
      force_password_change
      username
      session_id
      status
      page_accesses
      page_component_accesses
      is_active
      roles {
        id
        name
      }
      error {
        message
      }
    }
  }
`;

export const query_admin_login = gql`
  query admin_login($username: String, $password: String) {
    admin_login(username: $username, password: $password) {
      user_id
      name
      mobile
      email
      username
      session_id
      status
      page_accesses
      force_password_change
      page_component_accesses
      is_active
      roles {
        id
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const reset_password_query = gql`
  mutation reset_forgot_password($new_password: String!, $token: String!) {
    reset_forgot_password(new_password: $new_password, token: $token) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const logout_mutation = gql`
  mutation logout {
    logout {
      id
      status
    }
  }
`;
