import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  dynamic_request,
  query_get_all_category,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import CategoriesCarousal from "./categories_carousal";
import CustomText from "../custom/custom_text";

const CategoriesList = (props) => {
  const {
    set_article_category_id,
    article_category_id,
    set_article_category_name,
    perma_link,
    article_category_name,
  } = props;

  const history = useHistory();
  let current_language = retrieveItem("language");

  const dispatch = useDispatch();
  const [category_key, set_category_key] = useState();
  const { items: category_list } = useDynamicSelector("get_all_category_tag");
  const { items: article_items } = useDynamicSelector(
    "get_all_article_in_article_list"
  );

  const handle_click = (name, id) => {
    set_article_category_id(id);
    set_article_category_name(name);
    set_category_key(name);
    // history.push(`${ROUTES.LIST_OF_ARTICLES}/${name}/${perma_link + ".html"}`);
  };

  // useEffect(() => {
  //   history.push(
  //     `${ROUTES.LIST_OF_ARTICLES}/${article_category_name}/${
  //       perma_link + ".html"
  //     }`
  //   );
  // }, [article_category_id]);

  //  const handle_click=>(){
  //   navigate(ROUTES)

  //  }

  const hover_style = {
    cursor: "pointer",
    transition: "all 0.3s",
  };
  const normal_style = {
    fontWeight: "normal",
    color: "black",
    fontFamily: "Lato",
  };
  const hover_effect = (e) => {
    e.target.style.fontWeight = "bold";
    e.target.style.color = "#007BFF";
  };
  const normal_effect = (e) => {
    e.target.style.fontWeight = normal_style.fontWeight;
    e.target.style.color = normal_style.color;
  };

  const get_category_list = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_category_list();
  }, []);

  return (
    <div className="article_list_carousal">
      <div>
        <CustomText
          text={"Looking for more?"}
          size={"16px"}
          font_family={"Lato"}
          color={"#140F2D"}
          align={"left"}
          weight={"400"}
        />
        <CustomText
          class_name={"categories_text"}
          text={"All Categories"}
          size={"25px"}
          font_family={"Kufam"}
          color={"#140F2D"}
          align={"left"}
          line_height={"9vh"}
        />
      </div>
      <div
        className="fact-check-and-article-categories-list"
        // onClick={handleClick}
      >
        {category_list?.map((item) => (
          <span
            key={item.id}
            style={{
              // ...hover_style,
              // ...normal_style,
              // backgroundColor: "red",
              padding: "5px 28px 5px 28px",
              border: "1px solid #0065CC",
              borderRadius: "17px",
              cursor: "pointer",
              boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor:
                category_key === item.name ? "#007BFF" : "transparent",
              color: category_key === item.name ? "white" : "black",
            }}
            onClick={() => handle_click(item?.name, item?.id)}
            // onMouseEnter={hover_effect}
            // onMouseLeave={normal_effect}
          >
            {item.name}
          </span>
        ))}
      </div>

      <div style={{ width: "100%" }} className="article_list_mobile_carousal">
        <CategoriesCarousal
          category_key={category_key}
          set_category_key={set_category_key}
          set_article_category_name={set_article_category_name}
          set_article_category_id={set_article_category_id}
        />
      </div>
    </div>
  );
};

export default CategoriesList;
