import { Button, Col, Row, Skeleton } from "antd";
import React, { useEffect, useRef } from "react";
import CustomText from "../custom/custom_text";
import CustomNewsStoryCard from "../custom/custom_news_story_card";
import {
  DEFAULT_USER,
  UNDEFINED_PROFOLE,
} from "../../../helpers/image_constants";
import CustomContributors from "../custom/custom_contributors";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";
import { split_tamil_english_words } from "../../../helpers/functions";
import moment from "moment";
import { debounce } from "lodash";

const TopContributors = (props) => {
  const top_ref = useRef(null);
  const { t } = useTranslation();
  const {
    all_stories_sliced_items,
    page_limit_user_contributors,
    handle_click,
    handle_click_contributors,
    get_all_contributers_items,
    recently_user_contribution_pagination,
    recently_added_for_user_contribution_items,
    get_all_user_contributers_pagination,
    filtered_contributors,
  } = props;

  const { loading: recently_added_for_user_contribution_loading } =
    useDynamicSelector("recently_added_for_user_contribution");
  const {
    items: get__contributers_items,
    loading: get_all_contributers_items_loading,
    pagination: get_all_user_contributers_total_count,
  } = useDynamicSelector("get_all_user_contributers");

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Row
        style={{
          width: "91%",
          justifyContent: "space-between",
          padding: "30px",
        }}
      >
        <Col
          style={{
            width: "48%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "auto",
            }}
          >
            <CustomText
              class_name={"all_stories_text"}
              text={t("all_stories")}
              font_family={"Kufam"}
              color={"#140F2D"}
              size={"5vh"}
              align={"left"}
            />
            <div className="article_list_news_stories_card">
              {all_stories_sliced_items?.map((item, index) => (
                <CustomNewsStoryCard
                  article_img={item?.thumbnail_img}
                  name={item?.staff_profile?.name || item?.user_profile?.name}
                  date={item?.created_date_time}
                  viewed_count={item?.views}
                  reading_time={item?.reading_time}
                  img_width={"27.8vw"}
                  content_width={"320px"}
                  first_row_width={270}
                  text={split_tamil_english_words(item?.title, _, 7)}
                  subtitle={split_tamil_english_words(item?.subtitle, _, 9)}
                  type={item?.record_type}
                  img_height={"30.5vh"}
                  perma_link={item?.perma_link}
                />
              ))}
            </div>
          </div>
          {recently_added_for_user_contribution_loading &&
            Array.from({ length: 1 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="user-contribution-list-news-story-card-skeleton"
                active
              />
            ))}
          {recently_added_for_user_contribution_items?.length ===
            recently_user_contribution_pagination?.total_count ||
          recently_added_for_user_contribution_items?.length < 4 ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "flex-end",
                height: "15vh",
              }}
            >
              <Button
                style={{
                  color: "white",
                  borderRadius: "10px",
                  width: "320px",
                  backgroundColor: "rgb(0, 101, 205)",
                }}
                onClick={handle_click}
              >
                {t("load_more")}
              </Button>
            </div>
          )}
        </Col>

        <Col
          style={{
            width: "48%",
            height: "auto",
            position: "sticky",
            top: 0,
          }}
        >
          <CustomText
            text={t("top_contributors")}
            font_family={"Kufam"}
            color={"#140F2D"}
            size={"5vh"}
            align={"start"}
            marginLeft={"7vh"}
          />

          <div
            style={{
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Row className="custom_contributors_row">
              {filtered_contributors?.map((contributor, index) => (
                <CustomContributors
                  key={index}
                  contributor_name={contributor.name}
                  works={contributor.total_work}
                  views={contributor.total_views}
                  joined={moment(contributor?.joined_date).format(
                    "MMM DD, YYYY"
                  )}
                  contributor_image={
                    contributor.profile_pic
                      ? contributor.profile_pic
                      : DEFAULT_USER
                  }
                  user_profile_id={contributor.user_profile_id}
                />
              ))}
            </Row>
          </div>
          {/* {get__contributers_items?.length !==
            get_all_user_contributers_total_count?.total_count &&
          get__contributers_items?.length < 9 ? (
            ""
          ) : ( */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              style={{
                color: "white",
                borderRadius: "10px",
                width: "280px",
                backgroundColor: "rgb(0, 101, 205)",
              }}
              onClick={handle_click_contributors}
            >
              {t("see_all")}
            </Button>
          </div>
          {/* )} */}
          {get_all_contributers_items_loading &&
            Array.from(
              { length: page_limit_user_contributors + 4 },
              (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="top_contibutors_card_skeleton"
                  active
                />
              )
            )}
        </Col>
      </Row>
    </div>
  );
};

export default TopContributors;
