import React, { useEffect, useRef, useState } from "react";
import { Skeleton } from "antd";
import JoinUsCard from "../components/widgets/join_us_card";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import { debounce } from "lodash";
import { retrieveItem } from "../../helpers/functions";
import { get_you_turn_videos } from "../../services/redux/slices/graphql/graphql_videos";
import VideosTopContent from "../components/videos/videos_top_content";
import VideosCardYouTube from "../components/videos/videos_card_you_tube";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import VideosLoadMoreButton from "../components/videos/videos_load_more_button";

const Videos = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();
  const [page_limit, set_page_limit] = useState(7);
  let current_language = retrieveItem("language");
  const [filtered_item, set_filtered_item] = useState([]);

  const { items: you_turn_videos_list, loading: you_turn_videos_list_loading } =
    useDynamicSelector("you_turn_videos");

  useEffect(() => {
    if (you_turn_videos_list && you_turn_videos_list.length > 0) {
      set_filtered_item([...you_turn_videos_list]);
    }
  }, [you_turn_videos_list]);

  useEffect(() => {
    get_all_you_turn_videos();
  }, []);

  useEffect(() => {
    get_all_you_turn_videos();
  }, [page_limit]);

  useEffect(() => {
    debounced_handle_title_click();
  }, []);

  const get_all_you_turn_videos = () => {
    let key = [{ key: "you_turn_videos", loading: true }];
    let query = get_you_turn_videos;
    let variables = {
      page_limit: page_limit,
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_click = () => {
    set_page_limit(page_limit + 6);
  };

  const handle_page_Position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_Position, 200);

  return (
    <div
      ref={top_ref}
      className={`full-video-page-div ${is_dark_mode ? "content-dark" : ""}`}
    >
      <VideosTopContent />

      <div className="you_turn_videos_story_card">
        <div className="videos-page-story-div">
          {you_turn_videos_list_loading &&
            Array.from({ length: 3 }, (_, index) => (
              <Skeleton.Input
                key={index}
                active
                style={{
                  width: "27vw",
                  height: "35vh",
                  marginTop: "5vh",
                }}
              />
            ))}
          {filtered_item?.slice(1).map((item, index) => (
            <VideosCardYouTube video_item={item} key={index} />
          ))}
        </div>
      </div>
      <div className="videos_skeleton_div">
        {you_turn_videos_list_loading &&
          Array.from({ length: 3 }, (_, index) => (
            <Skeleton.Input
              key={index}
              active
              style={{
                width: "27vw",
                height: "35vh",
                marginTop: "5vh",
              }}
            />
          ))}
      </div>

      <VideosLoadMoreButton on_click={on_click} />

      <div>
        <JoinUsCard />
      </div>
    </div>
  );
};

export default Videos;
