import React from "react";
import CustomNewsArticle from "../custom/custom_news_article";
import Carousel from "react-multi-carousel";
import moment from "moment";
import { format_view_count } from "../../../helpers/functions";

const TrendingStoriesList = ({ list, name, date_type }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 4,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    list &&
    list?.length !== 0 && (
      <div className="trending-stories-container box-inner-right-shadow">
        <div className="trending-stories-title center-alignment">
          <text>{name}</text>
        </div>
        <Carousel
          responsive={responsive}
          infinite={true}
          className="carousel-stories-list"
        >
          {list?.length !== 0 ? (
            list?.map((item, index) => (
              <div key={item?.id}>
                <CustomNewsArticle
                  key={index}
                  article={item?.title}
                  header_color={"#e84756"}
                  name={
                    item
                      ? item?.staff_profile?.name ||
                        item?.user_profile?.name ||
                        ""
                      : ""
                  }
                  date={
                    item
                      ? moment(
                          item?.published_date_time
                            ? parseInt(item?.published_date_time, 10)
                            : item?.created_date_time
                        ).format(`MMM DD, YYYY`)
                      : ""
                  }
                  date_type_new={moment(
                    item?.published_date_time || item?.published_date_time
                  ).format("MMM DD, YYYY")}
                  view_count={item ? format_view_count(item?.views) : ""}
                  reading_time={item ? item?.reading_time : ""}
                  type={item?.record_type}
                  perma_link={item ? item?.perma_link : ""}
                  thumbnail_img={item?.thumbnail_img}
                />
              </div>
            ))
          ) : (
            <></>
          )}
        </Carousel>
      </div>
    )
  );
};

export default TrendingStoriesList;
