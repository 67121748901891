import React from "react";
import CustomFactCheckCategories from "../custom/custom_fact_check_categories";
import CustomButton from "../custom/custom_button";
import MediaShare from "../widgets/media_share";
import moment from "moment";

const PartyWiseArticles = ({
  list,
  handle_see_all_articles,
  category_name,
  category_wise_article_loading,
}) => {
  return (
    <div>
      <div className="top_six_article_card">
        <div className="top_six_article party-wise-article">
          {list?.map((article) => (
            <CustomFactCheckCategories
              loading={category_wise_article_loading}
              category_image={article ? article?.thumbnail_img : ""}
              title={article?.title ? article?.title : ""}
              sub_title={article?.subtitle ? article?.subtitle : ""}
              created_date={
                article
                  ? moment(article.created_date_time).format(`MMM D,YYYY`)
                  : ""
              }
              perma_link={article?.perma_link ? article?.perma_link : ""}
              type={"article"}
              category_name={category_name}
            />
          ))}
          <div className="see-all-stories-button center-alignment">
            <hr className="see-all-divider" />
            <CustomButton
              on_Click={handle_see_all_articles}
              minWidth={"250px"}
              name={
                category_name
                  ? `See All stories in ${category_name}`
                  : "See All stories"
              }
              width={"40%"}
            />
            <hr className="see-all-divider" />
          </div>
        </div>

        <MediaShare />
      </div>
    </div>
  );
};

export default PartyWiseArticles;
