import React, { useEffect, useState } from "react";
import {
  dynamic_clear,
  dynamic_request,
  mutation_add_article_book_mark,
  mutation_delete_article_book_mark,
  mutation_report_article,
  useDynamicSelector,
} from "../../../../services/redux";
import SingleArticleContentDetails from "./single_article_content_details";
import { Form, Row } from "antd";
import ShareReportSave from "../share_report_save";
import ReportsCard from "../../widgets/reports_card";
import DonationCard from "../../widgets/donation_card";
import JoinWhatsAppChannel from "../join_whatsapp_channel";
import SocialMediaList from "../../widgets/social_media_list";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { retrieveItem } from "../../../../helpers/functions";
import toast from "react-hot-toast";
import CustomReportModel from "../../custom/custom_report_model";

const SingleArticleContent = () => {
  const [model_open, set_model_open] = useState(false);

  const dispatch = useDispatch();
  const { perma_link } = useParams();
  const [form] = Form.useForm();
  const session_id = retrieveItem("session_id");
  const role = retrieveItem("role");
  const history = useHistory();

  const {
    id,
    title,
    thumbnail_img,
    category_tags,
    views,
    is_saved: book_mark_save,
  } = useDynamicSelector("get_article_by_perma_link");

  const { status: delete_article_status, error: delete_article_error } =
    useDynamicSelector("remove_article_from_saved_items");

  const { status: create_report_status, error: create_report_error } =
    useDynamicSelector("create_report_article");

  // useEffect(() => {
  //   if (create_report_status === "Success") {
  //     toast.success("Report submitted");
  //     set_model_open(false);
  //     form.resetFields("");
  //     dispatch(dynamic_clear("create_report_article"));
  //   } else if (create_report_error) {
  //     toast.error(create_report_error?.message);
  //   }
  //   dispatch(dynamic_clear("create_report_article"));
  // }, [create_report_status, create_report_error]);

  const add_article_in_book_mark = () => {
    let key = [{ key: "add_article_to_saved_items", loading: true }];
    let query = mutation_add_article_book_mark;
    let variables = {
      article_id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const delete_article_in_book_mark = () => {
    let key = [{ key: "remove_article_from_saved_items", loading: true }];
    let query = mutation_delete_article_book_mark;
    let variables = {
      article_id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const report = (values) => {
    let key = [{ key: "create_report_article", loading: true }];
    let query = mutation_report_article;
    let variables = {
      json: {
        article_id: id,
        reason_id: values?.reason,
        description: values?.description,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_save = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      if (book_mark_save) {
        delete_article_in_book_mark();
      } else if (book_mark_save === false) {
        add_article_in_book_mark();
      }
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "bookmark");
      queryParams.set("types", "article");
      queryParams.set("id", id);
      queryParams.set("redirecturl", window.location.pathname);
      history.push({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  return (
    <div className="article_page_content_row">
      <div className="article_page_content_col">
        <SingleArticleContentDetails />

        <Row className="share_report_save">
          <ShareReportSave
            views={views}
            is_saved={book_mark_save}
            handle_save={handle_save}
            title={title}
            thumbnail_img={thumbnail_img}
          />
        </Row>
        <Row className="report_card">
          <ReportsCard img_top={"3px"} />
        </Row>
      </div>

      <div className="single_article_donation_and_social_media">
        <div className="single_article_donation_card">
          <DonationCard height={"89vh"} />
          <JoinWhatsAppChannel />

          <div className="single_article_social_media">
            <SocialMediaList width={"100%"} />
          </div>
        </div>
      </div>
      {/* <CustomReportModel
        model_open={model_open}
        set_model_open={set_model_open}
        report={report}
        form={form}
      /> */}
    </div>
  );
};

export default SingleArticleContent;
