import { gql } from "@apollo/client";

export const file_upload = gql`
  query get_upload_url($type: String, $fileName: String) {
    get_upload_url(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_user_profile = gql`
  mutation update_user($data: system_user_update_input) {
    update_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const quey_get_user_profile = gql`
  query get_user($id: String!) {
    get_user(id: $id) {
      id
      email
      mobile
      user_profile {
        name
        email
        mobile
        profile_pic
        url
        about
      }
      staff_profile {
        name
        email
        mobile
        url
        profile_pic
        about
      }
      roles {
        id
        name
      }
    }
  }
`;

export const mutation_delete_my_profile_pic = gql`
  mutation {
    delete_my_profile_pic {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
