import React from "react";
import { CONTRIBUTORS } from "../../../helpers/image_constants";
import { Col, Image, Row, Typography } from "antd";
import CustomText from "./custom_text";
import { FaPen } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import { format_view_count } from "../../../helpers/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const CustomContributors = (props) => {
  const history = useHistory();
  const {
    contributor_name,
    works,
    views,
    joined,
    contributor_image,
    user_profile_id,
  } = props;

  const get_user_profile = (contributor) => {
    const queryParams = new URLSearchParams();

    if (user_profile_id) {
      queryParams.set("user_profile", user_profile_id);
    }
    queryParams.set("type", "fact_check");

    history.push({
      pathname: `/author/${contributor_name}`,
      search: `?${queryParams.toString()}`,
    });
  };
  return (
    <div
      style={{
        position: "relative",
        height: "35vh",
        width: "20vw",
      }}
      onClick={() => get_user_profile(user_profile_id)}
    >
      <Image
        style={{
          maxWidth: "30vw",
          height: "30vh",
          zIndex: 1,
          width: "31vh",
          borderRadius: "9px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        visible={false}
        preview={false}
        src={contributor_image}
      />

      <Col
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "15vh",
          width: "16vw",
          borderRadius: "2px",
          zIndex: 2,
          marginTop: "20vh",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "12vw",
            height: "13vh",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "25px",
              width: "145px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomText
              text={contributor_name}
              font_family={"Kufam"}
              size={"11px"}
              weight={"600"}
            />
          </div>

          <Row
            style={{
              width: "145px",
              height: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "15px",
                  display: "flex",
                  color: "#666376",
                }}
              >
                <div style={{ fontSize: "10px", marginTop: "2px" }}>
                  <FaPen />
                </div>
                <div style={{ marginLeft: "8px" }}>
                  <CustomText
                    text={"Works"}
                    font_family={"Lato"}
                    size={"10px"}
                    weight={"600"}
                    color={"#666376"}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "50px",
                  height: "15px",
                }}
              >
                <CustomText
                  text={works}
                  font_family={"Lato"}
                  size={"10px"}
                  weight={"500"}
                  align={"left"}
                />
              </div>
            </div>
          </Row>

          <Row
            style={{
              width: "145px",
              height: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "15px",
                  display: "flex",
                  color: "#666376",
                }}
              >
                <div style={{ fontSize: "10px", marginTop: "2px" }}>
                  <LuEye />
                </div>
                <div style={{ marginLeft: "8px" }}>
                  <CustomText
                    text={"Views"}
                    font_family={"Lato"}
                    size={"10px"}
                    weight={"600"}
                    color={"#666376"}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "50px",
                  height: "15px",
                }}
              >
                <CustomText
                  text={format_view_count(views)}
                  font_family={"Lato"}
                  size={"10px"}
                  weight={"500"}
                  align={"left"}
                />
              </div>
            </div>
          </Row>

          <Row
            style={{
              height: "15px",
              display: "flex",
              marginLeft: "2vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "15px",
                  display: "flex",
                  color: "#666376",
                }}
              >
                <div style={{ fontSize: "10px", marginTop: "2px" }}>
                  <AiFillClockCircle />
                </div>
                <div style={{ marginLeft: "8px" }}>
                  <CustomText
                    text={"Joined"}
                    font_family={"Lato"}
                    size={"10px"}
                    weight={"600"}
                    color={"#666376"}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "5vw",
                  height: "15px",
                }}
              >
                <CustomText
                  text={joined}
                  font_family={"Lato"}
                  size={"10px"}
                  weight={"500"}
                  align={"left"}
                />
              </div>
            </div>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default CustomContributors;
