import {
  Button,
  Pagination,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import { HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_clear,
  dynamic_request,
  get_all_donations_query,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  mutation_delete_user,
  get_all_user_query,
} from "../../../../services/redux/slices/graphql/graphql_user";
import { useDispatch } from "react-redux";
import DonationDetails from "./donation_details";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { showToast } from "../../../../helpers/functions";
import UserDetailsModal from "./user_deatils_modal";
import { FaIndianRupeeSign } from "react-icons/fa6";
import moment from "moment";
import { render } from "@testing-library/react";
import { countFormat } from "../../../../helpers/constants";

const DonationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [modal_open, set_modal_open] = useState(false);
  const [user_record, set_record] = useState({});
  const [user_id, set_user_id] = useState();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [from_date, set_from_date] = useState("");
  const [to_date, set_to_date] = useState("");
  const [form] = Form.useForm();
  const { Search } = Input;
  const { RangePicker } = DatePicker;

  const {
    loading: delete_loading,
    status: delete_status,
    error: delete_error,
  } = useDynamicSelector("delete_user");

  const {
    items: donations_list,
    loading: list_loading,
    pagination: donations_list_pagination,
  } = useDynamicSelector("get_all_donations");

  useEffect(() => {
    get_donations_list();
  }, [from_date, to_date, page_number]);

  useEffect(() => {
    if (mutation_form_values?.id) {
      form.setFieldsValue(mutation_form_values);
    } else {
      form.resetFields();
    }
  }, [mutation_form_values]);

  useEffect(() => {
    if (delete_status === "Success") {
      showToast({
        type: "success",
        message: "User deleted successfully",
      });
      handle_close();
      dispatch(dynamic_clear("delete_user"));
    } else if (delete_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("delete_user"));
    }
  }, [delete_status, delete_error]);

  const handle_delete = (value) => {
    set_delete_modal(true);
    set_mutation_form_values(value);
  };

  const get_donations_list = (search_value) => {
    let key = [{ key: "get_all_donations", loading: true }];
    let query = get_all_donations_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      search_string: search_value,
      filter: {
        from_date: from_date,
        to_date: to_date,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_close = () => {
    set_mutation_modal(false);
    set_mutation_type(null);
    form.resetFields();
    set_mutation_form_values({});
    set_delete_modal(false);
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_user", loading: true }];
    let query = mutation_delete_user;
    let variables = { id: mutation_form_values?.id };
    dispatch(dynamic_request(key, query, variables));
  };

  const onSearch = (value) => {
    let search_value = value?.target?.value || value;
    if (search_value?.length >= 0) {
      get_donations_list(search_value);
    }
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  const handle_row_click = (record) => {
    set_user_id(record?.system_user?.id);
    set_modal_open(true);
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("table:references_id"),
      dataIndex: "receipt",
      key: "receipt",
    },
    {
      title: t("table:name"),
      key: "name",
      render: (text, record) => {
        const user = record?.system_user?.user_profile?.name || "Anonymous";
        const textDecorationStyle = user === "Anonymous" ? "" : "underline";
        return (
          <span
            onClick={() => handle_row_click(record)}
            style={{ cursor: "pointer", textDecoration: textDecorationStyle }}
          >
            {user}
          </span>
        );
      },
    },
    {
      title: t("table:date"),
      // dataIndex: "name",
      key: "created_date_time",
      render: (record) => {
        return moment(record?.created_date_time).format("MMM DD, YYYY");
      },
      width: 180,
    },
    {
      title: t("table:address"),
      key: "address",
      render: (record) => {
        return record?.address ? record?.address : "-";
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (record) => {
        const mobile =
          record?.system_user?.user_profile?.mobile || record?.mobile;
        return mobile ? mobile : "-";
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (amount) => (
        <>
          <FaIndianRupeeSign />
          {amount}
        </>
      ),
    },
  ];

  const set_date_range = (dateString, date) => {
    set_from_date(date[0]);
    set_to_date(date[1]);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} alignItems={"end"} space={"10px"}>
        <div style={{ width: "200px" }}>
          <RangePicker
            format="YYYY-MM-DD"
            onChange={set_date_range}
            className="admin-donation-list-date-picker"
          />
        </div>
        <Search
          enterButton={true}
          onSearch={onSearch}
          allowClear={true}
          onChange={onSearch}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
      </HStack>
      <Table
        columns={columns}
        loading={list_loading}
        dataSource={donations_list}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => handle_row_click(record),
        //     className: "table-cursor",
        //   };
        // }}
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={donations_list_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
      <div style={{ backgroundColor: "pink" }}>
        <UserDetailsModal
          set_modal_open={set_modal_open}
          modal_open={modal_open}
          user_record={user_record}
          user_id={user_id}
        />
      </div>

      <Modal
        open={mutation_modal}
        title={
          mutation_type?.includes("add") ? t("add_user") : t("update_user")
        }
        onCancel={handle_close}
        footer={null}
      >
        <DonationDetails
          onCancel={handle_close}
          form={form}
          action_type={mutation_type}
          // get_all_list={get_all_list}
          form_values={mutation_form_values}
        />
      </Modal>

      <Modal
        open={delete_modal}
        title={t("delete_confirmation")}
        footer={null}
        onCancel={handle_close}
      >
        <span>
          Are you sure you want to delete {mutation_form_values?.name}?
        </span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_loading}
          >
            {t("delete")}
          </Button>
          <Button onClick={handle_close} danger>
            {t("cancel")}
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default DonationList;
