import React from "react";
import CustomText from "../custom/custom_text";
import { Image, Skeleton } from "antd";
import CustomJoinWhatsappChannel from "../custom/custom_join_whatsapp_channel";
import { useDynamicSelector } from "../../../services/redux";
import {
  FAKE_NEWS,
  FALSE_NEWS,
  MISLEADING_NEWS,
  MIX_NEWS,
  SARCASM_NEWS,
  TRUE_NEWS,
} from "../../../helpers/image_constants";
import { useTranslation } from "react-i18next";

const SingleFactCheckClaim = () => {
  const { t } = useTranslation();
  const { is_fact, loading: ger_all_fact_check_loading } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );

  const image_source_map = {
    true: TRUE_NEWS,
    false: FALSE_NEWS,
    fake: FAKE_NEWS,
    mix: MIX_NEWS,
    sarcasm: SARCASM_NEWS,
    misleading: MISLEADING_NEWS,
  };
  const image_source = image_source_map[is_fact] || null;

  return (
    <div className="single-fact-check-claim-column">
      <div className="single-fact-check-content-title-div">
        <CustomText
          class_name="single-fact-check-rating-text"
          text={t("youTurn_rating")}
          color={"#140F2D"}
          font_family={"Kufam"}
          align={"left"}
          weight={"700"}
        />
      </div>

      {ger_all_fact_check_loading ? (
        <Skeleton.Input
          className="skeleton-fact-check-rating"
          style={{
            width: "60vh",
            height: "40vh",
          }}
          active
        />
      ) : (
        <div className="single-fact-check-fake-image-div ">
          <Image
            visible={false}
            preview={false}
            src={image_source}
            className="single-fact-check-rating-image-size"
          />
        </div>
      )}

      <div className="hide-mobile">
        <CustomJoinWhatsappChannel />
      </div>
    </div>
  );
};

export default SingleFactCheckClaim;
