import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  get_verify_mobile_otp_query,
  useDynamicSelector,
} from "../../../services/redux";
import toast from "react-hot-toast";
``;
import OtpInputFieldAndButtons from "./otp_input_field_and_buttons";

function OTPScreen(props) {
  const { mobile_number, mutation_sign_in, all_values, get_otp_api_call } =
    props;

  const dispatch = useDispatch();
  const form_ref = useRef(null);
  const inputs_ref = useRef([]);
  const [show_resend_message, set_show_resend_message] = useState(false);
  const [counter, set_counter] = useState(30);
  const [otp, set_otp] = useState("");
  const {
    status: verify_otp_status,
    error: verify_otp_error,
    loading: verify_otp_loading,
  } = useDynamicSelector("verify_mobile_otp");

  const verify_otp_api_call = (otp) => {
    let key = [{ key: "verify_mobile_otp", loading: true }];
    let query = get_verify_mobile_otp_query;
    let variables = {
      mobile: mobile_number,
      otp: otp,
    };

    dispatch(dynamic_request(key, query, variables));
  };
  useEffect(() => {
    if (verify_otp_status === "Success") {
      mutation_sign_in(all_values);
      dispatch(dynamic_clear("verify_mobile_otp"));
    } else if (verify_otp_status === "Failure") {
      toast.error(verify_otp_error.message);
      dispatch(dynamic_clear("verify_mobile_otp"));
    }
    dispatch(dynamic_clear("verify_mobile_otp"));
  }, [verify_otp_status, verify_otp_error]);
  useEffect(() => {
    if (counter <= 30) {
      const timer =
        counter > 0 && setInterval(() => set_counter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);
  useEffect(() => {
    const form = form_ref.current;
    const inputs = [...form.querySelectorAll("input[type=text]")];
    inputs_ref.current = inputs;
    const submit = form.querySelector("button[type=submit]");

    const handle_key_down = (e) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        const index = inputs.indexOf(e.target);
        if (index > 0) {
          inputs[index - 1].value = "";
          inputs[index - 1].focus();
        }
      }
    };

    const handle_input = (e) => {
      const { target } = e;
      const index = inputs.indexOf(target);
      if (target.value) {
        const new_otp = inputs.map((input) => input.value).join("");
        set_otp(new_otp);
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit?.focus();
        }
      }
    };

    const handle_focus = (e) => {
      e.target.select();
    };

    const handle_paste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputs.forEach((input, index) => (input.value = digits[index]));
      submit.focus();
    };

    inputs.forEach((input) => {
      input.addEventListener("input", handle_input);
      input.addEventListener("keydown", handle_key_down);
      input.addEventListener("focus", handle_focus);
      input.addEventListener("paste", handle_paste);
    });

    const timer = setTimeout(() => {
      set_show_resend_message(true);
    }, 30000);

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handle_input);
        input.removeEventListener("keydown", handle_key_down);
        input.removeEventListener("focus", handle_focus);
        input.removeEventListener("paste", handle_paste);
      });

      clearTimeout(timer);
    };
  }, []);

  const handle_verify = () => {
    verify_otp_api_call(otp);
    const inputs = form_ref.current.querySelectorAll("input[type='text']");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  const resend_otp = () => {
    set_counter(30);
    get_otp_api_call(all_values);
    const inputs = form_ref.current.querySelectorAll("input[type='text']");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  return (
    <div className="relative font-inter antialiased">
      <main className="relative min-h-screen flex flex-col justify-center bg-slate-50 overflow-hidden">
        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24 otp_screen_second_div">
          <div className="flex justify-center">
            <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
              <header className="mb-8">
                <h1 className="text-1xl font-bold mb-1">OTP Verification</h1>
                <p className="text-[15px] text-slate-500">
                  {`Enter the 4-digit verification code sent to your
    ${mobile_number}`}
                </p>
              </header>
              <OtpInputFieldAndButtons
                handle_verify={handle_verify}
                form_ref={form_ref}
                verify_otp_loading={verify_otp_loading}
                resend_otp={resend_otp}
                counter={counter}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
export default OTPScreen;
