import Editor from "vc-editor";

import RichTextEditor from "./editor";

const RichText = (props) => {
  const {
    type = "lexical",
    content,
    set_content,
    is_disabled = false,
    disabled,
  } = props;

  const handle_change = (html, json) => {
    if (html || json) {
      set_content({ html: html || "", json: html ? json : "" });
    }
  };

  if (type === "lexical") {
    return (
      <div style={{ width: "100%", marginBottom: "10px" }}>
        <Editor
          json_content={content?.json || ""}
          html_content={content.html || ""}
          on_change={handle_change}
          disabled={disabled ? true : false}
        />
      </div>
    );
  } else if (type === "rich_text") {
    return (
      <div style={{ width: "100%", marginBottom: "10px" }}>
        <RichTextEditor
          value={content?.html || ""}
          set_value={set_content}
          is_disabled={is_disabled}
        />
      </div>
    );
  }
};
export default RichText;
