import { Button, Dropdown, Form, Menu, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AdminFactCheckForm from "../admin_fact_check/admin_fact_check_form";
import { HStack, VStack } from "native-base";
import PageTitle from "../../system/page_title/page_title";
import { navigate } from "../../../../helpers/navigator";
import { ROUTES } from "../../../routes/my_routes";
import { UploadOutlined } from "@ant-design/icons";
import {
  dynamicSet,
  dynamic_clear,
  dynamic_request,
  fact_check_process_query,
  query_fact_check_preview,
  save_fact_check_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { error } from "jquery";
import {
  get_tag,
  navigateBack,
  navigateWithProps,
  retrieveItem,
  showToast,
} from "../../../../helpers/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { QueryRequest } from "../../../../services/apollo/api_service";
import { fileQuery } from "../../../../helpers/constants";
import axios from "axios";

dayjs.extend(utc);
dayjs.extend(timezone);

const FactCheckDetails = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const current_time = dayjs(new Date()).format("HH:mm:ssZ");
  console.log("current_time", current_time);

  const fact_check_title = Form.useWatch("title", form);
  const fact_check_sub_title = Form.useWatch("subtitle", form);
  const fact_check_language_id = Form.useWatch("language_id", form);
  const fact_check_perma_link = Form.useWatch("perma_link", form);
  const back_button_state = location?.props_state?.set_back_button_click;
  const fact_check_type_location = location?.props_state?.fact_check_type;
  const back_button_default_language =
    location?.props_state?.default_language?.children;
  let current_language_id_new = location?.props_state?.default_language?.value;

  const { id } = useParams();
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const { t } = useTranslation();

  const [selected_item, set_selected_item] = useState({});
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [action_type, set_action_type] = useState("");
  const [preview_type, set_preview_type] = useState("");
  const [modal_type, set_modal_type] = useState("");
  const [save_fact_check_id_state, set_save_fact_check_id_state] = useState();
  const [publish_date, set_publish_date] = useState(null);
  const [content, set_content] = useState({ html: null, json: null });
  const [spread_news, set_spread_news] = useState({ html: null, json: null });
  const [short_story, set_short_story] = useState({ html: null, json: null });
  const [source, set_source] = useState({ html: null, json: null });
  const [initial_save, set_initial_save] = useState(false);
  const [file_list, set_file_list] = useState({});
  const [publish_time, set_publish_time] = useState(null);
  const [modal_open, set_modal_open] = useState(false);

  const { status, error, loading } = useDynamicSelector("process_fact_check");
  const {
    id: save_id,
    status: save_fact_check_status,
    loading: save_fact_check_loading,
    error: save_fact_check_error,
  } = useDynamicSelector("save_fact_check");

  const data = useDynamicSelector("get_fact_check");

  let current_language_id = data?.language_id;

  const { loading: create_fact_check_loading } =
    useDynamicSelector("create_fact_check");

  const {
    loading: update_fact_check_loading,
    status: update_fact_check_status,
  } = useDynamicSelector("update_fact_check");

  useEffect(() => {
    if (status === "Success") {
      if (action_type === "approve") {
        showToast({
          type: "success",
          message: "Fact check Approved Successfully",
        });
      } else if (action_type === "reject") {
        showToast({
          type: "success",
          message: "Fact check Rejected Successfully",
        });
      } else if (action_type === "published") {
        showToast({
          type: "success",
          message:
            modal_type === "schedule"
              ? "Fact check scheduled Successfully"
              : "Fact check Published Successfully",
        });
      } else if (action_type === "unpublished") {
        showToast({
          type: "success",
          message: "Fact check Unpublished Successfully",
        });
      } else if (action_type === "submit") {
        if (role === "Admin") {
          showToast({
            type: "success",
            message:
              modal_type === "schedule"
                ? "Fact check scheduled Successfully"
                : "Fact check Published Successfully",
          });
        } else {
          showToast({
            type: "success",
            message: "Fact check Submitted Successfully",
          });
        }
      }
      navigateWithProps(`${ROUTES.ADMIN_FACT_CHECK}`, {
        fact_check_type_location,
        back_button_default_language,
        current_language_id_new,
        back_button_state: true,
        set_default_language: () =>
          set_default_language(back_button_default_language),
      });
      dispatch(dynamic_clear("process_fact_check"));
    } else if (error) {
      toast.error("Something went wrong");
      dispatch(dynamic_clear("process_fact_check"));
    }
  }, [status, error, action_type, role, modal_type]);

  useEffect(() => {
    if (id && data?.id) {
      let formatted_date = null;
      let formatted_time = null;
      if (data?.status === "draft") {
        formatted_date = null;
        formatted_time = null;
      } else if (data?.published_date_time) {
        const moment_object = dayjs(data?.published_date_time).tz(
          "Asia/Kolkata"
        );
        formatted_date = moment_object
          .subtract(5, "hour")
          .subtract(30, "minute")
          .format("YYYY-MM-DD");
        formatted_time = moment_object;
      } else {
        (formatted_date = dayjs(new Date())),
          (formatted_time = dayjs(current_time, "HH:mm:ssZ"));
      }
      form.setFieldsValue({
        ...data,
        user_claims: data?.user_claims?.map((x) => x.id),
        category_tags: data?.category_tags?.map((x) => x.id),
        party_tags: data?.party_tags?.map((x) => x.id),
        anti_party_tags: data?.anti_party_tags?.map((x) => x.id),
        // notify_post:data?.notify_post,
        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ssZ")
          : null,
        thumbnail_img: data?.thumbnail_img
          ? [
              {
                url: data?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });

      set_publish_date(
        formatted_date
          ? moment(new Date(formatted_date)).format("YYYY-MM-DD")
          : null
      );
      set_publish_time(
        formatted_time
          ? moment(new Date(formatted_time)).format("HH:mm:ssZ")
          : null
      );

      set_selected_item({
        ...data,
        user_claims: data?.user_claims?.map((x) => x.id),
        category_tags: data?.category_tags?.map((x) => x.id),
        party_tags: data?.party_tags?.map((x) => x.id),
        anti_party_tags: data?.anti_party_tags?.map((x) => x.id),
        // notify_post:data?.notify_post,
        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ssZ")
          : null,
        content: {
          html: data?.content || "",
          json: data?.content_json || "",
        },
        spread_news: {
          html: data?.spread_news || "",
          json: data?.spread_news_json || "",
        },
        short_story: {
          html: data?.short_story || "",
          json: data?.short_story_json || "",
        },
        source: { html: data?.source || "", json: data?.source_json || "" },
        thumbnail_img: data?.thumbnail_img?.[0]
          ? [
              {
                url: data?.thumbnail_img?.[0]?.url,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
      if (data?.content) {
        set_content({
          html: data?.content || "",
          json: data?.content_json || "",
        });
      }
      if (data?.spread_news) {
        set_spread_news({
          html: data?.spread_news || "",
          json: data?.spread_news_json || "",
        });
      }
      if (data?.short_story) {
        set_short_story({
          html: data?.short_story || "",
          json: data?.short_story_json || "",
        });
      }
      if (data?.source) {
        set_source({
          html: data?.source || "",
          json: data?.source_json || "",
        });
      }
    }
  }, [id, data]);
  useEffect(() => {
    if (!id) {
      dispatch(dynamic_clear("get_fact_check"));
      form.setFieldsValue({
        published_date: dayjs(new Date()),
        published_time: dayjs(current_time, "HH:mm:ssZ"),
      });
      set_publish_date(dayjs(new Date()).format("YYYY-MM-DD"));
      set_publish_time(current_time);
    }
  }, [id]);

  useEffect(() => {
    if (save_fact_check_status === "Success") {
      const id = save_id;
      dispatch(dynamicSet("is_initial_save", { is_initial_save: true }));
      set_save_fact_check_id_state(save_id);
      set_modal_type("");
      set_modal_open(false);
      toast.success("Fact check Saved successfully");
      dispatch(dynamic_clear("save_fact_check"));
      navigate(`${ROUTES.ADMIN_FACT_CHECK_DETAILS}/${id}`);
    } else if (save_fact_check_error) {
      dispatch(dynamic_clear("save_fact_check"));
    }
  }, [save_fact_check_status, save_fact_check_error]);

  const handle_back = () => {
    navigateWithProps(`${ROUTES.ADMIN_FACT_CHECK}`, {
      fact_check_type_location,
      back_button_default_language,
      current_language_id_new,
      back_button_state: true,
    });
    dispatch(dynamic_clear("get_fact_check"));
  };

  const mutation_save_fact_check = () => {
    const json = form.getFieldsValue();
    let key = [{ key: "save_fact_check", loading: true }];
    let query = save_fact_check_query;
    let data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      anti_party_tags: json?.anti_party_tags,
      is_fact: json?.is_fact,
      perma_link: json?.perma_link,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content?.html || null,
      content_json: content.json || null,
      spread_news: spread_news.html || null,
      short_story: short_story?.html || null,
      source: source.html || null,
      spread_news_json: spread_news.json || null,
      short_story_json: short_story.json || null,
      source_json: source.json || null,
    };
    delete data.party_tag_id;
    let variables = {
      json: data,
    };
    // if (role === "Admin") {
    //   variables.json.published_date_time = `${publish_date}T${publish_time}`;
    // }
    dispatch(dynamic_request(key, query, variables));
  };

  const get_one_fact_check = () => {
    let key = [{ key: "get_fact_check", loading: true }];
    let query = query_fact_check_preview;
    let variables = {
      id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    if (id) {
      get_one_fact_check();
      dispatch(dynamicSet("is_initial_save", { is_initial_save: true }));
    } else {
      dispatch(dynamic_clear("is_initial_save"));
    }
  }, [id]);

  useEffect(() => {
    if (
      fact_check_title &&
      fact_check_language_id &&
      !id &&
      data?.status !== "published"
    ) {
      const timer = setTimeout(() => {
        mutation_save_fact_check();
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [fact_check_title, fact_check_language_id]);

  const on_click_save_fact_check = () => {
    if (fact_check_title && fact_check_language_id) {
      mutation_save_fact_check();
    } else {
      toast.error("Title and language is required");
    }
  };

  const menu_items = [
    {
      key: "publish_now",
      label: "Publish Now",
    },

    {
      key: "schedule_publish",
      label: "Schedule Publish",
    },
  ];

  const publish_fact_check = (value) => {
    if (
      form.getFieldValue("thumbnail_img") &&
      form.getFieldValue("thumbnail_img").length !== 0
    ) {
      if (value.key === "publish_now") {
        set_modal_type("");
        if (data?.status === "draft") {
          set_action_type("submit");
        } else if (
          data?.status === "pending" ||
          data?.status === "unpublished" ||
          data?.status === "approved_by_editor"
        ) {
          set_action_type("published");
        } else {
          set_action_type("create");
        }
        set_modal_open(true);
      } else if (value.key === "schedule_publish") {
        set_modal_type("schedule");
        if (data?.status === "draft") {
          set_action_type("submit");
        } else if (
          data?.status === "pending" ||
          data?.status === "unpublished" ||
          data?.status === "approved_by_editor"
        ) {
          set_action_type("published");
        } else {
          set_action_type("create");
        }
        set_modal_open(true);
      }
    } else {
      toast.error("Please upload thumbnail image");
    }
  };

  return (
    <>
      <PageTitle title={!id ? "Add FactCheck" : "Update FactCheck"} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {id &&
            data?.id &&
            (moment(data?.published_date_time).isAfter(moment())
              ? get_tag("scheduled")
              : get_tag(data?.status))}
          {id &&
            data?.published_date_time &&
            data?.status !== "unpublished" && (
              <span>
                {moment(data?.published_date_time).isAfter(moment())
                  ? "Scheduled on: "
                  : "Published on: "}
                {moment(data?.published_date_time).format("DD-MM-YYYY hh:mm A")}
              </span>
            )}
        </div>
      </div>
      <VStack padding={"30px"}>
        <AdminFactCheckForm
          selected_item={id ? selected_item : {}}
          modal_title={!id ? "add" : "edit"}
          form={form}
          status={data?.status}
          action_type={action_type}
          preview_type={preview_type}
          current_language_id={current_language_id}
          setSaveTriggered={setSaveTriggered}
          saveTriggered={saveTriggered}
          save_fact_check_id_state={save_fact_check_id_state}
          set_publish_date={set_publish_date}
          publish_date={publish_date}
          set_content={set_content}
          set_spread_news={set_spread_news}
          set_short_story={set_short_story}
          set_source={set_source}
          short_story={short_story}
          source={source}
          content={content}
          spread_news={spread_news}
          fact_check_type_location={fact_check_type_location}
          get_one_fact_check={get_one_fact_check}
          publish_time={publish_time}
          set_publish_time={set_publish_time}
          set_action_type={set_action_type}
          set_modal_open={set_modal_open}
          modal_open={modal_open}
          modal_type={modal_type}
        />
        <div
          style={{
            height: "80px",
            position: "fixed",
            bottom: "-10px",
            right: "30px",
            backgroundColor: "#f0f2f5",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Button onClick={handle_back} danger>
            {t("back")}
          </Button>
          {!id && (
            <Button
              onClick={on_click_save_fact_check}
              style={{ backgroundColor: "#cc972c", color: "white" }}
              loading={save_fact_check_loading}
            >
              {t("Save")}
            </Button>
          )}
          {id &&
            data?.status != "published" &&
            data?.status != "approved_by_editor" &&
            data?.status != "draft" &&
            role != "Admin" &&
            data?.user_profile?.id && (
              <div>
                <Button
                  danger
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    set_action_type("reject");
                    form.submit();
                  }}
                >
                  Reject
                </Button>
                <Button
                  style={{ backgroundColor: "green", color: "white" }}
                  form="fact_check_form"
                  onClick={() => {
                    set_action_type("approve");
                    form.submit();
                  }}
                >
                  {"Approve"}
                </Button>
              </div>
            )}
          {!id &&
            (role === "Admin" ? (
              <Dropdown
                overlay={
                  <Menu
                    items={menu_items}
                    onClick={(e) => publish_fact_check(e)}
                  />
                }
                trigger={["click"]}
              >
                <Button
                  className="custom-dropdown-menu"
                  style={{ width: "190px" }}
                  form="fact_check_form"
                  loading={create_fact_check_loading}
                >
                  Create & Publish
                </Button>
              </Dropdown>
            ) : (
              <Button
                onClick={() => {
                  set_action_type("create");
                  form.submit();
                }}
                loading={create_fact_check_loading}
                style={{ backgroundColor: "#1e90ff", color: "white" }}
              >
                {"Submit"}
              </Button>
            ))}

          {data?.id && id && (
            <Button
              form="fact_check_form"
              loading={update_fact_check_loading}
              onClick={() => {
                set_action_type("preview");
                form.submit();
              }}
              style={{
                backgroundColor: "#F39C12",
                color: "white",
              }}
            >
              {"Preview"}
            </Button>
          )}
          {data?.id &&
            id &&
            !(role === "Editor" && data?.status === "published") && (
              <Button
                style={{ backgroundColor: "#1e90ff", color: "white" }}
                form="fact_check_form"
                loading={update_fact_check_loading}
                onClick={() => {
                  set_action_type("update");
                  form.submit();
                }}
              >
                {data?.status === "published" &&
                moment(data?.published_date_time).isSameOrBefore(moment())
                  ? "Update & Republish"
                  : "Update "}
              </Button>
            )}

          {id &&
            data?.id &&
            data?.status === "published" &&
            role === "Admin" &&
            moment(data?.published_date_time).isSameOrBefore(moment()) && (
              <Button
                onClick={() => {
                  set_action_type("unpublished");
                  form.submit();
                }}
                form="fact_check_form"
                loading={loading}
                style={{ backgroundColor: "#ff1a1a", color: "white" }}
              >
                {"Unpublish"}
              </Button>
            )}
          {data?.id &&
            data?.status != "published" &&
            role === "Admin" &&
            data?.status != "draft" && (
              <Dropdown
                overlay={
                  <Menu
                    items={menu_items}
                    onClick={(e) => publish_fact_check(e)}
                  />
                }
                trigger={["click"]}
              >
                <Button
                  className="custom-dropdown-menu"
                  style={{ width: "110px" }}
                  loading={loading}
                >
                  Publish
                </Button>
              </Dropdown>
            )}
          {id &&
            data?.status === "draft" &&
            (role === "Admin" ? (
              <Dropdown
                overlay={
                  <Menu
                    items={menu_items}
                    onClick={(e) => publish_fact_check(e)}
                  />
                }
                trigger={["click"]}
              >
                <Button
                  style={{ width: "110px" }}
                  loading={loading}
                  className="custom-dropdown-menu"
                >
                  Publish
                </Button>
              </Dropdown>
            ) : (
              <Button
                style={{ backgroundColor: "#008000", color: "white" }}
                loading={loading}
                htmlType="submit"
                onClick={() => {
                  set_action_type("submit");
                  form.submit();
                }}
              >
                {"Submit"}
              </Button>
            ))}
        </div>
      </VStack>
    </>
  );
};

export default FactCheckDetails;
