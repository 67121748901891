import { Image, Popover } from "antd";
import React, { useEffect, useState } from "react";
import {
  REPORT_ICON_WHITE,
  SAVE_WHITE_FILLED,
  SAVE_WHITE_OUTLINED,
  SHARE_NEW,
} from "../../../helpers/image_constants";
import {
  getContent,
  navigate,
  retrieveItem,
  storeItem,
} from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import { useDynamicSelector } from "../../../services/redux";
import { SITE_URL } from "../../../helpers/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";

const SingleFactCheckReportShareSave = (props) => {
  const { is_saved, handle_save, set_model_open } = props;
  const [share_url, set_share_url] = useState();
  const { pathname } = useLocation();
  let current_language = retrieveItem("language");
  const url = window?.location?.href;
  const session_id = retrieveItem("session_id");
  const role = retrieveItem("role");

  const { thumbnail_img, title } = useDynamicSelector(
    "get_fact_check_by_perma_link"
  );

  const report_click = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      set_model_open(true);
    } else {
      storeItem("redirectPath", window.location.pathname);
      navigate(ROUTES.ADMIN_LOGIN);
    }
  };

  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    toast.success("Link Copied");
  };

  const content = getContent(
    title,
    share_url,
    thumbnail_img,
    copy_to_clipboard
  );

  useEffect(() => {
    if (current_language?.name === "tamil") {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}${link_without_html}`;
      set_share_url(url);
    } else {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}/en${link_without_html}`;
      set_share_url(url);
    }
  }, [pathname, SITE_URL]);

  return (
    <div className="report-share-icon-div">
      <Image
        style={{
          width: "20px",
          cursor: "pointer",
        }}
        visible={false}
        preview={false}
        src={REPORT_ICON_WHITE}
        onClick={report_click}
      />
      <Popover
        placement="top"
        content={content}
        trigger="hover"
        className="share_pop_over"
      >
        <Image
          style={{
            width: "20px",
            cursor: "pointer",
          }}
          visible={false}
          preview={false}
          src={SHARE_NEW}
        />
      </Popover>
      {is_saved ? (
        <Image
          style={{
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          visible={false}
          preview={false}
          src={SAVE_WHITE_FILLED}
          onClick={handle_save}
        />
      ) : (
        <Image
          style={{
            width: "20px",
            cursor: "pointer",
            height: "20px",
          }}
          visible={false}
          preview={false}
          src={SAVE_WHITE_OUTLINED}
          onClick={handle_save}
        />
      )}
    </div>
  );
};

export default SingleFactCheckReportShareSave;
