import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../../../services/redux";

const FeaturedUserContribution = (props) => {
  const {
    set_type,
    set_article_id,
    set_fact_check_id,
    record_type,
    items,
    is_editing,
  } = props;

  const on_change = (value) => {
    set_type("user_contribution");
    if (record_type === "articles") {
      set_article_id(value);
    } else {
      set_fact_check_id(value);
    }
  };

  return (
    <>
      <Form.Item
        name="user_contribution_id"
        rules={[
          {
            required: true,
            message:
              record_type === "articles"
                ? "Please choose the article"
                : "Please choose the fact check",
          },
        ]}
      >
        {is_editing ? (
          <Select allowClear onChange={on_change}>
            {items?.map((option, index) => (
              <Select.Option key={option.id} value={option.id}>
                {option.title}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input readOnly disabled={true} />
        )}
      </Form.Item>
    </>
  );
};

export default FeaturedUserContribution;
