import React, { useEffect, useRef, useState } from "react";
import {
  dynamic_request,
  query_get_all_party_tag,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";

import { style } from "styled-system";
import { retrieveItem } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../contexts/dark_mode_provider";

const PartyWiseTag = (props) => {
  const { set_party_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const { items: party_list } = useDynamicSelector("get_all_party_tag");
  const [categories_five, set_categories_five] = useState([]);
  const [show_more, set_show_more] = useState(true);
  const { is_dark_mode } = useDarkMode();
  const [category_key, set_category_key] = useState(t("all"));

  useEffect(() => {
    if (party_list) {
      set_categories_five([
        { id: "All", name: t("all") },
        ...party_list?.slice(0, 7),
        { id: "More", name: t("more") },
      ]);
    }
  }, [party_list]);

  const handle_click = (category, category_name) => {
    if (category !== "More" && category !== "மேலும்") {
      set_party_id(category_name);
    } else if (category === "More" || category === "மேலும்") {
      set_show_more(false);
      set_category_key((pre) => pre);
    }
    if (category !== "More" && category !== "மேலும்") {
      set_category_key(category);
    }

    if (category === "More" || category === "மேலும்") {
      set_categories_five([
        { id: "All", name: t("all") },
        ...party_list?.slice(0, 20),
        ...(show_more ? [] : [{ id: "More", name: t("more") }]),
      ]);
    }
  };

  const get_all_party_list = () => {
    let key = [{ key: "get_all_party_tag", loading: true }];
    let query = query_get_all_party_tag;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_all_party_list();
  }, []);

  return (
    <div className="fact_check_category_tags">
      {categories_five?.map((item) => (
        <span
          key={item?.id}
          style={{
            padding: "5px 28px 5px 28px",
            border: "1px solid #0065CC",
            borderRadius: "17px",
            boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor:
              category_key === item?.name && category_key !== t("more")
                ? "#00adee"
                : "transparent",

            color: is_dark_mode
              ? "#FFFF"
              : category_key === item?.name && category_key !== t("more")
              ? "white"
              : "black",
          }}
          onClick={() => handle_click(item?.name, item?.id)}
        >
          {item?.name}
        </span>
      ))}
    </div>
  );
};

export default PartyWiseTag;
