import { Col, Image, Row } from "antd";
import React from "react";
import { DEFAULT_USER } from "../../../helpers/image_constants";
import moment from "moment";
import { LuEye } from "react-icons/lu";
import CustomText from "../custom/custom_text";
import { format_view_count } from "../../../helpers/functions";
import { AiFillClockCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SingleFactCheckMobileAuthorDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    reading_time,
    views,
    created_date_time,
    staff_profile,
    user_profile,
  } = useDynamicSelector("get_fact_check_by_perma_link");

  const on_click = () => {
    const queryParams = new URLSearchParams();
    if (staff_profile?.id) {
      queryParams.set("staff_profile", staff_profile?.id);
    }
    if (user_profile?.id) {
      queryParams.set("user_profile", user_profile?.id);
    }
    history.push({
      pathname: "/author",
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <div className="you-turn-logo-div">
      <Row className="you-turn-logo-row">
        <Col className="fact-check-you-turn-logo-col">
          <Image
            className="single-fact-check-you-turn-logo"
            visible={false}
            preview={false}
            onClick={on_click}
            src={staff_profile?.profile_pic || DEFAULT_USER}
          />
        </Col>
        <Col className="fact-check-date-views-col">
          <CustomText
            class_name={"icon_card_text_bold"}
            text={staff_profile?.name}
            color={"#61666D"}
            font_family={"Lato"}
            align={"left"}
            on_click={on_click}
            is_link={true}
          />

          <CustomText
            class_name={"icon_card_text"}
            text={moment(created_date_time).format(`MMM D, YYYY`)}
            color={"#61666D"}
            weight={"400"}
            font_family={"Lato"}
            align={"left"}
          />
          <div className="eye-image-div">
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col className="single-fact-check-view-col">
                <div className="laptop-eye-image-row">
                  <LuEye className="single-fact-check-eye-icon" />
                </div>

                <CustomText
                  text={format_view_count(views)}
                  size={"2vh"}
                  color={"#E84756"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </Col>
              <Col className="single-fact-check-view-time-col">
                <div className="fact-check-clock-circle-image">
                  <AiFillClockCircle className="single-fact-check-eye-icon" />
                </div>

                <CustomText
                  text={`${reading_time} ${t("min")}`}
                  size={"2vh"}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SingleFactCheckMobileAuthorDetails;
