import { gql } from "@apollo/client";

export const get_all_reports_query = gql`
  query get_all_report(
    $page_number: Int
    $page_limit: Int
    $reason_id: String
    $language_id: String
  ) {
    get_all_report(
      page_number: $page_number
      page_limit: $page_limit
      reason_id: $reason_id
      language_id: $language_id
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id

        reason {
          id
          name
        }

        reported_date_time
        description
        article_id
        fact_check_id

        article {
          id
          title
          perma_link
          language_id
        }
        fact_check {
          id
          title
          perma_link
          language_id
        }
        system_user {
          id
          user_profile {
            name
            mobile
          }
        }
      }
    }
  }
`;

export const get_report_query = gql`
  query get_report($id: String!) {
    get_report(id: $id) {
      id
      article_id
      fact_check_id
      reason_id
      description
      reported_date_time
    }
  }
`;

export const update_report_query = gql`
  mutation update_report($id: String, $json: report_input) {
    update_report(id: $id, json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_report_query = gql`
  mutation delete_report($id: String) {
    delete_report(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const create_reason_query = gql`
  mutation create_reason($json: reason_input) {
    create_reason(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_reason_query = gql`
  mutation update_reason($id: String, $json: reason_input) {
    update_reason(id: $id, json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_reason_query = gql`
  mutation delete_reason($id: String) {
    delete_reason(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_reasons_query = gql`
  query get_all_reason(
    $language_id: String
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    report_reasons: get_all_reason(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        name
        created_date_time
        language_id
      }
    }
  }
`;

export const get_reason_query = gql`
  query get_reason($id: String!) {
    get_reason(id: $id) {
      id
      name
      created_date_time
      language_id
      error {
        message
      }
    }
  }
`;
