import React, { useEffect, useRef, useState } from "react";
import { Box, HStack, Image, Menu, Pressable } from "native-base";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import {
  clearLocalStorage,
  change_tamil_font_style,
  storeItem,
  retrieveItem,
} from "@helpers/functions";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Drawer, Form, Row, Typography } from "antd";
// import ChangePassword from "../change_password/change_password";
import HeaderSearch from "./header_search";
import HeaderBarOption from "./header_bar_option";
import {
  MENU_LOGO,
  SEARCH_IMAGE,
  USER_LOGO,
} from "../../../../helpers/image_constants";
import HeaderBarDrawer from "./header_bar_drawer";
import { ROUTES } from "../../../routes/my_routes";
import SearchBar from "../../headerbar/search_bar";
import { query_get_user } from "../../../../services/redux/slices/graphql/graphql_user_profile";

import { logout_mutation } from "../../../../services/redux";
import { changeLanguage } from "i18next";

const { text } = Typography;

const HeaderBar = (props) => {
  const {
    search_open,
    set_search_open,
    set_search_string_value,
    set_handle_logout,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const role = retrieveItem("role");
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [is_menu_open, set_is_menu_open] = useState(false);
  const [log_out_text, set_log_out_text] = useState("Logout");
  const [form] = Form.useForm();
  const session_id = retrieveItem("session_id");
  const is_initial = retrieveItem("isInitial");
  const user_id = retrieveItem("user_id");
  let chosen_language_key = window.sessionStorage.getItem(
    "chosen_language_key"
  );

  const {
    user_profile,
    staff_profile,
    roles: get_user_roles,
  } = useDynamicSelector("get_user");
  const { status: log_out_status } = useDynamicSelector("logout");

  useEffect(() => {
    if (user_id) {
      get_user_profile();
    }
  }, [user_id]);

  const get_user_profile = () => {
    let key = [{ key: "get_user", loading: true }];
    let query = query_get_user;
    let variables = { id: user_id };
    dispatch(dynamic_request(key, query, variables));
  };
  const log_out_api_call = () => {
    let key = [{ key: "logout", loading: true }];
    let query = logout_mutation;
    dispatch(dynamic_request(key, query));
  };

  const { count } = useDynamicSelector("getUnreadNotificationCount");

  const mobile_width = 767;
  const screenWidth = window.innerWidth;
  const placement = screenWidth <= mobile_width ? "left" : "right";

  useEffect(() => {
    if (log_out_status === "Success") {
      set_handle_logout(log_out_text);
      // if (chosen_language_key === "en") {
      //   storeItem("language", {
      //     id: "ea83e859-ce26-4086-9e85-ec40f5dbe4f0",
      //     name: "english",
      //   });
      //   changeLanguage("en");
      // } else {
      //   storeItem("language", {
      //     id: "839bcc29-853f-4c4c-8700-98fd88558952",
      //     name: "tamil",
      //   });
      //   changeLanguage("ta");
      // }
      clearLocalStorage();
      dispatch(dynamic_clear("get_user"));
      dispatch(dynamic_clear("logout"));
      window.location.reload();
      window.location.replace(ROUTES.LANDING_PAGE);
    }
  }, [log_out_status]);

  const handleLogout = () => {
    dispatch(dynamic_clear("admin_login"));
    storeItem("session_id", null);
    storeItem("roles", []);
    storeItem("role", "");
    storeItem("role_id", "");
    storeItem("page_accesses", []);
    storeItem("page_component_accesses", []);
    log_out_api_call();
  };

  const get_fact = () => {
    history.push(ROUTES.FACT_CHECK);
  };
  const get_articles = () => {
    history.push(ROUTES.ARTICLES);
  };
  const get_videos = () => {
    history.push(ROUTES.VIDEOS);
  };
  const get_info = () => {
    history.push(ROUTES.ABOUT_US);
  };

  const get_menu = () => {
    set_is_menu_open(true);
    set_search_string_value("");
    set_search_open(false);
  };

  const on_close = () => {
    set_is_menu_open(false);
  };

  const handle_home = () => {
    history.push(ROUTES.LANDING_PAGE);
  };

  const handle_redirect_profile = () => {
    history.push(ROUTES.USER_PROFILE);
  };
  const handle_redirect_article = () => {
    history.push(ROUTES.USER_CONTRIBUTED_FORM);
  };
  const handle_redirect_fact_check = () => {
    history.push(ROUTES.USER_FACT_CHECK_CONTRIBUTION_FORM);
  };

  const get_search_box = () => {
    // if (search_open) {
    set_search_open(!search_open);
    // } else set_search_open(search_open);
  };

  const on_route_click = () => {
    history.push(ROUTES.LANDING_PAGE);
  };

  const on_route_click_login = () => {
    history.push(ROUTES.ADMIN_LOGIN);
  };

  return (
    <div>
      <div className="hide_content">
        <Box h={"100px"} w="100%" bg="#ffffff" style={{ alignItems: "center" }}>
          <Box
            h={"100px"}
            w="100%"
            bg="#140f2d"
            style={{ alignItems: "center" }}
          >
            <HStack
              h="100%"
              w="90%"
              bg="#140f2d"
              justifyContent={"space-around"}
            >
              <Row
                style={{ width: "100%", height: "auto", alignItems: "center" }}
              >
                <div
                  className="mobile_menu_logo"
                  style={{
                    width: "97.5%",
                    marginLeft: "20px",
                    height: "50%",
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="mobile_header_image">
                    <Pressable onPress={handle_home}>
                      <Image
                        source={require(`../../../../assets/icons/YT1.png`)}
                        width={"230px"}
                        height={"47px"}
                      />
                    </Pressable>
                  </div>
                  <HeaderSearch />
                  <HeaderBarOption
                    option={change_tamil_font_style(
                      t("header_fact_check"),
                      "2.2vh",
                      _
                    )}
                    on_click={get_fact}
                  />
                  <HeaderBarOption
                    option={change_tamil_font_style(
                      t("header_articles"),
                      "2.2vh",
                      _
                    )}
                    on_click={get_articles}
                  />
                  <HeaderBarOption
                    option={change_tamil_font_style(
                      t("header_videos"),
                      "2.2vh",
                      _
                    )}
                    on_click={get_videos}
                  />
                  <HeaderBarOption
                    option={change_tamil_font_style(
                      t("header_about"),
                      "2.2vh",
                      _
                    )}
                    on_click={get_info}
                  />

                  {role === "User" ||
                  role === "Previleged User" ||
                  get_user_roles?.[0]?.name == "User" ||
                  get_user_roles?.[0]?.name === "Previleged User" ? (
                    <Menu
                      w="180"
                      top="50px"
                      style={{
                        border: "1px solid #d7d7d7",
                        boxShadow: "-2px 2px #7a7a7a42",
                      }}
                      shouldOverlapWithTrigger={true}
                      placement={"right bottom"}
                      trigger={(triggerProps) => {
                        return (
                          <Pressable
                            alignSelf="center"
                            alignItems="center"
                            variant="solid"
                            {...triggerProps}
                          >
                            <img
                              width={30}
                              height={30}
                              src={
                                session_id
                                  ? user_profile?.profile_pic ||
                                    staff_profile?.profile_pic ||
                                    USER_LOGO
                                  : USER_LOGO
                              }
                              style={{ objectFit: "cover", borderRadius: 25 }}
                            />
                          </Pressable>
                        );
                      }}
                    >
                      <div>
                        {session_id && (
                          <>
                            <Menu.Item onPress={handle_redirect_profile}>
                              {change_tamil_font_style(
                                t("profile"),
                                "2.2vh",
                                _
                              )}
                            </Menu.Item>
                            <Menu.Item onPress={handle_redirect_article}>
                              {change_tamil_font_style(
                                t("create_article"),
                                "2.2vh",
                                _
                              )}
                            </Menu.Item>
                            <Menu.Item onPress={handle_redirect_fact_check}>
                              {change_tamil_font_style(
                                t("create_fact_check"),
                                "2.2vh",
                                _
                              )}
                            </Menu.Item>
                            <Menu.Item onPress={handleLogout}>
                              {change_tamil_font_style(t("logout"), "2.2vh", _)}
                            </Menu.Item>
                          </>
                        )}
                      </div>
                    </Menu>
                  ) : (
                    <Menu
                      w="180"
                      top="50px"
                      style={{
                        border: "1px solid #d7d7d7",
                        boxShadow: "-2px 2px #7a7a7a42",
                      }}
                      shouldOverlapWithTrigger={true}
                      placement={"right bottom"}
                      trigger={(triggerProps) => {
                        return (
                          <Pressable
                            alignSelf="center"
                            alignItems="center"
                            variant="solid"
                            {...triggerProps}
                          >
                            <HeaderBarOption
                              option={change_tamil_font_style(
                                t("login"),
                                "2.2vh",
                                _
                              )}
                              on_click={on_route_click_login}
                            />
                          </Pressable>
                        );
                      }}
                    ></Menu>
                  )}
                  <div className="menu_logo">
                    <Avatar
                      className="mobile_header_menu_icon"
                      size="20"
                      src={MENU_LOGO}
                      style={{ cursor: "pointer" }}
                      onClick={get_menu}
                    />
                  </div>
                  <Drawer
                    className="mobile_view_drawer_list"
                    title={null}
                    placement={placement}
                    onClose={on_close}
                    open={is_menu_open}
                    drawerStyle={{
                      background: "none",
                      border: "none",
                    }}
                    footer={null}
                  >
                    <HeaderBarDrawer set_is_menu_open={set_is_menu_open} />
                  </Drawer>
                </div>
              </Row>
            </HStack>
          </Box>
        </Box>
      </div>

      {/* ...........................mobile_header............................... */}
      <div className="hide_content_in_desktop">
        <Box h={"60px"} w="100%" bg="#ffffff">
          <HStack h="100%" w="100%" bg="#140f2d">
            <div style={{ marginTop: "60px" }}>
              <Drawer
                title={null}
                placement={placement}
                onClose={on_close}
                open={is_menu_open}
                drawerStyle={{
                  background: "none",
                  border: "none",
                }}
                footer={null}
              >
                <HeaderBarDrawer set_is_menu_open={set_is_menu_open} />
              </Drawer>
            </div>
            {/* </Col> */}
            <Row
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "10px" }}>
                <Avatar
                  className="mobile_header_menu_icon"
                  size="17"
                  src={MENU_LOGO}
                  style={{ cursor: "pointer" }}
                  onClick={get_menu}
                />
              </div>
              <div onClick={on_route_click}>
                <Image
                  source={require(`../../../../assets/icons/YT1.png`)}
                  width={"170px"}
                  height={"35px"}
                />
              </div>

              <div style={{ marginRight: "10px" }}>
                <Avatar
                  size="25"
                  src={SEARCH_IMAGE}
                  style={{ cursor: "pointer" }}
                  onClick={get_search_box}
                />
              </div>
            </Row>
          </HStack>
        </Box>
      </div>
    </div>
  );
};

export default HeaderBar;
