import { gql } from "@apollo/client";

export const create_recommended_stories_query=gql`
mutation create_recommended_story($json:recommended_story_input){
    create_recommended_story(json:$json){
      id
      status
      error{
        message
      }
    }
  }`;

  export const get_all_recommended_stories_query=gql`
  query get_all_recommended_story($language_id: String!) {
    admin_recommended_story:get_all_recommended_story(language_id: $language_id) {
     items{
      ref_id
      fact_check_id
      fact_check {
         id
        user_profile_id
        staff_profile_id
        staff_profile {
          name
        }
        title
        subtitle
        content
        reading_time
        likes
        views
        created_date_time
        thumbnail_img
        perma_link
      }
      article_id
      article{
       id
        user_profile_id
        staff_profile_id
        title
        subtitle
        content
        reading_time
        likes
        views
        created_date_time
        thumbnail_img
        perma_link
      }
      language_id
    }
    }
  }
  `;