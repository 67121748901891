import React, { useEffect, useState } from "react";
import PageTitle from "../../system/page_title/page_title";
import { HStack, VStack } from "native-base";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import VForm from "../../system/ui/antd_form";
import { navigate, navigateBack } from "../../../../helpers/navigator";
import {
  create_award_query,
  update_award_query,
} from "../../../../services/redux/slices/graphql/graphql_awards";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ROUTES } from "../../../routes/my_routes";
import Content from "../admin_article/admin_article_form/content";

const AwardsDetails = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const [content, set_content] = useState({ html: null, json: null });

  const {
    status: create_award_status,
    loading: create_award_loading,
    error: create_award_error,
  } = useDynamicSelector("create_award");

  const {
    status: update_award_status,
    loading: update_award_loading,
    error: update_award_error,
  } = useDynamicSelector("update_award");

  useEffect(() => {
    if (create_award_status === "Success") {
      toast.success("Award created successfully");
      navigate(ROUTES.AWARDS);
      dispatch(dynamic_clear("create_award"));
    } else if (create_award_error) {
      toast.error(create_award_error?.message);
      dispatch(dynamic_clear("create_award"));
    } else if (update_award_status === "Success") {
      toast.success("Award updated successfully");
      navigate(ROUTES.AWARDS);
      dispatch(dynamic_clear("update_award"));
    } else if (update_award_error) {
      toast.error(update_award_error?.message);
      dispatch(dynamic_clear("update_award"));
    }
  }, [
    create_award_status,
    create_award_error,
    update_award_status,
    update_award_error,
  ]);

  useEffect(() => {
    if (location?.props_state?.record?.id) {
      form.setFieldsValue({
        ...location?.props_state?.record,
        content: set_content({
          html: location?.props_state?.record?.content,
          json: null,
        }),
        thumbnail_img: location?.props_state?.record?.thumbnail_img?.[0]
          ? [
              {
                url: location?.props_state?.record?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
    } else {
      form.resetFields();
    }
  }, [location]);

  const on_finish = (record) => {
    if (location?.props_state?.modal_title === "add") {
      create_awards(record);
    } else {
      update_awards(record);
    }
  };

  const create_awards = (json) => {
    let key = [{ key: "create_award", loading: true }];
    let query = create_award_query;
    let data = {
      ...json,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content,
    };
    let variables = {
      json: data,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_awards = (json) => {
    let key = [{ key: "update_award", loading: true }];
    let query = update_award_query;
    let data = {
      ...json,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content,
    };
    let variables = {
      id: location?.props_state?.record?.id,
      json: data,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const go_back = () => {
    navigateBack();
  };

  return (
    <div>
      <PageTitle
        title={
          location?.props_state?.modal_title === "edit"
            ? "Update Award"
            : "Add Award"
        }
      />
      <VStack padding={"30px"}>
        <Form layout="vertical" form={form} onFinish={on_finish}>
          <VForm.TextBox
            label={t("title")}
            field={"title"}
            rules={[{ required: true, message: "Title is required " }]}
          />
          <VForm.TextBox
            label={t("media")}
            field={"subtitle"}
            rules={[{ required: true, message: "Media is required " }]}
          />

          <VForm.TextBox
            label={"Perma link"}
            field={"perma_link"}
            rules={[{ required: true, message: "Perma link is required " }]}
          />
          <VForm.ImageFile
            label={"Thumbnail Img"}
            field={"thumbnail_img"}
            rules={[{ required: true, message: "Image is required " }]}
          />

          <VForm.TextArea
            label={t("description")}
            field={"description"}
            rules={[{ required: true, message: "Description is required " }]}
          />
          <Content content={content} set_content={set_content} />

          <HStack justifyContent={"flex-end"} space={"10px"}>
            <Button danger onClick={go_back}>
              {t("back")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={create_award_loading || update_award_loading}
            >
              {location?.props_state?.modal_title === "edit"
                ? "Update"
                : "Create"}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </div>
  );
};

export default AwardsDetails;
