import { Row } from "antd";
import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";

import ReportShareCard from "./report_share_card";
import CustomReportModel from "../custom/custom_report_model";

import {
  format_view_count,
  split_tamil_english_words_for_breadcrumbs,
} from "../../../helpers/functions";
import { useDynamicSelector } from "../../../services/redux";
import SingleArticleTopDetails from "./single_article_top_details";
import SingleArticleTopThumbnailImage from "./single_article/single_article_top_thumbnail_image";
import SingleArticleTopTitleAndShareSave from "./single_article/single_article_top_title_and_share_save";

const SingleArticleTopContent = (props) => {
  const { handle_save, is_saved, report, set_model_open, model_open, form } =
    props;

  const {
    title,
    staff_profile,
    thumbnail_img,
    views,
    reading_time,
    user_profile,
    is_saved: book_mark_save,
    loading: article_loading,
  } = useDynamicSelector("get_article_by_perma_link");

  return (
    <div className="single_article_page_top_content">
      <Row className="your-row-class">
        <div
          className="single_article_page_bread_crumbs"
          style={{ width: "100%" }}
        >
          <CustomBreadCrumbs
            pages={`Home/ Articles/ ${split_tamil_english_words_for_breadcrumbs(
              title,
              "_",
              2
            )}`}
          />
        </div>
        <div className="single-article-page-first_division">
          <SingleArticleTopTitleAndShareSave
            set_model_open={set_model_open}
            is_saved={is_saved}
            handle_save={handle_save}
          />

          <SingleArticleTopThumbnailImage />
        </div>
      </Row>

      <div className="top_details_in_single_article">
        <SingleArticleTopDetails
          profile={staff_profile?.profile_pic || user_profile?.profile_pic}
        />

        <div className="report_share_card">
          <ReportShareCard
            reading_time={reading_time}
            view_count={format_view_count(views)}
            title={title}
            thumbnail_img={thumbnail_img}
            loading={article_loading}
            report={report}
            set_model_open={set_model_open}
            model_open={model_open}
          />
        </div>
      </div>

      <CustomReportModel
        model_open={model_open}
        set_model_open={set_model_open}
        report={report}
        form={form}
      />
    </div>
  );
};

export default SingleArticleTopContent;
