import React from "react";
import { useTranslation } from "react-i18next";
import { change_tamil_font_style } from "../../../helpers/functions";

const DonationSmallCardText = (props) => {
  const { padding, font_size } = props;
  const { t } = useTranslation();

  return (
    <p
      className="donation-content-small-card-text"
      style={{
        fontSize: font_size ? font_size : "2vh",
        // padding: padding ? padding : "20px 35px 0 35px",
        // marginLeft: "3vw",
      }}
    >
      {change_tamil_font_style(t("donate_horizontal_content"), "2vh", "1px")}
    </p>
  );
};

export default DonationSmallCardText;
