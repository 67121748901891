import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_request,
  query_get_all_category,
  useDynamicSelector,
} from "../../../../../services/redux";
import { query_get_all_party_tag } from "../../../../../services/redux/slices/graphql/graphql_party_tag";

const PartyTag = (props) => {
  const { language_id, disabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selected_tags, set_selected_tags] = useState([]);

  const { items: party_tag_list } = useDynamicSelector("get_all_party_tag");

  useEffect(() => {
    get_party_tag_list();
  }, [language_id]);

  const get_party_tag_list = () => {
    let key = [{ key: "get_all_party_tag", loading: true }];
    let query = query_get_all_party_tag;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_tag_change = (value) => {
    set_selected_tags(value);
  };

  return (
    <Form.Item name="party_tags" label={t("party_tag")}>
      <Select
        placeholder="PartyTag"
        allowClear
        showSearch
        disabled={props.disabled}
        mode="multiple"
        value={selected_tags}
        onChange={handle_tag_change}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {party_tag_list?.map((option, index) => (
          <Select.Option key={option.name} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PartyTag;
