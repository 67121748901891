import { gql } from "@apollo/client";

export const get_all_featured_story_query = gql`
  query get_all_featured_story($language_id: String!) {
    fact_check_get_all_featured_story: get_all_featured_story(
      language_id: $language_id
    ) {
      items {
        type
        language_id
        fact_check_id
        fact_check {
          id
          language_id
          staff_profile_id
          user_profile_id
          title
          subtitle
          content
          is_fact
          reading_time
          likes
          views
          comments_count
          thumbnail_img
          created_date_time
          perma_link
          staff_profile {
            id
            name
          }
          user_profile {
            id
            name
          }
        }
        article {
          id
          language_id
          staff_profile_id
          user_profile_id
          title
          subtitle
          perma_link
          reading_time
          reading_time
          likes
          views
          thumbnail_img
          created_date_time
          error {
            status_code
            message
          }
        }
      }
    }
  }
`;
