import React from "react";
import Editor from "vc-editor";

const Test = () => {
  const handle_submit = (e) => {
    console.log("handle_submit", e);
  };
  return (
    <div>
      <Editor on_submit={handle_submit}content={""} />
    </div>
  );
};

export default Test;
