import { gql } from "@apollo/client";
export const change_password_query = gql`
  query change_password($current_password: String!, $new_password: String!) {
    change_password(
      current_password: $current_password
      new_password: $new_password
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
