import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";
import ArticleDetailsAndAuthorProfile from "./article_details_and_author_profile";
import { useDynamicSelector } from "../../../services/redux";
import { split_tamil_english_words_for_breadcrumbs } from "../../../helpers/functions";
import { Col, Skeleton } from "antd";

const SingleArticleTopDetails = (props) => {
  const { profile } = props;
  const {
    title,
    thumbnail_img,
    is_saved: book_mark_save,
    loading: article_loading,
  } = useDynamicSelector("get_article_by_perma_link");

  return (
    <div className="title_and_first_card_of_article">
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: "#140f2d",
            height: "23vh",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
          }}
        ></div>

        <div className="article_first_card_mobile">
          <Col className="single_article_page_second_col_antibiotic">
            {article_loading ? (
              <Skeleton.Input
                className="single_article_image_skeleton"
                active
              />
            ) : (
              <img
                className="single_article_image"
                visible={false}
                preview={false}
                src={thumbnail_img}
              />
            )}
          </Col>
        </div>
      </div>
      <div className="single_article_top_text_mobile">
        {article_loading ? (
          <Skeleton.Input className="single_article_title_skeleton" active />
        ) : (
          <CustomText
            class_name={"single_article_title"}
            text={title}
            font_family={"Kufam"}
            align={"left"}
          />
        )}

        <ArticleDetailsAndAuthorProfile profile={profile} />
      </div>
    </div>
  );
};

export default SingleArticleTopDetails;
