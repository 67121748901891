import React, { useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import CustomText from "../custom/custom_text";
import CustomNewsStoryCardVertical from "../custom/custom_news_story_card_vertical";
import {
  change_tamil_font_style,
  extractTextFromPTags,
  split_tamil_english_words,
} from "../../../helpers/functions";
import ArticleFirstCard from "./article_first_card";
import moment from "moment";
import CustomFactCheckRectangleSmallCard from "../custom/custom_fact_check_rectangle_small_card";
import DonationCard from "../widgets/donation_card";
import { Row } from "antd";
import RecommendedStoriesCarousel from "./recommended_stories_carousel";
import { useTranslation } from "react-i18next";

const RecommendedStories = (props) => {
  const { height } = props;
  const { t } = useTranslation();
  const { items: recommended_stories, loading: recommended_stories_loading } =
    useDynamicSelector("get_all_recommended_story");

  const filtered_recommended_stories = recommended_stories?.filter(
    (item, index) => index !== 0
  );
  const date = moment(
    recommended_stories &&
      (recommended_stories[0]?.fact_check_id
        ? recommended_stories[0]?.fact_check?.published_date_time
          ? parseInt(
              recommended_stories[0]?.fact_check?.published_date_time,
              10
            )
          : recommended_stories[0]?.fact_check?.created_date_time
        : recommended_stories[0]?.article?.published_date_time
        ? parseInt(recommended_stories[0]?.article?.published_date_time, 10)
        : recommended_stories[0]?.article?.created_date_time)
  ).format("MMM D, YYYY");

  return (
    <>
      <Row
        className="article_recommended_stories"
        style={{
          height: height ? height : "600px",
        }}
      >
        <div className="recommended_stories_title_box">
          <div className="recommended_stories_text">
            <CustomText
              class_name={"recommended_stories_mobile_text"}
              text={change_tamil_font_style(t("recommended_stories"), "3vh", _)}
              font_family={"kufam"}
              size={"4vh"}
              weight={"700"}
              align={"left"}
            />
          </div>
          <div className="article_recommended_stories">
            <div className="article_news_story_card_vertical">
              <CustomNewsStoryCardVertical
                title={split_tamil_english_words(
                  recommended_stories &&
                    (recommended_stories[0]?.fact_check_id
                      ? recommended_stories[0]?.fact_check?.title
                      : recommended_stories[0]?.article?.title),
                  _,
                  50
                )}
                subtitle={
                  split_tamil_english_words(
                    recommended_stories &&
                      recommended_stories[0] &&
                      (recommended_stories[0]?.fact_check_id
                        ? recommended_stories[0]?.fact_check?.subtitle
                        : recommended_stories[0]?.article?.subtitle),
                    _,
                    90
                  ) ||
                  extractTextFromPTags(
                    recommended_stories &&
                      recommended_stories[0] &&
                      (recommended_stories[0]?.fact_check_id
                        ? recommended_stories[0]?.fact_check?.content
                        : recommended_stories[0]?.article?.content)
                  )
                }
                reading_time={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.reading_time
                    : recommended_stories[0]?.article?.reading_time)
                }
                view_count={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.views
                    : recommended_stories[0]?.article?.views)
                }
                date={date}
                name={
                  (recommended_stories &&
                    (recommended_stories[0]?.fact_check_id
                      ? recommended_stories[0]?.fact_check?.staff_profile
                          ?.name ||
                        recommended_stories[0]?.fact_check?.user_profile
                          ?.name ||
                        "YouTurn Editorial"
                      : recommended_stories[0]?.article?.staff_profile?.name ||
                        recommended_stories[0]?.article?.user_profile?.name)) ||
                  "YouTurn Editorial"
                }
                src={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.thumbnail_img
                    : recommended_stories[0]?.article?.thumbnail_img)
                }
                perma_link={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.perma_link
                    : recommended_stories[0]?.article?.perma_link)
                }
                type={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? "fact-check"
                    : "article")
                }
              />
            </div>

            <div className="article_card_first_half">
              <ArticleFirstCard
                title={split_tamil_english_words(
                  recommended_stories &&
                    (recommended_stories[0]?.fact_check_id
                      ? recommended_stories[0]?.fact_check?.title
                      : recommended_stories[0]?.article?.title),
                  _,
                  10
                )}
                image={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.thumbnail_img
                    : recommended_stories[0]?.article?.thumbnail_img)
                }
                view_count={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.views
                    : recommended_stories[0]?.article?.views)
                }
                created_date_time={date}
                id={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? recommended_stories[0]?.fact_check?.id
                    : recommended_stories[0]?.article?.id)
                }
                type={
                  recommended_stories &&
                  (recommended_stories[0]?.fact_check_id
                    ? "fact-check"
                    : "article")
                }
                name={
                  (recommended_stories &&
                    (recommended_stories[0]?.fact_check_id
                      ? recommended_stories[0]?.fact_check?.staff_profile
                          ?.name ||
                        recommended_stories[0]?.fact_check?.user_profile
                          ?.name ||
                        "YouTurn Editorial"
                      : recommended_stories[0]?.article?.staff_profile?.name ||
                        recommended_stories[0]?.article?.user_profile?.name)) ||
                  "YouTurn Editorial"
                }
              />
            </div>
          </div>
        </div>

        <div className="article_article_small_card">
          {recommended_stories?.slice(1, 5).map((list) => (
            <CustomFactCheckRectangleSmallCard
              src={
                list?.fact_check_id
                  ? list?.fact_check?.thumbnail_img
                  : list?.article?.thumbnail_img
              }
              title={
                list?.fact_check_id
                  ? list?.fact_check?.title
                  : list?.article?.title
              }
              date={moment(
                list?.fact_check_id
                  ? list?.fact_check?.published_date_time
                    ? parseInt(list?.fact_check?.published_date_time)
                    : list?.fact_check?.created_date_time
                  : list?.article?.published_date_time
                  ? parseInt(list?.article?.published_date_time)
                  : list?.article?.created_date_time
              ).format(`MMM D,YYYY`)}
              perma_link={
                list?.fact_check_id
                  ? list.fact_check?.perma_link
                  : list?.article?.perma_link
              }
              name={
                list?.fact_check_id
                  ? list.fact_check?.staff_profile?.name
                  : list?.article?.staff_profile?.name
              }
              loading={recommended_stories_loading}
              type={
                list && list?.article_id === null ? "fact-check" : "article"
              }
            />
          ))}
        </div>

        <RecommendedStoriesCarousel list={filtered_recommended_stories} />

        <div className="article_donation_card">
          <DonationCard height={"89vh"} />
        </div>
      </Row>
    </>
  );
};

export default RecommendedStories;
