import { gql } from "@apollo/client";

export const mutation_user = gql`
  mutation sign_up($data: system_user_input) {
    sign_up(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_staff_query=gql`
mutation create_staff($data: system_user_input) {
  create_staff(data: $data) {
    id
    status
    error {
      message
    }
  }
}
`

export const update_staff_query=gql`
mutation update_staff($id: String!, $data: system_user_update_input) {
  update_staff(id: $id, data: $data) {
    id
    status
    error {
      message
    }
  }
}
`

export const query_get_all_previleged_user_article = gql`
  query get_all_previleged_user_article(
    $json: get_all_article_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_one_previleged_user_article: get_all_previleged_user_article(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        title
        subtitle
        perma_link
        status
        created_date_time
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const query_get_one_previleged_access_to_user = gql`
  mutation give_previleged_access_to_user($user_id: String!) {
    get_one_give_previleged_access_to_user: give_previleged_access_to_user(
      user_id: $user_id
    ) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const query_get_back_access_user = gql`
  mutation get_back_previleged_access_from_user($user_id: String!) {
    get_back_access_for_user: get_back_previleged_access_from_user(
      user_id: $user_id
    ) {
      id
      status
      error {
        message
      }
    }
  }
`;
