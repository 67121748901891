import React, { useEffect, useState } from "react";
import {
  change_tamil_font_style,
  retrieveItem,
} from "../../../../helpers/functions";
import FactCheckCategories from "../fact_check_categories";
import CategoriesListSelector from "../categories_list_selector";
import CustomFactCheckCategories from "../../custom/custom_fact_check_categories";
import { Divider, Empty, Row, Skeleton } from "antd";
import CustomButton from "../../custom/custom_button";
import MediaShare from "../../widgets/media_share";
import {
  dynamic_request,
  get_all_fact_check_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useTranslation } from "react-i18next";
import CustomText from "../../custom/custom_text";
import { useDispatch } from "react-redux";
import SixCategoriesCard from "./six_categories_card";

const FactCheckCategoriesRow = (props) => {
  const {} = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const [input_value, set_input_value] = useState();
  const [category_name, set_category_name] = useState(t("all"));
  const [page_limit, set_page_limit] = useState(6);
  const [filtered_item, set_filtered_item] = useState([]);
  const [default_category_name, set_default_category_name] = useState("");
  const [fact_check_page_limit, set_fact_check_page_limit] = useState(7);

  const { items, loading: fact_check_list_loading } =
    useDynamicSelector("get_all_fact_check");

  const filtered_fact_check = items?.filter((item, index) => index !== 0);

  const on_click = () => {
    set_page_limit(page_limit + 6);
    set_fact_check_page_limit(fact_check_page_limit + 6);
  };
  useEffect(() => {
    if (input_value !== "More") {
      get_fact_check(input_value);
    }
  }, [input_value, page_limit, fact_check_page_limit]);
  useEffect(() => {
    if (
      items &&
      items?.length > 0 &&
      (!default_category_name ||
        default_category_name === "All" ||
        default_category_name === "அனைத்தும்" ||
        default_category_name === "மேலும்" ||
        default_category_name === "More")
    ) {
      set_filtered_item([...filtered_fact_check]);
    } else if (items && items?.length > 0) {
      set_filtered_item([...items]);
    } else if (!fact_check_list_loading && !items?.length) {
      set_filtered_item([]);
    }
  }, [items, default_category_name]);
  const get_fact_check = (category_id) => {
    let key = [{ key: "get_all_fact_check", loading: true }];
    let query = get_all_fact_check_query;
    let variables = {
      page_number: 1,
      page_limit:
        !default_category_name ||
        default_category_name === "All" ||
        default_category_name === "அனைத்தும்" ||
        default_category_name === "மேலும்" ||
        default_category_name === "More"
          ? fact_check_page_limit
          : page_limit,
      json: {
        language_id: current_language?.id,
        category_tag: category_id === "All" ? null : category_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <Row className="browse_category_mobile_view">
      <div style={{ width: "100%" }}>
        <div className="browse_categories_text">
          <CustomText
            weight={"700"}
            text={change_tamil_font_style(
              t("browse_categories_title"),
              "3vh",
              _
            )}
            font_family={"Kufam"}
            size={"4vh"}
            align={"start"}
            line_height={"4vh"}
          />
        </div>
        <div
          style={{
            width: "70%",
            marginLeft: "10%",
            marginTop: "20px",
            flexDirection: "column",

            justifyContent: "space-between",
          }}
          className="fact_check_categories_list"
        >
          <FactCheckCategories
            set_category_id={set_input_value}
            set_category_name={set_category_name}
            set_page_limit={set_page_limit}
            set_filtered_item={set_filtered_item}
            set_default_category_name={set_default_category_name}
            default_page_limit={set_fact_check_page_limit}
          />
        </div>

        <div className="fact_check_categories_list_selector">
          <div className="fact_check_category_modal_div">
            <div style={{ width: "100%", height: "4vh" }}>
              <CategoriesListSelector set_category_id={set_input_value} />
            </div>
          </div>
        </div>
      </div>

      <div className="six-categories-card-first-div">
        <SixCategoriesCard
          filtered_item={filtered_item}
          on_click={on_click}
          category_name={category_name}
        />

        <MediaShare />
      </div>

      <div className="see_all_button_mobile_view">
        {items?.length > 0 && !fact_check_list_loading ? (
          <CustomButton
            class_name={"see_all_button_categories"}
            name={t("see_more")}
            on_Click={on_click}
          />
        ) : (
          ""
        )}
      </div>
    </Row>
  );
};

export default FactCheckCategoriesRow;
