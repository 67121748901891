import { Button, Form, Select } from "antd";
import { HStack } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "../../../../system/ui/antd_form/antd_form";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../../../services/redux";
import {
  create_reason_query,
  update_reason_query,
} from "../../../../../../services/redux/slices/graphql/graphql_admin_report";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ReasonsForm = (props) => {
  const {
    mutation_type,
    handle_mutation_modal_close,
    get_all_reasons,
    edit_reason_id,
    selected_items,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );
  const {
    status: create_reason_status,
    loading: create_reason_loading,
    error: create_reason_error,
  } = useDynamicSelector("create_reason");

  const {
    status: update_reason_status,
    loading: update_reason_loading,
    error: update_reason_error,
  } = useDynamicSelector("update_reason");

  useEffect(() => {
    if (create_reason_status === "Success") {
      toast.success("Reason created successfully");
      get_all_reasons();
      handle_mutation_modal_close();
      dispatch(dynamic_clear("create_reason"));
    } else if (create_reason_error) {
      toast.error(create_reason_error?.message);
      handle_mutation_modal_close();
      dispatch(dynamic_clear("create_reason"));
    } else if (update_reason_status === "Success") {
      toast.success("Reason updated successfully");
      get_all_reasons();
      handle_mutation_modal_close();
      dispatch(dynamic_clear("update_reason"));
    } else if (update_reason_error) {
      toast.error(update_reason_error?.message);
      dispatch(dynamic_clear("update_reason"));
    }
  }, [
    create_reason_status,
    create_reason_error,
    update_reason_status,
    update_reason_error,
  ]);

  const create_report_reason = (json) => {
    let key = [{ key: "create_reason", loading: true }];
    let query = create_reason_query;
    let variables = {
      json,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_report_reason = (json) => {
    let key = [{ key: "update_reason", loading: true }];
    let query = update_reason_query;
    let variables = {
      id: edit_reason_id,
      json,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_submit = (values) => {
    if (mutation_type === "add") {
      create_report_reason(values);
    } else {
      update_report_reason(values);
    }
  };

  const on_cancel = () => {
    handle_mutation_modal_close();
  };

  return (
    <Form
      onFinish={handle_submit}
      form={form}
      layout="vertical"
      initialValues={selected_items}
    >
      <VForm.TextBox
        label="Name"
        field="name"
        rules={[
          {
            required: true,
            message: "Name is required ",
          },
        ]}
      />
      <Form.Item
        name={"language_id"}
        label="Language"
        rules={[
          {
            required: true,
            message: "Language is required ",
          },
        ]}
      >
        <Select>
          {languages_list?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Button danger onClick={on_cancel}>
          Close
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={
            mutation_type === "add"
              ? create_reason_loading
              : update_reason_loading
          }
        >
          {mutation_type === "edit" ? "Update" : "Create"}
        </Button>
      </HStack>
    </Form>
  );
};

export default ReasonsForm;
