import { gql } from "@apollo/client";

export const get_read_user_queries = gql`
  query get_read_user_queries($json: get_all_user_query_input) {
    get_read_user_queries(json: $json) {
      items {
        id
        first_name
        last_name
        email
        country
        message
        is_read
      }
      error {
        message
      }
    }
  }
`;

export const get_unread_user_queries = gql`
  query get_unread_user_queries($json: get_all_user_query_input) {
    get_unread_user_queries(json: $json) {
      items {
        id
        first_name
        last_name
        email
        country
        message
        is_read
      }
      error {
        message
      }
    }
  }
`;

export const update_user_queries = gql`
  mutation update_user_query($json: update_user_query_input) {
    update_user_query(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_user_queries = gql`
  mutation delete_user_query($json: delete_user_query_input) {
    delete_user_query(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_user_queries = gql`
  query get_user_queries($json: get_all_user_query_input) {
    get_user_queries(json: $json) {
      items {
        id
        first_name
        last_name
        email
        country
        message
        is_read
        created_date_time
        mobile
        error {
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
