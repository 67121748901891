import { Badge } from "antd";
import React from "react";

const DonationInputs = (props) => {
  const { set_selected_amount, selected_amount, set_amount } = props;

  const onclick_amount = (amount) => {
    set_selected_amount(amount);
    set_amount(amount);
  };
  return (
    <div className="donation-amount-badges">
      <Badge
        count={"₹199"}
        overflowCount={199}
        className="cursor-pointer amount-badge-font"
        onClick={() => onclick_amount(199)}
        style={{
          backgroundColor: selected_amount === 199 ? "#0065df" : "white",
          color: selected_amount === 199 ? "white" : "black",
          border: "1px solid #140f2d",
        }}
      />
      <Badge
        count={"₹499"}
        overflowCount={499}
        className="cursor-pointer amount-badge-font"
        onClick={() => onclick_amount(499)}
        style={{
          backgroundColor: selected_amount === 499 ? "#0065df" : "white",
          color: selected_amount === 499 ? "white" : "black",
          border: "1px solid #140f2d",
        }}
      />
      <Badge
        count={"₹999"}
        overflowCount={999}
        className="cursor-pointer amount-badge-font"
        onClick={() => onclick_amount(999)}
        style={{
          backgroundColor: selected_amount === 999 ? "#0065df" : "white",
          color: selected_amount === 999 ? "white" : "black",
          border: "1px solid #140f2d",
        }}
      />
      <Badge
        count={"₹1,999"}
        overflowCount={1999}
        className="cursor-pointer amount-badge-font"
        onClick={() => onclick_amount(1999)}
        style={{
          backgroundColor: selected_amount === 1999 ? "#0065df" : "white",
          color: selected_amount === 1999 ? "white" : "black",
          border: "1px solid #140f2d",
        }}
      />
      <Badge
        count={"Other"}
        // color="#0065df"
        className="cursor-pointer amount-badge-font"
        onClick={() =>
          set_manual_amount(!manual_amount) || onclick_amount("others")
        }
        style={{
          backgroundColor: selected_amount === "others" ? "#00adee" : "white",
          color: selected_amount === "others" ? "white" : "black",
          border: "1px solid #140f2d",
        }}
      />
    </div>
  );
};

export default DonationInputs;
