import { Col, Skeleton } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux";

const SingleArticleTopThumbnailImage = () => {
  const { thumbnail_img, loading: article_loading } = useDynamicSelector(
    "get_article_by_perma_link"
  );

  return (
    <Col className="single_article_page_second_col_antibiotic">
      {article_loading ? (
        <Skeleton.Input className="single_article_image_skeleton" active />
      ) : (
        <img
          className="single_article_image"
          visible={false}
          preview={false}
          src={thumbnail_img}
        />
      )}
    </Col>
  );
};

export default SingleArticleTopThumbnailImage;
