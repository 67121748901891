import { Button, Modal, Pagination, Select, Table } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import {
  delete_report_query,
  get_all_reasons_query,
  get_all_reports_query,
} from "../../../../services/redux/slices/graphql/graphql_admin_report";
import { toast } from "react-toastify";
import {
  countFormat,
  get_language,
  language,
} from "../../../../helpers/constants";
import { render } from "@testing-library/react";

const Reports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [report_id, set_report_id] = useState("");
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [reason_id, set_reason_id] = useState("");
  const [language, set_language] = useState({
    id: "839bcc29-853f-4c4c-8700-98fd88558952",
    name: "தமிழ்",
  });

  const {
    items: report_items,
    loading: report_items_loading,
    pagination: report_pagination,
  } = useDynamicSelector("get_all_report");
  const {
    status: delete_report_status,
    loading: delete_report_loading,
    error: delete_report_error,
  } = useDynamicSelector("delete_report");

  const { items: reasons_list } = useDynamicSelector("report_reasons");

  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    get_reports();
  }, [page_number, page_limit, reason_id, language]);

  useEffect(() => {
    get_all_reasons();
  }, [language]);

  useEffect(() => {
    get_languages();
  }, []);

  useEffect(() => {
    if (delete_report_status === "Success") {
      toast.success("Report deleted successfully");
      handle_close();
      get_reports();
      dispatch(dynamic_clear("delete_report"));
    } else if (delete_report_error) {
      toast.error(delete_report_error?.message);
      dispatch(dynamic_clear("delete_report"));
    }
  }, [delete_report_status, delete_report_error]);

  const handle_edit = (record) => {
    set_mutation_modal(true);
    set_mutation_type("edit");
    set_mutation_form_values(record);
  };

  const handle_delete = (record) => {
    set_delete_modal(true);
    set_report_id(record?.id);
  };

  const handle_close = () => {
    set_delete_modal(false);
    set_mutation_modal(false);
  };

  const on_change = (value) => {
    set_reason_id(value);
  };

  const handle_change = (val, values) => {
    set_language({ id: values?.key, name: values?.children });
  };

  const get_reports = () => {
    let key = [{ key: "get_all_report", loading: true }];
    let query = get_all_reports_query;
    let variables = {
      page_limit: page_limit,
      page_number: page_number,
      reason_id: reason_id,
      language_id: language.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_reasons = () => {
    let key = [{ key: "report_reasons", loading: true }];
    let query = get_all_reasons_query;
    let variables = {
      language_id: language.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_report", loading: true }];
    let query = delete_report_query;
    let variables = {
      id: report_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const fetch_data = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const action_items = (record) => {
    return (
      <HStack justifyContent={"center"} alignItems={"center"}>
        <Pressable onPress={() => handle_delete(record)}>
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  const handle_click = (record) => {
    const type = record.article_id ? "articles" : "factcheck";
    const perma_link = record.article_id
      ? record.article?.perma_link
      : record.fact_check?.perma_link;
    const url = `${get_language(language.name)}${type}/${perma_link}.html`;
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Invalid URL:", url);
    }
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: "Type",
      key: "type",
      render: (record) => {
        return record?.article_id ? "Article" : "Fact Check";
      },
    },
    {
      title: "Title",
      key: "title",
      render: (record) => {
        return (
          <span
            onClick={() => handle_click(record)}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {record.article_id
              ? record.article?.title
              : record.fact_check?.title}
          </span>
        );
      },
    },

    {
      title: t("table:description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Reason",
      key: "reason",
      render: (record) => {
        return record?.reason?.name;
      },
    },
    {
      title: "Reported By",
      key: "reported_by",
      render: (record) => {
        return record?.system_user?.user_profile?.name;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (record) => {
        return record?.system_user?.user_profile?.mobile;
      },
    },

    {
      title: t("table:action"),
      dataIndex: "",
      key: "action",
      width: "50px",
      align: "center",
      render: (record) => action_items(record),
    },
  ];

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Select
          onChange={handle_change}
          defaultValue={languages_list ? languages_list?.[0]?.name : ""}
        >
          {languages_list?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          onChange={on_change}
          style={{ width: "250px" }}
          placeholder="Reason"
          allowClear
        >
          {reasons_list?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option?.name}
            </Select.Option>
          ))}
        </Select>
      </HStack>
      <Table
        columns={columns}
        loading={report_items_loading}
        dataSource={report_items}
        onRow={() => {
          return {
            onClick: () => handle_edit(),
          };
        }}
        pagination={false}
      />

      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={report_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>

      {/* <Modal
        open={mutation_modal}
        onCancel={handle_close}
        footer={null}
        title={"Update Report"}
        destroyOnClose={true}
      >
        <ReportsForm />
      </Modal> */}
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_report_loading}
            className="delete-team-member"
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default Reports;
