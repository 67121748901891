import React from "react";
import { Image } from "antd";
import { DEFAULT_USER } from "../../../helpers/image_constants";
import { useDynamicSelector } from "../../../services/redux";
import CustomText from "../custom/custom_text";
import { useDarkMode } from "../contexts/dark_mode_provider";

const AuthorDetailsCard = () => {
  const { is_dark_mode } = useDarkMode();
  const { items: author_details } = useDynamicSelector(
    "get_all_author_stories"
  );

  const profile_picture =
    author_details?.[0]?.staff_profile?.profile_pic ||
    author_details?.[0]?.user_profile?.profile_pic;
  const image_source = profile_picture || DEFAULT_USER;
  return (
    <div
      className={"editor_profile_card"}
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
    >
      <div className="editor_profile_inner_card">
        <Image
          className="editor_image"
          visible={false}
          preview={false}
          src={image_source}
        />
        <div className="author_name_and_about">
          <CustomText
            class_name={"author_name"}
            text={
              author_details?.[0]?.staff_profile?.name ||
              author_details?.[0]?.user_profile?.name
            }
            size={"3vh"}
            font_family={"Kufam"}
            align={"left"}
            color={"#140f2d"}
          />
          <CustomText
            text={
              author_details?.[0]?.staff_profile?.about ||
              author_details?.[0]?.user_profile?.about
            }
            font_family={"Kufam"}
            align={"left"}
            color={"#140f2d"}
            weight={"400"}
            class_name={"author_about_details"}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthorDetailsCard;
