import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_request,
  query_get_all_category,
  useDynamicSelector,
} from "../../../../../services/redux";

const Category = (props) => {
  const { language_id, disabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: category_list } = useDynamicSelector("get_all_category_tag");

  useEffect(() => {
    get_category_list();
  }, [language_id]);

  const get_category_list = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <Form.Item name="category_tags" label={t("category")}>
      <Select
        disabled={props.disabled}
        placeholder="Category"
        allowClear
        showSearch
        mode="multiple"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {category_list?.map((option, index) => (
          <Select.Option key={option.name} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Category;
