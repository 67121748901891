import { Form } from "antd";
import { TextArea } from "native-base";
import React from "react";

const CommentsForm = (props) => {
  const { form } = props;
  return (
    <Form form={form}>
      <Form.Item name="content" label="Comment">
        <TextArea isDisabled={true} />
      </Form.Item>
    </Form>
  );
};

export default CommentsForm;
