import { Space, Typography } from "antd";
import React from "react";

const { Title } = Typography;
const PageTitle = ({ title, class_name }) => {
  return (
    <Title
      className={class_name ? class_name : ""}
      style={{
        fontSize: "23px",
        fontWeight: "600",
        margin: "10px",
        color: "#140F2D ",
        marginTop: "20px",
      }}
    >
      {title}
    </Title>
  );
};

export default PageTitle;
