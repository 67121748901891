import { Col, Row } from "antd";
import React from "react";
import CustomText from "../custom/custom_text";
import { change_tamil_font_style } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";

const SingleFactCheckReportText = ({ margin_top }) => {
  const { t } = useTranslation();

  return (
    <Row
      className="single-fact-check-report-card-title"
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "row",
        height: "100%",
        marginTop: margin_top ? margin_top : "",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "30%",
          width: "40%",
        }}
      >
        <CustomText
          class_name={"single-fact-check-report-card-text"}
          text={t("feel_something_is_wrong_with_the_article")}
          size={"25px"}
          weight={"700"}
          font_family={"Kufam"}
          align={"left"}
          line_height={"32px"}
        />
      </Col>
      <Col
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "5vh",
        }}
      >
        <CustomText
          class_name={"single-fact-check-report-card-second-text"}
          text={change_tamil_font_style(
            t("we_are_committed_text"),
            "2vh",
            "3px"
          )}
          size={"13px"}
          weight={"400"}
          font_family={"Lato"}
          align={"left"}
        />
      </Col>
    </Row>
  );
};

export default SingleFactCheckReportText;
