import React from "react";
import CustomText from "./custom_text";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import moment from "moment";
import { ROUTES } from "../../routes/my_routes";
import { navigate } from "../../../helpers/functions";

const CustomContributionSmallCard = (props) => {
  const { item, type, perma_link } = props;

  const on_click = () => {
    if (type === "article") {
      navigate(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };
  return (
    <div className="recently_added_carousel" onClick={on_click}>
      <img
        className="recently_added_thumbnail_image"
        src={item?.thumbnail_img}
      />

      <div className="search_result_name_and_view_icon">
        <div className="recently_added_author_name_and_date">
          <CustomText
            line_height={"2vh"}
            text={
              item?.staff_profile?.name ||
              item?.user_profile?.name ||
              "YouTurn Editorial"
            }
            size={"2vh"}
            weight={"400"}
            align={"left"}
            font_family={"Lato"}
            color={"#61666D"}
          />
        </div>
        <div className="search_result_view_and_clock_icon_div">
          <div className="search_result_view_eye_icon">
            <LuEye style={{ color: "#E84756" }} />
          </div>
          <div className="search_result_view_count_text">
            <CustomText
              text={item?.views}
              size={"12px"}
              color={"#E84756"}
              weight={"400"}
              font_family={"Lato"}
              align={"left"}
            />
          </div>
        </div>
      </div>

      <div className="search_result_date_and_clock_icon">
        <div className="recently_added_author_name_and_date">
          <CustomText
            line_height={"2vh"}
            text={moment(item?.published_date_time).format("MMM DD, YYYY")}
            size={"2vh"}
            weight={"400"}
            align={"left"}
            font_family={"Lato"}
            color={"#61666D"}
          />
        </div>
        <div className="search_result_view_and_clock_icon_div">
          <div className="search_result_view_eye_icon">
            <AiFillClockCircle style={{ color: "#61666D" }} />
          </div>

          <div className="search_result_reading_time_text">
            <CustomText
              text={`${item?.reading_time} min`}
              size={"12px"}
              color={"#61666D"}
              weight={"400"}
              font_family={"Lato"}
              align={"left"}
            />
          </div>
        </div>
      </div>

      <div className="recently_added_title">
        <CustomText
          line_height={"2.8vh"}
          text={item?.title}
          size={"1.7vh"}
          weight={"600"}
          align={"left"}
          font_family={"Lato"}
          color={"#140F2D"}
        />
      </div>
    </div>
  );
};

export default CustomContributionSmallCard;
