import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";

const SubTitle = ({ color, modal_title, action_type }) => {
  const { t } = useTranslation();
  const isMandatory = modal_title === "add" || action_type === "create";
  const validationRules = isMandatory
    ? [{ required: true, message: "Please enter the subtitle" }]
    : [];
  return (
    <Form.Item
      label={
        <span style={{ color: color ? color : "black" }}>{t("subtitle")}</span>
      }
      name="subtitle"
      style={{ marginTop: "22px" }}
      rules={[{ required: true, message: "Subtitle is required " }]}
      // rules={validationRules}
    >
      <TextArea placeholder="Subtitle" />
    </Form.Item>
  );
};
export default SubTitle;
