import React, { useEffect, useState } from "react";
import CustomText from "../custom/custom_text";
import { Skeleton } from "antd";
import CustomFactCheckRectangleSmallCard from "../custom/custom_fact_check_rectangle_small_card";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import {
  dynamic_request,
  query_get_all_recent_fact_check_list,
  useDynamicSelector,
} from "../../../services/redux";

const SingleFactCheckRecentFactCheck = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const [recent_page_number, set_recent_page_number] = useState(1);
  const [recent_page_limit, set_recent_page_limit] = useState(5);

  const { items: recent_fact_check_list, loading: recent_fact_check_loading } =
    useDynamicSelector("get_all_recent_fact_check_list");

  const slice_fact_check_data = recent_fact_check_list?.slice(1, 5);

  useEffect(() => {
    get_recent_fact_check_list();
  }, []);

  const get_recent_fact_check_list = () => {
    let key = [{ key: "get_all_recent_fact_check_list", loading: true }];
    let query = query_get_all_recent_fact_check_list;
    let variables = {
      language_id: current_language?.id,
      page_number: recent_page_number,
      page_limit: recent_page_limit,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div className="single-fact-check-recently-added-col">
      <div className="related-fact-check-title">
        <CustomText
          class_name="single-fact-check-related-fact-check-title"
          text={t("recently_added_fact_check")}
          font_family={"Lato"}
          align={"left"}
          weight={700}
        />
      </div>
      <div className="fact-check-rectangle-row">
        {recent_fact_check_loading
          ? Array.from({ length: 4 }, (_, index) => (
              <Skeleton.Input
                key={index}
                className="skeleton-recent-fact-check"
                active
              />
            ))
          : slice_fact_check_data?.map((recent_added) => (
              <CustomFactCheckRectangleSmallCard
                src={recent_added?.thumbnail_img}
                title={recent_added?.title}
                date={
                  recent_added
                    ? moment(recent_added.created_date_time).format(
                        `MMM D, YYYY`
                      )
                    : ""
                }
                perma_link={recent_added?.perma_link}
                type={"fact-check"}
                name={recent_added?.staff_profile?.name}
              />
            ))}
      </div>
    </div>
  );
};

export default SingleFactCheckRecentFactCheck;
