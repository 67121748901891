import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDarkMode } from "../../contexts/dark_mode_provider";
import { Skeleton } from "antd";
import { split_tamil_english_words } from "../../../../helpers/functions";
import { ARROW_GIF_ICON } from "../../../../helpers/image_constants";
import { ROUTES } from "../../../routes/my_routes";

const ArticleTitleAndSubtitle = (props) => {
  const { id, type, loading, title, subtitle } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { is_dark_mode } = useDarkMode();

  const on_click = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${id}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${id}.html`);
    }
  };
  return (
    <div className="single_article_page_text">
      {loading ? (
        <Skeleton.Input className="article_featured_story_skeleton" active />
      ) : (
        <div>
          <text className="article_featured_story">{`#${t(
            "featured_articles"
          )}`}</text>
        </div>
      )}

      {loading ? (
        <Skeleton.Input className="article_recent_title_skeleton" active />
      ) : (
        <div className={"article_headline"} onClick={on_click}>
          <text className="article_headline_text">{title}</text>
        </div>
      )}

      {loading ? (
        <Skeleton.Input className="article_recent_sub_title" active />
      ) : (
        <>
          <div className="article_content" onClick={on_click}>
            <text
              className="article_sub_title"
              style={{
                color: is_dark_mode ? "#ffffff" : "",
              }}
            >
              {split_tamil_english_words(subtitle, _, 20)}
            </text>
          </div>

          <div className="article_continue_reading_div">
            <text className="article_continue_reading" onClick={on_click}>
              {t("Continue Reading")}
              <img width={30} height={30} src={ARROW_GIF_ICON} />
            </text>
          </div>
        </>
      )}
    </div>
  );
};

export default ArticleTitleAndSubtitle;
