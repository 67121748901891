import { REPORT_ICON_WHITE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import {
  SAVE_WHITE_FILLED,
  SAVE_WHITE_OUTLINED,
  SHARE_NEW,
} from "../../../../helpers/image_constants";
import { retrieveItem } from "../../../../helpers/functions";
import PreviewSingleArticleWriterDetails from "./preview_single_article_writer_details";

const PreviewSingleArticleFirstColumn = () => {
  const { title } = useDynamicSelector("get_article");
  let current_language = retrieveItem("current_language");

  return (
    <div className="single_article_first_column_container">
      <div className="single_article_title_report_share_save_container">
        <text
          className={
            current_language?.name === "tamil"
              ? "single_article_first_column_title_ta"
              : "single_article_first_column_title"
          }
        >
          {title}
        </text>
        <div className="single_article_top_icons_container">
          <img className="single_article_top_icons" src={REPORT_ICON_WHITE} />
          <img className="single_article_top_icons" src={SHARE_NEW} />
          <img className="single_article_top_icons" src={SAVE_WHITE_OUTLINED} />
        </div>
      </div>
      <div className="single_article_writer_details">
        <PreviewSingleArticleWriterDetails />
        {/* <SingleArticleWriterDetails handle_save={handle_save} /> */}
      </div>
    </div>
  );
};

export default PreviewSingleArticleFirstColumn;
