import React, { useEffect, useState } from "react";
import "./style.css";
import {
  navigateWithProps,
  retrieveItem,
  storeItem,
} from "../../helpers/functions";
import {
  all_user_login_query,
  dynamic_clear,
  dynamic_request,
  mutation_add_article_book_mark,
  mutation_add_fact_check_to_saved_items,
  mutation_sign_up,
  query_admin_login,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../routes/my_routes";
import toast from "react-hot-toast";
import { navigate } from "../../helpers/navigator";
import { Form } from "antd";
import { YOU_TURN_LOGO_BLUE } from "../../helpers/image_constants";
import { queryStringRetrieve1 } from "../../helpers/crypto";
import SignUpMainForm from "../components/login/sign_up_form/sign_up_main_form";
import SignInFormMobileView from "../components/login/sign_up_form/sign_in_form_mobile_view";
import OtpForm from "../components/login/sign_up_form/otp_form";
import LogoImageForHomePage from "../components/login/sign_up_form/logo_image_form_home";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [register_form] = Form.useForm();
  const [password_visible, set_password_visible] = useState(false);
  const [otp, set_otp] = useState(false);
  const [form_submitted, set_form_submitted] = useState(true);
  const [hide_form, set_hide_form] = useState(true);
  const location = useLocation();
  const {
    status: create_user_status,
    error: create_user_error,
    loading: create_user_loading,
    user_id,
    name,
    mobile,
    email,
    force_password_change,
    username,
    session_id,
    page_accesses,
    page_component_accesses,
    is_active,
    roles,
  } = useDynamicSelector("admin_login");
  const {
    status: create_status,
    error: create_error,
    loading: sing_up_loading,
  } = useDynamicSelector("sign_up");

  let chosen_language_key = window.sessionStorage.getItem(
    "chosen_language_key"
  );

  useEffect(() => {
    document.title = "Login";
  }, []);

  useEffect(() => {
    storeItem("session_id", session_id);
    storeItem("user_id", user_id);
    storeItem("name", name);
    storeItem("email", email);
    storeItem("mobile", mobile);
    storeItem("force_password_change", force_password_change);
    storeItem("username", username);
    storeItem("page_accesses", page_accesses);
    storeItem("page_component_accesses", page_component_accesses);
    storeItem("is_active", is_active);
    storeItem("roles", roles);
    storeItem("role", roles?.[0]?.name);
    storeItem("role_id", roles?.[0]?.id);
    if (session_id) {
      // form.resetFields("");
      if (roles?.length > 0 && session_id) {
        if (roles?.[0]?.name === "Admin") {
          navigate(ROUTES.ADMIN_FACT_CHECK);
        } else if (roles?.[0]?.name === "Editor") {
          navigate(ROUTES.ADMIN_FACT_CHECK);
        } else if (roles?.[0]?.name === "Previleged User") {
          navigate(ROUTES.LANDING_PAGE);
        } else if (roles?.[0]?.name === "Super Admin") {
          navigate(ROUTES.ACCESS_RIGHTS);
        } else if (roles?.[0]?.name === "User") {
          if (location.search) {
            let type1 = queryStringRetrieve1("type", location.search);
            let type2 = queryStringRetrieve1("types", location.search);
            let id = queryStringRetrieve1("id", location.search);
            let redirectUrl = queryStringRetrieve1(
              "redirecturl",
              location.search
            );
            if (type1 === "bookmark" && type2 === "article") {
              add_article_in_book_mark(id, type2);
            } else if (type1 === "bookmark" && type2 === "fact-check") {
              get_mutation_add_fact_check_to_saved_items(id);
            }
            history.push(redirectUrl);
          } else {
            navigateWithProps(ROUTES.LANDING_PAGE, {
              chosen_language_key: chosen_language_key,
            });
          }
        } else {
          navigateWithProps(ROUTES.LANDING_PAGE, {
            chosen_language_key: chosen_language_key,
          });
        }
      }

      // dispatch(dynamic_clear("admin_login"));
    }
    if (create_user_error?.message) {
      toast.error(create_user_error?.message);
      // dispatch(dynamic_clear("admin_login"));
    }
  }, [create_user_error, session_id]);

  useEffect(() => {
    if (create_status === "Success") {
      register_form.resetFields("");
      toast.success("Excited to have you join us!");
      sign_in();
      dispatch(dynamic_clear("sign_up"));
    } else if (create_status === "Failure") {
      toast.error(create_error.message);
      dispatch(dynamic_clear("sign_up"));
    }
    dispatch(dynamic_clear("sign_up"));
  }, [create_status, create_error]);

  const add_article_in_book_mark = (id, type) => {
    let key = [{ key: "add_article_to_saved_items", loading: true }];
    let query = mutation_add_article_book_mark;
    let variables = {
      article_id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_mutation_add_fact_check_to_saved_items = (id) => {
    let key = [{ key: "add_fact_check_to_saved_items", loading: true }];
    let query = mutation_add_fact_check_to_saved_items;
    let variables = {
      fact_check_id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const on_finish = (values) => {
    login(values);
  };

  const forgot_click = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const login = (values) => {
    let key = [{ key: "admin_login", loading: true }];
    let query = query_admin_login;
    let variables = {
      username: values?.username,
      password: values?.password,
    };

    dispatch(dynamic_request(key, query, variables));
  };
  const mutation_sign_in = (values) => {
    let key = [{ key: "sign_up", loading: true }];
    let query = mutation_sign_up;
    let variables = {
      data: {
        name: values?.name,
        email: values?.email,
        mobile: values?.mobile,
        username: values?.email,
        password: values?.password,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const sign_in = (event) => {
    form.resetFields("");
    const container = document.getElementById("container");
    container.classList.remove("active");
    event?.preventDefault();
    set_otp(false);
    set_form_submitted(true);
    set_hide_form(true);
  };

  const sign_up = (event) => {
    register_form.resetFields("");
    event?.preventDefault();
    const container = document.getElementById("container");
    container.classList.add("active");
  };

  const toggle_password_visibility = () => {
    set_password_visible(!password_visible);
  };

  useEffect(() => {
    dispatch(dynamic_clear("get_user"));
  }, []);

  return (
    <div className="body">
      <SignUpMainForm
        toggle_password_visibility={toggle_password_visibility}
        password_visible={password_visible}
        sign_in={sign_in}
        otp={otp}
        mutation_sign_in={mutation_sign_in}
        hide_form={hide_form}
        set_hide_form={set_hide_form}
        set_otp={set_otp}
        sing_up_loading={sing_up_loading}
      />
      <div className="container" id="container">
        <LogoImageForHomePage />
        <OtpForm
          otp={otp}
          on_finish={on_finish}
          create_user_loading={create_user_loading}
          sign_up={sign_up}
          forgot_click={forgot_click}
          form={form}
          password_visible={password_visible}
          toggle_password_visibility={toggle_password_visibility}
        />
        <SignInFormMobileView
          sign_in={sign_in}
          sign_up={sign_up}
          create_user_loading={create_user_loading}
        />
      </div>
    </div>
  );
};
export default Login;
