import { Button, Form, Modal, Select, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { TextArea } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import {
  dynamic_request,
  query_get_all_reason,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomReportModel = ({ model_open, set_model_open, report, form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Option } = Select;
  const session_id = retrieveItem("session_id");
  const history = useHistory();
  let current_language = retrieveItem("language");

  const { loading: create_report_loading } =
    useDynamicSelector("create_report");

  const { items } = useDynamicSelector("get_all_reason");

  let reasons = items;

  useEffect(() => {
    get_all_reasons();
  }, []);

  const get_all_reasons = () => {
    let key = [{ key: "get_all_reason", loading: true }];
    let query = query_get_all_reason;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_finish = (values) => {
    if (session_id) {
      report(values);
    } else {
      history.push(`${ROUTES.ADMIN_LOGIN}`);
    }
  };

  return (
    <div>
      <Modal
        title={t("report")}
        style={{
          width: "80vw",
          height: "60vh",
          margin: "auto",
          borderRadius: "6px",
        }}
        open={model_open}
        onCancel={() => set_model_open(false)}
        closeIcon={
          <span>
            <IoMdClose
              style={{
                color: "black",
                width: "28px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "3px",
                marginLeft: "2px",
              }}
            />
          </span>
        }
        footer={null}
      >
        <div style={{ marginTop: "30px" }}>
          <Form onFinish={on_finish} layout="vertical" form={form}>
            <FormItem
              name="reason"
              label={t("reason")}
              rules={[
                {
                  required: true,
                  message: "Please select a reason",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                {reasons?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              name="description"
              label={t("description")}
              rules={[
                {
                  required: true,
                  message: "Please enter a description",
                },
              ]}
            >
              <TextArea rows={4} />
            </FormItem>

            <div className="member-form-footer">
              <Button onClick={() => set_model_open(false)}>
                {t("cancel")}
              </Button>
              <Button
                htmlType="submit"
                loading={create_report_loading}
                style={{
                  borderRadius: "6px",
                  backgroundColor: "red",
                }}
              >
                <Typography
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  {t("report")}
                </Typography>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default CustomReportModel;
