import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { USER_PROFILE_BACK_GROUND } from "../../helpers/image_constants";
import { query_get_author_details } from "../../services/redux/slices/graphql/graphql_author_details";
import { retrieveItem } from "../../helpers/functions";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import { useDispatch } from "react-redux";
import AuthorDetailsCard from "../components/editor_details/author_details_card";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { debounce } from "lodash";
import AuthorArticleFactCheckSocialMedia from "../components/editor_details/author_article_factcheck_socialmedia";
import { query_get_dash_board_count } from "../../services/redux/slices/graphql/graphql_user_profile";
import { useState } from "react";
import { Button } from "antd";
import { navigateBack } from "../../helpers/navigator";

const AuthorDetails = () => {
  const location = useLocation();
  const top_ref = useRef(null);
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();
  const { is_dark_mode } = useDarkMode();
  const [type, set_type] = useState();
  const [language_id, set_language_id] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );

  const { article_count, fact_check_count } = useDynamicSelector(
    "get_dashboard_count"
  );

  const queryParams = new URLSearchParams(location.search);
  const staffProfileId = queryParams.get("staff_profile");
  const userProfileId = queryParams.get("user_profile");
  const record_type = queryParams.get("type");

  useEffect(() => {
    if (article_count > 0) {
      set_type("article");
    } else if (fact_check_count > 0) {
      set_type("fact_check");
    }
  }, []);

  const get_author_detail = () => {
    let key = [{ key: "get_all_author_stories", loading: true }];
    let query = query_get_author_details;
    let variable = {
      // user_profile_id: userProfileId,
      staff_profile_id: staffProfileId,
      language_id: language_id,
      type: type || record_type,
      page_number: 1,
      page_limit: 3,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  const get_all_dash_board_count = () => {
    let key = [{ key: "get_dashboard_count", loading: true }];
    let query = query_get_dash_board_count;
    let variable = {
      user_id: staffProfileId,
      // language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  const go_back = () => {
    navigateBack();
  };

  useEffect(() => {
    get_all_dash_board_count();
  }, [staffProfileId]);

  useEffect(() => {
    get_author_detail();
  }, [staffProfileId, type]);

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  return (
    <div className={is_dark_mode ? "content-dark" : ""} ref={top_ref}>
      {/* <AuthorProfileTitle /> */}
      <div className="user_profile_card_div">
        <div
          className="author_profile_background_div"
          style={{
            background: `url('${USER_PROFILE_BACK_GROUND}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={go_back} danger>
              Back
            </Button>
          </div>
          <AuthorDetailsCard />
          <AuthorArticleFactCheckSocialMedia />
        </div>
      </div>
    </div>
  );
};

export default AuthorDetails;
