import React, { useEffect, useRef, useState } from "react";
import CustomBreadCrumbs from "../components/custom/custom_breadcrumbs";
import CustomText from "../components/custom/custom_text";
import CustomFactCheckCardFirstHalf from "../components/custom/custom_fact_check_card_first_half";
import { ARROW_ICON } from "../../helpers/image_constants";
import { Image, Skeleton, Typography } from "antd";
import { Button, Input, Row } from "antd";
import CustomNewsStoryCard from "../components/custom/custom_news_story_card";
import JoinUsCard from "../components/widgets/join_us_card";
import CustomNewsArticle from "../components/custom/custom_news_article";
import SocialMediaList from "../components/widgets/social_media_list";
import CustomButton from "../components/custom/custom_button";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import CategoriesList from "../components/list_of_article/categories_list";
import DonationCard from "../components/widgets/donation_card";
import { debounce } from "lodash";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import moment from "moment";
import RecentlyAdded from "../components/list_of_article_page/recently_added";
import {
  navigate,
  retrieveItem,
  split_tamil_english_words,
  split_tamil_english_words_for_breadcrumbs,
} from "../../helpers/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import ListOfArticleTopContent from "../components/list_of_article_page/list_of_article_top_content";
import {
  get_get_check_by_perma_link_query,
  get_recent_fact_check_list_query,
  get_trending_fact_checks_query,
  query_get_all_fact_check_in_fact_check_list_category_wise,
  query_list_of_fact_check,
} from "../../services/redux/slices/graphql/graphql_list_of_fact_check";
import CustomFactCheckCategories from "../components/custom/custom_fact_check_categories";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { ROUTES } from "../routes/my_routes";
import ArticleListTopText from "../components/list_of_article_page/article_list_top_text";

const FactCheckList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let current_language = retrieveItem("language");
  const location = useLocation();
  const { category, link } = useParams();
  const link_without_html = link?.replace(".html", "");

  const { pathname } = useLocation();

  const { is_dark_mode } = useDarkMode();

  const [filtered_item, set_filtered_item] = useState([]);

  const {
    items: category_wise_article_list,
    loading: category_wise_article_list_loading,
  } = useDynamicSelector("get_all_fact_check");

  const { items: article_list, loading: article_list_loading } =
    useDynamicSelector("get_all_article_in_article_list");
  const {
    subtitle,

    views,
    thumbnail_img,
    created_date_time,
    title,
    loading: article_by_perma_link_loading,
  } = useDynamicSelector("get_articles_by_perma_link");

  const {
    items: three_trending_fact_check_list,
    loading: three_trending_fact_check_list_loading,
  } = useDynamicSelector("fact_check_list_trending");

  const {
    title: fact_check_title,
    subtitle: fact_check_subtitle,
    // perma_link: perma_link_for_fact_check,
    views: fact_check_view,
    thumbnail_img: fact_check_thumbnail_img,
    created_date_time: fact_check_created_date_time,
    loading: fact_check_by_perma_link_loading,
    staff_profile,
  } = useDynamicSelector("get_fact_check_by_perma_link");

  const {
    items: fact_check_list,
    loading: fact_check_list_loading,
    pagination: total_count,
  } = useDynamicSelector("get_all_fact_check_list");

  const {
    items: category_wise_fact_check_list,
    loading: category_wise_fact_check_list_loading,
  } = useDynamicSelector("get_all_fact_check");

  const {
    items: recent_fact_check_items,
    loading: recent_fact_check_items_loading,
  } = useDynamicSelector("get_recent_fact_check_list");

  const sliced_link = category?.slice(0, category.indexOf(".html"));

  const perma_link =
    location.state && !location.state.is_category
      ? location.state.perma_link
      : "";

  const check_html = (link) => {
    let sliced_link = "";

    if (link?.includes(".html")) {
      sliced_link = link?.slice(0, link.indexOf(".html"));
    } else {
      sliced_link = link;
    }
    return sliced_link;
  };

  const filtered_items = perma_link
    ? filtered_item?.filter((item) => item.perma_link !== perma_link)
    : filtered_item?.filter((item) => item.perma_link !== sliced_link);
  const [page_limit, set_page_limt] = useState(6);

  const [article_category_id, set_article_category_id] = useState();
  const [article_category_name, set_article_category_name] = useState();

  const { Search } = Input;
  const top_ref = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (fact_check_list && fact_check_list.length > 0) {
      set_filtered_item([...fact_check_list]);
    }
  }, [fact_check_list]);

  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  useEffect(() => {
    if (link) {
      fact_check_perma_link(link);
    } else {
      fact_check_perma_link(category);
    }
    // get_all_fact_check_list_api_call();
    get_three_trending_fact_check_list();
    get_recent_fact_check_api_call();
  }, [location?.props_state?.type, page_limit, sliced_link, perma_link]);

  useEffect(() => {
    get_all_fact_check_list_api_call();
  }, [page_limit, article_category_id]);

  useEffect(() => {
    get_category_wise_fact_check_list;
  }, [article_category_id]);

  useEffect(() => {
    get_recent_fact_check_api_call();
  }, []);

  useEffect(() => {
    if (fact_check_list?.length && article_category_name)
      history.push(
        `${ROUTES.LIST_OF_FACT_CHECK}/${article_category_name}/${
          fact_check_list?.[0]?.perma_link + ".html"
        }`
      );
  }, [category_wise_fact_check_list, article_category_name]);
  const category_name = article_category_name
    ? article_category_name
    : location?.props_state?.category_name;

  const get_category_wise_fact_check_list = () => {
    let key = [{ key: "get_all_fact_check", loading: true }];
    let query = query_get_all_fact_check_in_fact_check_list_category_wise;
    let variables = {
      page_limit: 6,
      json: {
        category_tag: article_category_id || "",
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  // fact - check - API;
  const fact_check_perma_link = (link) => {
    let key = [{ key: "get_fact_check_by_perma_link", loading: true }];
    let query = get_get_check_by_perma_link_query;
    let variables = {
      language_id: current_language.id,
      perma_link: check_html(link),
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_all_fact_check_list_api_call = () => {
    let key = [{ key: "get_all_fact_check_list", loading: true }];
    let query = query_list_of_fact_check;
    let variables = {
      page_limit: page_limit,
      page_number: 1,
      json: {
        category_tag: location?.props_state?.category_id
          ? location?.props_state?.category_id
          : article_category_id,
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_three_trending_fact_check_list = () => {
    let key = [{ key: "fact_check_list_trending", loading: true }];
    let query = get_trending_fact_checks_query;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_recent_fact_check_api_call = () => {
    let key = [{ key: "get_recent_fact_check_list", loading: true }];
    let query = get_recent_fact_check_list_query;
    let variables = {
      language_id: current_language?.id,
      page_number: 1,
      page_limit: 5,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_click = () => {
    set_page_limt(page_limit + 6);
  };

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  const [filteredContent, setFilteredContent] = useState("");
  let htmlContent = article_list?.items?.[5]?.content;
  const allowedTags = ["p", "h1"];
  useEffect(() => {
    // Parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Filter the content based on allowed tags
    const filteredElements = Array.from(doc.body.children).filter((element) => {
      return allowedTags.includes(element.tagName.toLowerCase());
    });

    // Create a new HTML string with the filtered content
    const filteredHtml = filteredElements
      .map((element) => element.outerHTML)
      .join("");

    // Set the filtered content to the state
    setFilteredContent(filteredHtml);
  }, [htmlContent, allowedTags]);

  const tagRegex = /<[^>]*>([^<]*)<\/[^>]*>/g;

  // Use the match function to find all matches
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const matches = htmlContent?.match(tagRegex);
  const content = article_list?.[0]?.content?.split("<span>")?.[0]?.split(".");

  const handle_click = (type, id) => {
    if (type === "article") {
      navigate(`/single-article-page/${id}.html`);
    } else if (type === "fact-check") {
      navigate(`/fact-check/${id}.html`);
    }
  };

  useEffect(() => {
    debouncedHandleTitleClick();
  }, [pathname]);
  return (
    <div
      // style={{ overflow: "hidden" }}
      ref={top_ref}
      className={`${is_dark_mode ? "content-dark" : ""}`}
    >
      <div className="article_page_thumnail_image_with_text">
        <div className="article_page">
          <div className="inner_article">
            <div className="article_top_title">
              <CustomBreadCrumbs
                pages={`Home /Fact Check / ${split_tamil_english_words_for_breadcrumbs(
                  fact_check_title,
                  "_",
                  2
                )}`}
              />

              <div className="article_title">
                <CustomText
                  text={
                    category_name === "அனைத்தும்" ||
                    category_name === "All" ||
                    category_name === "மேலும்" ||
                    category_name === "More"
                      ? ""
                      : category_name
                  }
                  size={"5vh"}
                  font_family={"Kufam"}
                  color={"#FFFFFF"}
                  align={"left"}
                  className={"articles"}
                />
              </div>
            </div>
            <div className="article">
              <ListOfArticleTopContent
                loading={fact_check_by_perma_link_loading}
                title={fact_check_title}
                image={fact_check_thumbnail_img}
                view_count={fact_check_view}
                created_date_time={moment(fact_check_created_date_time).format(
                  "MMM D, YYYY"
                )}
                id={link_without_html || sliced_link}
                name={staff_profile?.name}
                type={"fact_check"}
              />

              <ArticleListTopText
                loading={fact_check_by_perma_link_loading}
                headline={fact_check_title}
                content={fact_check_subtitle}
                id={link_without_html || sliced_link}
                type={"fact-check"}
              />
            </div>
          </div>
        </div>
      </div>

      <Row className="videos_list_news_story_card">
        <div className="search_results_items">
          <div className="search_result_cards">
            <div className="article_list_news_stories_card">
              {filtered_items?.map((item, index) => (
                <CustomNewsStoryCard
                  article_img={item?.thumbnail_img}
                  name={item?.staff_profile?.name}
                  date={item?.created_date_time}
                  viewed_count={item?.views}
                  reading_time={item?.reading_time}
                  img_width={"27.8vw"}
                  content_width={"320px"}
                  first_row_width={270}
                  text={split_tamil_english_words(item?.title, _, 7)}
                  subtitle={split_tamil_english_words(item?.subtitle, _, 9)}
                  type={"fact-check"}
                  img_height={"30.5vh"}
                  perma_link={item?.perma_link}
                  loading={fact_check_list_loading}
                />
              ))}
            </div>
            <div className="article_list_in_list_of_article">
              {fact_check_list_loading
                ? Array.from({ length: page_limit }, (_, index) => (
                    <Skeleton.Input
                      key={index}
                      className="six-categories-card-second-div-for-skeleton"
                      active
                    />
                  ))
                : fact_check_list?.map((item, index) => (
                    <CustomFactCheckCategories
                      key={index}
                      category_image={item?.thumbnail_img}
                      created_date={item?.created_date_time}
                      viewed_count={item?.views}
                      reading_time={item?.reading_time}
                      title={split_tamil_english_words(item?.title, _, 7)}
                      sub_title={split_tamil_english_words(
                        item?.subtitle,
                        _,
                        9
                      )}
                      type={location?.props_state?.type}
                      perma_link={item?.perma_link}
                      loading={fact_check_list_loading}
                    />
                  ))}
            </div>

            {fact_check_list_loading &&
              Array.from({ length: 4 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="fact-check-list-news-story-card-skeleton"
                  active
                />
              ))}
            {fact_check_list?.length === total_count ? (
              ""
            ) : (
              <div className="load_more_button">
                <Button className="load_button_color" onClick={on_click}>
                  {t("Load More")}
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
            }}
          >
            <div className="trending-now-arrow-with-text">
              <Image
                className="mobile_drawer_moon"
                visible={false}
                preview={false}
                src={ARROW_ICON}
              />
              <CustomText
                text={"Trending Now"}
                font_family={"lato"}
                size={"20px"}
                align={"left"}
                weight={"700"}
              />
            </div>
            <div className="list_of_article_page_news_article">
              {three_trending_fact_check_list_loading
                ? Array.from({ length: 3 }, (_, index) => (
                    <Skeleton.Input
                      key={index}
                      className="fact-check-list-three-trending-skeleton"
                      active
                    />
                  ))
                : three_trending_fact_check_list
                    ?.slice(0, 3)
                    ?.map((item, index) => (
                      <CustomNewsArticle
                        key={index}
                        view_count={item?.views}
                        reading_time={item?.reading_time}
                        date={moment(item?.created_date_time).format(
                          `MMM DD, YYYY`
                        )}
                        header_color={"#0065cc"}
                        name={item?.staff_profile?.name}
                        article={item?.title}
                        type={"fact-check"}
                        perma_link={item?.perma_link}
                        loading={three_trending_fact_check_list_loading}
                      />
                    ))}
            </div>
            <div className="social-media-list-fact-check">
              <SocialMediaList width={"100%"} />
            </div>
          </div>
        </div>
      </Row>
      <Row style={{ width: "100%" }}>
        <CategoriesList
          set_article_category_id={set_article_category_id}
          set_article_category_name={set_article_category_name}
          article_category_name={article_category_name}
          article_category_id={article_category_id}
          perma_link={
            article_category_id
              ? category_wise_article_list?.[0]?.perma_link
              : ""
          }
        />
      </Row>
      <div>
        {recent_fact_check_items_loading ? (
          Array.from({ length: 1 }, (_, index) => (
            <Skeleton.Input
              key={index}
              className=" "
              active
              style={{ width: "20vw", height: "70vh", marginLeft: "10vh" }}
            />
          ))
        ) : (
          <div>
            <RecentlyAdded
              height={"103vh"}
              article_list={recent_fact_check_items}
              type={"fact-check"}
              loading={recent_fact_check_items_loading}
            />
          </div>
        )}
      </div>
      <div className="fact-check-join-us-card">
        <JoinUsCard />
      </div>
    </div>
  );
};

export default FactCheckList;
