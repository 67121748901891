import React from "react";
import { useTranslation } from "react-i18next";
import AntdDynamicForm from "../../system/ui/antd_form/antd_form";

const UserContributionSource = (props) => {
  const { t } = useTranslation();
  const { color } = props;
  return (
    <AntdDynamicForm.RichText
      label={<span style={{ color: color ? color : "black" }}>{"Source"}</span>}
      field="source"
      is_disabled={true}
    />
  );
};
export default UserContributionSource;
