import { gql } from "@apollo/client";

export const get_all_slot_query = gql`
  query get_all_slot($language_id: String!) {
    get_all_slot(language_id: $language_id) {
      items {
        fact_check_id
        fact_check {
          id
          user_profile_id
          staff_profile_id
          staff_profile {
            name
          }
          title
          subtitle
          content
          reading_time
          likes
          views
          created_date_time
          thumbnail_img
          perma_link
        }
        article_id
        article {
          id
          user_profile_id
          staff_profile_id
          title
          subtitle
          content
          reading_time
          likes
          views
          created_date_time
          thumbnail_img
          perma_link
        }
      }
    }
  }
`;

export const get_four_fact_check_content_query = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        staff_profile {
          name
        }
        id
        created_date_time
        published_date_time

        title
        subtitle
        content
        reading_time
        views
        thumbnail_img
        perma_link
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const create_user_claim = gql`
  mutation create_user_claim($json: create_user_claim_input) {
    create_user_claim(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_awards = gql`
  query get_all_award {
    get_all_award {
      items {
        id
        title
        subtitle
        content
        description
        perma_link
        thumbnail_img
        created_date_time
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_all_awards_query = gql`
  query get_all_award {
    get_awards: get_all_award {
      items {
        id
        title
        subtitle
        content
        description
        perma_link
        thumbnail_img
        created_date_time
        error {
          message
        }
      }
    }
  }
`;

export const query_get_videos = gql`
  query get_videos_from_channel {
    get_videos_from_channel {
      items {
        title
        published_at
        thumbnails
        views
        days_since_published
        video_id
      }
    }
  }
`;

export const query_recent_articles = gql`
  query get_user_recent_articles(
    $json: get_all_article_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_user_recent_articles(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        language_id
        user_profile_id
        title
        subtitle
        perma_link
        description
        views
        reading_time
      }
    }
  }
`;

export const get_user_contributed_stories_query = gql`
  query get_all_user_contribution($language_id: String!) {
    get_all_user_contribution(language_id: $language_id) {
      items {
        id
        record_type
        user_profile {
          name
        }
        staff_profile {
          name
        }
        staff_profile_id
        user_profile_id
        title
        subtitle
        content
        reading_time
        perma_link
        perma_link
        thumbnail_img
        views
        created_date_time
        published_date_time
      }
    }
  }
`;

export const get_month_wise_top_contributors_query = gql`
  query get_top_month_wise_user_contributers($language_id: String) {
    get_top_month_wise_user_contributors: get_top_month_wise_user_contributers(
      language_id: $language_id
    ) {
      items {
        profile_pic
        user_profile_id
        language_id
        name
      }
    }
  }
`;

export const get_all_languages_query = gql`
  query get_all_system_languages {
    get_all_system_languages {
      items {
        id
        key
        name
      }
    }
  }
`;
