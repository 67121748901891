import { gql } from "@apollo/client";

export const mutation_create_user = gql`
  mutation create_user($json: user_input) {
    create_user(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_user = gql`
  mutation update_user($json: user_input, $id: String) {
    update_user(json: $json, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_user = gql`
  mutation delete_user($id: String!) {
    delete_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_user_query = gql`
  query get_users(
    $page_number: Float
    $page_limit: Float
    $role_name: String
    $search_string: String
  ) {
    get_users(
      page_number: $page_number
      page_limit: $page_limit
      role_name: $role_name
      search_string: $search_string
    ) {
      items {
        id
        mobile
        username
        is_active
        user_profile {
          name
          mobile
          email
        }
        staff_profile {
          name
          mobile
          email
        }
        roles {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const give_privileged_access_query = gql`
  mutation give_previleged_access_to_user($user_id: String!) {
    give_previleged_access_to_user(user_id: $user_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_back_privileged_access_query = gql`
  mutation get_back_previleged_access_from_user($user_id: String!) {
    get_back_previleged_access_from_user(user_id: $user_id) {
      id
      status
      error {
        message
      }
    }
  }
`;
