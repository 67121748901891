import React, { useEffect, useRef } from "react";
import UserProfileTitle from "../components/user_profile/user_profile_title";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import CustomFactCheckCategories from "../components/custom/custom_fact_check_categories";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import {
  query_get_my_all_article,
  query_get_my_all_fact_check,
} from "../../services/redux/slices/graphql/graphql_user_profile";
import { retrieveItem } from "../../helpers/functions";
import { useDispatch } from "react-redux";
import { Button, Empty, Skeleton, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { BROKEN_IMAGE } from "../../helpers/image_constants";
import { debounce } from "lodash";
import CustomText from "../components/custom/custom_text";
import { navigate } from "../../helpers/navigator";
import { ROUTES } from "../routes/my_routes";
import { HStack } from "native-base";
import { MdOutlinePendingActions, MdPublish } from "react-icons/md";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import UserArticleList from "../components/user_contribution_list/user_article_list";
import UserFactCheckList from "../components/user_contribution_list/user_fact_check_list";

const { TabPane } = Tabs;

const UserProfileList = () => {
  const { type } = useParams();
  const [list, set_list] = useState([]);
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const session_id = retrieveItem("session_id");
  const { is_dark_mode } = useDarkMode();

  const [page_limit, set_page_limit] = useState(6);
  const [list_loading, set_list_loading] = useState();
  const [list_pagination, set_list_pagination] = useState();
  const [status, set_type] = useState("published");
  const [redirect_type, set_redirect_type] = useState();

  const {
    items: my_fact_check,
    loading: my_fact_check_loading,
    pagination: my_fact_check_pagination,
  } = useDynamicSelector("get_my_fact_check_list");
  const {
    items: my_articles,
    loading: my_articles_loading,
    pagination: my_article_pagination,
  } = useDynamicSelector("get_my_article_list");

  const on_click = () => {
    set_page_limit(page_limit + 6);
  };

  const get_all_my_fact_check = () => {
    let key = [{ key: "get_my_fact_check_list", loading: true }];
    let query = query_get_my_all_fact_check;
    let variable = {
      page_limit: page_limit,
      status: status,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  const get_all_my_article = () => {
    let key = [{ key: "get_my_article_list", loading: true }];
    let query = query_get_my_all_article;
    let variable = {
      page_limit: page_limit,
      status: status,
    };
    dispatch(dynamic_request(key, query, variable));
  };

  // const get_author_detail = () => {
  //   let key = [{ key: "get_all_author_stories", loading: true }];
  //   let query = query_get_author_details;
  //   let variable = {
  //     user_profile_id: userProfileId,
  //     staff_profile_id: staffProfileId,
  //     language_id: current_language?.id,
  //     type: "article",
  //     page_number: 1,
  //     page_limit: 30,
  //   };
  //   dispatch(dynamic_request(key, query, variable));
  // };
  useEffect(() => {
    if (list && list > 0) {
      set_total_list(...list);
    }
  }, [list]);

  useEffect(() => {
    if (session_id) {
      if (type === "my-article" && status === "published") {
        get_all_my_article();
      }
      if (type === "my-factcheck" && status === "published") {
        get_all_my_fact_check();
      }
    }
  }, [type, page_limit, session_id, status]);

  useEffect(() => {
    if (
      my_fact_check ||
      (my_articles && (my_fact_check || my_articles).length > 0)
    ) {
      if (type === "my-factcheck" && status === "published") {
        set_list(my_fact_check);
        set_list_loading(my_fact_check_loading);
        set_list_pagination(my_fact_check_pagination);
      }
      if (type === "my-article" && status === "published") {
        set_list(my_articles);
        set_list_loading(my_articles_loading);
        set_list_pagination(my_article_pagination);
      }
      // if (type === "fact_check" || type === "article") {
      //   set_list(my_book_marks);
      //   set_list_loading(my_book_marks_loading);
      //   set_list_pagination(my_book_marks_pagination);
      // }
    }
  }, [
    my_fact_check,
    my_articles,
    my_fact_check_loading,
    my_articles_loading,
    my_fact_check_pagination,
    my_article_pagination,
    status,
  ]);

  useEffect(() => {
    if (type === "my-factcheck") {
      set_redirect_type("My Fact Checks");
    } else if (type === "my-article") {
      set_redirect_type("My Articles");
    }
  }, [type]);

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);
  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  const on_click_create = () => {
    if (redirect_type === "My Articles") {
      navigate(ROUTES.USER_CONTRIBUTED_FORM);
    } else if (redirect_type === "My Fact Checks") {
      navigate(ROUTES.USER_FACT_CHECK_CONTRIBUTION_FORM);
    }
  };
  const get_list = () => {
    if (redirect_type === "My Articles") {
      navigate(ROUTES.USER_ARTICLE_LIST);
    } else if (redirect_type === "My Fact Checks") {
      navigate(ROUTES.USER_FACT_CHECK_LIST);
    }
  };

  const handleTabChange = (key) => {
    set_type(key);
  };

  return (
    <div
      style={{ overflow: "hidden" }}
      ref={top_ref}
      className={is_dark_mode ? "content-dark" : ""}
    >
      <UserProfileTitle type={redirect_type} title={""} />

      <CustomText
        class_name={"user_profile_titles"}
        text={redirect_type}
        font_family={"Lato"}
        size={"4vh"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />
      <div className="my_fact_check_container">
        <Tabs onChange={handleTabChange} defaultValue={type}>
          <TabPane
            tab={
              <span>
                <MdPublish style={{ marginRight: "6px" }} />
                Published
              </span>
            }
            key="published"
          ></TabPane>
          <TabPane
            tab={
              <span>
                <MdOutlinePendingActions style={{ marginRight: "6px" }} />
                Pending
              </span>
            }
            key="pending"
          ></TabPane>
        </Tabs>
        <HStack justifyContent={"flex-end"}>
          <Button
            style={{ backgroundColor: "#0065cc", color: "white" }}
            onClick={on_click_create}
          >
            Create
          </Button>
        </HStack>
      </div>

      {status === "pending" && type === "my-factcheck" && <UserFactCheckList />}
      {status === "pending" && type === "my-article" && <UserArticleList />}
      {status === "published" && (
        <div className="top_six_article_card">
          <div className="top_six_article">
            {list?.length > 0
              ? list?.map((item) => (
                  <CustomFactCheckCategories
                    loading={false}
                    category_image={
                      item?.thumbnail_img === "null"
                        ? BROKEN_IMAGE
                        : item.thumbnail_img
                    }
                    title={item?.title ? item.title : ""}
                    sub_title={item?.subtitle ? item.subtitle : ""}
                    created_date={
                      item
                        ? moment(item.created_date_time).format(`MMM D,YYYY`)
                        : ""
                    }
                    perma_link={item?.perma_link ? item.perma_link : ""}
                    type={redirect_type ? redirect_type : ""}
                    name={
                      item.user_profile?.name ||
                      item.staff_profile?.name ||
                      "YouTurn Editorial"
                    }
                  />
                ))
              : !list_loading && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <Empty description="No data" />
                  </div>
                )}

            {list_loading &&
              Array.from({ length: 6 }, (_, index) => (
                <Skeleton.Input
                  key={index}
                  className="six-categories-card-second-div-for-skeleton"
                  active
                />
              ))}
          </div>
        </div>
      )}

      {status === "published" && (
        <div className="see_all_article_button">
          <div className="user_profile_load_more_button">
            {list?.length === list_pagination?.total_count ||
            list?.length < 6 ? (
              ""
            ) : (
              <Button
                className="user_profile_load_button_color"
                onClick={on_click}
              >
                {t("Load More")}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileList;
