import React, { useEffect, useState } from "react";
import { FORM_BACKGROUND } from "../../../helpers/image_constants";
import { Button, Form, Select } from "antd";
import { HStack, VStack } from "native-base";
import VForm from "../system/ui/antd_form";
import { useTranslation } from "react-i18next";
import { navigateBack } from "../../../helpers/navigator";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {
  dynamic_request,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import UserContributionSource from "./user_details_card/user_contribution_source";
import UserContributionShortStory from "./user_details_card/user_contribution_short_story";
import UserContributionSpreadNews from "./user_details_card/user_contribution_spread_news";

const UserProfilePendingForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { props_state } = useLocation();
  const dispatch = useDispatch();
  const records = props_state?.record?.record;
  const type = props_state?.contribution_type;

  const [initial_values, set_initial_values] = useState({});

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_back = () => {
    navigateBack();
  };

  useEffect(() => {
    get_all_languages();
  }, []);

  useEffect(() => {
    if (records?.id) {
      form.setFieldsValue({
        ...records,

        thumbnail_img: records?.thumbnail_img?.[0]
          ? [
              {
                url: records?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
    } else {
      form.resetFields();
    }
  }, [records]);

  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${FORM_BACKGROUND})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.1,
        }}
      ></div>
      <VStack style={{ padding: "50px" }}>
        <Form
          id="article_form"
          initialValues={initial_values}
          form={form}
          layout="vertical"
        >
          <Form.Item
            label={<span style={{ color: "#ffffff" }}>{t("title")}</span>}
            name="title"
            style={{ marginTop: "22px" }}
          >
            <TextArea disabled />
          </Form.Item>

          <Form.Item
            label={<span style={{ color: "#ffffff" }}>{t("subtitle")}</span>}
            name="subtitle"
            style={{ marginTop: "22px" }}
          >
            <TextArea disabled />
          </Form.Item>
          <VForm.ImageFile
            label={<span style={{ color: "white" }}>Thumbnail Img</span>}
            field={"thumbnail_img"}
            is_disabled={true}
          />

          <Form.Item
            label={<span style={{ color: "white" }}>Language</span>}
            name="language_id"
          >
            <Select disabled>
              {system_languages?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <VForm.TextBox
            label={<span style={{ color: "white" }}>Perma link</span>}
            field={"perma_link"}
            disabled
          />

          <VForm.RichText
            label={<span style={{ color: "white" }}>{t("content")}</span>}
            field={"content"}
            is_disabled={true}
          />

          {type === "fact_check" && (
            <>
              <UserContributionShortStory color="#ffffff" />
              <UserContributionSpreadNews color="#ffffff" />
              <UserContributionSource color="#ffffff" />
            </>
          )}

          <HStack justifyContent={"flex-end"} space={"10px"}>
            <Button onClick={handle_back} danger>
              {t("back")}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </div>
  );
};

export default UserProfilePendingForm;
