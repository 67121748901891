import React from "react";
import {
  DEFAULT_USER,
  YOU_TURN_LOGO_BLUE,
} from "../../../helpers/image_constants";
import CustomText from "../custom/custom_text";
import { useDynamicSelector } from "../../../services/redux";
import { Col, Image, Row } from "antd";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Skeleton } from "antd";
import { format_view_count } from "../../../helpers/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ArticleDetailsAndAuthorProfile = (props) => {
  const { profile } = props;
  const history = useHistory();
  const {
    staff_profile,
    views,
    reading_time,
    created_date_time,
    published_date_time,
    user_profile,
    is_saved: book_mark_save,
    loading: article_loading,
  } = useDynamicSelector("get_article_by_perma_link");

  const { t } = useTranslation();
  const on_click = () => {
    const queryParams = new URLSearchParams();
    if (staff_profile?.id) {
      queryParams.set("staff_profile", staff_profile?.id);
    }
    if (user_profile?.id) {
      queryParams.set("user_profile", user_profile?.id);
    }
    queryParams.set("type", "article");

    history.push({
      pathname: `/author/${staff_profile?.name || user_profile?.name}`,
      search: `?${queryParams.toString()}`,
    });
  };
  return (
    <div>
      {article_loading ? (
        <Skeleton.Input
          active
          className="single_article_view_skeleton_loading"
        />
      ) : (
        <Row gutter={24} className="icon_card">
          <Col className="icon_card_first_col">
            <Image
              onClick={on_click}
              className="single-fact-check-you-turn-logo"
              visible={false}
              preview={false}
              src={profile || DEFAULT_USER}
            />
          </Col>
          <Col span={12} className="single_article_logo_text">
            <Row>
              <CustomText
                class_name={"icon_card_text_bold"}
                text={staff_profile?.name || user_profile?.name}
                color={"#61666D"}
                font_family={"Lato"}
                align={"left"}
                on_click={on_click}
                is_link={true}
              />
            </Row>
            <Row className="single_article_page_article_date">
              <CustomText
                class_name={"icon_card_text"}
                text={moment(
                  published_date_time
                    ? parseInt(published_date_time, 10)
                    : created_date_time
                ).format("DD MMM, YYYY")}
                size={"12px"}
                color={"#61666D"}
                font_family={"Lato"}
                align={"left"}
              />
            </Row>

            <Row className="single_article_view_icon">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    color: "#E84756",
                    width: "18px",
                    height: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1px",
                  }}
                >
                  <LuEye />
                </div>
                <div
                  style={{
                    height: "17px",
                  }}
                >
                  <CustomText
                    text={format_view_count(views)}
                    size={"12px"}
                    color={"#E84756"}
                    weight={600}
                    font_family={"Lato"}
                    align={"left"}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    color: "gray",
                    width: "18px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiFillClockCircle />
                </div>

                <div
                  style={{
                    height: "17px",
                  }}
                >
                  <CustomText
                    text={`${reading_time} ${t("min")}`}
                    size={"12px"}
                    color={"#61666D"}
                    weight={600}
                    font_family={"Lato"}
                    align={"left"}
                  />
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ArticleDetailsAndAuthorProfile;
