import { gql } from "@apollo/client";
import * as XLSX from "xlsx";
import { retrieveItem, storeItem } from "./functions";
import { changeLanguage } from "i18next";
import { saveAs } from "file-saver";


export const font = {
  tamil: "2vh",
  english: "4vh",
};

export const language = {
  tamil_id: "839bcc29-853f-4c4c-8700-98fd88558952",
  english_id: "ea83e859-ce26-4086-9e85-ec40f5dbe4f0",
};
export const language_list = [
  {
    key: "ta",
    id: "839bcc29-853f-4c4c-8700-98fd88558952",
    name: "tamil",
  },
  {
    id: "ea83e859-ce26-4086-9e85-ec40f5dbe4f0",
    key: "en",
    name: "english",
  },
];

export const box_shadow = "5px 5px 15px 0px rgba(0, 101, 204, 0.15)";
export const colors = {
  fact_check_half_box_color: "rgba(20, 15, 45, 0.9)",
  gradient_color: "linear-gradient(147.32deg, #140F2D 40.85%, #F49D36 169.52%)",
  bread_crumbs_color: "#140f2d",
  text_grey: "rgb(141 122 122)",
};

export const languages = ["en"];
export const ProjectName = "YouTurn";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_STATE_CODE = "TN";
export const SITE_URL = process.env.REACT_APP_WEB_URL;

// export const fileQuery = gql`
//   mutation {
//     createHospitalPatientVisitDocumentUploadUrl {
//       status
//       url
//       error {
//         status_code
//         message
//       }
//     }
//   }
// `;

export const fileQuery = gql`
  query get_upload_url($type: String, $fileName: String) {
    get_upload_url(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
export const generateExcelFile = ({ header, data, filename }) => {
  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(account_sheet_items);

  // Define column headers
  const headers = header;
  // [
  //   "Institution",
  //   "Financial Year",
  //   "Income",
  //   "Demand",
  //   "Collection",
  //   "Balance",
  // ];

  // Set column headers in the worksheet
  const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
  const numColumns = headers.length;
  headerRange.e.c = numColumns - 1; // Adjust the end column of the header range
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const headerCell = XLSX.utils.encode_cell({ r: 0, c: col });
    worksheet[headerCell] = { v: headers[col] }; // Create a new cell object
  }

  // Process the data rows
  for (let row = headerRange.s.r + 1; row <= headerRange.e.r; row++) {
    const rowData = account_sheet_items[row - 1]; // Adjust index since row 0 is for headers
    const institutionName = rowData.institution.name;
    const termName = rowData.term.name;
    const income = rowData.demand.total_income;
    const demand = rowData.demand.contribution_net_accessed_amount;

    // Set values in respective columns
    const institutionCell = XLSX.utils.encode_cell({ r: row, c: 0 });
    worksheet[institutionCell] = { v: institutionName }; // Create a new cell object

    const termCell = XLSX.utils.encode_cell({ r: row, c: 1 });
    worksheet[termCell] = { v: termName }; // Create a new cell object

    const incomeCell = XLSX.utils.encode_cell({ r: row, c: 2 });
    worksheet[incomeCell] = { v: income }; // Create a new cell object

    const demandCell = XLSX.utils.encode_cell({ r: row, c: 3 });
    worksheet[demandCell] = { v: demand }; // Create a new cell object

    // Calculate values for Collection and Balance columnstm
    const collection = rowData.demand.contribution_net_accessed_amount;
    const balance = calculateBalance(income, collection);

    const collectionCell = XLSX.utils.encode_cell({ r: row, c: 4 });
    worksheet[collectionCell] = { v: collection }; // Create a new cell object

    const balanceCell = XLSX.utils.encode_cell({ r: row, c: 5 });
    worksheet[balanceCell] = { v: balance }; // Create a new cell object
  }

  // Update the worksheet range
  worksheet["!ref"] = XLSX.utils.encode_range(headerRange.s, headerRange.e);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate the Excel file
  const excelFile = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  // Download the Excel file
  const fileName = "data.xlsx";
  const blob = new Blob([s2ab(excelFile)], {
    type: "application/octet-stream",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};
export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const export_to_xlsx = (data, file_name) => {
  const style = {
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
    font:{bold:"true"}
  };

  const ws = XLSX.utils.json_to_sheet(data, {
    header: Object.keys(data[0]),
    styles: { header: [style], cell: [style] },
    autoSize: true,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${file_name}.xlsx`);
};

export const SpecialCharacterRemove = (input) => {
  // Remove forward slashes and concatenate the remaining string
  const result = input?.replace(/\//g, "");
  return result;
};

export const countFormat = (value) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};

export const social_media_option = [
  {
    id: "instagram",
    name: "Instagram",
  },
  {
    id: "twitter",
    name: "Twitter",
  },
  {
    id: "face_book",
    name: "Facebook",
  },
  {
    id: "linked_in",
    name: "LinkedIn",
  },
];

export const getDefaultLanguageId = () => {
  let url = window.location.href;
  if (url.includes(process.env.REACT_APP_EN_WEB_URL)) {
    changeLanguage("en");
    storeItem("language", {
      name: "english",
      id: language?.english_id,
      key: "en",
    });
    return language?.english_id;
  } else if (url.includes(process.env.REACT_APP_WEB_URL)) {
    changeLanguage("ta");
    storeItem("language", {
      name: "tamil",
      id: language?.tamil_id,
      key: "ta",
    });
    return language?.tamil_id;
  } else {
    changeLanguage("ta");
    storeItem("language", {
      name: "tamil",
      id: language?.tamil_id,
      key: "ta",
    });
    return language?.tamil_id;
  }
};

export const get_language = (language) => {
  let url = [
    {
    name:"தமிழ்",
    url:"https://youturn.in/"
  },
  {
    name:"English",
    url:"https://en.youturn.in/"
  }
];
 return url?.find((lan)=>lan?.name===language).url
};

export const fact_check_status = {
  Admin_my_fact_check: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Draft", id: "draft" },
    {name:"Scheduled",id:"scheduled"}
  ],
  Admin_editors_fact_check: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Draft", id: "draft" },
    { name: "Pending", id: "pending" },
    {name:"Scheduled",id:"scheduled"}

  ],
  Admin_user_fact_check: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Approved", id: "approved_by_editor" },
    { name: "Rejected", id: "rejected_by_editor" },
    {name:"Scheduled",id:"scheduled"}

  ],
  Editor_my_fact_check: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Draft", id: "draft" },
    { name: "Pending", id: "pending" },
  ],
  Editor_user_fact_check: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Pending", id: "pending" },
    { name: "Approved", id: "approved_by_editor" },
    { name: "Rejected", id: "rejected_by_editor" },
  ],
};

export const article_status = {
  Admin_my_article: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Draft", id: "draft" },
    {name:"Scheduled",id:"scheduled"}

  ],
  Admin_editor_article: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Draft", id: "draft" },
    { name: "Pending", id: "pending" },
    {name:"Scheduled",id:"scheduled"}

  ],
  Admin_user_article: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Approved", id: "approved_by_editor" },
    { name: "Rejected", id: "rejected_by_editor" },
    {name:"Scheduled",id:"scheduled"}

  ],
  Editor_my_article: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Draft", id: "draft" },
    { name: "Pending", id: "pending" },
  ],
  Editor_user_article: [
    { name: "Published", id: "published" },
    { name: "Unpublished", id: "unpublished" },
    { name: "Pending", id: "pending" },
    { name: "Approved", id: "approved_by_editor" },
    { name: "Rejected", id: "rejected_by_editor" },
  ],
};
