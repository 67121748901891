import { Button } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";

const UserContributionStoriesSeeAllButton = (props) => {
  const { handle_click } = props;
  const { t } = useTranslation();
  const {
    items: recently_added_for_user_contribution_items,
    pagination: recently_user_contribution_pagination,
  } = useDynamicSelector("recently_added_for_user_contribution");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        height: "8vh",
      }}
    >
      {recently_added_for_user_contribution_items?.length ===
      recently_user_contribution_pagination?.total_count ? (
        ""
      ) : (
        <Button
          style={{
            color: "#140F2D",
            borderRadius: "10px",
            width: "320px",
            border: "1px solid black",
          }}
          onClick={handle_click}
        >
          {t("load_more")}
        </Button>
      )}
    </div>
  );
};

export default UserContributionStoriesSeeAllButton;
