export const NEWS = require("../assets/icons/news.jpg");
export const LOGO = require("../assets/icons/YouTurn_logo.png");
export const LOGO_WITH_BLUE_TEXT = require("../assets/icons/youturn_logo_blue.png");
export const SIGNATORY_LOGO_WITH = require("../assets/icons/signatory.png");
export const PLASTIC = require("../assets/icons/plastic.png");
export const INSTAGRAM_LOGO = require("../assets/icons/instagram.png");
export const FACEBOOK_LOGO = require("../assets/icons/facebook.png");
export const TWITTER_LOGO = require("../assets/icons/twitter.png");
export const YOUTUBE_LOGO = require("../assets/icons/youtube.png");
export const LINKEDIN_LOGO = require("../assets/icons/linkedin.png");
export const TELEGRAM_LOGO = require("../assets/icons/telegram.png");
export const PROFILE = require("../assets/icons/profile1.png");
export const PROFILE2 = require("../assets/icons/profile2.jpg");
export const LOGO_TRANSPARENT = require("../assets/icons/you_turn_logo_transparent.png");
export const CONTRIBUTORS = require("../assets/icons/contributors.png");
export const TELEGRAM_COLOR = require("../assets/icons/telegram_og.png");
export const FACEBOOK_COLOR = require("../assets/icons/facebook_og.png");
export const YOUTUBE_COLOR = require("../assets/icons/youtube_og_2.png");
export const LINKEDIN_COLOR = require("../assets/icons/linkedin_og.png");
export const INSTAGRAM_COLOR = require("../assets/icons/instagram_og.png");
export const X_TWITTER_COLOR = require("../assets/icons/twitter_og_2.png");
export const ACCOUNT_PROFILE = require("../assets/icons/account_circle.png");
export const HEALTH_ICON = require("../assets/icons/health_icon.png");
export const TECHNOLOGY_ICON = require("../assets/icons/technology.png");
// export const WORKS_ICON = require("../assets/icons/works_icons.png");
export const TRUE_NEWS = require("../assets/icons/true_news.png");
export const FAKE_NEWS = require("../assets/icons/fake_news.png");
export const MIX_NEWS = require("../assets/icons/mix_news.png");
export const SARCASM_NEWS = require("../assets/icons/sarcasm_news.png");
export const MISLEADING_NEWS = require("../assets/icons/misleading_news.png");
export const FALSE_NEWS = require("../assets/icons/false_news.png");
export const SIGNATORY = require("../assets/icons/signatory.png");
export const FACT_CHECK_IMAGES = require("../assets/icons/fact_check_image.png");
export const CIRCLE_NOTIFICATION = require("../assets/icons/circle_notifications.png");
export const FACT_CHECK_IMAGE = require("../assets/icons/fact_check_img.png");
export const FACT_CHECK_CATEGORY = require("../assets/icons/fact_check_categories.png");
export const YOU_TURN_LOGO = require("../assets/icons/youturn_logo_2.png");
export const LAUNCH = require("../assets/icons/launch.png");
export const DVR = require("../assets/icons/dvr.png");
export const MENU_BOOK = require("../assets/icons/menu_book.png");
export const MAINSTREAM = require("../assets/icons/mainstream2.png");
export const POLITICAL_FACT = require("../assets/icons/political_fact.png");
export const MEDIA_RUMOUR = require("../assets/icons/media_rumour.png");
export const NOTIFICATION = require("../assets/icons/notification.png");
export const VIDEO_ICON = require("../assets/icons/video_icon.png");
export const VIDEO_CARD_IMAGE = require("../assets/icons/video_card_image.png");
export const VIDEO_ROUND_ICON = require("../assets/icons/video_round_icon.png");
export const SMALL_VIDEO_IMAGE = require("../assets/icons/small_video_image.png");
export const AWARD_CARD_IMAGE = require("../assets/icons/award_card_image.png");
export const START_ICON = require("../assets/icons/star_icon.png");
export const LOGO_1 = require("../assets/icons/logo1.png");
export const ANTIBIOTICS_IMAGE = require("../assets/icons/antibiotics.png");
export const YOU_TURN_LOGO_BLUE = require("../assets/icons/you_turn_small_logo_image.png");
export const LAB_TOO_IMAGE = require("../assets/icons/labtoo_image.png");
export const MICK_IMAGE = require("../assets/icons/mick_image.png");
export const REPORT_ARTICLE = require("../assets/icons/report_article_icon.png");
export const SHARE_ICON = require("../assets/icons/share_icon .png");
export const SAVE_ICON = require("../assets/icons/save_icon.png");
export const EYE_ICON = require("../assets/icons/eye_icon.png");
export const REPORT_WHITE_ICON = require("../assets/icons/report_white_icon.png");
export const SHARE_WHITE_ICON = require("../assets/icons/share_white_icon.png");
export const FACT_CHECK_IMAGE_2 = require("../assets/icons/fact_check_image (2).png");
export const AWARD_FACT_CHECK = require("../assets/icons/award_fact_check.png");
export const CLOCK_ICON = require("../assets/icons/clock_icon.png");
export const USER_LOGO = require("../assets/icons/user_logo.png");
export const MENU_LOGO = require("../assets/icons/menu_icon.png");
export const FAKE_NEWS_CHECK = require("../assets/icons/fact_check_news_img.png");
export const PM_IMAGE = require("../assets/icons/pm_img.png");
export const RAILWAY = require("../assets/icons/railway_img.png");
export const HALF_MOON = require("../assets/icons/half_moon.png");
export const USER_IMAGE = require("../assets/icons/user_image.png");
export const DRAWER_CLOSE_IMAGE = require("../assets/icons/drawer_close_image.png");
export const SEARCH_IMAGE = require("../assets/icons/search_image.png");
export const INDIA = require("../assets/icons/india.png");
export const FILTER_ICON = require("../assets/icons/filter_image.png");
export const ARROW_ICON = require("../assets/icons/arrow_image.png");
export const ARTICLE_IMAGE = require("../assets/icons/article_img.png");
export const UNDEFINED_PROFOLE = require("../assets/icons/undefined_image.png");
export const SYSTEM_IMAGE = require("../assets/icons/system_image.png");
export const HEALTH = require("../assets/icons/health.png");
export const MOBILE = require("../assets/icons/mobile.png");
export const MAIN_STREAM = require("../assets/icons/mainstream_media.png");
export const AWARD_RECOGNITION = require("../assets/icons/award_recognition.png");
export const WHITE_STAR = require("../assets/icons/white_star.png");
export const CLAIM_IMAGE = require("../assets/icons/claim.png");
export const EXPLANATION_IMAGE = require("../assets/icons/explanation_image2.png");
export const REPLY_IMAGE = require("../assets/icons/reply_image.png");
export const REPLY_IMAGE1 = require("../assets/icons/reply_image1.png");
export const DETAIL_IMAGE = require("../assets/icons/details_icon.png");
export const POST_IMAGE = require("../assets/icons/post_icon.png");
export const ARROW_POST_IMAGE = require("../assets/icons/arrow_post_img.png");
export const WHATS_APP_LOGO = require("../assets/icons/what's_app_logo.png");
export const EMAIL_LOGO = require("../assets/icons/email_logo.png");
export const TWITTER_X_LOGO_BLACK = require("../assets/icons/twitter_x_logo_black.png");
export const STAR_IMAGE = require("../assets/icons/hotel_class.png");
export const TWITTER_BLACK_BACKGROUND_IMAGE = require("../assets/icons/twitter_black_background.png");
export const PLAY_ICON = require("../assets/icons/play_icon.png");
export const USER_COMMENT_PROFILE = require("../assets/icons/user_profile.png");
export const LOGIN_IMAGE = require("../assets/icons/login_image.png");
export const SAVE_FILLED_ICON = require("../assets/icons/save_filled_icon.png");
export const DEFAULT_TOP_CONTRIBUTORS_AVATAR = require("../assets/icons/default-avatar-top-contributors.png");
export const BROKEN_IMAGE = require("../assets/icons/broken_image.png");
export const YOUTURN_NEW_LOGO = require("../assets/icons/youturn_new_logo_image.png");
export const HOME_ICON = require("../assets/icons/back_home_icon.png");
export const YOU_TUBE_LOGO = require("../assets/icons/you_tube.png");
export const POLITICS = require("../assets/icons/politics_icon.png");
export const USER_PROFILE_BACK_GROUND = require("../assets/icons/user_profile_background.png");
export const PREMIUM_BADGE = require("../assets/icons/premium_badge.png");
export const NO_DATA_IMAGE = require("../assets/icons/no_data_image.png");
export const NO_DATA_IMAGE_ORANGE = require("../assets/icons/no_data_image_green_color.png");
export const FORM_BACKGROUND = require("../assets/icons/background.jpg");
export const FOOTER_LOGO = require("../assets/icons/you_turn_footer_logo.png");
export const SHARE_NEW = require("../assets/icons/share_new.png");
export const SAVE_WHITE_FILLED = require("../assets/icons/save_white_filled.png");
export const SAVE_WHITE_OUTLINED = require("../assets/icons/save_white_out_line.png");
export const REPORT_WHITE = require("../assets/icons/report_white.png");
export const THANK_YOU_IMAGE = require("../assets/icons/thank_you_image_new_2.jpeg");
export const ARROW_GIF_ICON = require("../assets/icons/arrow_gif.gif");
export const FACEBOOK_NEW = require("../assets/icons/facebook_og.png");
export const WHATSAPP_GIF_ICON = require("../assets/icons/animation_whatsapp.gif");
export const REPORT_ICON_WHITE = require("../assets/icons/report_icon_white.png");
export const REPORT_ICON_COLOR = require("../assets/icons/report_icon_color.png");
export const SHARE_ICON_COLOR = require("../assets/icons/share_icon_color.png");
export const MIC_WHITE_IMAGE = require("../assets/icons/mic_white.png");
export const LINK_ICON = require("../assets/icons/link_icon.png");
export const THREADS_ICON = require("../assets/icons/threads-app-icon.png");
export const SHARE_CHAT = require("../assets/icons/share_chat.png");
export const NEW_LOGO_IMAGE_TRANSPARENT = require("../assets/icons/youturn_new_logo_image.png");
export const NEW_LOGO_IMAGE_TRANSPARENT1 = require("../assets/icons/new_logo_image_transparent.png");
export const THREADS_LOGO = require("../assets/icons/threads_gray.png");
export const TELEGRAM_GRAY_LOGO = require("../assets/icons/telegram_gray.png");
export const TELEGRAM_FILLED_IMAGE = require("../assets/icons/telegram_filled_icon.png");
export const TWITTER_FILLED_IMAGE = require("../assets/icons/twitter_filled_icon.png");
export const INSTAGRAM_FILLED_IMAGE = require("../assets/icons/instagram_filled_icon.png");
export const THREADS_COLOR_LOGO = require("../assets/icons/threads_color_logo.jpeg");
export const ANIMATION_UP_ARROW = require("../assets/icons/up_arrow_animation.gif");
export const NEW_MIC_IMAGE = require("../assets/icons/new_mic_image.png");
export const NEW_YOUTURN_SMALL_LOGO = require("../assets/icons/you_turn_small_logo_image.png");
export const ARROW = require("../assets/icons/arrow.png");
export const WRITING_IMAGE = require("../assets/icons/writing_image_new.png");
export const DEFAULT_USER = require("../assets/icons/user_image.jpg");
export const PUBLIC_SPIRITED_IMAGE  = require("../assets/icons/public_spirited_media_image.png");
export const  SUBSCRIBE_BUTTON_IMAGE  = require("../assets/icons/subscribe_button_image.jpeg");
export const  PLAY_BUTTON = require("../assets/icons/play_button.png");
export const   NOTIFICATION_ICON = require("../assets/icons/notification_icon.png");
 
