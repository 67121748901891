import { HStack, VStack } from "native-base";
import React from "react";
import PageTitle from "../../components/system/page_title/page_title";
import ReasonsList from "../../components/admin/reports/reports_form/reasons/reasons_list";

const Reasons = () => {
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Reasons"} />
      </HStack>
      <VStack padding={"40px"}>
        <ReasonsList />
      </VStack>
    </div>
  );
};

export default Reasons;
