import React, { useEffect, useState } from "react";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import moment from "moment";
import { Divider } from "antd";
import { useDarkMode } from "../contexts/dark_mode_provider";
import DonationCardBeforeDonation from "./donation_card/donation_card_before_donation";
import LastThreeDonations from "./donation_card/last_three_transations";
import DonationCardTitle from "./donation_card/donation_card_title";
import { get_my_donation_query } from "../../../services/redux/slices/graphql/graphql_user_profile";

const UserProfileDonationDetails = (props) => {
  const { card_title } = props;
  const dispatch = useDispatch();
  const session_id = retrieveItem("session_id");
  const [manual_amount, set_manual_amount] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const payment_status = payment_response.razorpay_signature;

  const [paymentStatus, setPaymentStatus] = useState(false);
  const { is_dark_mode } = useDarkMode();
  const user_id = retrieveItem("user_id");
  const { items: my_donation } = useDynamicSelector("get_all_donations");

  const current_date = moment();
  const last_three_months_donations = my_donation?.filter((donation) => {
    const donation_date = moment(donation?.created_date_time);
    return current_date.diff(donation_date, "months") < 3;
  });
  const last_three_donations = last_three_months_donations?.slice(0, 4);

  useEffect(() => {
    if (session_id || payment_status) {
      get_my_donation_list();
    }
  }, [session_id, payment_status]);

  const get_my_donation_list = () => {
    let key = [{ key: "get_all_donations", loading: true }];
    let query = get_my_donation_query;
    let variables = {
      user_id: user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div
      className="user_profile_book_mark_card"
      style={{ backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF" }}
    >
      <DonationCardTitle card_title={card_title} />

      <Divider className="user_profile_divider" />

      {last_three_months_donations &&
      last_three_months_donations?.length > 0 ? (
        <>
          <LastThreeDonations last_three_donations={last_three_donations} />
        </>
      ) : (
        <>
          <DonationCardBeforeDonation
            payment_status={payment_status}
            set_manual_amount={set_manual_amount}
            manual_amount={manual_amount}
            set_payment_response={set_payment_response}
            setPaymentStatus={setPaymentStatus}
            paymentStatus={paymentStatus}
          />
        </>
      )}
    </div>
  );
};
export default UserProfileDonationDetails;
