import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_clear,
  dynamic_request,
  forgot_password_query,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  YOUTURN_NEW_LOGO,
  YOU_TURN_LOGO_BLUE,
} from "../../helpers/image_constants";
import { navigate, navigateBack } from "../../helpers/functions";
import { ROUTES } from "../routes/my_routes";
import CustomButton from "../components/custom/custom_button";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading: forgot_password_loading, status: forgot_password_status } =
    useDynamicSelector("forgot_password");
  const [toast_message, set_toast_message] = useState(false);
  const message = () => {
    return t("sent_email_text");
  };
  const onFinish = (values) => {
    forgot_password_api_call(values);
  };

  const handle_back = () => {
    navigate(ROUTES.LANDING_PAGE);
  };

  const forgot_password_api_call = ({ username }) => {
    let key = [{ key: "forgot_password", loading: true }];
    let query = forgot_password_query;
    let variables = {
      username,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const Signin = () => {
    navigate(ROUTES.ADMIN_LOGIN);
  };
  useEffect(() => {
    if (forgot_password_status === "Success") {
      set_toast_message(true);
      dispatch(dynamic_clear("forgot_password"));
    }
    dispatch(dynamic_clear("forgot_password"));
  }, [forgot_password_status]);
  const handle_click = () => {
    navigateBack();
  };
  return (
    <div className="body">
      {/* <div onClick={handle_back} className="back_arrow">
        <img
          style={{
            borderRadius: "9px",
            zIndex: 1,
            objectFit: "cover",
            height: "50px",
            cursor: "pointer",
          }}
          src={YOU_TURN_LOGO_BLUE}
          alt="Profile"
        />
      </div> */}
      <div onClick={handle_back} className="back_arrow"></div>
      <div className="container" id="container">
        <div className="form-container sign-up">
          <Form layout="vertical">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
            <h2 className="sing_in_text">Create Account</h2>
            <div className="view-text">
              <h6 className="sing_in_text">Already have an account?</h6>
              <a
                href="#"
                className="sing_in_text"
                style={{ marginLeft: "5vh" }}
              >
                Sign in
              </a>
            </div>
            <Form.Item
              className="margin-bottom"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <input
                type="text"
                placeholder="Name"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="mobile"
              rules={[{ required: true, message: "Mobile number is required" }]}
            >
              <input
                type="number"
                placeholder="Mobile"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <input
                type="email"
                placeholder="Email"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <button type="submit">
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
              "Submit"
            </button>
          </Form>
        </div>
        <div className="form-container sign-in">
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
            <h1 className="sing_in_text">Reset Password</h1>
            <div className="view-text">
              <h6
                style={{ textDecoration: "underline" }}
                className="not_register_text"
              ></h6>
              <a href="" style={{ marginLeft: "3vh", color: "white" }}></a>
            </div>
            {toast_message ? (
              <div className="forgot_password_send_mail">
                {message()}
                <div
                  style={{
                    // backgroundColor: "yellow",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    name={t("back")}
                    width="70px"
                    on_Click={handle_click}
                  />
                </div>
              </div>
            ) : (
              <>
                <Form.Item
                  className="margin-bottom"
                  name="username"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <input
                    type="text"
                    placeholder="Username or Email"
                    style={{ width: "300px" }}
                  />
                </Form.Item>

                <button type="submit">
                  {forgot_password_loading ? (
                    <div className="leap-frog">
                      <div className="leap-frog__dot"></div>
                      <div className="leap-frog__dot"></div>
                      <div className="leap-frog__dot"></div>
                    </div>
                  ) : (
                    t("forgot_password_submit")
                  )}
                </button>
              </>
            )}
          </Form>
        </div>

        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <div
                style={{
                  marginTop: "70px",
                }}
              >
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Profile"
                />
                <h1>Welcome Back!</h1>
                <p>Unlock Your News Universe! Register Today.</p>
              </div>
            </div>
            <div className="toggle-panel toggle-right">
              <div>
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Profile"
                />
                <h1>{t("welcome")}</h1>
                <p>{t("you_remembered_your_password")}</p>
                <div
                  style={{
                    height: "8vh",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                  className="sing_up_button_div"
                >
                  <button className="hidden" id="register" onClick={Signin}>
                    {t("back")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
