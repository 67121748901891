import { Row } from "antd";
import React from "react";
import CustomText from "../../custom/custom_text";
import { useDynamicSelector } from "../../../../services/redux";

const SingleArticleTopTitle = () => {
  const { title } = useDynamicSelector("get_article_by_perma_link");
  return (
    <Row className="single_article_title_row">
      <CustomText
        class_name={"single_article_title"}
        text={title}
        font_family={"Kufam"}
        align={"left"}
      />
    </Row>
  );
};

export default SingleArticleTopTitle;
