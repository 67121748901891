import { gql } from "@apollo/client";

export const get_all_team_mates_query = gql`
  query get_all_team_member($json: get_all_team_member_input) {
    get_all_team_member(json: $json) {
      items {
        id
        name
        role
        social_media_link
        description
        image_url
        type
        order_number
      }
      error {
        message
        status_code
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_team_member_query = gql`
  query get_team_member($json: get_all_team_member_input) {
    get_team_member(json: $json) {
      id
      name
      role
      social_media_link
      description
      type
      image_url
    }
  }
`;

export const create_team_member_query = gql`
  mutation create_team_member($json: create_team_member_input) {
    create_team_member(json: $json) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

export const update_team_member_query = gql`
  mutation update_team_member($json: update_team_member_input) {
    update_team_member(json: $json) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

export const delete_team_member_query = gql`
  mutation delete_team_member($json: delete_team_member_input) {
    delete_team_member(json: $json) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;
