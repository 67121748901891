import { Col, Row, Skeleton } from "antd";
import React from "react";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import CustomText from "./custom_text";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../routes/my_routes";
import { format_view_count } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import { ARROW_GIF_ICON } from "../../../helpers/image_constants";

const CustomNewsStoryCard = (props) => {
  const { t } = useTranslation();
  const {
    text,
    article_img,
    name,
    date,
    viewed_count,
    reading_time,
    img_width,
    content_width,
    first_row_width,
    perma_link,
    subtitle,
    type,
    img_height,
    loading,
  } = props;

  const history = useHistory();

  const onclick_continue_reading = () => {
    if (type === "article") {
      history.push(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };

  return (
    <Row>
      <Row
        className="custom_news_story_card_box"
        onClick={onclick_continue_reading}
      >
        <Col className="custom_news_story_card_box_first_col">
          <img
            className="custom_news_story_card_box_first_col_image"
            style={{
              // height: img_height ? img_height : "33vh",
              // backgroundColor: "red",
              width: img_width ? img_width : "20.5vw",
              borderRadius: "9px 0 0 9px",
              objectFit: "cover",
              transform: "scale(1)",
            }}
            src={article_img}
          />
        </Col>

        <Col
          className="story_card"
          style={{
            width: content_width ? content_width : "250px",
          }}
        >
          <Row
            className="story_card_items"
            style={{
              width: first_row_width ? first_row_width : "208px",
            }}
          >
            <Col>
              <div className="custom_news_story_card_date">
                <CustomText
                  line_height={"2vh"}
                  text={name}
                  size={"2vh"}
                  weight={"400"}
                  align={"left"}
                  font_family={"Lato"}
                  color={"#61666D"}
                />
                <CustomText
                  line_height={"4vh"}
                  text={moment(date).format("MMM DD, YYYY")}
                  size={"2vh"}
                  weight={"400"}
                  align={"left"}
                  font_family={"Lato"}
                  color={"#61666D"}
                />
              </div>
            </Col>
            <Col>
              <div className="center-align-views-and-reading-time">
                <LuEye style={{ color: "red" }} />
                <CustomText
                  line_height={"2vh"}
                  text={format_view_count(viewed_count ?? 0)}
                  size={"2vh"}
                  weight={"400"}
                  align={"left"}
                  font_family={"Lato"}
                  color={"red"}
                />
              </div>
              <div className="center-align-views-and-reading-time">
                <AiFillClockCircle />

                <CustomText
                  line_height={"4vh"}
                  text={`${reading_time} ${t("min")}`}
                  size={"2vh"}
                  weight={"400"}
                  align={"left"}
                  font_family={"Lato"}
                  color={"#61666D"}
                />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              height: "6vh",
              width: first_row_width ? first_row_width : "208px",
              overflow: "hidden",
            }}
          >
            <CustomText
              class_name={"article_list_news_story_card_title"}
              text={text}
              font_family={"Kufam"}
              size={"2vh"}
              weight={"600"}
              align={"left"}
            />
          </Row>

          <Row
            style={{
              height: "10vh",
              width: first_row_width ? first_row_width : "208px",
              overflow: "hidden",
            }}
          >
            <CustomText
              class_name={"article_list_news_story_card_sub_title"}
              text={subtitle}
              font_family={"Kufam"}
              size={"1.5vh"}
              weight={"400"}
              align={"left"}
            />
          </Row>

          <Row
            style={{
              height: "20px",
              width: first_row_width ? first_row_width : "200px",
              marginLeft: "-5px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <CustomText
              class_name={"continue_reading"}
              text={t("Continue Reading")}
              font_family={"Kufam"}
              size={"1.8vh"}
              weight={"400"}
              align={"left"}
              color={"#0065CC"}
              on_click={onclick_continue_reading}
              is_link={true}
            />

            <img width={30} height={30} src={ARROW_GIF_ICON} />
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default CustomNewsStoryCard;
