import { Button, Form, Modal, Pagination, Select, Table, Tag } from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  delete_user_claim_query,
  get_all_user_claims_query,
} from "../../../../services/redux/slices/graphql/graphql_user_claims";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import UserClaimForm from "./user_claim_form";
import { toast } from "react-toastify";
import moment from "moment";
import Search from "antd/es/input/Search";
import { get_status_tag } from "../../../../helpers/functions";
import { query_get_my_all_claims } from "../../../../services/redux/slices/graphql/graphql_user_profile";
import { countFormat } from "../../../../helpers/constants";
import { EyeOutlined } from "@ant-design/icons";

const UserClaimsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [user_claim_id, set_user_claim_id] = useState("");
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [search_string, set_search_string] = useState();
  const [type, set_type] = useState();
  const [record_type, set_record_type] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [claim_status, set_claim_status] = useState("");
  const {
    items: user_claims_items,
    loading: user_claims_items_loading,
    pagination: user_claims_pagination,
  } = useDynamicSelector("get_user_claims");
  const { items: my_claims_items, loading: my_user_claims_items_loading } =
    useDynamicSelector("get_all_my_user_claims");

  const {
    status: delete_user_claim_status,
    loading: delete_user_claim_loading,
    error: delete_user_claim_error,
  } = useDynamicSelector("delete_user_claim");

  useEffect(() => {
    if (delete_user_claim_status === "Success") {
      toast.success("User Claim deleted Successfully");
      handle_close();
      get_all_user_claims();
      dispatch(dynamic_clear("delete_user_claim"));
    } else if (delete_user_claim_error) {
      toast.error(delete_user_claim_error?.message);
      handle_close();
      dispatch(dynamic_clear("delete_user_claim"));
    }
  }, [delete_user_claim_status, delete_user_claim_error]);

  useEffect(() => {
    get_all_user_claims();
  }, [page_limit, page_number]);

  useEffect(() => {
    if (mutation_form_values?.id) {
      form.setFieldsValue({
        description: mutation_form_values?.description,
        url: mutation_form_values?.ref_url,
        created_date_time: moment(
          mutation_form_values?.created_date_time
        ).format("DD/MM/YYYY"),
        status: claim_status,
      });
      set_claim_status(mutation_form_values?.status);
    } else {
      form.resetFields("");
    }
  }, [mutation_form_values, claim_status]);

  const get_all_user_claims = () => {
    let key = [{ key: "get_user_claims", loading: true }];
    let query = get_all_user_claims_query;
    let variables = {
      json: {
        page_limit: page_limit,
        page_number: page_number,
      },
    };

    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_user_claim", loading: true }];
    let query = delete_user_claim_query;
    let variables = {
      json: {
        id: user_claim_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_my_user_claims = () => {
    let key = [{ key: "get_all_my_user_claims", loading: true }];
    let query = query_get_my_all_claims;
    let variables = {
      json: {
        status: record_type,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_edit = (record) => {
    set_mutation_modal(true);
    set_mutation_type("edit");
    set_mutation_form_values(record);
  };

  const handle_delete = (record) => {
    set_delete_modal(true);
    set_user_claim_id(record?.id);
  };

  const handle_close = () => {
    set_delete_modal(false);
    set_mutation_modal(false);
  };

  const action_items = (record) => {
    return (
      <HStack justifyContent={"center"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: "S.No",
      key: "s_no",
      fixed: "left",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (text, record) => {
        return record?.system_user?.name || record?.name;
      },
    },
    {
      title: t("table:mobile_no"),
      dataIndex: "mobile",
      key: "mobile_no",
      fixed: "left",
      render: (text, record) => {
        return record?.system_user?.mobile || record?.mobile;
      },
    },
    {
      title: t("table:description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("table:url"),
      align: "center",
      key: "url",
      render: (text, record) =>
        record?.ref_url ? (
          <a
            href={record?.ref_url}
            target="_blank"
            rel="noopener noreferrer"
            className="user_claim_url"
          >
            Visit link
          </a>
        ) : (
          "_"
        ),
    },
    {
      title: "Attachments",
      key: "attachment",
      align: "center",
      render: (record) =>
        record?.attachment ? (
          <EyeOutlined
            style={{ cursor: "pointer", color: "#00adee" }}
            onClick={() => window.open(record?.attachment, "_blank")}
          />
        ) : (
          "-"
        ),
    },
    {
      title: t("table:date"),
      key: "date",
      render: (record) => {
        return moment(record?.created_date_time).format("DD/MM/YYYY");
      },
    },
    {
      title: t("table:progressed_staff"),
      key: "progressed_staff",
      render: (record) => {
        return record?.progressed_staff?.name || "-";
      },
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "is_active",
      fixed: "right",
      render: (status) => get_status_tag(status),
    },
  ];

  const on_search = (value) => {
    let search_value = value?.target?.value || value;
    set_search_string(search_value);
    if (search_value?.length > 3 || search_value?.length === 0) {
      get_all_user_claims();
    }
  };
  const on_change = (key, value) => {
    set_type(value);
    set_record_type(key);
  };

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };
  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}></HStack>
      <Table
        columns={columns}
        loading={user_claims_items_loading}
        dataSource={user_claims_items}
        onRow={(record) => {
          return {
            onClick: () => handle_edit(record),
            className: "table-cursor",
          };
        }}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={user_claims_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>

      <Modal
        open={mutation_modal}
        onCancel={handle_close}
        footer={null}
        title={"Update User Claim"}
        destroyOnClose={true}
      >
        <UserClaimForm
          on_cancel={handle_close}
          mutation_form_values={mutation_form_values}
          form={form}
          get_all_user_claims={get_all_user_claims}
          claim_status={claim_status}
        />
      </Modal>
      <Modal
        open={delete_modal}
        title={"Confirmation"}
        footer={null}
        onCancel={handle_close}
      >
        <span>Are you sure to delete?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={handle_close} danger>
            Close
          </Button>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_user_claim_loading}
            className="delete-team-member"
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default UserClaimsList;
