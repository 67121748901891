import { gql } from "@apollo/client";

export const mutation_sign_up = gql`
  mutation sign_up($data: system_user_input) {
    sign_up(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_login = gql`
  query user_login($username: String, $password: String) {
    user_login(username: $username, password: $password) {
      user_id
      name
      mobile
      email
      force_password_change
      username
      session_id
      page_accesses
      page_component_accesses
      is_active
      status
      roles {
        id
        name
      }
      error {
        message
      }
    }
  }
`;

export const admin_login_query=gql`
query admin_login($username:String,$password:String){
  admin_login(username:$username,password:$password){
    user_id
    name
    mobile
    email
    session_id
    page_accesses
    page_component_accesses
    status
    roles{
      id
      name
    }
    error{
      message
    }
  }
}`;
