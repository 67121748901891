import { Form, Input } from "antd";
import React from "react";

const TextInput = () => {
  return (
    <Form.Item name={"about"}>
      <Input.TextArea style={{ height: "9vh" }} />
    </Form.Item>
  );
};

export default TextInput;
