import { HStack, VStack } from "native-base";
import React from "react";
import PageTitle from "../../components/system/page_title/page_title";
import AwardsList from "../../components/admin/awards/awards_list";

const Awards = () => {
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Awards"} />
      </HStack>
      <VStack padding={"40px"}>
        <AwardsList />
      </VStack>
    </div>
  );
};

export default Awards;
