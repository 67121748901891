import React, { useEffect } from "react";
import CustomArticleText from "../custom/custom_article_text";
import ListOfArticleTopContent from "./list_of_article_top_content";
import CustomText from "../custom/custom_text";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import { query_get_article_by_perma_link_in_article_list } from "../../../services/redux/slices/graphql/graphql_article_list";
import { dynamic_request, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  split_tamil_english_words,
  split_tamil_english_words_for_breadcrumbs,
} from "../../../helpers/functions";
import ArticleListTopText from "./article_list_top_text";

const TopArticleInListOfArticle = (props) => {
  const { category_name, article_perma_link } = props;
  const dispatch = useDispatch();

  const {
    title: article_title,
    subtitle,
    perma_link,
    views,
    staff_profile,
    thumbnail_img,
    content,
    created_date_time,
    loading: article_by_perma_link_loading,
  } = useDynamicSelector("get_articles_by_perma_link");
  useEffect(() => {
    get_one_article_by_perma_link();
  }, [article_perma_link]);
  const get_one_article_by_perma_link = () => {
    let key = [{ key: "get_articles_by_perma_link", loading: true }];
    let query = query_get_article_by_perma_link_in_article_list;
    let variables = {
      perma_link: article_perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div className="article_page">
      <div className="inner_article">
        <div className="article_top_title">
          <CustomBreadCrumbs
            pages={`Home/ Articles/ ${split_tamil_english_words_for_breadcrumbs(
              article_title,
              "_",
              2
            )}`}
          />
          <div className="article_title">
            <CustomText
              text={
                category_name === "அனைத்தும்" ||
                category_name === "All" ||
                category_name === "மேலும்" ||
                category_name === "More"
                  ? ""
                  : category_name
              }
              size={"5vh"}
              font_family={"Kufam"}
              color={"#FFFFFF"}
              align={"left"}
              className={"articles"}
            />
          </div>
        </div>
        <div className="article">
          <ListOfArticleTopContent
            loading={article_by_perma_link_loading}
            title={article_title}
            image={thumbnail_img}
            view_count={views}
            created_date_time={moment(created_date_time).format("MMM D, YYYY")}
            id={perma_link}
            name={staff_profile?.name}
            type={"article"}
          />

          <ArticleListTopText
            loading={article_by_perma_link_loading}
            headline={article_title}
            content={subtitle}
            id={perma_link}
            type={"article"}
          />
        </div>
      </div>
    </div>
  );
};

export default TopArticleInListOfArticle;
