import { Row, Breadcrumb } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";
import lodash from "lodash";

const CustomBreadCrumbs = ({ pages, color, class_name }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (item, url) => {
    setActiveItem(item);
    history.push(url);
    window.history.pushState({}, "", url);
  };

  const breadcrumb_items = pages.split("/").filter((item) => item !== "");

  return (
    <Row className={class_name ? class_name : "custom-breadcrumbs"}>
      <Breadcrumb separator="">
        {breadcrumb_items.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {index === breadcrumb_items.length - 1 ? (
              <span
                style={{
                  marginLeft: "1vh",
                  color: color ? color : "white",
                  textDecoration: activeItem === item ? "underline" : "none",
                }}
              >
                {item}
              </span>
            ) : (
              <>
                <Link
                  to={
                    item === breadcrumb_items[0]
                      ? "/"
                      : `/${lodash.kebabCase(item)}`
                  }
                  onClick={() => handleItemClick(item, pathname)}
                  style={{ color: color ? color : "white" }}
                  oncha
                >
                  {item}
                </Link>
                <span
                  style={{
                    marginLeft: "5px",
                    textDecoration: activeItem === item ? "underline" : "none",
                    color: color ? color : "white",
                  }}
                >
                  &gt;
                </span>
              </>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Row>
  );
};

export default CustomBreadCrumbs;
