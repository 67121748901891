import React, { useRef, useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import { Empty } from "antd";
import SingleFactCheckCommentProfile from "./single_fact_check_comment_profile";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { useTranslation } from "react-i18next";

const SingleFactCheckAllComments = (props) => {
  const {
    set_comment_id,
    expandComments,
    handle_child_comment,
    commentListHeight,
  } = props;
  const { t } = useTranslation();
  const firstCommentRef = useRef(null);
  const { is_dark_mode } = useDarkMode();
  const [main_comment_id, set_main_comment_id] = useState(null);
  const [expandedComments, setExpandedComments] = useState({});
  const { items: comment_list } = useDynamicSelector("get_all_parent_comments");
  const { items: child_comment_list } = useDynamicSelector(
    "get_all_child_comments"
  );

  const toggleExpandComment = (comment) => {
    setExpandedComments((prevExpanded) => ({
      ...prevExpanded,
      [comment.id]: !prevExpanded[comment.id],
    }));
  };

  const renderToggleText = (comment) => {
    if (comment?.replies_count === 0) {
      return "View reply";
    } else if (comment?.replies_count === 1) {
      return expandedComments[comment.id] ? "Hide reply" : "View 1 reply";
    } else {
      return expandedComments[comment.id]
        ? "Hide replies"
        : `View ${comment?.replies_count} replies`;
    }
  };

  return (
    <div
      className={`single-fact-check-comment-list  ${
        is_dark_mode ? "content-dark" : ""
      }`}
      style={{
        height: commentListHeight,
        backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF",
      }}
    >
      <div>
        {comment_list?.length === 0 ? (
          <div className="single-fact-check-no-data">
            <Empty
              description={
                <span
                  style={{
                    color: is_dark_mode ? "#FFFF" : "",
                  }}
                >
                  {t("no_comments")}
                </span>
              }
            />
          </div>
        ) : (
          comment_list?.map((comment, cmd_index) => (
            <div key={cmd_index}>
              <SingleFactCheckCommentProfile
                set_comment_id={set_comment_id}
                cmd_index={cmd_index}
                comment={comment}
                set_main_comment_id={set_main_comment_id}
                main_comment_id={main_comment_id}
                ref={
                  expandComments
                    ? cmd_index === 0
                      ? firstCommentRef
                      : null
                    : null
                }
              />

              {comment.replies_count > 0 && (
                <Divider>
                  <Pressable
                    onPress={() => {
                      handle_child_comment(comment);
                      toggleExpandComment(comment);
                    }}
                    style={{
                      fontSize: 10,
                      color: "#0065CC",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{
                        width: "20px",
                      }}
                      visible={false}
                      preview={false}
                      src={REPLY_IMAGE1}
                    />
                    {renderToggleText(comment)}
                  </Pressable>
                </Divider>
              )}

              {expandedComments[comment.id] &&
                child_comment_list?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      {child_comment_list
                        .filter(
                          (child_comment) =>
                            child_comment.parent_comment_id === comment.id
                        )
                        .map((filteredChildComment, child_index) => (
                          <SingleFactCheckReplies
                            key={child_index}
                            child_comment={filteredChildComment}
                          />
                        ))}
                    </div>
                  </div>
                )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SingleFactCheckAllComments;
