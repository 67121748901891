import { gql } from "@apollo/client";
export const query_get_fact_check_by_perma_link = gql`
  query get_fact_check_by_perma_link(
    $perma_link: String!
    $language_id: String!
  ) {
    get_fact_check_by_perma_link(
      perma_link: $perma_link
      language_id: $language_id
    ) {
      id
      is_fact
      status
      reading_time
      views
      likes
      thumbnail_img
      created_date_time
      published_date_time
      is_saved
      user_profile {
        id
        name
        profile_pic
        url
      }
      staff_profile {
        id
        name
        url
        profile_pic
      }
      category_tags {
        name
        id
      }
      title
      subtitle
      content
      spread_news
      error {
        message
        status_code
      }
    }
  }
`;

export const mutation_report = gql`
  mutation create_report($json: report_input) {
    create_report(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_reason = gql`
  query get_all_reason($language_id: String) {
    get_all_reason(language_id: $language_id) {
      items {
        id
        name
      }
      error {
        message
      }
    }
  }
`;

export const mutation_create_comments = gql`
  mutation create_comment($json: comment_input) {
    create_comment(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const query_get_all_comment = gql`
  query get_all_parent_comments(
    $fact_check_id: String
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_parent_comments(
      fact_check_id: $fact_check_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        fact_check_id
        article_id
        content
        replies_count
        parent_comment_id
        commented_by_id
        created_date_time
        child_comments {
          id
          article_id
          content
          commented_by {
            last_login_datetime
          }
        }
        commented_by {
          user_profile {
            name
            profile_pic
          }
        }
        error {
          message
        }
      }
    }
  }
`;

export const query_get_all_fact_check_related_list = gql`
  query get_all_fact_check($json: get_all_fact_check_input) {
    get_all_fact_check(json: $json) {
      items {
        id
        language_id
        staff_profile_id
        user_profile_id

        title
        subtitle
        content
        spread_news
        short_story
        source
        status
        is_fact
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const query_get_all_child_comments = gql`
  query get_all_child_comments($parent_comment_id: String!) {
    get_all_child_comments(parent_comment_id: $parent_comment_id) {
      items {
        id
        fact_check_id
        content
        created_date_time
        parent_comment_id
        commented_by {
          user_profile {
            name
            profile_pic
          }
          staff_profile {
            name
            profile_pic
          }
        }
        replies_count
        child_comments {
          id
          content
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const query_get_all_related_fact_check_list = gql`
  query get_all_fact_check(
    $page_number: Int
    $page_limit: Int
    $json: get_all_fact_check_input
  ) {
    get_related_fact_check_list: get_all_fact_check(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        status
        is_fact
        reading_time
        likes
        views
        comments_count
        created_date_time
        thumbnail_img
        perma_link
        title
        subtitle
        staff_profile {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const query_get_all_recent_fact_check_list = gql`
  query get_recent_fact_checks(
    $language_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_recent_fact_check_list: get_recent_fact_checks(
      language_id: $language_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        language_id
        staff_profile_id
        user_profile_id
        title
        subtitle
        thumbnail_img
        reading_time
        created_date_time
        perma_link
        staff_profile {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;

export const mutation_add_fact_check_to_saved_items = gql`
  mutation add_fact_check_to_saved_items($fact_check_id: String!) {
    add_fact_check_to_saved_items(fact_check_id: $fact_check_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_remove_fact_check_from_saved_items = gql`
  mutation remove_fact_check_from_saved_items($fact_check_id: String!) {
    remove_fact_check_from_saved_items(fact_check_id: $fact_check_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_fact_check_saved = gql`
  query get_fact_check_by_perma_link(
    $perma_link: String!
    $language_id: String!
  ) {
    get_fact_check_perma_link_saved: get_fact_check_by_perma_link(
      perma_link: $perma_link
      language_id: $language_id
    ) {
      id
      is_saved
    }
  }
`;

export const mutation_fact_check_report = gql`
  mutation create_report($json: report_input) {
    create_fact_check_report: create_report(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_fact_check_preview = gql`
  query get_fact_check($id: String!) {
    get_fact_check(id: $id) {
      id
      language_id
      title
      subtitle
      content
      content_json
      spread_news
      spread_news_json
      status
      is_fact
      reading_time
      views
      thumbnail_img
      created_date_time
      published_date_time
      perma_link
      short_story
      short_story_json
      source
      source_json
      category_tags {
        id
        language_id
        name
      }
      party_tags {
        id
        name
      }
      anti_party_tags {
        id
        name
      }
      staff_profile {
        id
        name
        mobile
        profile_pic
        system_user {
          roles {
            name
          }
        }
      }
      user_profile {
      id
        name
        mobile
        profile_pic
      }
      error {
        status_code
        message
      }
    }
  }
`;
