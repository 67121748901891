import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import cheerio from "cheerio";
import { useDarkMode } from "../../contexts/dark_mode_provider";
import TweetIframe from "../../single_fact_check/tweet_iframe";

const PreviewSingleFactCheckSpreadNews = () => {
  const { t } = useTranslation();
  const [parsed_html, set_parsed_html] = useState("");
  const { is_dark_mode } = useDarkMode();
  const { spread_news, language_id } = useDynamicSelector("get_fact_check");

  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "TweetIframe" || name === "tweetiframe") {
        return <TweetIframe {...attribs}>{children}</TweetIframe>;
      }
      return null;
    },
  };

  useEffect(() => {
    if (spread_news) {
      const $ = cheerio.load(
        spread_news
          .replace(/\[/g, "<")
          .replace(/\]/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );
      const set_iframe_timeout = (iframe) => {
        const wrapper = $(iframe).parent();
        const spinner = wrapper.find(".loading-spinner");
        setTimeout(() => {
          spinner.css("display", "none");
        }, 5000);
      };

      $("blockquote.instagram-media").each((index, element) => {
        const blockquote = $(element);
        const permalink = blockquote.attr("data-instgrm-permalink");
        if (permalink) {
          const instagramUrl = permalink.split("?")[0];
          const iframe = `<iframe src="${instagramUrl}embed" width="500" height="800" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("blockquote").each((index, element) => {
        const blockquote = $(element);
        const tweetUrl = blockquote
          .find('a[href^="https://twitter.com/"]')
          .last()
          .attr("href");

        if (tweetUrl) {
          const iframe = `<TweetIframe tweeturl=${tweetUrl}>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("video").each((index, element) => {
        const video_element = $(element);
        const video_src = video_element.attr("mp4");
        const video_width = video_element.attr("width");
        const video_height = video_element.attr("height");
        // Wrap the video element with a video wrapper
        const video_frame = `
              <div class="video-wrapper">
                <video width="100%" height="auto" controls>
                  <source src="${video_src}" type="video/mp4"/>
                </video>
              </div>`;
        video_element.replaceWith(video_frame);
      });

      $("div[data-lexical-tweet-id]").each((index, element) => {
        const twitter_post = $(element);
        const tweetId = $(twitter_post).attr("data-lexical-tweet-id");
        if (tweetId) {
          const tweetUrl = `https://twitter.com/twitter/status/${tweetId}`;

          if (tweetUrl) {
            const embedHtml = `<TweetIframe tweeturl=${tweetUrl}>`;
            $(element).replaceWith(embedHtml);
          }
        }
      });
      $("div[data-lexical-instagram-id]").each((index, element) => {
        const blockquote = $(element);
        const permalink = blockquote.attr("data-lexical-instagram-id");
        if (permalink) {
          const instagramUrl = `https://www.instagram.com/p/${permalink}`;
          const iframe = `<iframe src="${instagramUrl}/embed" width="500" height="500" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });
      $("iframe[data-lexical-video-url]").each((index, element) => {
        const video_element = $(element);
        const video_src = video_element.attr("data-lexical-video-url");
        const video_frame = `
              <div class="video-wrapper">
                <video  class="video-content" controls>
                  <source src="${video_src}" type="video/mp4"/>
                </video>
              </div>`;
        video_element.replaceWith(video_frame);
      });
      $("iframe[data-lexical-iframe-id]").each((index, element) => {
        const iframe_element = $(element);
        const iframe_src = iframe_element.attr("data-lexical-iframe-id");
        console.log("iframe_src", iframe_src, iframe_element);

        const iframe_frame = `
              <div class="iframe-loading">
          <div class="iframe-wrapper">
            <iframe src="${iframe_src}" width="100%" height="600" frameborder="0" allowfullscreen onload="this.parentElement.querySelector('.loading-spinner').style.display = 'none';"></iframe>
          </div>
        </div>`;
        iframe_element.replaceWith(iframe_frame);
        set_iframe_timeout(iframe_frame);
      });

      if (is_dark_mode) {
        $("body").addClass("content-dark fact-check-content");
      } else {
        $("body").addClass("fact-check-content");
      }

      set_parsed_html($.html());
    }
  }, [spread_news]);

  return (
    <div className="single_fact_check_spread_news_container">
      <div className="single_fact_details_title">
        <h5>
          {language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
            ? "பரவிய செய்தி"
            : "Claim"}
        </h5>
      </div>
      {parsed_html ? ReactHtmlParser(parsed_html, options) : ""}
    </div>
  );
};
export default PreviewSingleFactCheckSpreadNews;
