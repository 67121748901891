import React from 'react'
import CategoryList from '../../components/admin/categorys/category_list'
import { HStack, VStack } from 'native-base'
import PageTitle from '../../components/system/page_title/page_title'

const Category = () => {
    return (
        <div>
            <HStack justifyContent={"flex-start"}>
                <PageTitle title={"Category"} />
            </HStack>
            <VStack padding={"40px"}>
                <CategoryList />
            </VStack>
        </div>
    )
}

export default Category
