import { Button, Form, Image, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SEARCH_IMAGE } from "../../../../helpers/image_constants";

const SearchResultsSearchBox = ({
  set_search_value,
  search_text,
  search_value,
}) => {
  const [form] = Form.useForm();
  const [initial_values, set_initial_values] = useState({});

  const on_finish = (value) => {
    set_search_value(value?.search_text);
  };

  useEffect(() => {
    if (search_text) {
      const search_value = search_text || "";
      form.setFieldsValue({
        search_text: search_value,
      });
      set_initial_values({
        search_text: search_value,
      });
    }
  }, [search_text]);

  return (
    <div style={{ width: "100%" }}>
      <Form
        name="search"
        form={form}
        onFinish={on_finish}
        initialValues={initial_values}
        style={{ width: "100%", height: "100%" }}
      >
        <Row
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Form.Item name="search_text" style={{ width: "74%" }}>
            <Input
              style={{
                height: "40px",
                borderRadius: "7px",
                backgroundColor: "#ffffff",
                borderColor: "#0065CC",
                color: "#140f2d",
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              height: "40px",
              borderRadius: "7px",
              width: "160px",
              border: "none",
              backgroundColor: "#00acee",
            }}
          >
            Search
            <Image
              className="mobile_drawer_moon"
              style={{
                width: "22px",
                height: "22px",
                cursor: "pointer",
                marginLeft: "5px",
              }}
              visible={false}
              preview={false}
              src={SEARCH_IMAGE}
            />
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default SearchResultsSearchBox;
