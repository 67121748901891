import React, { useEffect, useRef, useState } from "react";
import { Row } from "antd";
import JoinUsCard from "../components/widgets/join_us_card";
import { dynamic_request } from "../../services/redux/slices/dynamic_request";
import {
  get_first_article_query,
  recommended_stories_query,
  trending_article_query,
} from "../../services/redux/slices/graphql";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../services/redux";
import DonationCardSmall from "../components/widgets/donation_card_small";
import { debounce } from "lodash";
import TrendingStoriesList from "../components/party_wise/party_wise_content";
import RecommendedStories from "../components/article/recommended_stories";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { retrieveItem } from "../../helpers/functions";
import SocialMediaList from "../components/widgets/social_media_list";
import TopFirstFeaturedArticle from "../components/article/article/top_first_featured_article";
import ArticleCategoryWiseList from "../components/article/article/article_category_wise_list";

const Articles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();
  let current_language = retrieveItem("language");

  const { items: trending_stories, loading: trending_stories_loading } =
    useDynamicSelector("get_trending_articles");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    get_first_article_from_article_list();
    get_trending_article_list();
    get_recommended_stories_list();
    debounced_handle_title_click();
    handle_scroll();
  }, []);

  const get_first_article_from_article_list = () => {
    let key = [{ key: "get_top_first_article", loading: true }];
    let query = get_first_article_query;
    let variables = {
      page_number: 1,
      page_limit: 1,
      json: {
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_trending_article_list = () => {
    let key = [{ key: "get_trending_articles", loading: true }];
    let query = trending_article_query;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_recommended_stories_list = () => {
    let key = [{ key: "get_all_recommended_story", loading: true }];
    let query = recommended_stories_query;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 200);

  const handle_scroll = (val, values) => {
    return <a href="#donation-card"></a>;
  };

  return (
    <div
      style={{ overflowX: "hidden" }}
      ref={top_ref}
      className={` ${is_dark_mode ? "content-dark" : ""}`}
    >
      <TopFirstFeaturedArticle />
      <Row className="article_donation_card_small">
        <DonationCardSmall />
      </Row>
      <ArticleCategoryWiseList />
      <div className="article_page_stay_connected">
        <SocialMediaList />
      </div>
      <TrendingStoriesList
        list={trending_stories}
        name={t("trending_stories")}
        type="article"
      />
      <Row className="article_recommended_stories_container">
        <RecommendedStories height={"95vh"} />
      </Row>
      <div className="article_join_us_card">
        <JoinUsCard />
      </div>
    </div>
  );
};

export default Articles;
