import React from "react";
import CustomFactCheckCategories from "../../custom/custom_fact_check_categories";
import moment from "moment";
import { Divider, Empty, Skeleton } from "antd";
import CustomButton from "../../custom/custom_button";
import { useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";

const SixCategoriesCard = (props) => {
  const { filtered_item, on_click, category_name } = props;
  const { t } = useTranslation();
  const {
    items,
    loading: fact_check_list_loading,
    pagination: fact_check_list_pagination,
  } = useDynamicSelector("get_all_fact_check");
  return (
    <div className="six-categories-card-second-div">
      {fact_check_list_loading &&
        Array.from({ length: 6 }, (_, index) => (
          <Skeleton.Input
            key={index}
            className="six-categories-card-second-div-for-skeleton"
            active
          />
        ))}
      {filtered_item?.length > 0
        ? filtered_item?.map((items) => (
            <CustomFactCheckCategories
              key={items.id}
              category_image={items?.thumbnail_img}
              title={
                items?.title?.length > 40
                  ? `${items?.title?.slice(0, 60)}`
                  : items?.title
              }
              sub_title={
                items?.subtitle?.length > 40
                  ? `${items?.subtitle?.slice(0, 50)}...`
                  : items?.subtitle
              }
              // created_date={
              //   items
              //     ? moment(items?.created_date_time).format(`MMM DD, YYYY`)
              //     : ""
              // }
              created_date={
                items
                  ? moment(
                      items?.published_date_time
                        ? parseInt(items?.published_date_time, 10)
                        : items?.created_date_time
                    ).format(`MMM DD, YYYY`)
                  : ""
              }
              loading={false}
              perma_link={items?.perma_link ? items?.perma_link : ""}
              type={"fact-check"}
              category_name={category_name}
              name={items ? items?.staff_profile?.name : ""}
            />
          ))
        : !fact_check_list_loading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Empty description="No data" />
            </div>
          )}
      {fact_check_list_loading &&
        Array.from({ length: 6 }, (_, index) => (
          <Skeleton.Input
            key={index}
            className="six-categories-card-second-div-for-skeleton"
            active
          />
        ))}

      <div className="see_all_button">
        <Divider className="categories_see_all_button_divider">
          {items?.length === fact_check_list_pagination?.total_count ||
          (items?.length < 6 && !fact_check_list_loading) ? (
            ""
          ) : (
            <div className="divider_see_all_button">
              <CustomButton
                class_name={"see_all_button_categories"}
                name={t("see_more")}
                on_Click={on_click}
              />
            </div>
          )}
        </Divider>
      </div>
    </div>
  );
};

export default SixCategoriesCard;
