import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  dynamic_request,
  get_all_fact_check_query,
  query_get_all_category,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Carousel from "react-multi-carousel";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { style } from "styled-system";
// import { RightOutlined } from "@ant-design/icons";

const PartyWiseFactCheckCategories = (props) => {
  const { set_category_id, set_category_name } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const carouselRef = useRef();

  const { items: category_list } = useDynamicSelector("get_all_category_tag");
  const [categories_more, set_categories_more] = useState([]);
  const [categories, set_categories] = useState([]);
  const [categories_five, set_categories_five] = useState([]);

  // ... (other variable declarations)

  const [category_key, set_category_key] = useState("All");

  // let a = categories.unshift("All");
  useEffect(() => {
    if (category_list) {
      set_categories([
        { id: "All", name: "All" },
        ...category_list,
        { id: "more", name: "More" },
      ]);
      set_categories_five([
        { id: "All", name: "All" },
        ...category_list,
        { id: "More", name: "" },
      ]);
    }
  }, [category_list]);

  const handleClick = (category, category_name) => {
    if (category !== "All" || category !== "More") {
      set_category_id(category);
    } else {
      set_category_key(null);
    }
    set_category_key(category_name);

    set_category_name(category_name);
    if (category === "More") {
      set_categories_more([...category_list?.slice()]);
    }
  };
  const carousel_style = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    // autoplay: true,
  };

  const hover_style = {
    cursor: "pointer",
    transition: "all 0.3s",
  };

  const normal_style = {
    fontWeight: "normal",
    color: "black",
  };

  const hover_effect = (e) => {
    e.target.style.fontWeight = "bold";
    e.target.style.color = "#007BFF";
  };

  const normal_effect = (e) => {
    e.target.style.fontWeight = normal_style.fontWeight;
    e.target.style.color = normal_style.color;
  };

  // const on_change = (value) => {
  // };

  const get_category_list = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category;
    dispatch(dynamic_request(key, query));
  };

  const handle_change = (e) => {
    const handle_change_name = e.target.value;
  };
  const handleClickAll = () => {
    set_category_id(null);
    set_category_name(null);
    setAllClicked(true);
  };
  useEffect(() => {
    get_category_list();
    // set_category_key("All");
  }, []);

  const handle_next = () => {
    carouselRef.current.next();
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 4,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 4,
      slidesToSlide: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      customLeftArrow={
        <>
          <MdOutlineKeyboardArrowLeft style={{}} />
        </>
      }
      infinite={true}
      className="carousel-category-list"
    >
      {categories_five.map((item, index) => (
        <span
          key={index}
          style={{ ...hover_style, ...normal_style }}
          onClick={() => handleClick(item?.id, item?.name)}
          onMouseEnter={hover_effect}
          onMouseLeave={normal_effect}
          onChange={() => handle_change()}
          className={
            category_key === item?.name
              ? "category_selected_key"
              : "un_selected_key"
          }
        >
          {item.name}
        </span>
      ))}
    </Carousel>
  );
};

export default PartyWiseFactCheckCategories;
