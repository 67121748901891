import { Button } from "antd";
import React from "react";

const SaveAndEditButton = (props) => {
  const { open_form, button_type } = props;
  return (
    <div className="profile_edit_button">
      <Button
        className="edit_user_profile_button"
        htmlType="submit"
        style={{
          width: "8vw",
        }}
      >
        {open_form == true ? button_type : "Edit"}
      </Button>
    </div>
  );
};

export default SaveAndEditButton;
