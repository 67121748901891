import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { query_get_my_all_claims } from "../../../../services/redux/slices/graphql/graphql_user_profile";
import { useDispatch } from "react-redux";
import moment from "moment";
import { HStack } from "native-base";
import { countFormat } from "../../../../helpers/constants";
import { EyeOutlined } from "@ant-design/icons";

const AdminUserProfileClaim = ({ user_id, type }) => {
  const dispatch = useDispatch();
  const { items: my_claims, pagination: my_claim_pagination } =
    useDynamicSelector("get_all_my_user_claims");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const get_all_my_claims = () => {
    let key = [{ key: "get_all_my_user_claims", loading: true }];
    let query = query_get_my_all_claims;
    let variable = {
      json: {
        user_profile_id: user_id,
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamic_request(key, query, variable));
  };

  useEffect(() => {
    if (type === "claim") {
      get_all_my_claims();
    }
  }, [type, page_number, page_limit]);

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  const handle_redirect = (url) => {
    window.open(url);
  };

  const columns = [
    {
      title: "S.No",
      key: "s_no",
      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: "Description",
      key: "topic",
      width: 170,
      render: (record) => {
        return record?.description || "-";
      },
    },
    {
      title: "Attachments",
      key: "attachment",
      align: "center",
      width: 100,
      render: (record) =>
        record?.attachment ? (
          <EyeOutlined
            style={{ cursor: "pointer", color: "#00adee" }}
            onClick={() => window.open(record?.attachment, "_blank")}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "URL",
      key: "ref_url",
      width: 300,
      render: (text, record) =>
        record?.ref_url ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handle_redirect(record?.ref_url)}
            style={{ color: "#62cbf3", textDecoration: "underline" }}
          >
            Visit link
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: "Date",
      dataIndex: "created_date_time",
      key: "created_date_time",

      render: (text, record) => moment(text).format("MMM DD, YYYY"),
    },
  ];

  return (
    <div>
      <div style={{ maxHeight: 300, overflowY: "auto" }}>
        <Table
          columns={columns}
          dataSource={my_claims}
          size="small"
          scroll={{ y: 170 }}
          pagination={false}
        />
        <HStack justifyContent={"flex-end"} mt={"20px"}>
          <Pagination
            total={my_claim_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </HStack>
      </div>
    </div>
  );
};

export default AdminUserProfileClaim;
