import {
  Button,
  DatePicker,
  TimePicker,
  Form,
  Select,
  Spin,
  Switch,
  Menu,
  Dropdown,
  Modal,
  Col,
  Tag,
  Row,
  Input,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { useTranslation } from "react-i18next";
import { Box, HStack, VStack } from "native-base";
import PageTitle from "../../system/page_title/page_title";
import { useLocation } from "react-router-dom";
import {
  get_tag,
  navigate,
  navigateWithProps,
  retrieveItem,
  showToast,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";

import {
  article_process_query,
  dynamicSet,
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  mutation_create_article,
  mutation_update_article,
  mutation_update_article_in_auto_save,
  query_get_all_category,
  save_article_query,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Content from "./admin_article_form/content";
import { useParams } from "react-router-dom";
import { query_get_article_preview } from "../../../../services/redux/slices/graphql/article_preview_graphql";
import PartyTag from "./admin_article_form/party_tags";
import moment from "moment";
import { check_perma_link_availability_query } from "../../../../services/redux/slices/graphql/graphql_perma_link";
import Category from "./admin_article_form/categories";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";
dayjs.extend(utc);
dayjs.extend(timezone);

const AdminArticleDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const data = useDynamicSelector("get_article");
  const { loading: article_loading } = useDynamicSelector("get_article");

  let current_language_id = data?.language_id;

  const new_article_type = location?.props_state?.article_type;
  const back_button_default_language =
    location?.props_state?.default_language?.children;
  const current_language_id_select =
    location?.props_state?.default_language?.value;
  const role = retrieveItem("role");
  const current_time = dayjs(new Date()).format("HH:mm:ssZ");

  const [language_id, set_language_id] = useState(current_language_id);
  const [action_type, set_action_type] = useState("");
  const [process_type, set_process_type] = useState("");
  const [publish_date, set_publish_date] = useState(null);
  const [publish_time, set_publish_time] = useState(null);
  const [content, set_content] = useState({ html: null, json: null });
  const [selected_item, set_selected_item] = useState({});
  const [form_loading, set_form_loading] = useState(false);
  const [modal_type, set_modal_type] = useState("");
  const [handle_notification_change, set_handle_notification_change] =
    useState(false);
  const [handle_change_slot, set_handle_change_slot] = useState(false);

  const [publish_date_change, set_publish_date_change] = useState({
    date: false,
    time: false,
  });
  const [modal_open, set_modal_open] = useState(false);
  const [modal_values, set_modal_values] = useState({
    published_date: null,
    published_time: null,
    notify_post: false,
    set_in_slot: false,
  });
  const [social_media, set_social_media] = useState(false);
  const [hashtag_input, set_hashtag_input] = useState("");
  const [hashtags, set_hashtags] = useState([]);
  const [new_tags, set_new_tags] = useState([]);
  console.log("hashtags", hashtags);

  const article_title = Form.useWatch("title", form);
  const article_sub_title = Form.useWatch("subtitle", form);
  const article_language_id = Form.useWatch("language_id", form);
  const article_perma_link = Form.useWatch("perma_link", form);

  const {
    status: create_article_status,
    error: create_article_error,
    loading: create_loading,
  } = useDynamicSelector("create_article");

  const {
    status: update_article_status,
    error: update_article_error,
    loading: update_loading,
  } = useDynamicSelector("update_article");
  const { is_initial_save } = useDynamicSelector("is_initial_save");
  const {
    status: process_status,
    error: process_error,
    loading: process_loading,
  } = useDynamicSelector("process_article");

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  const { items: categories } = useDynamicSelector("get_all_category_tag");
  const {
    id: save_article_id,
    status: save_article_status,
    loading: save_article_loading,
    error: save_article_error,
  } = useDynamicSelector("save_article");

  const { is_available, error: perma_link_error } = useDynamicSelector(
    "check_perma_link_availability"
  );

  const menu_items = [
    {
      key: "publish_now",
      label: "Publish Now",
    },

    {
      key: "schedule_publish",
      label: "Schedule Publish",
    },
  ];

  useEffect(() => {
    let timer;
    if (article_loading) {
      set_form_loading(true);
      timer = setTimeout(() => {
        set_form_loading(false);
      }, 3000);
    } else {
      set_form_loading(false);
    }
    return () => clearTimeout(timer);
  }, [article_loading]);

  useEffect(() => {
    set_language_id(language_id || current_language_id);
  }, [language_id, current_language_id]);

  useEffect(() => {
    if (article_perma_link) {
      const specialCharPattern = /[\/?!.,@#$%^&*()+_{}|~`]/;
      if (
        _.some(article_perma_link?.split(""), (char) =>
          specialCharPattern.test(char)
        )
      ) {
        dispatch(dynamic_clear("check_perma_link_availability"));
      } else {
        dispatch(dynamic_clear("check_perma_link_availability"));
        perma_link_availability_checking(article_perma_link);
      }
    }
  }, [article_perma_link]);

  useEffect(() => {
    if (is_available === false) {
      form.setFields([
        {
          name: "perma_link",
          errors: ["Perma link already exists"],
        },
      ]);
    } else if (is_available === true) {
      form.setFields([
        {
          name: "perma_link",
          errors: [],
        },
      ]);
    }
  }, [is_available]);

  useEffect(() => {
    if (is_initial_save) {
      const specialCharPattern = /[\/?!.,@#$%^&*()+_{}|~`]/;
      let check_is_special_char = _.some(
        article_perma_link?.split(""),
        (char) => specialCharPattern?.test(char)
      );
      if (!check_is_special_char && data?.status !== "published") {
        const interval_id = setInterval(() => {
          let auto_update_values = form.getFieldsValue();
          edit_article_in_auto_save(auto_update_values);
        }, 30000);

        return () => clearInterval(interval_id);
      }
    }
  }, [
    is_initial_save,
    form,
    content,
    selected_item,
    publish_time,
    publish_date,
  ]);

  useEffect(() => {
    get_all_categories();
    get_all_languages();
  }, [current_language_id]);

  useEffect(() => {
    if (id) {
      dispatch(dynamic_clear("save_article"));
      dispatch(dynamic_clear("process_article"));
      dispatch(dynamic_clear("update_article"));
      dispatch(dynamic_clear("create_article"));
      get_one_article();
      dispatch(dynamicSet("is_initial_save", { is_initial_save: true }));
    } else {
      dispatch(dynamic_clear("get_article"));
      dispatch(dynamic_clear("is_initial_save"));
    }
  }, [id]);

  useEffect(() => {
    if (id && data?.id) {
      let formatted_date = null;
      let formatted_time = null;
      if (data?.status === "draft") {
        formatted_date = null;
        formatted_time = null;
      } else if (data?.published_date_time) {
        const moment_object = dayjs(data?.published_date_time).tz(
          "Asia/Kolkata"
        );
        formatted_date = moment_object
          .subtract(5, "hour")
          .subtract(30, "minute")
          .format("YYYY-MM-DD");
        formatted_time = moment_object;
      } else {
        (formatted_date = dayjs(new Date())),
          (formatted_time = dayjs(current_time, "HH:mm:ssZ"));
      }
      form.setFieldsValue({
        ...data,
        category_tags: data?.category_tags?.map((x) => {
          return x.id;
        }),
        party_tags: data?.party_tags?.map((x) => {
          return x.id;
        }),
        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ss")
          : null,
        content: data?.content,
        thumbnail_img: data?.thumbnail_img
          ? [
              {
                url: data?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });
      set_publish_date(
        formatted_date
          ? moment(new Date(formatted_date)).format("YYYY-MM-DD")
          : null
      );
      set_publish_time(
        formatted_time
          ? moment(new Date(formatted_time)).format("HH:mm:ssZ")
          : null
      );
      set_selected_item({
        ...data,
        category_tags: data?.category_tags?.map((x) => {
          return x.id;
        }),
        party_tags: data?.party_tags?.map((x) => {
          return x.id;
        }),

        published_date: formatted_date
          ? dayjs(formatted_date, "YYYY-MM-DD")
          : null,
        published_time: formatted_time
          ? dayjs(formatted_time, "HH:mm:ss")
          : null,

        content: {
          html: data?.content || "",
          json: data?.content_json || "",
        },
        thumbnail_img: data?.thumbnail_img
          ? [
              {
                url: data?.thumbnail_img,
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
      });

      if (data?.content || data?.content_json) {
        set_content({
          html: data?.content || null,
          json: data?.content_json || null,
        });
      }
    }
  }, [id, data]);

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        published_date: dayjs(new Date()),
        published_time: dayjs(current_time, "HH:mm:ssZ"),
      });
      set_publish_date(dayjs(new Date()).format("YYYY-MM-DD"));
      set_publish_time(current_time);
    }
  }, [id]);

  useEffect(() => {
    set_process_type(null);
  }, []);

  useEffect(() => {
    if (create_article_status === "Success") {
      if (role === "Admin") {
        showToast({
          type: "success",
          message:
            modal_type === "schedule"
              ? "Article Scheduled Successfully"
              : "Article Published Successfully",
        });
      } else {
        showToast({ type: "success", message: "Article Created Successfully" });
      }
      dispatch(dynamic_clear("create_article"));
      navigate(`${ROUTES.ADMIN_ARTICLE}/${id}`);
    } else if (
      create_article_status === "Failure" &&
      create_article_error?.message
    ) {
      const formattedMessage = create_article_error?.message
        ? create_article_error.message
            .replace(/perma_link/i, "Perma link")
            .replace("exist", "exists")
        : "An error occurred";
      showToast({ type: "error", message: formattedMessage });
      dispatch(dynamic_clear("create_article"));
    }
  }, [create_article_status, create_article_error, role, modal_type]);
  console.log("update_article_status", update_article_status, process_type);

  useEffect(() => {
    if (update_article_status === "Success" && process_type) {
      showToast({ type: "success", message: "Article updated successfully" });
      dispatch(dynamic_clear("update_article"));
    } else if (
      update_article_status === "Failure" &&
      update_article_error?.message
    ) {
      const formattedMessage = update_article_error?.message
        ? update_article_error.message
            .replace(/perma_link/i, "Perma link")
            .replace("exist", "exists")
        : "An error occurred";
      showToast({ type: "error", message: formattedMessage });
      dispatch(dynamic_clear("update_article"));
    }
  }, [update_article_status, update_article_error, id]);
  console.log("process_status", process_status, process_type);

  useEffect(() => {
    if (process_status === "Success" && process_type) {
      if (process_type === "approve") {
        toast.success("Article Approved Successfully");
      } else if (process_type === "reject") {
        toast.success("Article Rejected Successfully");
      } else if (process_type === "submit") {
        if (role === "Admin") {
          if (modal_type === "schedule") {
            toast.success("Article Scheduled Successfully");
          } else {
            toast.success("Article Published Successfully");
          }
        } else {
          toast.success("Article Submitted Successfully");
        }
      } else if (process_type === "published") {
        if (modal_type === "schedule") {
          toast.success("Article Scheduled Successfully");
        } else {
          toast.success("Article Published Successfully");
        }
      } else if (process_type === "unpublished") {
        dispatch(dynamic_clear("update_article"));
        toast.success("Article Unpublished Successfully");
      }
      dispatch(dynamic_clear("process_article"));
      dispatch(dynamic_clear("update_article"));
      dispatch(dynamic_clear("save_article"));
      dispatch(dynamic_clear("create_article"));
      navigateWithProps(`${ROUTES.ADMIN_ARTICLE}`, {
        new_article_type,
        back_button_default_language,
        current_language_id_select,
        back_button_state: true,
      });
    } else if (process_status === "Failure") {
      toast.error("Couldn't approve the article");
      navigate(ROUTES.ADMIN_ARTICLE);
      dispatch(dynamic_clear("process_article"));
    } else if (process_error) {
      toast.error(process_error?.message);
      dispatch(dynamic_clear("process_article"));
    }
  }, [process_status, process_error, process_type, role, modal_type]);

  useEffect(() => {
    if (save_article_status === "Success") {
      const id = save_article_id;
      set_modal_type(false);
      set_modal_open(false);
      dispatch(dynamicSet("is_initial_save", { is_initial_save: true }));
      dispatch(dynamic_clear("save_article"));
      toast.success("Article Saved Successfully");
      navigate(`${ROUTES.ADMIN_ARTICLE_DETAILS}/${id}`);
    } else if (save_article_error) {
      dispatch(dynamic_clear("save_article"));
    }
  }, [save_article_status, save_article_error]);

  const create_articles = (json) => {
    let key = [{ key: "create_article", loading: true }];
    let query = mutation_create_article;
    let all_data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      perma_link: json?.perma_link,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };
    let variables = {
      json: all_data,
    };
    if (role === "Admin") {
      all_data.published_date_time = `${
        !publish_date_change.date
          ? moment(new Date()).format("YYYY-MM-DD")
          : publish_date
      }T${
        !publish_date_change.time
          ? moment(new Date()).format("HH:mm:ssZ")
          : publish_time
      }`;
      all_data.notify_post = handle_notification_change;
      all_data.set_in_slot = handle_change_slot;
      all_data.post_in_social_media = social_media;
      all_data.hashtags = hashtags;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const edit_article_in_auto_save = (json) => {
    let key = [{ key: "update_article_in_auto_save", loading: true }];
    let query = mutation_update_article_in_auto_save;
    let all_data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      perma_link: json?.perma_link,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };
    let variables = {
      id: id,
      json: all_data,
    };
    if (
      role === "Admin" &&
      publish_date &&
      publish_time &&
      data?.status === "published"
    ) {
      variables.json.published_date_time = `${publish_date}T${publish_time}`;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const edit_article = (json) => {
    delete json.published_date;
    delete json.published_time;
    let key = [{ key: "update_article", loading: true }];
    let query = mutation_update_article;
    let items = {
      ...json,
      thumbnail_img: json?.thumbnail_img?.[0]?.url || undefined,
      content: content.html,
      content_json: content.json,
    };

    let variables = {
      id: data?.id,
      json: items,
    };
    if (role === "Admin" && data?.status === "published") {
      items.published_date_time = `${publish_date}T${publish_time}`;
      items.notify_post = handle_notification_change;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const process_article = (type) => {
    let key = [{ key: "process_article", loading: true }];
    let query = article_process_query;
    let variables = {
      id: id,
      type: type,
    };
    if (role === "Admin") {
      variables.published_date_time = `${
        !publish_date_change.date
          ? moment(new Date()).format("YYYY-MM-DD")
          : publish_date
      }T${
        !publish_date_change.time
          ? moment(new Date()).format("HH:mm:ssZ")
          : publish_time
      }`;
      variables.notify_post = handle_notification_change;
      variables.set_in_slot = handle_change_slot;
      variables.post_in_social_media = social_media;
      variables.hashtags = hashtags;
    }
    dispatch(dynamic_request(key, query, variables));
  };

  const perma_link_availability_checking = (perma_link) => {
    let key = [{ key: "check_perma_link_availability", loading: true }];
    let query = check_perma_link_availability_query;
    let variables = {
      id: data?.id || "",
      type: "article",
      language_id: language_id,
      perma_link: perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_categories = () => {
    let key = [{ key: "get_all_category_tag", loading: true }];
    let query = query_get_all_category;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const save_articles = () => {
    const json = form.getFieldsValue();
    let key = [{ key: "save_article", loading: true }];
    let query = save_article_query;
    let data = {
      title: json?.title,
      subtitle: json?.subtitle,
      language_id: json?.language_id,
      perma_link: json?.perma_link,
      category_tags: json?.category_tags,
      party_tags: json?.party_tags,
      thumbnail_img: json?.thumbnail_img?.[0]?.url,
      content: content.html,
      content_json: content.json,
    };
    delete data.party_tag_id;
    let variables = {
      json: data,
    };
    // if (role === "Admin") {
    //   data.published_date_time = `${publish_date}T${publish_time}`;
    // }
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const publish_article = (value) => {
    console.log(
      "publish_article",
      form.getFieldValue("thumbnail_img"),
      form.getFieldValue("thumbnail_img")?.length !== 0
    );

    if (
      form.getFieldValue("thumbnail_img") &&
      form.getFieldValue("thumbnail_img")?.length !== 0
    ) {
      if (value.key === "publish_now") {
        set_modal_type("");
        if (
          data?.status === "pending" ||
          data?.status === "unpublished" ||
          data?.status === "approved_by_editor"
        ) {
          set_action_type("published");
        } else if (data?.status === "draft") {
          set_action_type("submit");
        } else if (!id) {
          set_action_type("create");
        }
        set_modal_open(true);
      } else if (value.key === "schedule_publish") {
        set_modal_type("schedule");
        if (
          data?.status === "pending" ||
          data?.status === "unpublished" ||
          data?.status === "approved_by_editor"
        ) {
          set_action_type("published");
        } else if (data?.status === "draft") {
          set_action_type("submit");
        } else if (!id) {
          set_action_type("create");
        }
        set_modal_open(true);
      }
    } else {
      toast.error("Please upload thumbnail image");
    }
  };

  const handle_submit = (values) => {
    debugger;
    form
      .validateFields()
      .then((values) => {
        const recordId = data?.id;
        const isCreateAction = action_type === "create";
        const isUpdateAction = action_type === "update";
        const isPreviewAction = action_type === "preview";
        const isSubmit = action_type === "submit";
        const isPublish = action_type === "published";
        const specialCharPattern = /[\/?!.,@#$%^&*()+_{}|~`]/;
        let check_is_special_char = _.some(
          article_perma_link?.split(""),
          (char) => specialCharPattern.test(char)
        );
        if (!check_is_special_char)
          if (!recordId && isCreateAction) {
            create_articles(values);
          } else if (isUpdateAction) {
            edit_article(values);
          } else if (isPreviewAction) {
            edit_article_in_auto_save(values);
            preview_article();
          } else if (isSubmit) {
            // edit_article_in_auto_save(values);
            edit_article(values);
            process_article("submit");
          } else if (isPublish) {
            // edit_article_in_auto_save(values);
            edit_article(values);
            setTimeout(() => {
              process_article("published");
            }, 2000);
          }
        set_process_type(action_type);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const preview_article = () => {
    const url = `${ROUTES.ARTICLE_PREVIEW}/${id}`;
    const props = {
      article_id: id,
    };
    const queryString = new URLSearchParams(props).toString();
    const fullUrl = `${url}?${queryString}`;
    window.open(fullUrl, "_blank");
  };

  const handle_back = () => {
    navigateWithProps(`${ROUTES.ADMIN_ARTICLE}`, {
      new_article_type,
      back_button_default_language,
      current_language_id_select,
      back_button_state: true,
    });

    dispatch(dynamic_clear("get_article"));
  };

  const on_click = (type) => {
    form
      .validateFields()
      .then((validatedValues) => {
        const json = form.getFieldsValue();
        edit_article(json);
        set_process_type(type);
        process_article(type);
      })
      .catch((errorInfo) => {
        // Handle validation error here, if any field is missing or incorrect
        console.log("Validation Failed:", errorInfo);
      });
  };

  const on_change_language = (value) => {
    set_language_id(value);
  };

  const on_finish_failed = (errorInfo) => {
    const { errorFields } = errorInfo;
    if (errorFields.length > 0) {
      const fieldName = errorFields[0].name[0];
      form.scrollToField(fieldName, { behavior: "smooth", block: "center" });
    }
  };

  const handle_form_values_change = (values, all_values) => {};

  const get_one_article = () => {
    let key = [{ key: "get_article", loading: true }];
    let query = query_get_article_preview;
    let variables = {
      id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const on_change_date = (date, date_string) => {
    const date_format = date_string
      ? moment(date_string).format("YYYY-MM-DD")
      : null;
    set_publish_date(date_format);
    set_publish_date_change({ ...publish_date_change, date: true });
  };

  const on_change_time = (time, timeString) => {
    const time_format = time ? dayjs(time).format("HH:mm:ssZ") : null;
    set_publish_date_change({ ...publish_date_change, time: true });
    set_publish_time(time_format);
  };

  const handle_key_press = (e) => {
    const inputValue = e.target.value;
    const updatedValue = inputValue.replace(/\s+/g, "-");
    form.setFieldsValue({ perma_link: updatedValue });
  };

  // const handle_paste = (e) => {
  //   if (/[\/?!.,@#$%^&*()+_{}|~`]/.test(e.target.value)) {
  //     const input = e.target;
  //     const start = input.selectionStart;
  //     const end = input.selectionEnd;
  //     const value = input.value;
  //     input.value = value.substring(0, start) + "-" + value.substring(end);
  //     input.setSelectionRange(start + 1, start + 1);
  //     const event = new Event("input", { bubbles: true });
  //     input.dispatchEvent(event);
  //   }
  //   if (e.target.value === " ") {
  //     e.preventDefault();
  //     const input = e.target;
  //     const start = input.selectionStart;
  //     const end = input.selectionEnd;
  //     const value = input.value;
  //     input.value = value.substring(0, start) + "-" + value.substring(end);
  //     input.setSelectionRange(start + 1, start + 1);
  //     const event = new Event("input", { bubbles: true });
  //     input.dispatchEvent(event);
  //   }
  //   const withoutSpecialChars = _.replace(
  //     text,
  //     /[^a-zA-Z0-9\s\u0B80-\u0BFF]/g,
  //     ""
  //   );

  //   // Step 2: Replace multiple spaces with a single space
  //   const cleanedText = _.replace(withoutSpecialChars, /\s+/g, " ").trim();

  //   // Step 3: Replace spaces with hyphens
  //   const result = _.replace(cleanedText, / /g, "-");
  // };
  const handle_paste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const withoutSpecialChars = _.replace(
      pastedText,
      /[^a-zA-Z0-9\s\u0B80-\u0BFF]/g,
      ""
    );
    const cleanedText = _.replace(withoutSpecialChars, /\s+/g, " ").trim();
    const result = _.replace(cleanedText, / /g, "-");
    form.setFieldsValue({ perma_link: result });
  };
  useEffect(() => {
    if (
      article_title &&
      article_language_id &&
      !id &&
      data?.status !== "published"
    ) {
      const timer = setTimeout(() => {
        if (!id) save_articles();
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [article_title, article_language_id]);

  const on_click_save_article = () => {
    if (article_title && article_language_id) {
      save_articles();
    } else {
      toast.error("Title and Language should be required");
    }
  };
  const handle_modal_submit = () => {
    form
      .validateFields()
      .then((values) => {
        handle_submit(values);
        set_modal_open(false);
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  };

  const disabled_hours = () => {
    const selectedDate = form.getFieldValue("published_date");
    const currentHour = moment().hour();
    if (selectedDate && selectedDate.isSame(moment(), "day")) {
      return Array.from(Array(24).keys()).filter((hour) => hour < currentHour);
    }
    return [];
  };

  const disabled_minutes = (selectedHour) => {
    const selectedDate = form.getFieldValue("published_date");
    const currentHour = moment().hour();
    const currentMinute = moment().minute();
    if (
      selectedDate &&
      selectedDate.isSame(moment(), "day") &&
      selectedHour === currentHour
    ) {
      return Array.from(Array(60).keys()).filter(
        (minute) => minute < currentMinute
      );
    }
    return [];
  };
  const handle_input_enter = (e) => {
    e.preventDefault();
    const { target } = e;
    const trimmedInput = target.value.trim();
    if (trimmedInput) {
      if (hashtags.length < 30) {
        set_hashtag_input(trimmedInput);
        set_hashtags([...hashtags, trimmedInput]);
        form.setFieldsValue({ hashtags: "" });
      } else {
        message.error("You can add up to 30 hashtags only.");
      }
    }
  };

  const remove_hashtag = (indexToRemove) => {
    console.log("indexToRemove", indexToRemove);
    const updated_tags = hashtags.filter((_, index) => index !== indexToRemove);
    set_hashtags(updated_tags);
  };
  return (
    <div>
      <PageTitle title={!id ? "Add Article" : "Update Article"} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {data.id &&
            id &&
            (moment(data?.published_date_time).isAfter(moment())
              ? get_tag("scheduled")
              : get_tag(data?.status))}
          {data?.published_date_time &&
            data?.status !== "unpublished" &&
            id && (
              <span>
                {moment(data?.published_date_time).isAfter(moment())
                  ? "Scheduled on: "
                  : "Published on: "}
                {moment(data?.published_date_time).format("DD-MM-YYYY hh:mm A")}
              </span>
            )}
        </div>
      </div>
      <VStack padding={"30px"}>
        <Spin spinning={form_loading} tip="Loading...">
          <Form
            layout="vertical"
            form={form}
            onFinish={handle_submit}
            onFinishFailed={on_finish_failed}
            // onValuesChange={handle_form_values_change}
            initialValues={selected_item}
          >
            <div
              style={{
                marginBottom: "150px",
              }}
            >
              <VForm.TextBox
                label={t("title_with_length")}
                field={"title"}
                rules={[{ required: true, message: "Title is required " }]}
                max_length={150}
              />
              <VForm.TextBox
                label={t("sub_title")}
                field={"subtitle"}
                rules={[{ required: true, message: "Subtitle is required " }]}
              />
              <HStack space={"10px"}>
                <Box flex={1}>
                  <Form.Item
                    name="language_id"
                    label={"Language"}
                    rules={[
                      { required: true, message: "Language is required " },
                    ]}
                  >
                    <Select onChange={on_change_language}>
                      {system_languages?.map((option, index) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Box>

                {role === "Admin" && (
                  <Modal
                    title={
                      modal_type === "schedule"
                        ? "Are you sure you want to schedule this Article ?"
                        : "Are you sure you want to publish this Article ?"
                    }
                    open={modal_open}
                    onCancel={() => set_modal_open(false)}
                    footer={[
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handle_modal_submit}
                      >
                        {modal_type === "schedule" ? "Set Schedule" : "Publish"}
                      </Button>,
                      <Button
                        key="cancel"
                        onClick={() => set_modal_open(false)}
                      >
                        Cancel
                      </Button>,
                    ]}
                  >
                    <Form layout="vertical" form={form}>
                      {modal_type === "schedule" && (
                        <>
                          <Form.Item
                            name="published_date"
                            label={"Publish By Date"}
                            rules={[
                              {
                                required: true,
                                message: "Please pick the Date ",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"YYYY-MM-DD"}
                              onChange={on_change_date}
                              disabledDate={(current) => {
                                return (
                                  current && current < moment().startOf("day")
                                );
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="published_time"
                            label={"Publish By Time"}
                            rules={[
                              {
                                required: true,
                                message: "Please pick the Time",
                              },
                            ]}
                          >
                            <TimePicker
                              use24Hours
                              format={"HH:mm"}
                              minuteStep={5}
                              disabledHours={disabled_hours}
                              disabledMinutes={disabled_minutes}
                              onChange={on_change_time}
                            />
                          </Form.Item>
                        </>
                      )}
                      {modal_type != "schedule" && (
                        <Col>
                          <Row style={{ gap: "60px" }}>
                            <Form.Item
                              name="notify_post"
                              label={"Send Notification"}
                              valuePropName="checked"
                            >
                              <Switch
                                onChange={(checked) => {
                                  set_handle_notification_change(checked);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Set in Home slot"
                              name="set_in_slot"
                              valuePropName="checked"
                            >
                              <Switch
                                onChange={(checked) =>
                                  set_handle_change_slot(checked)
                                }
                                checked={modal_values.set_in_slot}
                              />
                            </Form.Item>
                          </Row>
                          <Row style={{ gap: "60px" }}>
                            <Form.Item
                              label="Post in Social Media"
                              name="post_in_social_media"
                              valuePropName="checked"
                            >
                              <Switch
                                onChange={(checked) => {
                                  set_social_media(checked);
                                  set_hashtags([]); // Clear hashtags when the switch changes
                                }}
                              />
                            </Form.Item>
                            {social_media && (
                              <Form.Item
                                label="Type Hashtags and Press Enter"
                                name="hashtags"
                              >
                                <Input
                                  autoComplete="off"
                                  // value={hashtag_input}
                                  // onChange={handle_input_change}
                                  onPressEnter={handle_input_enter}
                                />
                              </Form.Item>
                            )}
                          </Row>
                          {social_media && hashtags.length > 0 && (
                            <Row
                              style={{
                                flexWrap: "wrap",
                                gap: "8px",
                                marginTop: "8px",
                                border: "1px solid #8c8c8c",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                            >
                              {hashtags?.map((tag, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      padding: "5px 10px",
                                      fontSize: "14px",
                                      border: "1px solid gray",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span>{tag}</span>
                                    <span
                                      onClick={() => remove_hashtag(index)}
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                );
                              })}
                            </Row>
                          )}
                        </Col>
                      )}
                    </Form>
                  </Modal>
                )}
              </HStack>

              <HStack space={"10px"}>
                <Box flex={1}>
                  <VForm.TextBox
                    label="Perma link"
                    field="perma_link"
                    onChange={handle_key_press}
                    onPaste={handle_paste}
                    rules={[
                      { required: true, message: "Perma link is required" },
                      {
                        // pattern: /^[^\/?!.,@#$%^&*()+_{}|~`]+$/,
                        pattern: /^[a-zA-Z0-9\u0B80-\u0BFF\s-]+$/,
                        message: "Please avoid special characters",
                      },
                    ]}
                  />
                </Box>
                <Box flex={1}>
                  <Category language_id={language_id} />
                </Box>
                <Box flex={1}>
                  <PartyTag language_id={language_id} />
                </Box>
              </HStack>
              <VForm.ImageFile
                label={"Thumbnail Image"}
                field={"thumbnail_img"}
                // rules={[
                //   {
                //     required: role === "Admin" ? true : false,
                //     message: "Please Upload the thumbnail image",
                //   },
                // ]}
              />
              <Content
                content={selected_item?.content || ""}
                set_content={set_content}
              />
            </div>
            <div
              style={{
                height: "100px",
                position: "fixed",
                display: "flex",
                bottom: "0px",
                right: "30px",
                backgroundColor: "#f0f2f5",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button onClick={handle_back} danger>
                {t("back")}
              </Button>

              {data?.id && (
                <Button
                  onClick={() => {
                    set_action_type("preview");
                    form.submit();
                  }}
                  style={{
                    backgroundColor: "#F39C12",
                    color: "white",
                  }}
                >
                  {t("Preview")}
                </Button>
              )}

              {!id && (
                <Button
                  onClick={on_click_save_article}
                  style={{
                    backgroundColor: "#cc972c",
                    color: "white",
                  }}
                  loading={save_article_loading}
                >
                  {t("Save")}
                </Button>
              )}
              {data?.id &&
                data?.status != "published" &&
                data?.status != "approved_by_editor" &&
                data?.status != "draft" &&
                role != "Admin" &&
                data?.user_profile?.id && (
                  <div>
                    <Button
                      danger
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginRight: "10px",
                      }}
                      onClick={() => on_click("reject")}
                      loading={process_type === "reject" && process_loading}
                    >
                      Reject
                    </Button>
                    <Button
                      style={{ backgroundColor: "green", color: "white" }}
                      onClick={() => on_click("approve")}
                      loading={process_type === "approve" && process_loading}
                    >
                      Approve
                    </Button>
                  </div>
                )}

              {data.id &&
                id &&
                !(role === "Editor" && data?.status === "published") && (
                  <Button
                    onClick={() => {
                      set_action_type("update");
                      form.submit();
                    }}
                    type="primary"
                    loading={action_type === "update" && update_loading}
                  >
                    {data?.status === "published" &&
                    moment(data?.published_date_time).isSameOrBefore(moment())
                      ? "Update & Republish"
                      : "Update "}
                  </Button>
                )}
              {!id &&
                (role === "Admin" ? (
                  <Dropdown
                    overlay={
                      <Menu
                        items={menu_items}
                        onClick={(e) => publish_article(e)}
                      />
                    }
                    trigger={["click"]}
                  >
                    <Button
                      className="custom-dropdown-menu"
                      style={{ width: "170px" }}
                      loading={create_loading}
                      // onClick={(e) => publish_article(e)}
                    >
                      Create & Publish
                    </Button>
                  </Dropdown>
                ) : (
                  <Button
                    onClick={() => {
                      set_action_type("create");
                      form.submit();
                    }}
                    loading={create_loading}
                    style={{ backgroundColor: "#1e90ff", color: "white" }}
                  >
                    {"Submit"}
                  </Button>
                ))}
              {!data?.id ||
                (data?.status === "draft" &&
                  (role === "Admin" ? (
                    <Dropdown
                      overlay={
                        <Menu
                          items={menu_items}
                          onClick={(e) => publish_article(e)}
                        />
                      }
                      trigger={["click"]}
                    >
                      <Button
                        loading={process_loading}
                        style={{ width: "110px" }}
                        className="custom-dropdown-menu"
                        // onClick={(e) => publish_article(e)} // Button's default click action
                      >
                        Publish
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button
                      onClick={() => {
                        set_action_type("submit");
                        form.submit();
                      }}
                      loading={process_loading}
                      style={{ backgroundColor: "#008000", color: "white" }}
                    >
                      {"Submit"}
                    </Button>
                  )))}
              {data?.id &&
                (data?.status === "pending" ||
                  data?.status === "unpublished" ||
                  data?.status === "approved_by_editor") &&
                role === "Admin" && (
                  <Dropdown
                    overlay={
                      <Menu
                        items={menu_items}
                        onClick={(e) => publish_article(e)}
                      />
                    }
                    trigger={["click"]} // Trigger dropdown on hover
                  >
                    <Button
                      className="custom-dropdown-menu"
                      style={{ width: "110px" }}
                      loading={process_loading}
                      // onClick={(e) => publish_article(e)}  // Handle the primary button click
                    >
                      Publish
                    </Button>
                  </Dropdown>
                )}
              {data?.id &&
                data?.status === "published" &&
                role === "Admin" &&
                moment(data?.published_date_time).isSameOrBefore(moment()) && (
                  <Button
                    onClick={() => on_click("unpublished")}
                    loading={process_loading}
                    style={{ backgroundColor: "#ff1a1a", color: "white" }}
                  >
                    {"Unpublish"}
                  </Button>
                )}
            </div>
          </Form>
        </Spin>
      </VStack>
    </div>
  );
};

export default AdminArticleDetails;
