import {
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Tag,
} from "antd";
import { HStack, Pressable, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  mutation_delete_user,
  get_all_user_query,
  give_privileged_access_query,
  get_back_privileged_access_query,
} from "../../../../services/redux/slices/graphql/graphql_user";
import { useDispatch } from "react-redux";
import UserDetails from "./user_details";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { showToast } from "../../../../helpers/functions";
import { decryptData } from "../../../../helpers/crypto";
import { MdKey } from "react-icons/md";
import { toast } from "react-toastify";
import { FaLock } from "react-icons/fa";
import { countFormat } from "../../../../helpers/constants";

const UserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mutation_modal, set_mutation_modal] = useState(false);
  const [mutation_type, set_mutation_type] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [mutation_form_values, set_mutation_form_values] = useState({});
  const [search_string, set_search_string] = useState("");
  const [user_type, set_user_type] = useState("User");
  const [confirmation_modal, set_confirmation_modal] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [user_id, set_user_id] = useState("");
  const [privileged_user_id, set_privileged_user_id] = useState("");
  const [page_limit, set_page_limit] = useState(10);
  const [search_value, set_search_value] = useState();
  const [page_number, set_page_number] = useState(1);

  const [form] = Form.useForm();
  const { Search } = Input;

  const {
    items: list,
    loading: list_loading,
    pagination,
  } = useDynamicSelector("get_users");

  const {
    loading: delete_user_loading,
    status: delete_user_status,
    error: delete_user_error,
  } = useDynamicSelector("delete_user");

  const {
    status: give_privileged_access_status,
    loading: give_privileged_access_loading,
    error: give_privileged_access_error,
  } = useDynamicSelector("give_previleged_access_to_user");

  const {
    status: get_back_privileged_access_status,
    loading: get_back_privileged_access_loading,
    error: get_back_privileged_access_error,
  } = useDynamicSelector("get_back_previleged_access_from_user");

  useEffect(() => {
    get_all_list();
  }, [user_type, page_limit, page_number, search_value]);

  useEffect(() => {
    if (mutation_form_values?.id) {
      form.setFieldsValue({
        name: mutation_form_values.user_profile?.name,
        email_id: mutation_form_values.user_profile?.email,
        mobile: mutation_form_values.user_profile?.mobile,
        username: mutation_form_values?.username,
        is_active: mutation_form_values.is_active,
      });
    } else {
      form.resetFields("");
    }
  }, [mutation_form_values]);

  useEffect(() => {
    if (give_privileged_access_status === "Success") {
      toast.success("Privileged Access Given to the user");
      get_all_list();
      handle_access_modal_close();
      dispatch(dynamic_clear("give_previleged_access_to_user"));
    } else if (give_privileged_access_error) {
      toast.error(give_privileged_access_error?.message);
    }
  }, [give_privileged_access_status, give_privileged_access_error]);

  useEffect(() => {
    if (get_back_privileged_access_status === "Success") {
      toast.success("Privileged access get back successfully");
      get_all_list();
      handle_gat_back_modal_close();
      dispatch(dynamic_clear("get_back_previleged_access_from_user"));
    } else if (get_back_privileged_access_error) {
      toast.error(get_back_privileged_access_error?.message);
      handle_gat_back_modal_close();
      dispatch(dynamic_clear("get_back_previleged_access_from_user"));
    }
  }, [get_back_privileged_access_status, get_back_privileged_access_error]);

  useEffect(() => {
    if (delete_user_status === "Success") {
      showToast({
        type: "success",
        message: "User deleted successfully",
      });
      handle_close();
      get_all_list();
      dispatch(dynamic_clear("delete_user"));
    } else if (delete_user_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamic_clear("delete_user"));
    }
  }, [delete_user_status, delete_user_error]);

  const action_items = (record) => {
    return (
      <HStack justifyContent={"space-between"} alignItems={"center"}>
        {/* {user_type === "User" && (
          <Pressable
            onPress={() => {
              give_privilege_access(record);
            }}
          >
            <MdKey size={20} />
          </Pressable>
        )}
        {user_type === "Previleged User" && (
          <Pressable
            onPress={() => {
              get_back_privilege_access(record);
            }}
          >
            <FaLock size={20} />
          </Pressable>
        )} */}

        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: t("table:name"),
      key: "name",
      render: (record) => {
        return record?.user_profile?.name || record?.staff_profile?.name || "-";
      },
    },
    {
      title: t("table:username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("table:mobile_number"),
      align: "center",
      key: "mobile",
      render: (record) => {
        return (
          record?.user_profile?.mobile || record?.staff_profile?.mobile || "-"
        );
      },
    },
    {
      title: t("table:email"),
      key: "email",
      render: (record) => {
        return (
          record?.user_profile?.email || record?.staff_profile?.email || "-"
        );
      },
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "action",
      width: "100px",
      render: (record) => action_items(record),
    },
  ];

  const get_all_list = () => {
    let key = [{ key: "get_users", loading: true }];
    let query = get_all_user_query;
    let variables = {
      role_name: user_type,
      page_number: page_number,
      page_limit: page_limit,
      search_string: search_value,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_conformation = () => {
    let key = [{ key: "delete_user", loading: true }];
    let query = mutation_delete_user;
    let variables = { id: mutation_form_values?.id };
    dispatch(dynamic_request(key, query, variables));
  };

  const give_privileged_access_conformation = () => {
    let key = [{ key: "give_previleged_access_to_user", loading: true }];
    let query = give_privileged_access_query;
    let variables = {
      user_id: user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_back_privileged_user_access = () => {
    let key = [{ key: "get_back_previleged_access_from_user", loading: true }];
    let query = get_back_privileged_access_query;
    let variables = {
      user_id: privileged_user_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_gat_back_modal_close = () => {
    set_modal_open(false);
  };

  const handle_edit = (value) => {
    set_mutation_modal(true);
    set_mutation_type("edit");
    set_mutation_form_values(value);
  };

  const handle_delete = (value) => {
    set_delete_modal(true);
    set_mutation_form_values(value);
  };

  const give_privilege_access = (record) => {
    set_confirmation_modal(true);
    set_user_id(record?.id);
  };

  const get_back_privilege_access = (record) => {
    set_modal_open(true);
    set_privileged_user_id(record?.id);
  };

  const handle_add = () => {
    set_mutation_modal(true);
    set_mutation_type("add");
  };

  const handle_close = () => {
    set_mutation_modal(false);
    set_mutation_type(null);
    form.resetFields("");
    set_mutation_form_values({});
    set_delete_modal(false);
  };

  const onSearch = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_value(search_value);
    }
  };

  const handle_change = (value) => {
    set_user_type(value);
  };
  const handle_access_modal_close = () => {
    set_confirmation_modal(false);
  };
  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  return (
    <VStack space={"10px"}>
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Select
          defaultValue="User"
          onChange={handle_change}
          style={{ width: "130px" }}
        >
          <Option value="User">Users</Option>
          <Option value="Editor">Editors</Option>
          {/* <Option value="Previleged User">Previleged Users</Option> */}
        </Select>
        <Search
          enterButton={true}
          onSearch={onSearch}
          allowClear={true}
          onChange={onSearch}
          placeholder="Search"
          style={{ width: "250px", backgroundColor: "white" }}
          className="admin-list-search"
        />
        {user_type === "Editor" && (
          <Button onClick={handle_add} type="primary">
            {t("add_editor")}
          </Button>
        )}
      </HStack>
      <Table
        columns={columns}
        loading={list_loading}
        dataSource={list}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            // onClick: (event) => handle_edit(record),
            // className: "table-cursor",
          };
        }}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
      <Modal
        open={mutation_modal}
        title={mutation_type?.includes("add") ? "Add Editor" : "Update Editor"}
        onCancel={handle_close}
        footer={null}
      >
        <UserDetails
          onCancel={handle_close}
          form={form}
          action_type={mutation_type}
          get_all_list={get_all_list}
          form_values={mutation_form_values}
          set_mutation_modal={set_mutation_modal}
        />
      </Modal>
      <Modal
        open={delete_modal}
        title={t("delete_confirmation")}
        footer={null}
        onCancel={handle_close}
      >
        <span>
          Are you sure you want to delete {mutation_form_values?.name}?
        </span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button
            onClick={handle_delete_conformation}
            type="primary"
            loading={delete_user_loading}
          >
            {t("delete")}
          </Button>
          <Button onClick={handle_close} danger>
            {"Cancel"}
          </Button>
        </HStack>
      </Modal>
      <Modal
        title={"Confirmation"}
        open={confirmation_modal}
        onCancel={handle_access_modal_close}
        footer={null}
      >
        <span>
          Are you sure you want to grant privileged access to this user?
        </span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button
            onClick={give_privileged_access_conformation}
            type="primary"
            loading={give_privileged_access_loading}
          >
            {t("Ok")}
          </Button>
          <Button onClick={handle_access_modal_close} danger>
            {"Cancel"}
          </Button>
        </HStack>
      </Modal>
      <Modal
        title="Confirmation"
        open={modal_open}
        onCancel={handle_gat_back_modal_close}
        footer={null}
      >
        <span>
          Are you sure you want to revoke privileged access from this user?
        </span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button
            onClick={get_back_privileged_user_access}
            type="primary"
            loading={get_back_privileged_access_loading}
          >
            {t("Ok")}
          </Button>
          <Button onClick={handle_gat_back_modal_close} danger>
            {t("cancel")}
          </Button>
        </HStack>
      </Modal>
    </VStack>
  );
};

export default UserList;
