import React, { useEffect, useRef, useState } from "react";
import JoinUsCard from "../components/widgets/join_us_card";
import { Row } from "antd";
import SocialMediaList from "../components/widgets/social_media_list";
import TrendingStoriesList from "../components/party_wise/party_wise_content";
import { dynamic_request, useDynamicSelector } from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  get_all_user_contribution_for_recently_added_query,
  get_all_user_contribution_query,
  get_all_user_contributors_query,
  get_user_contribution_trending_stories_query,
} from "../../services/redux/slices/graphql/graphql_user_contribution";
import { retrieveItem } from "../../helpers/functions";
import { debounce } from "lodash";
import DonationCardSmall from "../components/widgets/donation_card_small";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import UserContributionRecommendedStoriesCard from "../components/user_contribution/user_contribution_recommended_stories_card";
import RecommendedStoriesMobileViewCard from "../components/user_contribution/user_contribution_recommended_stories_card/user_contribution_recommended_stories_card_mobile_view";
import UserContributionTopContributors from "../components/user_contribution/user_contribution_top_contributors/user_contribution_top_contributers";
import UserContributionStoriesSeeAllButton from "../components/user_contribution/user_contribution_recommended_stories_card/user_contribution_stories_see_all_button";
import AllStoriesTitle from "../components/user_contribution/user_contribution_recommended_stories_card/user_contribution_all_stories_title";
import RecentlyAddedMainRow from "../components/user_contribution/user_contribution_recently_added_card/recently_added_main_row";
import UserContributionFirstHalf from "../components/user_contribution/user_contribution_first_half";

const UserContribution = () => {
  const dispatch = useDispatch();
  const top_ref = useRef(null);
  let current_language = retrieveItem("language");
  const { is_dark_mode } = useDarkMode();
  const [page_limit, set_page_limit] = useState(10);
  const [page_limit_user_contributors, set_page_limit_user_contributors] =
    useState(4);
  const [filtered_item, set_filtered_item] = useState([]);
  const [filtered_contributors, set_filtered_contributors] = useState([]);
  const all_stories_sliced_items = filtered_item?.slice(6, page_limit);

  const { items: get_all_contributers_items } = useDynamicSelector(
    "get_all_user_contributers"
  );

  useEffect(() => {
    get_all_user_contribution_first_half();
    get_all_user_contribution_details();
    user_contribution_trending_stories_list();
    get_recently_added_for_user_contribution_list();
  }, []);
  useEffect(() => {
    get_recently_added_for_user_contribution_list();
  }, [page_limit]);
  useEffect(() => {
    get_all_user_contribution_details();
  }, [page_limit_user_contributors]);

  useEffect(() => {
    debounced_handle_title_click();
  }, []);

  const {
    items: recently_added_for_user_contribution_items,
    loading: recently_added_for_user_contribution_loading,
  } = useDynamicSelector("recently_added_for_user_contribution");

  const { items: get_user_contribution_trending_stories_items } =
    useDynamicSelector("get_user_trending_results");

  useEffect(() => {
    if (
      recently_added_for_user_contribution_items &&
      recently_added_for_user_contribution_items.length > 0
    ) {
      set_filtered_item([...recently_added_for_user_contribution_items]);
    }
  }, [recently_added_for_user_contribution_items]);
  useEffect(() => {
    if (get_all_contributers_items && get_all_contributers_items.length > 0) {
      set_filtered_contributors([...get_all_contributers_items]);
    }
  }, [get_all_contributers_items]);

  const get_all_user_contribution_first_half = () => {
    let key = [{ key: "get_all_user_contribution", loading: true }];
    let query = get_all_user_contribution_query;
    let variables = {
      language_id: current_language?.id,
      page_number: 1,
      page_limit: 1,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_recently_added_for_user_contribution_list = () => {
    let key = [{ key: "recently_added_for_user_contribution", loading: true }];
    let query = get_all_user_contribution_for_recently_added_query;
    let variables = {
      language_id: current_language?.id,
      page_number: 1,
      page_limit: page_limit,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const get_all_user_contribution_details = () => {
    let key = [{ key: "get_all_user_contributers", loading: true }];
    let query = get_all_user_contributors_query;
    let variables = {
      language_id: current_language?.id,
      page_number: 1,
      page_limit: page_limit_user_contributors,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const user_contribution_trending_stories_list = () => {
    let key = [{ key: "get_user_trending_results", loading: true }];
    let query = get_user_contribution_trending_stories_query;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_click = () => {
    set_page_limit(page_limit + 4);
  };

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 200);
  return (
    <div
      ref={top_ref}
      style={{
        overflowX: "hidden",
        backgroundColor: is_dark_mode ? "#1f1f1f" : "#FFFF",
      }}
    >
      <UserContributionFirstHalf
        recently_added_for_user_contribution_loading={
          recently_added_for_user_contribution_loading
        }
      />
      <RecentlyAddedMainRow page_limit={page_limit} />
      <div className="user_contribution_donation_small_card_mobile_view">
        <DonationCardSmall />
      </div>
      <UserContributionRecommendedStoriesCard page_limit={page_limit} />

      <div className="user_contribution_social_media_list_mobile_view">
        <SocialMediaList width={"100%"} />
      </div>
      <div className="user_contribution_all_stories_mobile_view">
        <AllStoriesTitle />
        <RecommendedStoriesMobileViewCard
          all_stories_sliced_items={all_stories_sliced_items}
        />
        <UserContributionStoriesSeeAllButton handle_click={handle_click} />
      </div>

      <div className="user_contribution_trending_stories">
        <TrendingStoriesList
          name={"Trending Stories"}
          list={get_user_contribution_trending_stories_items}
          date_type={"user_contribution"}
        />
      </div>
      <UserContributionTopContributors
        all_stories_sliced_items={all_stories_sliced_items}
        handle_click={handle_click}
        page_limit={page_limit}
        page_limit_user_contributors={page_limit_user_contributors}
        filtered_contributors={filtered_contributors}
      />
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <JoinUsCard />
      </Row>
    </div>
  );
};
export default UserContribution;
