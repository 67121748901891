import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const MobileDonationForm = ({ set_donation_amount }) => {
  const { t } = useTranslation();

  const handle_input_change = (e) => {
    e.target.value = e.target.value.replace(/\D/, "");
    set_donation_amount(parseInt(e.target.value));
  };
  return (
    <Form.Item name={"amount"}>
      <Input
        rules={[
          {
            required: true,
            message: "Please enter the donation amount",
          },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: "Please enter a valid donation amount",
          },
        ]}
        style={{
          height: "40px",
          borderRadius: "10px",
          marginTop: "10px",
        }}
        placeholder={t("enter_amount")}
        onInput={handle_input_change}
      />
    </Form.Item>
  );
};

export default MobileDonationForm;
