import React, { memo } from "react";
import CustomText from "./custom_text";
import { Skeleton } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../routes/my_routes";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { BROKEN_IMAGE } from "../../../helpers/image_constants";

const CustomFactCheckCategories = memo((props) => {
  const { t } = useTranslation();
  const {
    category_image,
    title,
    created_date,
    width,
    loading,
    perma_link,
    height,
    type,
    name,
    language_id,
  } = props;

  const history = useHistory();

  const on_click_to_single_article = () => {
    let base_url =
      language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
        ? process.env.REACT_APP_WEB_URL
        : process.env.REACT_APP_EN_WEB_URL;
    let record_type = type === "article" ? "articles" : "factcheck";
    const url = `${base_url}${record_type}/${perma_link}.html`;
    window.open(url, "_blank");
  };

  return (
    <>
      {loading ? (
        <Skeleton.Input
          className="fact_check_categories_skeleton"
          active
          style={{ height: "30vh", width: "20vw", gap: "15px" }}
        />
      ) : (
        <div
          style={{
            width: width ? width : "30%",
            height: height ? height : "35%",
            boxShadow: "5px 5px 15px 0px rgba(0, 101, 204, 0.13)",
            cursor: "pointer",
            borderRadius: "10px",
          }}
          className="fact_check_category_card"
          onClick={on_click_to_single_article}
        >
          {loading ? (
            <Skeleton.Input
              className="fact_check_categories_skeleton"
              style={{ height: "30vh", width: "20vw", gap: "15px" }}
              active
            />
          ) : (
            <div className="fact_check_category_card_image_div">
              <img
                src={category_image || BROKEN_IMAGE}
                alt="fact-check-category"
                className="fact_check_category_card_image"
                style={{
                  transform: "scale(1)",
                }}
              />
              <div className="fact_check_category_card_text">
                <div className="related-fact-check-title-div">
                  <CustomText
                    class_name={"related_fact_check_card_last_text"}
                    text={
                      title?.split(" ").length > 100
                        ? title
                            ?.split(" ")
                            ?.slice(0, isMobile ? 5 : isTablet ? 8 : 4)
                            ?.join(" ") + "..."
                        : title
                    }
                    font_family={"Lato"}
                    color={"white"}
                    weight={"600"}
                    align={"left"}
                  />
                </div>

                <CustomText
                  text={`${name} | ${created_date}`}
                  size={"12px"}
                  font_family={"Lato"}
                  color={"white"}
                  weight={"400"}
                  align={"left"}
                  class_name={"related_fact_check_name_view"}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default CustomFactCheckCategories;
