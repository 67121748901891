import { Form, Input } from "antd";
import React from "react";

const MobileInput = () => {
  return (
    <div>
      <Form.Item
        name={"mobile"}
        rules={[
          {
            pattern: /^\d{10}$/,
            message: "Please enter a 10-digit number",
          },
        ]}
        style={{ borderBottom: "1px solid #000", paddingTop: "6%" }}
      >
        <Input className="edit_profile_input_field" />
      </Form.Item>
    </div>
  );
};

export default MobileInput;
