import React from "react";
import CustomText from "../custom/custom_text";
import { useTranslation } from "react-i18next";

const TopContributionTitle = () => {
  const { t } = useTranslation();
  return (
    <div>
      <CustomText
        text={t("top_contributors")}
        font_family={"Kufam"}
        color={"#140F2D"}
        size={"2.4vh"}
        align={"start"}
        weight={"700"}
        margin_top={"3vh"}
      />
    </div>
  );
};

export default TopContributionTitle;
