import React, { useEffect, useState } from "react";
import {
  FORM_BACKGROUND,
  THANK_YOU_IMAGE,
} from "../../../helpers/image_constants";
import { Button, Form, Modal, Select } from "antd";
import Title from "../admin/admin_article/admin_article_form/title";
import SubTitle from "../admin/admin_article/admin_article_form/sub_title";
import Content from "../admin/admin_article/admin_article_form/content";
import SpreadNews from "../admin/admin_article/admin_article_form/spread_news";
import ShortStory from "../admin/admin_article/admin_article_form/short_story";
import Source from "../admin/admin_article/admin_article_form/source";
import VForm from "../system/ui/antd_form";
import { HStack, VStack } from "native-base";
import { navigate, navigateBack } from "../../../helpers/navigator";
import { useTranslation } from "react-i18next";
import PageTitle from "../system/page_title/page_title";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  mutation_create_article,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ROUTES } from "../../routes/my_routes";
import ConfettiAnimation from "../widgets/result";

const ContributionForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [show_modal, set_show_modal] = useState(false);
  const [create_status, set_create_status] = useState(false);

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  const {
    status: create_article_status,
    error: create_article_error,
    loading: create_article_loading,
  } = useDynamicSelector("create_article");

  useEffect(() => {
    if (create_article_status === "Success") {
      set_show_modal(true);
      set_create_status(true);
      setTimeout(() => {
        set_show_modal(false);
        set_create_status(false);

        navigate(`${ROUTES.USER_PROFILE_LIST}/my-article`);
      }, 10000);

      dispatch(dynamic_clear("create_article"));
    } else if (create_article_error) {
      toast.error(create_article_error?.message);
      dispatch(dynamic_clear("create_article"));
    }
  }, [create_article_status, create_article_error]);

  useEffect(() => {
    get_all_languages();
  }, []);

  const handle_back = () => {
    navigateBack();
  };

  const handle_submit = (values) => {
    create_articles(values);
  };

  const create_articles = (json) => {
    let key = [{ key: "create_article", loading: true }];
    let query = mutation_create_article;
    let data = { ...json, thumbnail_img: json?.thumbnail_img?.[0]?.url };
    let variables = {
      json: data,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url(${FORM_BACKGROUND})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Modal
        visible={show_modal}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => set_show_modal(false)}
      >
        <div style={{ textAlign: "center", height: "400px" }}>
          <h2>Thank You!</h2>
          <h6>We extend our sincere appreciation for your article</h6>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",
              height: "170px",
              marginTop: "4vh",
            }}
            src={THANK_YOU_IMAGE}
            alt="Profile"
          />
          <h6 style={{ marginTop: "4vh" }}>
            Together, let's make an impact! We're submitting your article to
            process and Keep supporting us, and let's change lives!
          </h6>
          {create_status && <ConfettiAnimation />}
        </div>
      </Modal>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.1,
        }}
      ></div>
      <VStack style={{ padding: "50px" }}>
        <PageTitle title={"Add Article"} class_name={"user_article_form"} />
        <Form
          id="article_form"
          //   initialValues={selected_item}
          form={form}
          layout="vertical"
          onFinish={handle_submit}
        >
          <Title color={"#ffffff"} />
          <SubTitle color={"#ffffff"} />
          <VForm.ImageFile
            label={<span style={{ color: "white" }}>Thumbnail Image</span>}
            field={"thumbnail_img"}
            rules={[{ required: true, message: "Please upload the image" }]}
          />
          <Form.Item
            label={<span style={{ color: "white" }}>Language</span>}
            name="language_id"
            rules={[{ required: true, message: "Language is required " }]}
          >
            <Select
            //   onChange={on_change_language}
            //   defaultValue={system_languages ? system_languages?.[0]?.name : ""}
            >
              {system_languages?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <VForm.TextBox
            label={<span style={{ color: "white" }}>Perma link</span>}
            field={"perma_link"}
            rules={[{ required: true, message: "Perma link is required " }]}
          />
          <VForm.TextArea
            label={<span style={{ color: "white" }}>{"Description"}</span>}
            field={"description"}
            // rules={[{ required: true, message: "Description is required " }]}
          />
          <VForm.RichText
            label={<span style={{ color: "white" }}>{t("content")}</span>}
            field={"content"}
            rules={[{ required: true, message: "Content is required " }]}
          />
          <HStack justifyContent={"flex-end"} space={"10px"}>
            <Button onClick={handle_back} danger>
              {t("back")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={create_article_loading}
            >
              {"Create"}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </div>
  );
};

export default ContributionForm;
