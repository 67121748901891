import React from "react";
import { useTranslation } from "react-i18next";
import { WHATSAPP_GIF_ICON } from "../../../../helpers/image_constants";

const PreviewJoinWhatsappChannel = () => {
  const { t } = useTranslation();

  return (
    <div className="join_whatsapp_channel">
      <div className="whatsapp_gif_icon_container">
        <img className="whatsapp_gif_icon" src={WHATSAPP_GIF_ICON} />
      </div>
      <text className="whatsapp_channel_content">
        {t("join_our_whatsapp_channel_for_quick_updates")}
      </text>
    </div>
  );
};

export default PreviewJoinWhatsappChannel;
