import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../custom/custom_text";

const SingleFactCheckCommentTitle = () => {
  const { t } = useTranslation();

  return (
    <div className="single_fact_check_comment_title">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomText
          text={t("comments_title")}
          marginRight={"30px"}
          align={"left"}
          font_family={"lato"}
          weight={"700"}
          class_name={"single_fact_check_comment_title"}
        />
      </div>
    </div>
  );
};

export default SingleFactCheckCommentTitle;
