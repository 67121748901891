import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";

const EditUserProfileTopContents = () => {
  return (
    <div className="edit-profile-bread-crumbs-div">
      <div className="edit-profile-bread-crumbs-title-div">
        <div className="edit-profile-bread-crumbs-row">
          <CustomBreadCrumbs pages={"Home/Profile/Edit Profile"} />
        </div>
        <div className="edit-profile-profile-row">
          <CustomText
            class_name={"edit-profile-profile-title"}
            text={"Profile"}
            font_family={"Lato"}
            align={"left"}
          />
        </div>
      </div>
    </div>
  );
};

export default EditUserProfileTopContents;
