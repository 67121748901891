import React from "react";
import CustomText from "../custom/custom_text";
import { useDynamicSelector } from "../../../services/redux";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

const SingleFactCheckSpreadNews = () => {
  const { t } = useTranslation();
  const { spread_news } = useDynamicSelector("get_fact_check_by_perma_link");

  return (
    <div className="fact-check-claim-detailed-explanation-row">
      <div className="single-fact-check-claim-div">
        <CustomText
          class_name="single-fact-check-rating-text"
          text={t("claim")}
          color={"#140F2D"}
          weight={"700"}
          font_family={"Kufam"}
          align={"left"}
          marginTop={"5%"}
        />
      </div>
      <div className="claim-news single-fact-heck-content-align">
        {spread_news ? ReactHtmlParser(spread_news) : ""}
      </div>
    </div>
  );
};

export default SingleFactCheckSpreadNews;
