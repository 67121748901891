import { Badge, Card, Row, Space } from "antd";
import { Form, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import DonationText from "./donation_text";
import DonationAmountInput from "./donation_amount_input";
import CustomButton from "../custom/custom_button";
import {
  dynamic_clear,
  dynamic_request,
  payment_handler,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import {
  change_tamil_font_style,
  initiate_request_razorpay_payment,
  retrieveItem,
} from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import ConfettiAnimation from "./result";
import { THANK_YOU_IMAGE } from "../../../helpers/image_constants";
import UserDetailsForm from "./user_details_main_form_and_form_items/user_details_form";

const DonationCardHorizontal = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const donation_ref = useRef();
  const dispatch = useDispatch();
  const {
    class_name,
    donation_horizontal_main_div_class,
    donation_text_class,
    contribution_class,
    donate_button_class,
    fake_news_title_class,
  } = props;
  const {
    loading,
    order_id,
    status: create_donation_status,
  } = useDynamicSelector("create_donation");
  const [manual_amount, set_manual_amount] = useState(false);
  const [amount, set_amount] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [payment_response, set_payment_response] = useState("");

  const [show_modal, set_show_modal] = useState(false);
  const [paymentStatus, set_payment_status] = useState(false);
  const [user_details_modal_open, set_user_details_modal_open] =
    useState(false);
  const [user_details_values, set_user_details_values] = useState({});

  const donor_name = Form.useWatch("name", form);
  const donor_mobile = Form.useWatch("mobile", form);
  const donor_address = Form.useWatch("address", form);

  const payment_status = payment_response.razorpay_signature;
  useEffect(() => {
    set_amount(null);
  }, [manual_amount]);
  useEffect(() => {
    if (payment_status) {
      set_payment_status(true);
      set_show_modal(true);
      setTimeout(() => {
        set_payment_status(false);
        set_show_modal(false);
      }, 10000);
    }
  }, [payment_status]);

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(order_id, set_payment_response);
      dispatch(dynamic_clear("create_donation"));
    }
    if (create_donation_status === "Success") {
      form.resetFields();
    }
  }, [order_id]);
  create_donation_status;

  const onclick_amount = (amount) => {
    setSelectedAmount(amount);
    set_amount(amount);
  };

  const query_get_order_id = (amount) => {
    let key = [{ key: "create_donation", loading: true }];
    let query = payment_handler;
    let variables = {
      amount: parseInt(amount),
      mobile: String(donor_mobile),
      address: donor_address,
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const razorpay_button = () => {
    if (amount > 0) {
      set_user_details_modal_open(true);
    }
  };
  const user_detail_modal_close = () => {
    set_user_details_modal_open(false);
    form.resetFields();
  };
  const handle_ok = (values) => {
    query_get_order_id(amount, values);
    set_user_details_modal_open(false);
  };

  return (
    <div
      className={
        donation_horizontal_main_div_class
          ? donation_horizontal_main_div_class
          : "donation-horizontal-card"
      }
    >
      <Modal
        visible={show_modal}
        closable={false}
        footer={null}
        width={800}
        onCancel={() => set_show_modal(false)}
      >
        <div style={{ textAlign: "center", height: "400px" }}>
          <h2>Thank You!</h2>
          <h6>We extend our sincere appreciation for your donation.</h6>
          <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",
              height: "170px",
              marginTop: "4vh",
            }}
            src={THANK_YOU_IMAGE}
            alt="Profile"
          />
          <h6 style={{ marginTop: "4vh" }}>
            Together, let's make an impact through your donations. Keep
            supporting us.
          </h6>
          {paymentStatus && <ConfettiAnimation />}{" "}
        </div>
      </Modal>

      <Modal
        title={t("enter_details")}
        width={500}
        open={user_details_modal_open}
        onCancel={user_detail_modal_close}
        // onOk={handle_ok}
        destroyOnClose={true}
        footer={null}
      >
        <UserDetailsForm
          form={form}
          set_user_details_values={set_user_details_values}
          handle_ok={handle_ok}
        />
      </Modal>

      <div className="donation-horizontal">
        <div
          className={
            class_name ? class_name : "donation_card_horizontal_third_div"
          }
        >
          <div
            className={
              fake_news_title_class ? fake_news_title_class : "fake_news_title"
            }
          >
            <text
              style={{
                color: "#f49d36",
                fontSize: "75px",
                fontWeight: "bold",
              }}
            >
              #
            </text>
            <text
              style={{
                color: "#f49d36",
                fontSize: "30px",
                fontWeight: "bold",
                lineHeight: "43px",
                fontFamily: "Kufam",
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("fight_against_fake_news")}
            </text>
          </div>
          <div
            className={
              donation_text_class ? donation_text_class : "donate-width"
            }
          >
            <DonationText />
          </div>
          <div
            className={
              contribution_class
                ? contribution_class
                : "donation_card_horizontal_contribution_main_div"
            }
          >
            <text
              style={{ color: "white", fontSize: "15px", fontWeight: "bold" }}
            >
              {t("make_a_small_contribution...")}
            </text>
            {manual_amount && (
              <Row className="donation-input-container">
                <DonationAmountInput
                  set_donation_amount={set_amount}
                  value={amount}
                  ref={donation_ref}
                />
                <div className="back-btn">
                  <Badge
                    count={"Back"}
                    color="black"
                    className="cursor-pointer amount-badge-font"
                    onClick={() => set_manual_amount(false)}
                  />
                </div>
              </Row>
            )}
            {manual_amount === true ? (
              <></>
            ) : (
              <div className="donation-amount-badges">
                <Badge
                  count={"₹199"}
                  overflowCount={199}
                  className="cursor-pointer amount-badge-font"
                  onClick={() => onclick_amount(199)}
                  style={{
                    backgroundColor:
                      selectedAmount === 199 ? "#0065df" : "white",
                    color: selectedAmount === 199 ? "white" : "black",
                  }}
                />
                <Badge
                  count={"₹499"}
                  overflowCount={499}
                  className="cursor-pointer amount-badge-font"
                  onClick={() => onclick_amount(499)}
                  style={{
                    backgroundColor:
                      selectedAmount === 499 ? "#0065df" : "white",
                    color: selectedAmount === 499 ? "white" : "black",
                  }}
                />
                <Badge
                  count={"₹999"}
                  overflowCount={999}
                  className="cursor-pointer amount-badge-font"
                  onClick={() => onclick_amount(999)}
                  style={{
                    backgroundColor:
                      selectedAmount === 999 ? "#0065df" : "white",
                    color: selectedAmount === 999 ? "white" : "black",
                  }}
                />
                <Badge
                  count={"₹1,999"}
                  overflowCount={1999}
                  className="cursor-pointer amount-badge-font"
                  onClick={() => onclick_amount(1999)}
                  style={{
                    backgroundColor:
                      selectedAmount === 1999 ? "#0065df" : "white",
                    color: selectedAmount === 1999 ? "white" : "black",
                  }}
                />
                <Badge
                  count={"Other"}
                  className="cursor-pointer amount-badge-font"
                  onClick={() =>
                    set_manual_amount(!manual_amount) ||
                    onclick_amount("others")
                  }
                  style={{
                    backgroundColor:
                      selectedAmount === "others" ? "#00adee" : "white",
                    color: selectedAmount === "others" ? "white" : "black",
                  }}
                />
              </div>
            )}

            <text style={{ color: "gray", fontSize: "12px" }}>
              {change_tamil_font_style(
                t("currently_we_accept_only_INR_donations"),
                "10px",
                _
              )}
            </text>
          </div>

          <div
            className={
              donate_button_class
                ? donate_button_class
                : "donation_card_horizontal_donate_button"
            }
          >
            <CustomButton
              is_disabled={amount > 0 ? false : true}
              id="razorpay-button"
              name={change_tamil_font_style(t("donate"), "1.8vh", _)}
              loading={loading}
              width={"12vw"}
              backgroundColor={"#00adee"}
              on_Click={razorpay_button}
              htmlType={"submit"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCardHorizontal;
