import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { MdLightMode } from "react-icons/md";
import { CiLight } from "react-icons/ci";
import { changeLanguage } from "../../i18n";
import { ROUTES } from "../routes/my_routes";
import { retrieveItem, storeItem } from "../../helpers/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { get_language } from "../../helpers/constants";

const { SubMenu } = Menu;
const LayoutOptions = (props) => {
  const { handle_logout } = props;
  const history = useHistory();
  const session_id = retrieveItem("session_id");
  const user_id = retrieveItem("user_id");
  const location = useLocation();
  const storedLanguage = retrieveItem("language");
  const chosen_language_key = window.sessionStorage.getItem(
    "chosen_language_key"
  );

  const { is_dark_mode, toggleDarkMode } = useDarkMode();

  const [selected_language, set_selected_language] = useState(
    chosen_language_key ||
      (retrieveItem("language") === "english" ? "en" : "ta")
  );

  const handle_menu_click = (event) => {
    const { key } = event;
    if (key === "fact-check") {
      history.push(`${ROUTES.USER_BOOK_MARKS}/fact_check`);
    } else if (key === "article") {
      history.push(`${ROUTES.USER_BOOK_MARKS}/article`);
    }
  };

  const handle_language_change = (language) => {
    const new_language_key = get_language() !== "en" ? "ta" : "en";
    set_selected_language(new_language_key);
    window.sessionStorage.setItem("chosen_language_key", new_language_key);

    window.location.assign(
      get_language() === "en"
        ? process.env.REACT_APP_EN_WEB_URL
        : process.env.REACT_APP_WEB_URL
    );
  };

  const menuItems = [
    {
      key: "bookmarks",
      label: "Bookmarks",
      children: [
        {
          type: "article",
          label: "Articles",
          key: "article",
        },
        {
          type: "fact-check",
          label: "Fact Checks",
          key: "fact-check",
        },
      ],
    },
  ];
  return (
    <div
      className="header-stripe"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0 6% 0 7%",
        backgroundColor: "#140f2d",
        color: "white",
        height: "4vh",
      }}
    >
      <div className="social-media-icons"></div>
      <div
        style={{
          // width: handle_logout === "Logout" ? "13%" : "36%",
          display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
          zIndex: "1",
          height: "7vh",
          gap: "4vh",
        }}
      >
        {user_id && (
          <div className="segment-container">
            <Menu
              mode="horizontal"
              className="main-menu"
              selectedKeys={[]}
              onClick={handle_menu_click}
            >
              {menuItems.map((item) => (
                <SubMenu
                  key={item.key}
                  title={item.label}
                  className="bookmarks-submenu"
                >
                  {item.children.map((child) => (
                    <Menu.Item
                      className="bookmarks-submenu-action"
                      key={child.key}
                    >
                      {child.label}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </div>
        )}
        <div
          className="language-main-div"
          style={{
            display: "flex",
            alignItems: "center",
            // backgroundColor: "green",
          }}
        >
          <div
            style={{
              color: get_language() !== "ta" ? "#f49d36" : "white",
              cursor: get_language() === "ta" ? "pointer" : "not-allowed",
              marginRight: "23px",
            }}
            onClick={() =>
              get_language() === "ta" && handle_language_change("tamil")
            }
          >
            தமிழ்
          </div>
          <div
            style={{
              width: "3vh",
              display: "flex",
              justifyContent: "start",
            }}
          >
            |
          </div>
          <div
            style={{
              color: get_language() !== "en" ? "#f49d36" : "white",
              cursor: get_language() === "en" ? "pointer" : "not-allowed",
            }}
            onClick={() =>
              get_language() === "en" && handle_language_change("english")
            }
          >
            English
          </div>
        </div>
        <span style={{ cursor: "pointer" }} onClick={toggleDarkMode}>
          {is_dark_mode ? (
            <CiLight size={20} />
          ) : (
            <MdLightMode size={20} color />
          )}
        </span>
      </div>
    </div>
  );
};

export default LayoutOptions;
