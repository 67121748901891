import React, { createContext, useState, useContext } from "react";
import { language as Language } from "../../../helpers/constants";
import { retrieveItem, storeItem } from "../../../helpers/functions";

const languageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, set_language] = useState({
    name: "english",
    id: Language.english_id,
  });
  const toggle_language = () => {
    // set_language((previous_language)=>{
    //     if(previous_language.name === "english"){
    //       storeItem("language",language);
    //       return {
    //         name:"tamil",
    //         id:Language.tamil_id
    //       }
    //     }
    //     else{
    //       storeItem("language",language);
    //       return {
    //         name:"english",
    //         id:Language.english_id
    //       }
    //     }
    // })
  };

  return (
    <languageContext.Provider value={{ language, toggle_language }}>
      {children}
    </languageContext.Provider>
  );
};

export const useLanguageProvider = () => useContext(languageContext);
