import { gql } from "@apollo/client";

export const query_get_article_by_perma_link_in_article_list = gql`
  query get_article_by_perma_link($perma_link: String!) {
    get_articles_by_perma_link: get_article_by_perma_link(
      perma_link: $perma_link
    ) {
      id
      category_tags {
        id
        name
      }
      title
      staff_profile {
        id
        name
        profile_pic
        url
      }
      user_profile {
        id
        name
        profile_pic
        url
      }
      subtitle
      perma_link
      description
      content
      status
      reading_time
      likes
      views
      comments_count
      is_reported
      is_deleted
      thumbnail_img
      created_date_time
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_article_in_article_list = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_all_article_in_article_list: get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        thumbnail_img
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        created_date_time
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_article_in_article_list_category_wise = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        thumbnail_img
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        created_date_time
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_three_trending_article = gql`
  query get_trending_articles($language_id: String!) {
    get_three_trending_article: get_trending_articles(
      language_id: $language_id
    ) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        thumbnail_img
        created_date_time
        user_profile {
          name
        }
      }
    }
  }
`;

export const query_get_recent_article_in_article_list = gql`
  query get_recent_articles($language_id: String!) {
    get_recent_article_list: get_recent_articles(language_id: $language_id) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          name
        }
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        thumbnail_img
        created_date_time
      }
    }
  }
`;
