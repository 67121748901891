import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  query_get_all_articles,
  query_get_all_fact_check,
  useDynamicSelector,
} from "../../../../services/redux";
import { HStack, VStack } from "native-base";
import {
  create_recommended_stories_query,
  get_all_recommended_stories_query,
} from "../../../../services/redux/slices/graphql/graphql_recommended_stories";
import CustomAdminSlotCard from "../../custom/custom_admin_slot_card";
import CustomAdminSlotDetailsCard from "../../custom/custom_admin_slot_details_card";
import moment from "moment";
import {
  extractTextFromPTags,
  isEnglish,
  split_tamil_english_words,
} from "../../../../helpers/functions";

const RecommendedStoriesList = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [story_values, set_story_values] = useState([]);
  const [search_string, set_search_string] = useState("");

  const [language_id, set_language] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );
  const [form_open, set_form_open] = useState(false);
  const [slot_items, set_slot_items] = useState({});
  const [selected_slot, set_selected_slot] = useState(null);
  const [initial_values, set_initial_values] = useState({});
  const [ref_id, set_ref_id] = useState(null);
  const [debounced_search_string, set_debounced_search_string] = useState("");

  const slot_record_type = Form.useWatch("type", form);

  const is_english = (text) => isEnglish(text);

  const record_type = [
    {
      title: "Article",
      type: "articles",
    },
    {
      title: "Fact Check",
      type: "fact_check",
    },
  ];

  const { items: fact_check_list, loading: fact_check_list_loading } =
    useDynamicSelector("get_fact_check_list");
  const { items: article_list, loading: article_list_loading } =
    useDynamicSelector("get_all_articles");
  const { items: get_story_list } = useDynamicSelector(
    "admin_recommended_story"
  );

  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_recommended_story");

  const { items: languages_list } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (search_string) {
        set_debounced_search_string(search_string);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search_string]);

  useEffect(() => {
    if (debounced_search_string) {
      get_all_article(language_id);
      get_all_fact_check(language_id);
    }
  }, [debounced_search_string]);

  useEffect(() => {
    if (search_string) {
      if (slot_record_type === "fact_check") {
        get_all_fact_check(language_id);
        set_search_string("");
      } else if (slot_record_type === "articles") {
        get_all_article(language_id);
        set_search_string("");
      }
    }
  }, [search_string, slot_record_type]);

  useEffect(() => {
    get_recommended_stories(language_id);
    get_all_languages();
    get_all_fact_check(language_id);
    get_all_article(language_id);
  }, []);

  useEffect(() => {
    if (slot_items) {
      let data = slot_items;
      let form_data = {
        type: data.article_id ? "articles" : "fact_check",
        language_id: data.language_id,
      };
      form_data[`story`] = data.fact_check_id || data.article_id;
      form.setFieldsValue(form_data);
      set_initial_values(form_data);
    }
  }, [slot_items]);

  useEffect(() => {
    if (
      get_story_list?.length &&
      fact_check_list?.length &&
      article_list?.length &&
      ref_id
    ) {
      let data1 = get_story_list[0];
      let data2 = get_story_list[1];
      let data3 = get_story_list[2];
      let data4 = get_story_list[3];
      let data5 = get_story_list[4];
      let values = [];
      if (ref_id == 1) {
        if (data1.article_id) {
          values = article_list.filter(
            (x) =>
              x.id !== data2.article_id ||
              x.id !== data3.article_id ||
              x.id !== data4.article_id ||
              x.id !== data5.article_id
          );
        } else {
          values = fact_check_list.filter(
            (x) =>
              x.id !== data2.fact_check_id ||
              x.id !== data3.fact_check_id ||
              x.id !== data4.fact_check_id ||
              x.id !== data5.fact_check_id
          );
        }
      } else if (ref_id == 2) {
        if (data2.article_id) {
          values = article_list.filter(
            (x) =>
              x.id !== data1.article_id ||
              x.id !== data3.article_id ||
              x.id !== data4.article_id ||
              x.id !== data5.article_id
          );
        } else {
          values = fact_check_list.filter(
            (x) =>
              x.id !== data1.fact_check_id ||
              x.id !== data3.fact_check_id ||
              x.id !== data4.fact_check_id ||
              x.id !== data5.fact_check_id
          );
        }
      } else if (ref_id == 3) {
        if (data3.article_id) {
          values = article_list.filter(
            (x) =>
              x.id !== data1.article_id ||
              x.id !== data2.article_id ||
              x.id !== data4.article_id ||
              x.id !== data5.article_id
          );
        } else {
          values = fact_check_list.filter(
            (x) =>
              x.id !== data1.fact_check_id ||
              x.id !== data2.fact_check_id ||
              x.id !== data4.fact_check_id ||
              x.id !== data5.fact_check_id
          );
        }
      } else if (ref_id == 4) {
        if (data4.article_id) {
          values = article_list.filter(
            (x) =>
              x.id !== data1.article_id ||
              x.id !== data2.article_id ||
              x.id !== data3.article_id ||
              x.id !== data5.article_id
          );
        } else {
          values = fact_check_list.filter(
            (x) =>
              x.id !== data1.fact_check_id ||
              x.id !== data2.fact_check_id ||
              x.id !== data3.fact_check_id ||
              x.id !== data5.fact_check_id
          );
        }
      } else if (ref_id == 5) {
        if (data5.article_id) {
          values = article_list.filter(
            (x) =>
              x.id !== data1.article_id ||
              x.id !== data2.article_id ||
              x.id !== data3.article_id ||
              x.id !== data4.article_id
          );
        } else {
          values = fact_check_list.filter(
            (x) =>
              x.id !== data1.fact_check_id ||
              x.id !== data2.fact_check_id ||
              x.id !== data3.fact_check_id ||
              x.id !== data4.fact_check_id
          );
        }
      }
      set_story_values(values);
    }
  }, [article_list, fact_check_list, ref_id, get_story_list]);

  useEffect(() => {
    if (create_status === "Success") {
      on_cancel();
      message.success("Story updated successfully");
      dispatch(dynamic_clear("create_recommended_story"));
      get_recommended_stories(language_id);
    } else if (create_status === "Failure") {
      on_cancel();
      message.error("something went wrong");
      dispatch(dynamic_clear("create_recommended_story"));
    } else if (create_error) {
      on_cancel();
      message.error("something went wrong");
      dispatch(dynamic_clear("create_recommended_story"));
    }
  }, [create_status, create_error]);

  const on_value_change = (value) => {};

  const handle_submit = (values) => {
    let data = {};
    data.ref_id = Number(ref_id);
    if (values.type === "fact_check") {
      data.fact_check_id = values.story;
      data.language_id = language_id;
    } else {
      data.article_id = values.story;
      data.language_id = language_id;
    }
    create_recommended_stories(data);
  };

  const get_all_fact_check = (language_id) => {
    let key = [{ key: "get_fact_check_list", loading: true }];
    let query = query_get_all_fact_check;
    let variables = {
      page_limit: 40,
      json: {
        language_id: language_id,
        search_string: search_string,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_article = (language_id) => {
    let key = [{ key: "get_all_articles", loading: true }];
    let query = query_get_all_articles;
    let variables = {
      page_limit: 40,
      json: {
        language_id: language_id,
        search_string: search_string,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_recommended_stories = (language_id) => {
    let key = [{ key: "admin_recommended_story", loading: true }];
    let query = get_all_recommended_stories_query;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const create_recommended_stories = (json) => {
    let key = [{ key: "create_recommended_story", loading: true }];
    let query = create_recommended_stories_query;
    let variables = {
      json,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const on_change = (value) => {
    set_search_string("");
    form.setFieldsValue({
      story: "",
    });
    if (value === "articles") {
      set_story_values(article_list);
    } else if (value === "fact_check") {
      set_story_values(fact_check_list);
    }
  };

  const on_change_language = (values) => {
    form.setFieldsValue({
      story: "",
    });
    set_language(values);
    get_recommended_stories(values);
    get_all_fact_check(values);
    get_all_article(values);
  };

  const on_cancel = () => {
    set_form_open(false);
  };

  const handle_card_click = (slot_item, story, ref) => {
    set_selected_slot(story);
    set_slot_items(slot_item);
    set_ref_id(ref);
    set_form_open(true);
    form.setFieldsValue(initial_values);
    get_all_fact_check(language_id);
    get_all_article(language_id);
  };

  return (
    <VStack padding={"25px"}>
      <HStack
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {get_story_list?.map((slot_item, index) =>
          slot_item ? (
            <div
              key={index}
              onClick={() =>
                handle_card_click(slot_item, `Story ${index + 1}`, index + 1)
              }
              className="custom_admin_slot_details_card_div"
            >
              <div className="content-wrapper">
                <CustomAdminSlotDetailsCard
                  show_icon={true}
                  key={index}
                  set_form_open={set_form_open}
                  reading_time={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.reading_time
                      : slot_item?.article?.reading_time
                  }
                  view_count={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.views
                      : slot_item?.article?.views
                  }
                  date={moment(
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.created_date_time
                      : slot_item?.article?.created_date_time
                  ).format("MMM DD, YYYY")}
                  title={
                    is_english(
                      slot_item?.fact_check_id
                        ? slot_item?.fact_check?.title
                        : slot_item?.article?.title
                    )
                      ? split_tamil_english_words(
                          slot_item?.fact_check_id
                            ? slot_item?.fact_check?.title
                            : slot_item?.article?.title,
                          _,
                          500
                        )
                      : split_tamil_english_words(
                          slot_item?.fact_check_id
                            ? slot_item?.fact_check?.title
                            : slot_item?.article?.title,
                          _,
                          500
                        )
                  }
                  subtitle={`${split_tamil_english_words(
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.subtitle
                      : slot_item?.article?.subtitle,
                    "_",
                    500
                  )}${split_tamil_english_words(
                    slot_item?.fact_check_id
                      ? extractTextFromPTags(slot_item?.fact_check?.content)
                      : extractTextFromPTags(slot_item?.article?.content),
                    "_",
                    500
                  )}`}
                  name={
                    slot_item?.fact_check_id
                      ? slot_item.fact_check?.staff_profile?.name
                      : slot_item?.article?.staff_profile?.name ||
                        "YouTurn Editorial"
                  }
                  src={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.thumbnail_img
                      : slot_item?.article?.thumbnail_img
                  }
                  id={slot_item?.id}
                  perma_link={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.perma_link
                      : slot_item?.article?.perma_link
                  }
                />
              </div>
            </div>
          ) : (
            <CustomAdminSlotCard key={index} />
          )
        )}
      </HStack>
      <Modal open={form_open} onCancel={on_cancel} footer={null}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handle_submit}
          onValuesChange={on_value_change}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="language_id" label="Language">
                <Select onChange={on_change_language}>
                  {languages_list?.map((option, index) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label="Type">
                <Select onChange={on_change}>
                  {record_type?.map((option, index) => (
                    <Select.Option key={option.title} value={option.type}>
                      {option.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="story"
            label={selected_slot}
            rules={[
              {
                required: false,
                message: t("Slot one is required"),
              },
            ]}
          >
            <Select
              placeholder={selected_slot}
              allowClear
              onSearch={(value) => set_search_string(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {story_values?.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <HStack justifyContent={"flex-end"} space={"10px"}>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                backgroundColor: "green",
                color: "white",
              }}
              loading={create_loading}
            >
              Update
            </Button>
          </HStack>
        </Form>
      </Modal>
    </VStack>
  );
};

export default RecommendedStoriesList;
