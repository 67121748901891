import { Button, Form } from "antd";
import React from "react";
import UserNameFormItem from "./user_name_form_item";
import UserMobileNumberFormItem from "./user_mobile_number_form_item";
import UserAddressFormItem from "./user_address_form_item";
import { retrieveItem } from "../../../../helpers/functions";
import { useTranslation } from "react-i18next";

const UserDetailsForm = (props) => {
  const { t } = useTranslation();
  const { form, set_user_details_values, handle_ok } = props;
  const role = retrieveItem("role");

  let session_id = retrieveItem("session_id");

  const handle_submit = (values) => {
    set_user_details_values(values);
  };
  return (
    <div>
      <Form form={form} onFinish={handle_ok} id="donation_form">
        {!session_id ? (
          <>
            <UserNameFormItem />
            <UserMobileNumberFormItem />
            <UserAddressFormItem />
          </>
        ) : role === "User" || role === "Previleged User" ? (
          <UserAddressFormItem />
        ) : role === "Admin" || role === "Editor" ? (
          <>
            <UserNameFormItem />
            <UserMobileNumberFormItem />
            <UserAddressFormItem />
          </>
        ) : null}

        <div className="submit_button_div">
          <Button htmlType="submit" className="submit_button">
            {t("submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UserDetailsForm;
