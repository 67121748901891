import React from "react";
import CustomBreadCrumbs from "../custom/custom_breadcrumbs";
import CustomText from "../custom/custom_text";
import {
  split_tamil_english_words,
  split_tamil_english_words_for_breadcrumbs,
} from "../../../helpers/functions";
import { Image, Skeleton } from "antd";
import { useDynamicSelector } from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BROKEN_IMAGE } from "../../../helpers/image_constants";
import SingleFactCheckMobileAuthorDetails from "./single_fact_check_mobile_author_details";

const SingleFactCheckMobileTopContent = () => {
  const { t } = useTranslation();
  const {
    thumbnail_img,
    title,
    loading: ger_all_fact_check_loading,
  } = useDynamicSelector("get_fact_check_by_perma_link");

  return (
    <div className="single-fact-check-mobile-top-second-div">
      <div
        style={{
          backgroundColor: "#140f2d",
          height: "20vh",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
        }}
      ></div>

      <div
        className="fact_check_first_card_mobile"
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="fact-check-bread-crumbs-div">
          <CustomBreadCrumbs
            pages={`Home/ Fact Check/ ${split_tamil_english_words_for_breadcrumbs(
              title,
              "_",
              2
            )}`}
          />
        </div>
        {ger_all_fact_check_loading ? (
          <Skeleton.Input
            className="skeleton-single-fact-check-top-image-col"
            active
          />
        ) : (
          <div className="single-fact-check-top-image-col">
            <Image
              className="single-fact-check-top-image"
              visible={false}
              preview={false}
              src={thumbnail_img ? thumbnail_img : BROKEN_IMAGE}
            />
          </div>
        )}

        {ger_all_fact_check_loading ? (
          <Skeleton.Input
            className="skeleton-single-fact-title-col"
            style={{
              width: "110vh",
              height: "90vh",
            }}
            active
          />
        ) : (
          <div className="single-fact-check-div">
            <div className="single-fact-check-title-small-div">
              <CustomText
                class_name={"single-fact-check-title"}
                text={split_tamil_english_words(title, _, 400)}
                font_family={"Kufam"}
                align={"left"}
              />
            </div>
          </div>
        )}

        {ger_all_fact_check_loading ? (
          <Skeleton.Input
            className="skeleton-single-fact-title-col"
            style={{
              width: "60vh",
              height: "40vh",
            }}
            active
          />
        ) : (
          <SingleFactCheckMobileAuthorDetails />
        )}
      </div>
    </div>
  );
};

export default SingleFactCheckMobileTopContent;
