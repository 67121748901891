import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../../services/redux";

import ReactHtmlParser from "html-react-parser";
import { useDarkMode } from "../../contexts/dark_mode_provider";
import TweetIframe from "../../single_fact_check/tweet_iframe";
import cheerio from "cheerio";

const SingleArticleContentDetails = () => {
  const [parsedHtml, set_parsed_html] = useState("");
  const { is_dark_mode } = useDarkMode();
  const {
    id,
    content,
    is_saved: book_mark_save,
    loading: article_loading,
    error: get_all_article_error,
  } = useDynamicSelector("get_article_by_perma_link");

  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "TweetIframe" || name === "tweetiframe") {
        return <TweetIframe {...attribs}>{children}</TweetIframe>;
      }
      return null;
    },
  };

  useEffect(() => {
    if (content) {
      const $ = cheerio.load(
        content
          .replace(/\[/g, "<")
          .replace(/\]/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );

      const twitterEmbed = (url) => {
        return `
          <blockquote class="twitter-tweet">
            <a href="${url}"></a>
          </blockquote>
          <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        `;
      };

      $("a[href^='https://twitter.com/']").each((index, element) => {
        const twitterLink = $(element).attr("href");
        if (twitterLink) {
          const embeddedTweet = twitterEmbed(twitterLink);
          $(element).replaceWith(embeddedTweet);
        }
      });

      $("blockquote.instagram-media").each((index, element) => {
        const blockquote = $(element);
        const instagramLink = blockquote.attr("data-instgrm-permalink");
        if (instagramLink) {
          const instagramUrl = instagramLink.split("?")[0]; // Get the base Instagram URL
          const iframe = `<iframe src="${instagramUrl}embed" width="500" height="800" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });
      $("blockquote").each((index, element) => {
        const blockquote = $(element);
        const tweetUrl = blockquote
          .find('a[href^="https://twitter.com/"]')
          .last()
          .attr("href");
        if (tweetUrl) {
          const iframe = `<br><TweetIframe tweeturl=${tweetUrl}></br>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("video").each((index, element) => {
        const videoElement = $(element);
        const videoSrc = videoElement.attr("mp4");
        const videoWidth = videoElement.attr("width");
        const videoHeight = videoElement.attr("height");
        const videoFrame = `<video width="${videoWidth}" height="${videoHeight}" controls>
                                <source src="${videoSrc}" type="video/mp4"/>
                            </video>`;
        videoElement.replaceWith(videoFrame);
      });
      if (is_dark_mode) {
        $("body").addClass("content-dark   article-content");
      } else {
        $("body").addClass("article-content");
      }
      set_parsed_html($.html());
    }
  }, [content, is_dark_mode]);
  return (
    <div className=" single-fact-heck-content-align">
      {parsedHtml ? ReactHtmlParser(parsedHtml, options) : ""}
    </div>
  );
};

export default SingleArticleContentDetails;
