import { HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import PageTitle from "../system/page_title/page_title";
import { useTranslation } from "react-i18next";
import { Button, Pagination, Table } from "antd";
import { get_status, retrieveItem } from "../../../helpers/functions";
import {
  dynamic_request,
  get_all_my_fact_check_query,
  useDynamicSelector,
} from "../../../services/redux";
import { countFormat, getDefaultLanguageId } from "../../../helpers/constants";
import { useDispatch } from "react-redux";
import { navigate, navigateWithProps } from "../../../helpers/navigator";
import { ROUTES } from "../../routes/my_routes";
import UserProfileTitle from "../user_profile/user_profile_title";
import CustomText from "../custom/custom_text";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { startCase } from "lodash";
import moment from "moment";

const UserFactCheckList = (props) => {
  const { my_articles } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const { is_dark_mode } = useDarkMode();

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const [language_id, set_language] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );

  const {
    items: all_fact_check_list,
    loading: all_fact_check_loading,
    pagination: all_fact_check_pagination,
    status: all_fat_check_list_status,
  } = useDynamicSelector("get_all_my_fact_check");

  useEffect(() => {
    get_all_my_fact_checks();
  }, [page_limit, page_number]);

  const get_all_my_fact_checks = () => {
    let key = [{ key: "get_all_my_fact_check", loading: true }];
    let query = get_all_my_fact_check_query;
    let variables = {
      page_limit: page_limit,
      page_number: page_number,
      // language_id: current_language?.id,
      status: "pending",
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handle_row_click = (record) => {
    navigateWithProps(ROUTES.USER_PROFILE_PENDING_FORM, {
      record: { record },
      contribution_type: "fact_check",
    });
  };

  const on_click = () => {
    navigate(`${ROUTES.USER_PROFILE_LIST}/my-factcheck`);
  };

  const columns = [
    // {
    //   title: "S.no",
    //   key: "s_no",
    //   render: (record, _, index) => index + 1,
    // },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: t("subtitle"),
    //   dataIndex: "subtitle",
    //   key: "subtitle",
    // },
    {
      title: "Created date",
      key: "created_date_time",
      render: (record) => {
        return moment.utc(record?.created_date_time).format("DD/MM/YYYY");
      },
    },
    {
      title: t("status"),
      key: "status",
      render: (record) => {
        return startCase(record?.status);
      },
    },
  ];
  return (
    <div>
      {/* <UserProfileTitle
        type={"Fact check"}
        title={""}
        className={is_dark_mode ? "content-dark" : ""}
      />
      <CustomText
        class_name={"user_profile_titles"}
        text={"Fact Check"}
        font_family={"Lato"}
        size={"24px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      /> */}
      {/* <HStack justifyContent={"flex-end"}>
        <div style={{ width: "10%" }}>
          <Button
            style={{ backgroundColor: "#0065cc", color: "white" }}
            onClick={on_click}
          >
            Back
          </Button>
        </div>
      </HStack> */}
      <div className="my_fact_check_table">
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={all_fact_check_list}
          pagination={false}
          loading={all_fact_check_loading}
          onRow={(record) => ({
            onClick: () => handle_row_click(record),
          })}
        />
        <HStack justifyContent={"flex-end"} mt={"20px"}>
          <Pagination
            total={all_fact_check_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </HStack>
      </div>
    </div>
  );
};

export default UserFactCheckList;
