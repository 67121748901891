import { gql } from "@apollo/client";
export const query_get_article_preview = gql`
  query get_article($id: String!) {
    get_article(id: $id) {
      id
      title
      subtitle
      perma_link
      language_id
      content
      content_json
      reading_time
      views
      thumbnail_img
      party_tags {
        id
        name
      }
      created_date_time
      status
      published_date_time
      category_tags {
        id
        name
        language_id
      }
      staff_profile {
        id
        name
        profile_pic
      }
      user_profile {
        id
        name
        profile_pic
      }
    }
  }
`;
