import React from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "@src/services/redux";
import {
  FAKE_NEWS,
  FALSE_NEWS,
  MISLEADING_NEWS,
  MIX_NEWS,
  SARCASM_NEWS,
} from "@src/helpers/image_constants";
import PreviewJoinWhatsappChannel from "../../article_preview/article_preview/preview_join_whatsapp_channel";
import { TRUE_NEWS } from "../../../../helpers/image_constants";

const PreviewSingleFactCheckClaim = () => {
  const { t } = useTranslation();
  const { is_fact, language_id } = useDynamicSelector("get_fact_check");

  const image_source_map = {
    true: TRUE_NEWS,
    false: FALSE_NEWS,
    fake: FAKE_NEWS,
    mix: MIX_NEWS,
    sarcasm: SARCASM_NEWS,
    misleading: MISLEADING_NEWS,
  };
  const image_source = image_source_map[is_fact] || null;

  return (
    <div className="single_fact_check_claim_container">
      <div className="single_fact_claim_title">
        <h5>
          {language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
            ? "மதிப்பீடு"
            : "Rating"}{" "}
        </h5>
      </div>

      <div className="single_fact_check_rating_container">
        <img
          visible={false}
          preview={false}
          src={image_source}
          className="youTurn_rating_image"
        />
      </div>

      <div className="single_fact_check_whats_app_container">
        <PreviewJoinWhatsappChannel />
      </div>
    </div>
  );
};

export default PreviewSingleFactCheckClaim;
