import { gql } from "@apollo/client";

export const introspection_query = gql`
  query IntrospectionQuery {
    schema: __schema {
      types {
        name
        fields {
          name
        }
      }
    }
  }
`;

export const get_system_ui_module_list_query = gql`
query get_ui_modules {
  get_ui_modules {
    items {
      id
      name
      is_deleted
      ui_module_components {
        id
        name
        api_modules {
          id
          name
        }
        roles {
          id
          name
        }
      }
      roles {
        id
        name
      }
      error {
        status_code
        message
      }
    }
  }
}

`;

export const create_system_ui_module_page = gql`
mutation create_ui_module($data:ui_module_input){
  create_ui_module (data:$data){
    id
    status
    error {
      message
    }
  }
}
`;

export const update_system_ui_module_page = gql`
mutation update_ui_module(
  $id: String!
  $data: ui_module_input
) {
  update_ui_module(id: $id, data: $data) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const delete_system_ui_module_page = gql`
mutation delete_ui_module($id: String!) {
  delete_ui_module(id: $id) {
    id
    status
    error {
      status_code
      message
    }
  }
}

`;

export const create_system_ui_module_component_mutation = gql`
mutation create_ui_module_component($data: ui_module_component_input) {
  create_ui_module_component(data: $data) {
    id
    status
    error {
      message
    }
  }
}`;

export const update_system_ui_module_component_mutation = gql`
mutation update_ui_module_component ($id:String!,$data:ui_module_component_update_input){
  update_ui_module_component(id:$id,data:$data) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;
export const update_link_end_point_system_ui_module_component_mutation = gql`
mutation update_ui_module_component(
  $id: String!
  $data: ui_module_component_update_input
) {
  update_link_end_point: update_ui_module_component(
    id: $id
    data: $data
  ) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const delete_system_ui_module_component_mutation = gql`
mutation delete_ui_module_component($id:String!){
  delete_ui_module_component(id:$id){
    id
    status
    error{
      message
    }
  }
}


`;

export const get_system_role_list_query = gql`
query get_roles {
  get_roles {
    items {
      id
      name
      is_deleted
      home_ui_module {
        id
        name
        roles {
          id
          name
        }
      }
      ui_modules {
        id
        name
        roles {
          id
          name
        }
      }
      ui_module_components {
        id
        name
        roles {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
}

`;

export const system_ui_module_role_access = gql`
  mutation set_system_ui_module_role_access(
    $custom: set_system_ui_module_role_access_input!
  ) {
    set_system_ui_module_role_access(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const system_ui_module_component_role_access = gql`
  mutation set_system_ui_module_component_role_access(
    $custom: set_system_ui_module_component_role_access_input!
  ) {
    set_system_ui_module_component_role_access(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_set_system_module_list_query = gql`
  query get_system_api_module_list(
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_system_api_module_list(sort: $sort, pagination: $pagination) {
      items {
        id
        name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const sync_system_api_modules_mutation = gql`
mutation sync_api_modules($api_names:[String]) {
  sync_endpoints:sync_api_modules (api_names:$api_names){
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const create_role_mutation=gql`
mutation create_role($data:role_input){
  create_role(data:$data){
    id
    status
    error{
      message
    }
  }
}`;

export const delete_role_mutation=gql`
mutation delete_role($id:String!){
  delete_role(id:$id){
    id
    status
    error{
      message
    }
  }
}`;

export const update_role_mutation=gql`
mutation update_role($id: String!, $data: role_input) {
  update_role(id: $id, data: $data) {
    id
    status
    error {
      message
    }
  }
}
`;

export const set_ui_module_role_access_mutation=gql`
mutation set_ui_module_role_access(
  $id: String
  $role_id: String
  $has_access: Boolean
) {
  set_ui_module_role_access(
    id: $id
    role_id: $role_id
    has_access: $has_access
  ) {
    id
    status
    error {
      message
    }
  }
}
`

export const set_ui_module_component_role_access_mutation=gql`
mutation set_ui_module_component_role_access(
  $id: String
  $role_id: String
  $has_access: Boolean
) {
  set_ui_module_component_role_access(
    id: $id
    role_id: $role_id
    has_access: $has_access
  ) {
    id
    status
    error {
      message
    }
  }
}
`;

export const get_api_modules_query=gql`
query get_api_modules($page_number:Float,$page_limit:Float){
  endpoints:get_api_modules(page_number:$page_number,page_limit:$page_limit){
    items{
      id
      name
      is_deleted
      error{
        message
      }
    }
  }
}
`;