import React, { useEffect, useRef } from "react";

import JoinUsCard from "../components/widgets/join_us_card";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  get_all_fact_check_first_half,
  get_trending_fact_checks,
  query_get_all_party_tag,
} from "../../services/redux/slices/graphql";
import { dynamic_request } from "../../services/redux/slices/dynamic_request";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../services/redux";
import DonationCardSmall from "../components/widgets/donation_card_small";
import SocialMediaList from "../components/widgets/social_media_list";
import { debounce } from "lodash";
import { retrieveItem } from "../../helpers/functions";
import TrendingStoriesList from "../components/party_wise/party_wise_content";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { useTranslation } from "react-i18next";
import FactCheckFirstHalfContent from "../components/fact_check/fact_check_first_half/fact_check_first_half_content";
import FactCheckCategoriesRow from "../components/fact_check/fact_check_categories_main_row/fact_check_categories_and_media_share";
import PartyWiseMainRow from "../components/fact_check/fact_check_party_wise_main_row/party_wise_main_row";

const FactCheck = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { is_dark_mode } = useDarkMode();
  const top_ref = useRef(null);
  const { pathname } = useLocation();
  let current_language = retrieveItem("language");

  const { items: trending_items } = useDynamicSelector(
    "get_trending_fact_checks"
  );
  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 200);
  useEffect(() => {
    debounced_handle_title_click();
  }, []);

  const get_trending_fact_checks_query = () => {
    let key = [{ key: "get_trending_fact_checks", loading: true }];
    let query = get_trending_fact_checks;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_fact_check_fist_half = () => {
    let key = [{ key: "get_all_fact_check_first_half", loading: true }];
    let query = get_all_fact_check_first_half;
    let variables = {
      page_number: 1,
      page_limit: 1,
      json: {
        language_id: current_language?.id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    get_trending_fact_checks_query();
    get_all_fact_check_fist_half();
    get_all_party_list();
  }, []);

  const get_all_party_list = () => {
    let key = [{ key: "get_all_party_tag", loading: true }];
    let query = query_get_all_party_tag;
    let variables = {
      language_id: current_language?.id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    debounced_handle_title_click();
  }, [pathname]);

  return (
    <div
      style={{ overflowX: "hidden" }}
      ref={top_ref}
      className={`${is_dark_mode ? "content-dark" : ""}`}
    >
      <div className="fact-check-fourth-div"> </div>
      <FactCheckFirstHalfContent />

      <div className="fact_check_donation_small_card_mobile_view">
        <DonationCardSmall />
      </div>
      <FactCheckCategoriesRow />

      <div className="social_media_list">
        <SocialMediaList width={"100%"} />
      </div>
      <TrendingStoriesList
        list={trending_items}
        name={t("trending_stories")}
        type={"fact-check"}
      />
      <PartyWiseMainRow />
      <div className="fact-check-join-us-card">
        <JoinUsCard />
      </div>
    </div>
  );
};

export default FactCheck;
