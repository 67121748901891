import React, { useEffect, useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
import { useDispatch } from "react-redux";
import { IeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { dynamicSet, useDynamicSelector } from "@services/redux";
import PageComponentRoleCheckBox from "./page_component_role_check_box";
const PageComponentItem = (props) => {
  const dispatch = useDispatch();
  const { items: roles } = useDynamicSelector("get_roles");
  const [on_hover, setOnHover] = useState(false);
  const { item, index } = props;

  const openLinkEndpoint = () => {
    dispatch(dynamicSet("page_component_item", item));
    dispatch(dynamicSet("page_component_action", "Link Endpoint To"));
  };

  const openEdit = () => {
    dispatch(dynamicSet("page_component_item", item));
    dispatch(dynamicSet("page_component_action", "Edit"));
  };

  const openDelete = () => {
    dispatch(dynamicSet("page_component_item", item));
    dispatch(dynamicSet("page_component_action", "Delete"));
  };

  return (
    <HStack>
      <Center
        key={index}
        position="sticky"
        left="0px"
        w="200px"
        h="40px"
        bg="#f4f0f0"
        zIndex={"2"}
        borderBottomWidth="1px"
        borderLeftWidth="1px"
        borderRightWidth={"1px"}
        borderBottomColor="black"
        borderLeftColor="black"
        borderRightColor="black"
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <HStack>
          <Box w="20px" bg="red"></Box>
          <Text w="180px" color="black">
            {item.name}
          </Text>
        </HStack>
        {on_hover && (
          <HStack space={3} position="absolute" top="3px" right="3px">
            <IeOutlined
              style={{ cursor: "pointer", color: "#0002a0" }}
              onClick={openLinkEndpoint}
            />
            <EditOutlined
              style={{ cursor: "pointer", color: "blue" }}
              onClick={openEdit}
            />
            <DeleteOutlined
              style={{ cursor: "pointer", color: "Red" }}
              onClick={openDelete}
            />
          </HStack>
        )}
      </Center>
      {roles &&
        roles.map((role, role_index) => (
          <Center
            key={role_index}
            w="150px"
            h="40px"
            borderTopWidth="0px"
            borderBottomWidth="1px"
            borderRightWidth={"1px"}
            borderTopColor="black"
            borderBottomColor="black"
          >
            <PageComponentRoleCheckBox page_component={item} role={role} />
          </Center>
        ))}
    </HStack>
  );
};
export default PageComponentItem;
