import { HStack, VStack } from "native-base";
import React from "react";
import PageTitle from "../../components/system/page_title/page_title";
import AntiPartyTagList from "../../components/admin/anti_party_tags/anti_party_tag_list";

const AntiPartyTags = () => {
  return (
    <div>
      <HStack justifyContent={"flex-start"}>
        <PageTitle title={"Anti Party tags"} />
      </HStack>
      <VStack padding={"40px"}>
        <AntiPartyTagList />
      </VStack>
    </div>
  );
};

export default AntiPartyTags;
