import React, { useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import { ARROW_GIF_ICON } from "../../../helpers/image_constants";
import { Col, Row, Skeleton } from "antd";
import CustomLanguageText from "./custom_language_text";
import { capitalize } from "lodash";
import CustomText from "./custom_text";
import { format_view_count } from "../../../helpers/functions";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../contexts/dark_mode_provider";
import { box_shadow } from "../../../helpers/constants";
import { EditTwoTone } from "@ant-design/icons";
import { FaEdit, FaUserEdit } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

const CustomAdminSlotDetailsCard = (props) => {
  const { t } = useTranslation();
  const { is_dark_mode } = useDarkMode();

  const {
    id,
    reading_time,
    view_count,
    date,
    name,
    src,
    title,
    subtitle,
    min_height,
    margin_top,
    height,
    max_height,
    set_form_open,
    show_icon,
  } = props;

  const { items: get_slots_list, loading: slots_loading } =
    useDynamicSelector("admin_slot");

  const open_slot_form = () => {
    set_form_open(true);
    
  };

  return (
    <div
      onClick={open_slot_form}
      style={{
        minHeight: min_height ?? "36vh",
        maxHeight: max_height ?? "73vh",
        width: "20.5vw",
        boxShadow: box_shadow,
        backgroundColor: is_dark_mode ? "#2D2D2D" : "#FFFF",
        borderRadius: "1px 1px 10px 10px",
        overflow: "hidden",
        // height: height ?? "76vh",
        cursor: "pointer",
        flexWrap: "nowrap",
        position: "relative",
      }}
      className="admin_vertical_card_container"
    >
      {slots_loading ? (
        <Skeleton.Input style={{ width: "50vw", height: "30vh" }} active />
      ) : (
        <Row
          className="landing_page_vertical_card_image_row"
          style={{
            width: "auto",
            height: "auto",
            overflow: "hidden",
          }}
        >
          <Col className="landing_page_vertical_image_card image-container">
            {src && (
              <img
                className="landing_page_vertical_image image"
                style={{
                  width: "20.5vw",
                  transform: "scale(1)",
                  objectFit: "cover",
                }}
                src={src}
              />
            )}
            {show_icon && (
              <div className="middle">
                <div className="text">
                  <CiEdit color="white" size={"50px"} />
                </div>
              </div>
            )}
          </Col>
        </Row>
      )}

      {slots_loading ? (
        <>
          <Skeleton.Input className="skeleton_heading_card" active />
        </>
      ) : (
        <div className="custom_language_text_vertical">
          <CustomLanguageText
            line_height={"3.2vh"}
            text={title ? title : ""}
            weight={"700"}
            font_family={"Lato_Bold"}
            align={"left"}
            id={id}
            color={is_dark_mode ? "#ffffff" : "#161715"}
          />
        </div>
      )}

      {slots_loading ? (
        <div className="subtitle_skeleton">
          <Skeleton.Input className="subtitle_vertical_card" active />
          <Skeleton.Input className="subtitle_vertical_card" active />
          <Skeleton.Input className="subtitle_vertical_card" active />
          <Skeleton.Input className="subtitle_vertical_card" active />
        </div>
      ) : (
        <div>
          <div
            className="continue_reading_text"
            style={{ color: is_dark_mode ? "#ffffff" : "gray" }}
          >
            {subtitle?.length > 0 ? (
              <>
                <div
                  className={
                    min_height === "89vh"
                      ? "subtitle_text_user_contributed_stories"
                      : "subtitle_text"
                  }
                >
                  {subtitle}
                </div>
              </>
            ) : (
              <>
                {subtitle}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginTop: "1vh",
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
      <div className="name_and_date_vertical_card">
        {slots_loading ? (
          <div className="skelton_name_date">
            <Skeleton.Input size="small" active block={false} />
            <Skeleton.Input size="small" active block={false} />
          </div>
        ) : (
          <div style={{ display: "flex", gap: "3px" }}>
            <CustomText
              line_height={"3vh"}
              text={capitalize(name)}
              color={"#61666D"}
              weight={"400"}
              font_family={"Lato"}
              align={"left"}
              class_name={"landing-author-text"}
            />
            <span>|</span>

            <CustomText
              line_height={"3vh"}
              text={date}
              color={"#61666D"}
              weight={"400"}
              font_family={"Lato"}
              align={"left"}
              class_name={"landing-author-text"}
            />
          </div>
        )}
      </div>
      <div>
        {slots_loading ? (
          <div className="skelton_view_time">
            <Skeleton.Input size="small" active block={false} />
            <Skeleton.Input size="small" active block={false} />
          </div>
        ) : (
          <div style={{ display: "flex", padding: "2% 5% 0 5%", gap: "15px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.9vh",
              }}
            >
              <div
                style={{
                  color: "#E84756",
                  width: "2.2vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LuEye />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomText
                  line_height={"3vh"}
                  text={format_view_count(view_count)}
                  color={"#E84756"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                  class_name={"landing-author-text"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5vh",
              }}
            >
              <div
                style={{
                  color: "gray",
                  width: "2.2vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiFillClockCircle />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomText
                  text={`${reading_time} ${t("min")}`}
                  color={"#61666D"}
                  weight={"400"}
                  font_family={"Lato"}
                  align={"left"}
                  class_name={"landing-author-text"}
                />
              </div>
            </div>
            <span
              style={{
                marginTop: margin_top ? margin_top : "",
              }}
              className="continue_reading_vertical_card"
            >
              {t("Continue Reading")}

              <img width={30} height={30} src={ARROW_GIF_ICON} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomAdminSlotDetailsCard;
