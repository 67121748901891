import React, { useEffect, useState } from "react";
import CustomText from "../../custom/custom_text";
import { Empty, Row, Skeleton } from "antd";
import {
  change_tamil_font_style,
  retrieveItem,
  split_tamil_english_words,
} from "../../../../helpers/functions";
import PartyWiseTag from "../party_wise_tag";
import PartWiseListSelector from "../party_wise_list_selector";
import DonationCard from "../../widgets/donation_card";
import { useTranslation } from "react-i18next";
import {
  dynamic_request,
  get_fact_check_query_category_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import FactCheckVerticalAndSmallCard from "./fact_check_vertical_and_small_card";

const PartyWiseMainRow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let current_language = retrieveItem("language");
  const { items: fact_check_category_list } = useDynamicSelector(
    "get_fact_check_query_category"
  );

  const [category_name, set_category_name] = useState(t("all"));
  const [party_id, set_party_id] = useState();
  const [filtered_item_party_wise, set_filtered_item_party_wise] = useState([]);
  const [page_limit_party_wise, set_page_limit_party_wise] = useState(5);
  const custom_news_story_card_vertical = filtered_item_party_wise?.slice(0, 1);
  const custom_fact_check_rectangle_small_card =
    filtered_item_party_wise?.slice(
      1,

      5 + page_limit_party_wise
    );
  useEffect(() => {
    if (party_id !== "More") {
      get_fact_check_party_wise(party_id);
    }
  }, [party_id, page_limit_party_wise]);

  useEffect(() => {
    if (fact_check_category_list && fact_check_category_list.length > 0) {
      set_filtered_item_party_wise([...fact_check_category_list]);
    } else {
      set_filtered_item_party_wise([]);
    }
  }, [fact_check_category_list]);

  const get_fact_check_party_wise = (party_id) => {
    let key = [{ key: "get_fact_check_query_category", loading: true }];
    let query = get_fact_check_query_category_list;
    let variables = {
      page_number: 1,
      page_limit: page_limit_party_wise,
      json: {
        language_id: current_language?.id,
        party_tag: party_id === "All" || party_id === "More" ? null : party_id,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };
  const see_more_click = () => {
    set_page_limit_party_wise(page_limit_party_wise + 4);
  };

  return (
    <Row className="party-wise-update-fist-row">
      <div className="party-wise-update-fist-div">
        <div className="party-wise-update-title">
          <CustomText
            text={change_tamil_font_style(t("party_wise_updates"), "3vh", _)}
            font_family={"kufam"}
            size={"23px"}
            weight={"700"}
            align={"start"}
            line_height={"40px"}
          />
          <div className="fact_check_categories_list">
            <PartyWiseTag
              set_party_id={set_party_id}
              set_category_name={set_category_name}
            />
          </div>
          <div className="fact_check_party_wise_selector">
            <PartWiseListSelector
              set_party_id={set_party_id}
              set_category_name={set_category_name}
            />
          </div>
        </div>

        <FactCheckVerticalAndSmallCard
          custom_news_story_card_vertical={custom_news_story_card_vertical}
          custom_fact_check_rectangle_small_card={
            custom_fact_check_rectangle_small_card
          }
          see_more_click={see_more_click}
        />
      </div>

      <div className="donation-card-div">
        <DonationCard height={"89vh"} />
      </div>
    </Row>
  );
};

export default PartyWiseMainRow;
