import React from "react";
import { useTranslation } from "react-i18next";
import AntdDynamicForm from "../../system/ui/antd_form/antd_form";

const UserContributionSpreadNews = (props) => {
  const { color } = props;
  const { t } = useTranslation();
  return (
    <AntdDynamicForm.RichText
      label={
        <span style={{ color: color ? color : "black" }}>{"Spread News"}</span>
      }
      field="spread_news"
      is_disabled={true}
    />
  );
};
export default UserContributionSpreadNews;
