import React, { useEffect, useState } from "react";
import { HStack, VStack } from "native-base";
import { Button, Col, Form, Modal, Row, Select, Spin, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamic_clear,
  dynamic_request,
  get_all_languages_query,
  mutation_update_slots,
  query_get_all_articles,
  query_get_all_fact_check,
  query_get_all_slots,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  extractTextFromPTags,
  isEnglish,
  retrieveItem,
  split_tamil_english_words,
} from "../../../../helpers/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomAdminSlotCard from "../../custom/custom_admin_slot_card";
import moment from "moment";
import CustomAdminSlotDetailsCard from "../../custom/custom_admin_slot_details_card";

const LandingPageForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const current_language_id = retrieveItem("language_id");

  const [slot_values, set_slot_values] = useState([]);
  const [form_open, set_form_open] = useState(false);
  const [initial_values, set_initial_values] = useState({});
  const [language_id, set_language] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );
  const [selected_slot, set_selected_slot] = useState(null);
  const [slot_items, set_slot_items] = useState({});
  const [ref_id, set_ref_id] = useState(null);
  const [search_string, set_search_string] = useState("");
  const [debounced_search_string, set_debounced_search_string] = useState("");

  const is_english = (text) => isEnglish(text);

  const record_type = [
    {
      title: "Article",
      type: "articles",
    },
    {
      title: "Fact Check",
      type: "fact_check",
    },
  ];

  const { items: fact_check_list, loading: fact_check_loading } =
    useDynamicSelector("get_fact_check_list");
  const { items: article_list, loading: article_loading } =
    useDynamicSelector("get_all_articles");
  const { items: get_slots_list } = useDynamicSelector("admin_slot");
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_slot");

  const { items: language_list } = useDynamicSelector(
    "get_all_system_languages"
  );

  const slot_record_type = Form.useWatch("type", form);

  useEffect(() => {
    get_slots(language_id);
    get_all_article(language_id);
    get_all_fact_check(language_id);
    get_languages();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (search_string) {
        set_debounced_search_string(search_string);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search_string]);

  useEffect(() => {
    if (debounced_search_string) {
      get_all_article(language_id);
      get_all_fact_check(language_id);
    }
  }, [debounced_search_string]);

  useEffect(() => {
    if (search_string) {
      if (slot_record_type === "fact_check") {
        get_all_fact_check(language_id);
        set_search_string("");
      } else if (slot_record_type === "articles") {
        get_all_article(language_id);
        set_search_string("");
      }
    }
  }, [search_string, slot_record_type]);

  useEffect(() => {
    if (slot_items) {
      let data = slot_items;
      let form_data = {
        type: data.article_id ? "articles" : "fact_check",
        language_id: data.language_id,
      };
      form_data[`slot`] = data.fact_check_id || data.article_id;
      console.log("form_data", form_data, slot_items);
      if (slot_items?.language_id === data?.language_id) {
        form.setFieldsValue(form_data);
        set_initial_values(form_data);
      }
    } else {
      form.setFieldValue("slot", null);
    }
  }, [slot_items]);
  console.log("data", slot_items);

  useEffect(() => {
    if (
      get_slots_list?.length &&
      fact_check_list?.length &&
      article_list?.length &&
      ref_id
    ) {
      let data1 = get_slots_list[0];
      let data2 = get_slots_list[1];
      let data3 = get_slots_list[2];
      let values = [];
      if (ref_id == 1) {
        if (data1.article_id) {
          values = article_list
            .filter(
              (x) => x?.id !== data2.article_id || x.id !== data3.article_id
            )
            .filter((x) => x?.id);
        } else
          values = fact_check_list
            .filter(
              (x) =>
                x?.id !== data2.fact_check_id || x.id !== data3.fact_check_id
            )
            .filter((x) => x?.id);
      } else if (ref_id == 2) {
        if (data2.article_id) {
          values = article_list
            .filter(
              (x) => x?.id !== data1.article_id || x.id !== data3.article_id
            )
            .filter((x) => x?.id);
        } else
          values = fact_check_list.filter(
            (x) =>
              x?.id !== data1.fact_check_id || x?.id !== data3.fact_check_id
          );
      } else {
        if (data3.article_id) {
          values = article_list
            .filter(
              (x) => x?.id !== data2.article_id || x?.id !== data1.article_id
            )
            .filter((x) => x?.id);
        } else
          values = fact_check_list
            .filter(
              (x) =>
                x?.id !== data2.fact_check_id || x?.id !== data1.fact_check_id
            )
            .filter((x) => x?.id);
      }
      set_slot_values(values);
    }
  }, [selected_slot, get_slots_list, fact_check_list, article_list]);

  useEffect(() => {
    if (create_status === "Success") {
      set_form_open(false);
      message.success("Slot created successfully");
      dispatch(dynamic_clear("create_slot"));
      get_slots(language_id);
    } else if (create_status === "Failure") {
      set_form_open(false);
      message.error("something went wrong");
      dispatch(dynamic_clear("create_slot"));
    } else if (create_error) {
      set_form_open(false);
      message.error("something went wrong");
      dispatch(dynamic_clear("create_slot"));
    }
  }, [create_status, create_error]);

  const on_value_change = (val, value) => {
    debugger;
    form.setFieldValue("slot", null);
    if (
      value?.language_id === slot_items?.language_id &&
      value.type === "fact_check" &&
      slot_items?.fact_check_id
    ) {
      form.setFieldValue("slot", value?.slot || slot_items?.fact_check_id);
      on_change(value.type);
    } else if (
      value?.language_id === slot_items?.language_id &&
      value?.type === "articles" &&
      slot_items?.article_id
    ) {
      form.setFieldValue("slot", value?.slot || slot_items?.article_id);
      on_change(value.type);
    } else {
      on_change(value.type);
      form.setFieldValue("slot", null);
    }
  };
  console.log("slot_values", slot_values);

  const handle_submit = (values) => {
    let data = {};
    data.ref_id = Number(ref_id);
    if (values.type === "fact_check") {
      data.fact_check_id = values.slot;
      data.language_id = language_id;
    } else {
      data.article_id = values.slot;
      data.language_id = language_id;
    }
    create_slots(data);
  };

  const handle_card_click = (slot_item, slot, ref) => {
    set_selected_slot(slot);
    set_slot_items(slot_item);
    set_ref_id(ref);
    set_form_open(true);
    form.setFieldsValue(initial_values);
    get_all_fact_check(language_id);
    get_all_article(language_id);
  };

  const get_all_fact_check = (language_id) => {
    let key = [{ key: "get_fact_check_list", loading: true }];
    let query = query_get_all_fact_check;
    let variables = {
      page_limit: 40,
      json: {
        language_id: language_id || current_language_id?.id,
        search_string: search_string,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_article = (language_id) => {
    let key = [{ key: "get_all_articles", loading: true }];
    let query = query_get_all_articles;
    let variables = {
      page_limit: 40,
      json: {
        language_id: language_id || current_language_id?.id,
        search_string: search_string,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_slots = (language_id) => {
    let key = [{ key: "admin_slot", loading: true }];
    let query = query_get_all_slots;
    let variables = {
      language_id: language_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const create_slots = (json) => {
    let key = [{ key: "create_slot", loading: true }];
    let query = mutation_update_slots;
    let variables = {
      json,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const on_change = (value) => {
    set_search_string("");
    if (
      get_slots_list?.length &&
      fact_check_list?.length &&
      article_list?.length &&
      ref_id
    ) {
      let data1 = get_slots_list[0];
      let data2 = get_slots_list[1];
      let data3 = get_slots_list[2];

      let values = [];
      if (ref_id == 1) {
        if (value === "articles") {
          values = article_list
            .filter(
              (x) => x?.id !== data2.article_id || x?.id !== data3.article_id
            )
            .filter((x) => x?.id);
        } else
          values = fact_check_list
            .filter(
              (x) =>
                x?.id !== data2.fact_check_id || x?.id !== data3.fact_check_id
            )
            .filter((x) => x?.id);
      } else if (ref_id == 2) {
        if (value === "articles") {
          values = article_list
            .filter(
              (x) => x?.id !== data1.article_id || x?.id !== data3.article_id
            )
            .filter((x) => x?.id);
        } else
          values = fact_check_list
            .filter(
              (x) =>
                x?.id !== data1.fact_check_id || x?.id !== data3.fact_check_id
            )
            .filter((x) => x?.id);
      } else {
        if (value === "articles") {
          values = article_list
            .filter(
              (x) => x?.id !== data2.article_id || x?.id !== data1.article_id
            )
            .filter((x) => x?.id);
        } else
          values = fact_check_list
            .filter(
              (x) =>
                x?.id !== data2.fact_check_id || x?.id !== data1.fact_check_id
            )
            .filter((x) => x?.id);
      }
      set_slot_values(values);
      console.log("valuevaluevaluevalue", value, values);
    }
  };

  const on_cancel = () => {
    set_form_open(false);
  };

  const on_change_language = (values) => {
    set_language(values);
    get_slots(values);
    get_all_article(values);
    get_all_fact_check(values);
  };
  return (
    <div>
      <VStack padding={"30px"}>
        <HStack style={{ display: "flex", gap: "25px" }}>
          {get_slots_list?.map((slot_item, index) =>
            slot_item ? (
              <div
                key={index}
                onClick={() =>
                  handle_card_click(slot_item, `Slot ${index + 1}`, index + 1)
                }
                className="custom_admin_slot_details_card_div"
              >
                <CustomAdminSlotDetailsCard
                  show_icon={true}
                  key={index}
                  set_form_open={set_form_open}
                  reading_time={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.reading_time
                      : slot_item?.article?.reading_time
                  }
                  view_count={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.views
                      : slot_item?.article?.views
                  }
                  date={moment(
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.created_date_time
                      : slot_item?.article?.created_date_time
                  ).format("MMM DD, YYYY")}
                  title={
                    is_english(
                      slot_item?.fact_check_id
                        ? slot_item?.fact_check?.title
                        : slot_item?.article?.title
                    )
                      ? split_tamil_english_words(
                          slot_item?.fact_check_id
                            ? slot_item?.fact_check?.title
                            : slot_item?.article?.title,
                          _,
                          500
                        )
                      : split_tamil_english_words(
                          slot_item?.fact_check_id
                            ? slot_item?.fact_check?.title
                            : slot_item?.article?.title,
                          _,
                          500
                        )
                  }
                  subtitle={`${split_tamil_english_words(
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.subtitle
                      : slot_item?.article?.subtitle,
                    "_",
                    500
                  )}${split_tamil_english_words(
                    slot_item?.fact_check_id
                      ? extractTextFromPTags(slot_item?.fact_check?.content)
                      : extractTextFromPTags(slot_item?.article?.content),
                    "_",
                    500
                  )}`}
                  name={
                    slot_item?.fact_check_id
                      ? slot_item.fact_check?.staff_profile?.name
                      : slot_item?.article?.staff_profile?.name ||
                        "YouTurn Editorial"
                  }
                  src={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.thumbnail_img
                      : slot_item?.article?.thumbnail_img
                  }
                  id={slot_item?.id}
                  perma_link={
                    slot_item?.fact_check_id
                      ? slot_item?.fact_check?.perma_link
                      : slot_item?.article?.perma_link
                  }
                />
              </div>
            ) : (
              <CustomAdminSlotCard key={index} />
            )
          )}
        </HStack>
        <Modal open={form_open} onCancel={on_cancel} footer={null}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handle_submit}
            onValuesChange={on_value_change}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="language_id" label="Language">
                  <Select onChange={on_change_language}>
                    {language_list?.map((option, index) => (
                      <Select.Option key={option?.id} value={option?.id}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="type" label="Type">
                  <Select>
                    {record_type?.map((option, index) => (
                      <Select.Option key={option?.title} value={option?.type}>
                        {option?.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="slot"
              label={selected_slot}
              rules={[
                {
                  required: false,
                  message: `${selected_slot} is required`,
                },
              ]}
            >
              <Select
                placeholder={selected_slot}
                allowClear
                onSearch={(value) => set_search_string(value)}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                loading={fact_check_loading || article_loading}
              >
                {slot_values?.map((option, index) => (
                  <Select.Option key={index} value={option?.id}>
                    {option?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <HStack justifyContent={"flex-end"} space={"10px"}>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: "green",
                  color: "white",
                }}
                loading={create_loading}
              >
                Update
              </Button>
            </HStack>
          </Form>
        </Modal>
      </VStack>
    </div>
  );
};

export default LandingPageForm;
