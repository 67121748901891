import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import VForm from "../../system/ui/antd_form/antd_form";
import { HStack } from "native-base";
import {
  mutation_create_user,
  mutation_update_user,
} from "../../../../services/redux/slices/graphql/graphql_user";
import {
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const DonationDetails = ({
  onCancel,
  form,
  action_type,
  get_all_list,
  form_values,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading: create_loading } = useDynamicSelector("create_user");
  const { loading: update_loading } = useDynamicSelector("update_user");

  const handle_submit = (value) => {
    if (action_type?.includes("add")) {
      create_one_category(value);
    } else {
      update_one_category(value);
    }
  };
  const create_one_category = (json) => {
    let key = [{ key: "create_user", loading: true }];
    let query = mutation_create_user;
    let variables = { json };
    dispatch(dynamic_request(key, query, variables));
  };

  const update_one_category = (json) => {
    let key = [{ key: "update_user", loading: true }];
    let query = mutation_update_user;
    let variables = { id: form_values?.id, json };
    dispatch(dynamic_request(key, query, variables));
  };

  return (
    <div>
      <Form onFinish={handle_submit} form={form} layout="vertical">
        <VForm.TextBox
          label={t("form:name")}
          field="name"
          rules={[
            {
              required: true,
              message: t("error:name_is_required"),
            },
          ]}
        />
        <VForm.TextBox
          label={t("form:email")}
          field="email_id"
          rules={[
            {
              required: true,
              message: t("error:email_is_required"),
            },
          ]}
        />
        <VForm.TextBox
          label={t("form:mobile_number")}
          field="mobile"
          rules={[
            {
              required: true,
              message: t("error:mobile_is_required"),
            },
          ]}
        />
        <VForm.CheckBox label={t("form:is_active")} field="is_active" />
        <HStack justifyContent={"flex-end"} space={"10px"}>
          <Button
            htmlType="submit"
            loading={update_loading || create_loading}
            type="primary"
          >
            {t("submit")}
          </Button>
          <Button danger onClick={onCancel}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default DonationDetails;
