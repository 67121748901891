import React from "react";
import PreviewSingleFactCheckClaim from "./preview_single_fact_check_claim";
import AdminFactCheckPreviewContent from "../admin_fact_check_preveiw_content";

const PreviewSingleFactCheckDetails = () => {
  return (
    <div>
      <div className="single_fact_check_claim_content_container">
        <div className="single_fact_check_claim_content_wrapper">
          <PreviewSingleFactCheckClaim />
          <AdminFactCheckPreviewContent />
        </div>
      </div>
    </div>
  );
};

export default PreviewSingleFactCheckDetails;
