import React, { useEffect, useRef, useState } from "react";
import { Form, Row } from "antd";
import DonationCard from "../components/widgets/donation_card";
import JoinUsCard from "../components/widgets/join_us_card";
import SingleArticleTopContent from "../components/single_article_page/single_article_top_content";
import {
  dynamic_clear,
  dynamic_request,
  mutation_add_article_book_mark,
  mutation_delete_article_book_mark,
  mutation_report_article,
  query_get_article_by_perma_link,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { debounce } from "lodash";
import { retrieveItem } from "../../helpers/functions";
import toast from "react-hot-toast";
import { useDarkMode } from "../components/contexts/dark_mode_provider";
import { ROUTES } from "../routes/my_routes";
import CustomJoinWhatsappChannel from "../components/custom/custom_join_whatsapp_channel";
import SingleArticleContent from "../components/single_article_page/single_article/single_article_content";
import RelatedArticleAndRecentlyAdded from "../components/single_article_page/related_article_and_recently_added";

const SingleArticlePage = () => {
  const dispatch = useDispatch();
  const { perma_link } = useParams();
  const [form] = Form.useForm();
  let current_language = retrieveItem("language");
  const session_id = retrieveItem("session_id");
  const { pathname } = useLocation();
  const [model_open, set_model_open] = useState(false);
  const history = useHistory();
  const { is_dark_mode } = useDarkMode();
  const top_ref = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const role = retrieveItem("role");

  const {
    id,
    title,
    is_saved: book_mark_save,
    error: get_all_article_error,
  } = useDynamicSelector("get_article_by_perma_link");

  const { status: save_article_status, error: save_article_error } =
    useDynamicSelector("add_article_to_saved_items");

  const { status: delete_article_status, error: delete_article_error } =
    useDynamicSelector("remove_article_from_saved_items");

  const { status: create_report_status, error: create_report_error } =
    useDynamicSelector("create_report_article");

  useEffect(() => {
    if (get_all_article_error) {
      dispatch(dynamic_clear("get_article_by_perma_link"));
      history.push(ROUTES.PAGE_NOT_FOUND);
    }
  }, [get_all_article_error, pathname]);

  useEffect(() => {
    debounced_handle_title_click();
  }, [pathname]);

  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  useEffect(() => {
    if (save_article_status === "Success") {
      get_one_article_by_perma_link();
      toast.success("Bookmark saved");
      dispatch(dynamic_clear("add_article_to_saved_items"));
    }
  }, [save_article_status]);

  useEffect(() => {
    if (delete_article_status === "Success") {
      get_one_article_by_perma_link();
      toast.success("Bookmark removed");
      dispatch(dynamic_clear("remove_article_from_saved_items"));
    }
  }, [delete_article_status]);

  useEffect(() => {
    if (create_report_status === "Success") {
      toast.success("Report submitted");
      set_model_open(false);
      form.resetFields("");
      dispatch(dynamic_clear("create_report_article"));
    } else if (create_report_error) {
      toast.error(create_report_error?.message);
    }
    dispatch(dynamic_clear("create_report_article"));
  }, [create_report_status, create_report_error]);

  useEffect(() => {
    get_one_article_by_perma_link();
  }, [perma_link]);

  const get_one_article_by_perma_link = () => {
    let key = [{ key: "get_article_by_perma_link", loading: true }];
    let query = query_get_article_by_perma_link;
    let variables = {
      language_id: current_language.id,
      perma_link,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const add_article_in_book_mark = () => {
    let key = [{ key: "add_article_to_saved_items", loading: true }];
    let query = mutation_add_article_book_mark;
    let variables = {
      article_id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const delete_article_in_book_mark = () => {
    let key = [{ key: "remove_article_from_saved_items", loading: true }];
    let query = mutation_delete_article_book_mark;
    let variables = {
      article_id: id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const report = (values) => {
    let key = [{ key: "create_report_article", loading: true }];
    let query = mutation_report_article;
    let variables = {
      json: {
        article_id: id,
        reason_id: values?.reason,
        description: values?.description,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_save = () => {
    if (session_id && (role === "User" || role === "Previleged User")) {
      if (book_mark_save) {
        delete_article_in_book_mark();
      } else if (book_mark_save === false) {
        add_article_in_book_mark();
      }
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "bookmark");
      queryParams.set("types", "article");
      queryParams.set("id", id);
      queryParams.set("redirecturl", window.location.pathname);
      history.push({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 800);

  return (
    <div ref={top_ref} className={is_dark_mode ? "content-dark" : ""}>
      <SingleArticleTopContent
        handle_save={handle_save}
        is_saved={book_mark_save}
        report={report}
        set_model_open={set_model_open}
        model_open={model_open}
        form={form}
      />
      <SingleArticleContent />
      <div className="single_article_whatsapp_channel">
        <CustomJoinWhatsappChannel />
      </div>
      <Row className="donation_card_small_view">
        <DonationCard />
      </Row>
      <div className="single_article_related_fact_check_card">
        <RelatedArticleAndRecentlyAdded />
      </div>
      <Row className="single_article_join_us_card">
        <JoinUsCard />
      </Row>
    </div>
  );
};
export default SingleArticlePage;
