import { Button, Form, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  delete_comments_query,
  get_all_child_comments_query,
} from "../../../../services/redux/slices/graphql/graphql_comments";
import {
  dynamic_clear,
  dynamic_request,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { HStack, Pressable } from "native-base";
import { toast } from "react-toastify";
import CommentsForm from "./comments_form";
import PageTitle from "../../system/page_title/page_title";
import { navigateBack } from "../../../../helpers/navigator";

const CommentsDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const parent_comment_id = location?.props_state?.record?.id;
  const [form] = Form.useForm();

  const [delete_id, set_delete_id] = useState("");
  const [delete_modal, set_delete_modal] = useState(false);
  const [mutation_modal, set_mutation_modal] = useState(false);

  const { items: child_comments_list, loading: child_comments_loading } =
    useDynamicSelector("get_child_comments");
  const {
    status: delete_child_comment_status,
    loading: delete_user_comments_loading,
    error: delete_child_comment_error,
  } = useDynamicSelector("delete_comment");

  useEffect(() => {
    get_all_child_comments();
  }, []);

  useEffect(() => {
    if (delete_child_comment_status === "Success") {
      delete_modal_close();
      toast.success("Comment deleted successfully");
      get_all_child_comments();
      dispatch(dynamic_clear("delete_comment"));
    } else if (delete_child_comment_error) {
      delete_modal_close();
      toast.error(delete_child_comment_error?.message);
      dispatch(dynamic_clear("delete_comment"));
    }
  }, [delete_child_comment_status, delete_child_comment_error]);

  const handle_delete = (record) => {
    set_delete_id(record?.id);
    set_delete_modal(true);
  };

  const delete_modal_close = () => {
    set_delete_modal(false);
  };

  const handle_edit_comment = (record) => {
    set_mutation_modal(true);
  };

  const edit_modal_close = () => {
    set_mutation_modal(false);
  };

  const action_items = (record) => {
    return (
      <HStack justifyContent={"space-between"} alignItems={"center"}>
        <Pressable
          onPress={() => {
            handle_edit_comment(record);
          }}
        >
          <AiOutlineEdit size={20} />
        </Pressable>
        <Pressable
          onPress={() => {
            handle_delete(record);
          }}
        >
          <AiOutlineDelete size={20} />
        </Pressable>
      </HStack>
    );
  };

  let columns = [
    {
      title: "Content",
      key: "content",
      render: (record) => record?.content,
    },
    {
      title: "Comment by",
      key: "comment_by",
      render: (record) => record?.commented_by?.username,
    },
    {
      title: t("table:action"),
      key: "action",
      width: "100px",
      render: (record) => action_items(record),
    },
  ];

  const get_all_child_comments = () => {
    let key = [{ key: "get_child_comments", loading: true }];
    let query = get_all_child_comments_query;
    let variables = {
      parent_comment_id: parent_comment_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const delete_user_comments = () => {
    let key = [{ key: "delete_comment", loading: true }];
    let query = delete_comments_query;
    let variables = {
      id: delete_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const go_back = () => {
    navigateBack();
  };

  return (
    <div style={{ padding: "40px" }}>
      <div>
        <PageTitle title={"Reply Comments"} />
        <HStack justifyContent={"flex-end"}>
          <Button onClick={go_back} style={{ backgroundColor: "#d3e3fd" }}>
            Back
          </Button>
        </HStack>
      </div>

      <Table
        columns={columns}
        dataSource={child_comments_list}
        loading={child_comments_loading}
      />
      <Modal
        open={mutation_modal}
        title={"Update Comment"}
        onCancel={edit_modal_close}
        footer={null}
      >
        <CommentsForm form={form} />
      </Modal>
      <Modal
        title={"Delete comment"}
        open={delete_modal}
        onCancel={delete_modal_close}
        footer={null}
      >
        <span>Are you sure to delete this comment ?</span>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"10px"}>
          <Button onClick={delete_modal_close} danger>
            Close
          </Button>
          <Button
            onClick={delete_user_comments}
            type="primary"
            loading={delete_user_comments_loading}
          >
            Ok
          </Button>
        </HStack>
      </Modal>
    </div>
  );
};
export default CommentsDetails;
