// PageNotFound.js

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../routes/my_routes";

const PageNotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRedirect = () => {
    history.push(ROUTES.LANDING_PAGE);
  };

  return (
    <div className="page-not-found-container">
      <div className="page-not-found-content">
        <h1 className="page-not-found-title">{t("not_found")}</h1>
        <p className="page-not-found-message">{t("Oops_the_page")}</p>
        <p className="page-not-found-message">{t("check_url")}</p>
        <button className="page-not-found-button" onClick={handleRedirect}>
          {t("got_to_home")}
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
