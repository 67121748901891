import React, { useEffect, useRef } from "react";
import UserProfileCard from "../components/user_profile/user_profile_card";
import UserProfileTitle from "../components/user_profile/user_profile_title";
import { query_get_dash_board_count } from "../../services/redux/slices/graphql/graphql_user_profile";
import { dynamic_request } from "../../services/redux";
import { retrieveItem } from "../../helpers/functions";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useDarkMode } from "../components/contexts/dark_mode_provider";

const UserProfile = () => {
  const session_id = retrieveItem("session_id");
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  const dispatch = useDispatch();

  const get_all_dash_board_count = () => {
    let key = [{ key: "get_dashboard_count", loading: true }];
    let query = query_get_dash_board_count;
    dispatch(dynamic_request(key, query));
  };

  useEffect(() => {
    if (session_id) {
      get_all_dash_board_count();
    }
  }, [session_id]);

  useEffect(() => {
    debounced_handle_title_click();
  }, []);

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 200);
  return (
    <div ref={top_ref} className={is_dark_mode ? "content-dark" : ""}>
      <UserProfileTitle />
      <div className="user_profile_card_div">
        <UserProfileCard />
      </div>
    </div>
  );
};

export default UserProfile;
